/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum VehicleEngineType {
    ELECTRIC = 'electric',
    PETROL = 'petrol',
    DIESEL = 'diesel',
    CNG = 'cng',
}
