import auth from './auth.json';
import booking from './booking.json';
import common from './common.json';
import order from './order.json';
import product from './product.json';
import team from './team.json';

export default {
    auth,
    booking,
    common,
    order,
    product,
    team,
};
