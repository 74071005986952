/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetAssetsResItemOwnerContactDetailsDto } from './GetAssetsResItemOwnerContactDetailsDto';
import type { Language } from './Language';
import type { PersonNameData } from './PersonNameData';

export type GetBookingsResItem0ProductCustomersItemReferenceDto = {
    /**
     * Schedule option which corresponds to the booked date
     */
    type: GetBookingsResItem0ProductCustomersItemReferenceDto.type;
    /**
     * Type of the capacity of the product
     */
    id: string;
    /**
     * Profile's (person, company) contact details.
     */
    contact_details?: GetAssetsResItemOwnerContactDetailsDto;
    /**
     * Person's name, separated into components.
     */
    name: PersonNameData;
    /**
     * List of languages the traveler speaks/understands.
     */
    languages: Array<Language>;
};

export namespace GetBookingsResItem0ProductCustomersItemReferenceDto {

    /**
     * Schedule option which corresponds to the booked date
     */
    export enum type {
        PERSON = 'person',
    }


}
