/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FileType {
    IMAGE = 'image',
    PDF = 'pdf',
}
