/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetBookingResProductCustomersItemReferenceDto } from './GetBookingResProductCustomersItemReferenceDto';
import type { GetOrderResAccessDto } from './GetOrderResAccessDto';
import type { GetOrderResBookingsItem0Dto } from './GetOrderResBookingsItem0Dto';
import type { GetOrderResBookingsItem1Dto } from './GetOrderResBookingsItem1Dto';
import type { GetOrderResPriceDto } from './GetOrderResPriceDto';
import type { GetUserResCreatedDto } from './GetUserResCreatedDto';
import type { OrderStatus } from './OrderStatus';

export type GetOrderResDto = {
    /**
     * Type of the resource
     */
    resource_type: GetOrderResDto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetUserResCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetUserResCreatedDto;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetUserResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<GetUserResCreatedDto>;
    /**
     * Order number which will be displayed to the customer
     */
    number: string;
    /**
     * Information about the customer who booked the event.
     */
    customer: GetBookingResProductCustomersItemReferenceDto;
    /**
     * Price information.
     */
    price?: GetOrderResPriceDto;
    /**
     * Status of the order
     */
    status: OrderStatus;
    /**
     * List of traveler preferences
     */
    bookings?: Array<(GetOrderResBookingsItem0Dto | GetOrderResBookingsItem1Dto)>;
    /**
     * Access to the shared product
     */
    access: GetOrderResAccessDto;
};

export namespace GetOrderResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        ORDER = 'order',
    }


}
