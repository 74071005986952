import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { formatValue, PaxPicker } from '@travelity/form';
import { useFormContext } from 'react-hook-form';
import { CardListContainer, LoadingOverlay } from '@travelity/ui';
import {
    Booking,
    Customer,
    getCustomerDtoToCustomer,
    PaxData,
    RouteStop,
    Traveler,
    useUpdatePax,
} from '@travelity/api';
import {
    GetProductResRouteDto,
    GetCustomer1ResDto,
} from '@travelity/api/src/requests';
import { CustomerSearchModal } from '../customer-search-modal';
import BookingTravelerCard from './components/booking-traveler-card';

export interface BookingParticipantsFormProps {
    preview?: boolean;
    eventId: string;
    bookingId: string;
    booking: Booking;
    siblingBookings: Booking[];
    route: GetProductResRouteDto;
    customer?: GetCustomer1ResDto;
    travelers: Traveler[];
    pax: PaxData;
}

const BookingParticipantsForm: React.FC<BookingParticipantsFormProps> = ({
    preview,
    eventId,
    bookingId,
    booking,
    siblingBookings,
    route,
    customer,
    travelers,
    pax,
}) => {
    // const { t } = useTranslation();
    const { watch, setValue } = useFormContext();

    const [tmpItem, setTmpItem] = useState<Partial<Traveler>>();

    const travelerRef = useRef(travelers);
    if (!tmpItem) {
        travelerRef.current = travelers;
    }
    const travelerList = travelerRef.current;

    const stops = useMemo(() => {
        const list: RouteStop[] = [
            {
                pinned: true,
                name: route.beginning_of_route.name,
                time: new Date(booking.date.start * 1000),
            },
        ];

        route.stops?.forEach(stop => {
            list.push({
                pinned: !!stop.pinned,
                name: stop.location.name,
                time: stop.date?.start
                    ? new Date(stop.date.start * 1000)
                    : new Date(),
                id: stop.id,
            });
        });

        list.push({
            pinned: true,
            name: route.end_of_route.name || route.beginning_of_route.name,
            time: new Date(
                booking.date.end
                    ? booking.date.end * 1000
                    : (booking.date.start + 3600) * 1000
            ),
        });
        return list;
    }, [route, travelers]);

    const travelersLeft = useMemo(() => {
        const totalPax = Object.values(pax as PaxData).reduce(
            (s: number, c) => s + (c || 0),
            0
        );
        const current =
            travelerList.reduce(
                (s: number, c: Traveler) => s + 1 + (c.guestCount || 0),
                0
            ) || 0;
        return totalPax > current ? totalPax - current : 0;
    }, [travelers, pax]);

    const customerObj = useMemo(() => {
        return customer ? getCustomerDtoToCustomer(customer) : undefined;
    }, [customer]);

    // Handle customer selection
    const [customerModal, setCustomerModal] = useState(false);

    const onAdd = useCallback(() => {
        setCustomerModal(true);
    }, []);

    const handleCustomerSelect = useCallback(
        (newItem: Partial<Customer>) => {
            setTmpItem({
                ...newItem,
                customerId: newItem.id,
                guestCount: travelersLeft - 1,
            });
            setCustomerModal(false);
        },
        [customerModal]
    );

    const { mutate: updatePax, isLoading: isUpdatingPax } = useUpdatePax({
        onSuccess: () => {},
        onError: () => {
            setValue('pax', pax);
        },
    });

    const onPaxClose = useCallback(() => {
        if (!formatValue(watch('pax'))) {
            setValue('pax', pax);
        } else if (formatValue(pax) !== formatValue(watch('pax'))) {
            updatePax({
                bookingId,
                eventId,
                pax: watch('pax'),
            });
        }
    }, [pax, bookingId]);

    return (
        <Stack spacing={2}>
            <PaxPicker
                name="pax"
                withDropdown
                selectProps={{
                    label: 'PAX',
                    placeholder: 'Select PAX',
                    onClose: onPaxClose,
                }}
            />
            <CardListContainer
                items={travelerList.length + (tmpItem ? 1 : 0)}
                disabled={preview}
                addButtonText="Add Traveler"
                disableAdding={travelersLeft === 0 || !!tmpItem}
                maxHeight={600}
                onAdd={onAdd}
            >
                {travelerList.map((traveler: Traveler) => (
                    <BookingTravelerCard
                        key={traveler.travelerId}
                        traveler={traveler}
                        bookingId={bookingId}
                        siblingBookings={siblingBookings}
                        stops={stops}
                        travelerCap={travelersLeft}
                    />
                ))}
                {tmpItem && (
                    <BookingTravelerCard
                        traveler={tmpItem}
                        bookingId={bookingId}
                        siblingBookings={siblingBookings}
                        stops={stops}
                        updateTmp={tmp => setTmpItem(tmp)}
                        travelerCap={travelersLeft}
                    />
                )}
            </CardListContainer>
            {isUpdatingPax && <LoadingOverlay />}
            <CustomerSearchModal
                handleCancel={() => setCustomerModal(false)}
                handleConfirm={handleCustomerSelect}
                open={customerModal}
                item={
                    !travelerList.length && customerObj
                        ? customerObj
                        : undefined
                }
            />
        </Stack>
    );
};

export default React.memo(BookingParticipantsForm);
