/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum EventStaffAssignmentActivity {
    STAFF_ASSIGNMENT = 'staff_assignment',
}
