import { Box, Stack } from '@mui/material';
import React from 'react';

export interface SquareCardProps {
    title?: string;
    value?: string;
    Icon?: React.ElementType;
    count?: number;
}

export const SquareCard: React.FC<SquareCardProps> = ({
    title,
    value,
    count = 3,
    Icon,
}) => {
    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
                borderRadius: '8px',
                background: '#F4F6FA',
                p: 1,
                minWidth: 1 / count,
                minHeight: 1 / count,
            }}
        >
            {Icon && <Icon sx={{ color: '#B2B9CD' }} />}
            <Box
                sx={{ color: '#B2B9CD', fontSize: '12px', textAlign: 'center' }}
            >
                {title}
            </Box>
            <Box
                sx={{
                    color: '#2B395B',
                    fontWeight: 300,
                    fontSize: '14px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                    overflow: 'hidden',
                }}
            >
                {value}
            </Box>
        </Stack>
    );
};
