/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateProductReqAccessDto } from './CreateProductReqAccessDto';
import type { CreateProductReqCapacityDto } from './CreateProductReqCapacityDto';
import type { CreateProductReqConfigurationDto } from './CreateProductReqConfigurationDto';
import type { CreateProductReqOptionsDto } from './CreateProductReqOptionsDto';
import type { CreateProductReqPricingDto } from './CreateProductReqPricingDto';
import type { CreateProductReqRouteDto } from './CreateProductReqRouteDto';
import type { CreateProductReqScheduleDto } from './CreateProductReqScheduleDto';
import type { ProductTag } from './ProductTag';

export type CreateProductReq1Dto = {
    /**
     * Name of the product.
     */
    name: string;
    /**
     * Detailed textual description of the product.
     */
    description: string;
    /**
     * Tags that characterize the product
     */
    tags?: Array<ProductTag>;
    /**
     * Type of the product
     */
    type: CreateProductReq1Dto.type;
    /**
     * Indicates whether the product is active or not
     */
    active?: boolean;
    /**
     * Defines the number and groups of people that can be served by a unit of product
     */
    configuration?: CreateProductReqConfigurationDto;
    /**
     * Defines the number and groups of people that can be served by a unit of product
     */
    capacity: CreateProductReqCapacityDto;
    /**
     * Defines the recurrent and non-recurrent items of the schedule
     */
    schedule: CreateProductReqScheduleDto;
    /**
     * Financial information of the product.
     */
    pricing?: CreateProductReqPricingDto;
    /**
     * Optional items and services for this product
     */
    options?: CreateProductReqOptionsDto;
    /**
     * Access to the shared product
     */
    access?: CreateProductReqAccessDto;
    /**
     * Route of the tour/transfer
     */
    route: CreateProductReqRouteDto;
};

export namespace CreateProductReq1Dto {

    /**
     * Type of the product
     */
    export enum type {
        TRANSFER = 'transfer',
    }


}
