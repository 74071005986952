import { createTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

declare module '@mui/material/styles' {
    interface Palette {
        neutral: Palette['primary'];
        light: Palette['primary'];
    }

    interface PaletteOptions {
        neutral: PaletteOptions['primary'];
        light: PaletteOptions['primary'];
    }
}

// @babel-ignore-comment-in-output Update the Button's color prop options
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
}

// @babel-ignore-comment-in-output Update the Chip's color prop options
declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        light: true;
    }
}

const theme = () => {
    // TODO do theme building here
    return createTheme({
        shape: {
            borderRadius: 32,
        },
        typography: {
            fontFamily: ['Lexend'].join(','),
        },
        palette: {
            primary: {
                main: '#55B5CF',
                contrastText: '#FFFFFF',
            },
            secondary: {
                main: '#36869C',
                contrastText: '#FFFFFF',
            },
            text: {
                primary: '#565D6D',
            },
            error: {
                main: '#EF5E6F',
            },
            success: {
                main: '#37C16E',
            },
            warning: {
                main: '#F8D627',
                contrastText: '#2B395B',
            },
            neutral: {
                main: '#F4F6FA',
                contrastText: '#2B395B',
            },
            light: {
                main: '#e8ebf7',
                dark: '#ced6e2',
                contrastText: '#2B395B',
            },
        },
        components: {
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        background: '#FFF',
                        borderRadius: 0,
                        '&:before': {
                            backgroundColor: 'transparent',
                        },
                        '&.Mui-disabled': {
                            backgroundColor: 'transparent',
                        },
                    },
                },
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: {
                        padding: '0 16px',
                        minHeight: '34px',
                    },
                    content: {
                        margin: '10px 0',
                        color: '#949BAC',
                        fontSize: '14px',
                        lineHeight: '1',
                        alignItems: 'center',
                        '&.Mui-expanded': {
                            marginTop: '16px',
                            marginBottom: '16px',
                            fontSize: '16px',
                            fontWeight: 600,
                            color: '#36869C',
                        },
                    },
                    expandIconWrapper: {
                        color: '#949BAC',
                    },
                },
                defaultProps: {
                    expandIcon: <ExpandMoreIcon />,
                },
            },
            MuiAlert: {},
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                        color: '#949BAC',
                        lineHeight: '12px',
                    },
                },
            },
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        borderRadius: '12px',
                        padding: '14px',
                        color: '#55B5CF',
                        borderColor: '#55B5CF',

                        '&.Mui-disabled': {
                            opacity: 0.5,
                        },
                        '&.Mui-selected': {
                            color: '#FFF',
                            backgroundColor: '#36869C',
                            borderColor: '#36869C',
                            '&:hover': {
                                color: '#FFF',
                                backgroundColor: '#36869C',
                                borderColor: '#36869C',
                            },
                        },
                    },
                },
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'rgba(255, 255, 255, 0.6)',
                    },
                },
            },
            MuiTooltip: {
                defaultProps: {
                    arrow: true,
                    enterDelay: 1000,
                    enterNextDelay: 1000,
                },
                styleOverrides: {
                    tooltip: {
                        padding: 12,
                        borderRadius: '27px',
                        border: '1px solid #B1B8CB',
                        color: '#FFF',
                        fontWeight: 400,
                        background: '#B1B8CB',
                        opacity: 1,
                        boxShadow: 'none',
                        lineHeight: '120%',
                        fontSize: '12px',
                    },
                    arrow: {
                        color: '#B1B8CB',
                        opacity: 1,
                    },
                },
            },
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true,
                },
                styleOverrides: {
                    root: {
                        '&&&:active': {
                            boxShadow: 'none',
                        },
                        '&&&:hover': {
                            boxShadow: 'none',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    outlinedPrimary: {
                        '&:hover': {
                            backgroundColor: '#55B5CF',
                            color: '#FFFFFF',
                        },
                    },
                    sizeSmall: {
                        padding: '4px 8px',
                        fontSize: '12px',
                        lineHeight: '12px',
                    },
                    sizeLarge: {
                        padding: '12px 36px',
                        fontSize: '16px',
                        lineHeight: '16px',
                        textTransform: 'initial',
                    },
                    root: {
                        boxShadow: 'none',
                        textTransform: 'initial',
                        borderRadius: '24px',
                        '&.Mui-disabled': {
                            backgroundColor: '#F4F6FA',
                            color: '#B2B9CD',
                        },
                    },
                },
                defaultProps: {
                    disableRipple: true,
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        height: 'auto',
                        padding: '10px',
                        border: 0,
                    },
                    outlinedPrimary: {
                        background: '#FFFFFF',
                        boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.6)',
                        borderRadius: '12px',
                        color: '#9198AA',
                        border: 0,
                        // padding: '4px',
                    },
                    sizeSmall: {
                        padding: '4px',
                        '& .MuiChip-label': {
                            padding: 0,
                        },
                    },
                    label: {
                        overflow: 'unset',
                        fontSize: '12px',
                        lineHeight: '12px',
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '&.Mui-disabled': {
                            opacity: 0.5,
                            pointerEvents: 'none',
                        },
                        '&:not(.Mui-focused):hover .MuiOutlinedInput-notchedOutline':
                            {
                                border: '1px solid #DFE1ED',
                            },
                        '&&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #EF5E6F',
                        },
                        fontSize: '12px',
                        lineHeight: '12px',
                    },
                    input: {
                        padding: '19px 24px',
                        '&::placeholder': {
                            color: '#949BAC',
                        },
                    },
                    notchedOutline: {
                        borderRadius: 32,
                        border: '1px solid #DFE1ED',
                        '& legend': {
                            marginLeft: '10px',
                            fontSize: '12px',
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    adornedEnd: {
                        '&&': {
                            paddingRight: '24px',
                        },
                    },
                    input: {
                        // padding: '19px 24px',
                        height: 'auto',
                    },
                    inputSizeSmall: {
                        '&&&': { padding: '12px 18px' },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    outlined: {
                        '&.Mui-disabled': {
                            opacity: 0.5,
                        },
                        paddingLeft: '13px',
                        color: '#949BAC',
                        fontSize: '1rem',
                        lineHeight: '1.4375rem',
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    root: {
                        padding: '5px 3px',
                    },
                    track: {
                        opacity: 1,
                        borderRadius: '16px',
                        backgroundColor: '#B2B9CD',
                        '&:before, &:after': {
                            content: '""',
                            position: 'absolute',
                            top: '19px',
                            transform: 'translateY(-50%)',
                            width: 16,
                            height: 16,
                        },
                        '&:before': {
                            left: '10px',
                            content: '"Yes"',
                            color: 'transparent',
                            fontSize: '11px',
                        },
                        '&:after': {
                            right: '11px',
                            content: '"No"',
                            color: '#FFF',
                            fontSize: '11px',
                        },
                    },
                    switchBase: {
                        '&:hover': {
                            backgroundColor: 'initial',
                        },
                        '&.Mui-checked + .MuiSwitch-track': {
                            opacity: 1,
                            '&:before': {
                                color: '#FFF',
                            },
                            '&:after': {
                                color: 'transparent',
                            },
                        },
                    },
                    thumb: {
                        backgroundColor: '#FFFFFF',
                        boxShadow: 'none',
                        width: 16,
                        height: 16,
                        margin: 2,
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    outlined: {
                        minHeight: '14px',
                    },
                    iconOutlined: {
                        color: '#B2B9CD',
                        marginRight: '10px',
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        '&::before': {
                            width: '0',
                        },
                        '&::after': {
                            width: '100%',
                        },
                    },
                    wrapper: {
                        paddingLeft: 0,
                        fontSize: '14px',
                        color: ' #2B395B',
                    },
                },
            },
            MuiStepConnector: {
                styleOverrides: {
                    root: {
                        paddingLeft: '19px',
                    },
                    line: {
                        borderLeft: '1.5px solid #DFE1ED',
                    },
                },
            },
            MuiStepLabel: {
                styleOverrides: {
                    root: {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                    label: {
                        color: '#67728B',
                        fontWeight: 400,
                        '&.Mui-active': { color: '#55B5CF', fontWeight: 500 },
                        '&.completed': {
                            color: '#55B5CF',
                        },
                    },
                    iconContainer: {
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        borderLeft: '4px solid transparent',
                        '&.completed': {
                            '& .MuiStepIcon-text': {
                                fill: '#55B5CF',
                            },
                            '& .MuiStepIcon-root': {
                                color: '#DDF0F5',
                            },
                        },
                        '&.Mui-active': {
                            borderLeft: '4px solid #55B5CF',
                        },
                    },
                },
            },
            MuiStepIcon: {
                styleOverrides: {
                    root: {
                        color: '#DFE1ED',
                        '&.Mui-active': {
                            color: '#55B5CF',
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#F6F6FB',
                        borderRadius: '12px',
                    },
                },
                defaultProps: {
                    elevation: 0,
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        minWidth: '50px',
                    },
                },
            },
            MuiPopover: {
                styleOverrides: {
                    paper: {
                        background: '#FFFFFF',
                        border: '1px solid #DFE1ED',
                        boxShadow: '0px 0px 10px rgba(178, 185, 205, 0.4)',
                        borderRadius: '12px',
                        marginTop: '2px',
                    },
                },
            },
        },
    });
};

export default theme;
