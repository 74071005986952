import { array, object } from 'yup';

const schema = object({
    items: array()
        .of(object())
        .notInEdit('Finish editing of a stop')
        .test('Default pricing', function defaultPricingRequired(value) {
            if (
                !(
                    !value ||
                    !value.length ||
                    !value.find(item => !item.capacity && !item.schedule)
                )
            )
                return true;
            return this.createError({
                message: 'Default pricing',
            });
        })
        .test(
            'Multiple default pricing',
            function defaultPricingRequired(value) {
                if (
                    value.filter(item => !item.capacity && !item.schedule)
                        .length < 2
                )
                    return true;
                return this.createError({
                    message: 'Multiple default pricing',
                });
            }
        ),
    paymentMethods: array().min(1, 'Payment method is required'),
});

export default schema;
