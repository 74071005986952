/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DirectSource {
    WALK_IN = 'walk_in',
    EMAIL = 'email',
    PHONE_CALL = 'phone_call',
    WEBSITE = 'website',
    FACEBOOK_MESSENGER = 'facebook_messenger',
    WHATSAPP = 'whatsapp',
    INSTAGRAM = 'instagram',
    LIVE_CHAT = 'live_chat',
    TELEGRAM = 'telegram',
}
