import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';

export interface CheckboxProps {
    name: string;
    label?: string;
    defaultValue?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
    defaultValue = false,
    name,
    label,
    ...rest
}) => {
    const { control } = useFormContext();

    return (
        <Controller
            render={({
                field: { value, ...field },
            }: {
                field: Record<string, any>;
            }) => (
                <FormControlLabel
                    sx={{
                        color: '#959CAD',
                        '& > span': { fontSize: '12px' },
                        justifyContent: 'space-between',
                        margin: 0,
                    }}
                    labelPlacement="start"
                    control={
                        <MuiCheckbox
                            sx={{
                                p: 1,
                                '&&&': { color: value ? '#37C16E' : '#959CAD' },
                            }}
                            checked={value}
                            {...field}
                            {...rest}
                        />
                    }
                    label={label}
                />
            )}
            defaultValue={defaultValue}
            name={name}
            control={control}
        />
    );
};

export default Checkbox;
