import React, { useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Tooltip } from '@mui/material';
import { IconButton } from '@travelity/ui';

export interface AccordionButtonsProps {
    editing?: boolean;
    onEdit?: () => void;
    saving?: boolean;
    onEditCancel?: () => void;
    formId?: string;
    errors: Record<string, any>;
    isDone?: boolean;
}

const AccordionButtons: React.FC<AccordionButtonsProps> = ({
    editing,
    onEdit,
    saving,
    onEditCancel,
    formId,
    errors,
    isDone,
}) => {
    const onSaveClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const stringErrors = useMemo(() => {
        return Object.values(errors)
            .map(error => error?.message)
            .filter(v => v) as string[];
    }, [errors]);

    return editing ? (
        <>
            {isDone ? (
                <Box
                    sx={{
                        ml: 2,
                        my: 0.25,
                        textAlign: 'center',
                        lineHeight: '14px',
                        color: '#6B748C',
                        fontSize: '14px',
                        '&:hover': {
                            color: 'success.dark',
                        },
                        px: 1,
                        py: 1,
                        borderRadius: '12px',
                        background: '#FFF',
                        boxShadow: '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                        minWidth: 'auto',
                        '&&&&:hover': {
                            boxShadow:
                                '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                        },
                    }}
                    component="span"
                    onClick={onEditCancel}
                >
                    Done
                </Box>
            ) : stringErrors.length ? (
                <Tooltip
                    componentsProps={{
                        tooltip: {
                            sx: {
                                borderRadius: '8px',
                            },
                        },
                    }}
                    title={
                        <>
                            {stringErrors.map(error => (
                                <li>{error}</li>
                            ))}
                        </>
                    }
                >
                    <Button
                        type="submit"
                        onClick={onSaveClick}
                        form={formId}
                        sx={{
                            ml: 2,
                            padding: '4px',
                            background: '#FFF',
                            cursor: 'default',
                            minWidth: 'auto',
                            boxShadow:
                                '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                            borderRadius: '12px',
                            '&&&&:hover': {
                                boxShadow:
                                    '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                            },
                        }}
                    >
                        <CheckIcon
                            sx={{
                                fontSize: '20px',
                                lineHeight: '20px',
                                color: 'grey.400',
                            }}
                        />
                    </Button>
                </Tooltip>
            ) : (
                <IconButton
                    withShadow
                    sx={{ ml: 2 }}
                    icon={<CheckIcon fontSize="small" />}
                    color="success.main"
                    hoverColor="success.dark"
                    disabled={!!saving}
                    isSubmit
                    form={formId}
                    tooltip="Save"
                    tooltipPlacement="bottom"
                />
            )}
            {!isDone && (
                <IconButton
                    withShadow
                    sx={{ ml: 1 }}
                    icon={<CloseIcon fontSize="small" />}
                    color="error.main"
                    hoverColor="error.dark"
                    onClick={onEditCancel}
                    disabled={!!saving}
                    tooltip="Cancel"
                    tooltipPlacement="bottom"
                />
            )}
        </>
    ) : (
        <IconButton
            withShadow
            sx={{ ml: 2 }}
            icon={<EditIcon fontSize="small" />}
            onClick={onEdit}
            tooltip="Edit"
        />
    );
};

export default AccordionButtons;
