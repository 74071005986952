import React, { useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    PaxPicker,
    SearchSelect,
    SearchSelectOption,
    useForm,
} from '@travelity/form';
import { Stack } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import { AvailableProductOption } from '@travelity/api';

import schema from './option-modal.schema';

const formId = 'option-modal';

export interface OptionModalProps {
    options: SearchSelectOption[];
    handleCancel: () => void;
    handleConfirm: (v: AvailableProductOption) => void;
    open: boolean;
    item?: AvailableProductOption;
}

export const OptionModal: React.FC<OptionModalProps> = ({
    options,
    handleCancel,
    handleConfirm,
    open,
    item,
}) => {
    const navigate = useNavigate();

    const {
        Form,
        trigger,
        reset,
        formState: { errors },
    } = useForm<{ option: string } & Pick<AvailableProductOption, 'pax'>>({
        schema,
        isModal: true,
        mode: 'onChange',
        validateInitially: true,
        onSubmit: useCallback(
            (
                data: { option: string } & Pick<AvailableProductOption, 'pax'>
            ) => {
                const option = options.find(o => o.name === data.option);

                if (option) {
                    handleConfirm({
                        name: option.name,
                        type: option.type,
                        pax: data.pax,
                        hasPrice: !!option.hasPrice,
                        pricing: option.pricing,
                    });
                }
            },
            [navigate, handleConfirm]
        ),
    });

    useEffect(() => {
        if (open) {
            reset({ option: item?.name || '', pax: item?.pax || {} });
        }
    }, [open, reset, trigger, item]);

    const errorFields = Object.values(errors)
        .map(error => error?.message)
        .filter(v => v) as string[];

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            sx={{
                '& .MuiDialog-container': { justifyContent: 'end' },
            }}
            PaperProps={{ sx: { py: 1, bgcolor: '#fff', maxWidth: '388px' } }}
        >
            <DialogTitle
                sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    fontWeight: 600,
                    color: '#2B395B',
                }}
                id="alert-dialog-title"
            >
                Select Product Option
            </DialogTitle>
            <DialogContent
                sx={{
                    overflow: 'hidden',
                    pr: 1,
                    '& > div': {
                        pr: 2,
                        maxHeight: 'calc(100vh - 212px)',
                    },
                }}
            >
                <PerfectScrollbar>
                    <Form id={formId}>
                        <Stack
                            direction="column"
                            gap={2}
                            sx={{ pt: 2, width: '340px' }}
                        >
                            <SearchSelect
                                name="option"
                                label="Product Option"
                                placeholder="Select Product Option"
                                width="100%"
                                options={options}
                            />
                            <PaxPicker
                                name="pax"
                                withDropdown
                                selectProps={{
                                    label: 'PAX',
                                    width: '100%',
                                    placeholder: 'Select PAX',
                                }}
                            />
                        </Stack>
                    </Form>
                </PerfectScrollbar>
            </DialogContent>
            <DialogActions sx={{ px: 3, py: 2 }}>
                <Button
                    color="neutral"
                    variant="contained"
                    onClick={handleCancel}
                    sx={{
                        px: '26px',
                        py: '10px',
                    }}
                >
                    Discard
                </Button>

                <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    form={formId}
                    disabled={!!errorFields.length}
                    sx={{
                        py: '10px',
                        '&&&': { pointerEvents: 'initial' },
                    }}
                >
                    Add Option
                </Button>
            </DialogActions>
        </Dialog>
    );
};
