/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingStatus } from './BookingStatus';
import type { GetAssetsResItemUsageItemEventDto } from './GetAssetsResItemUsageItemEventDto';
import type { GetBookingsResItem0AccessDto } from './GetBookingsResItem0AccessDto';
import type { GetBookingsResItem0FinancialsDto } from './GetBookingsResItem0FinancialsDto';
import type { GetBookingsResItem0OrderDto } from './GetBookingsResItem0OrderDto';
import type { GetBookingsResItem0Source0Dto } from './GetBookingsResItem0Source0Dto';
import type { GetBookingsResItem0Source1Dto } from './GetBookingsResItem0Source1Dto';
import type { GetBookingsResItem0TransactionsDto } from './GetBookingsResItem0TransactionsDto';
import type { GetBookingsResItem1ProductDto } from './GetBookingsResItem1ProductDto';
import type { GetTeamsResItemCreatedDto } from './GetTeamsResItemCreatedDto';

export type GetBookingsResItem1Dto = {
    /**
     * Type of the resource
     */
    resource_type: GetBookingsResItem1Dto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetTeamsResItemCreatedDto;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetTeamsResItemCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle?: Array<GetTeamsResItemCreatedDto>;
    /**
     * Source of the booking
     */
    source: (GetBookingsResItem0Source0Dto | GetBookingsResItem0Source1Dto);
    /**
     * Order reference
     */
    product: GetBookingsResItem1ProductDto;
    /**
     * Booked event
     */
    event?: GetAssetsResItemUsageItemEventDto;
    /**
     * Order reference
     */
    order: GetBookingsResItem0OrderDto;
    /**
     * List of transactions
     */
    transactions?: GetBookingsResItem0TransactionsDto;
    /**
     * List of transactions
     */
    financials: GetBookingsResItem0FinancialsDto;
    /**
     * List of notes of some importance to the booking
     */
    notes?: Array<string>;
    /**
     * Automatically computed status of the booking.
     */
    status: BookingStatus;
    /**
     * Access to the shared product
     */
    access: GetBookingsResItem0AccessDto;
};

export namespace GetBookingsResItem1Dto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        BOOKING = 'booking',
    }


}
