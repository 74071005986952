import React, { useMemo } from 'react';
import { uniqBy } from 'lodash';
import { useGetTeams } from '../../queries';
import {
    memberToReferral,
    memberToStaff,
    teamDtoToTeam,
} from './team.converters';
import { Referral, Staff } from './team.types';

export const useTeams = () => {
    const { data, ...other } = useGetTeams({});

    return {
        data: data?.items?.map(teamDtoToTeam),
        ...other,
    };
};

type UseGetStaffParamTypes = Parameters<typeof useGetTeams>;
type Filter = UseGetStaffParamTypes[0];
export const useDebouncedStaff = (
    search: string,
    teamIds: string[],
    debounce: number = 1000
) => {
    const [debouncedParams, setDebouncedParams] = React.useState<
        Filter | undefined
    >();

    React.useEffect(() => {
        const handler = setTimeout(() => {
            const filter: Filter = {};

            if (search.match(/^[^@]+@[^@.]+\.[a-zA-Z]+$/))
                filter.memberEmail = search;
            else if (search.match(/ /)) {
                filter.memberName = search;
            }
            setDebouncedParams(
                Object.keys(filter).length
                    ? { teamId: teamIds, ...filter }
                    : undefined
            );
        }, debounce);

        return () => {
            clearTimeout(handler);
        };
    }, [search, debounce]);

    const { data, ...other } = useGetTeams(
        debouncedParams || {},
        [debouncedParams],
        {
            keepPreviousData: true,
            staleTime: debounce,
            enabled: !!debouncedParams,
        }
    );

    const parsedData = useMemo(
        () =>
            debouncedParams && data?.items
                ? uniqBy(
                      data.items.reduce(
                          (staff: Staff[], team) => [
                              ...staff,
                              ...team.members.map(member =>
                                  memberToStaff(member)
                              ),
                          ],
                          []
                      ),
                      'email'
                  )
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
        isLoading: other.isFetching,
    };
};

export const useDebouncedReferral = (
    search: string,
    teamIds: string[],
    debounce: number = 1000
) => {
    const [debouncedParams, setDebouncedParams] = React.useState<
        Filter | undefined
    >();

    React.useEffect(() => {
        const handler = setTimeout(() => {
            const filter: Filter = {};

            if (search.match(/^[^@]+@[^@.]+\.[a-zA-Z]+$/))
                filter.memberEmail = search;
            else if (search.match(/ /)) {
                filter.memberName = search;
                filter.company = search;
            } else if (search) {
                filter.memberName = search;
                filter.company = search;
            }
            setDebouncedParams(
                Object.keys(filter).length
                    ? { teamId: teamIds, ...filter }
                    : undefined
            );
        }, debounce);

        return () => {
            clearTimeout(handler);
        };
    }, [search, debounce]);

    const { data, ...other } = useGetTeams(
        debouncedParams || {},
        [debouncedParams],
        {
            keepPreviousData: true,
            staleTime: debounce,
            enabled: !!debouncedParams,
        }
    );

    const parsedData = useMemo(
        () =>
            debouncedParams && data?.items
                ? uniqBy(
                      data.items.reduce(
                          (staff: Referral[], team) => [
                              ...staff,
                              ...team.members.map(member =>
                                  memberToReferral(member)
                              ),
                          ],
                          []
                      ),
                      'email'
                  )
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
        isLoading: other.isFetching,
    };
};
