import React from 'react';
import { convertNameDtoToFullName, UserReferenceData } from '@travelity/api';
import { Box } from '@mui/material';

export interface UserProps {
    user: UserReferenceData;
}

export const User: React.FC<UserProps> = ({ user }) => {
    return <Box component="span">{convertNameDtoToFullName(user.name)}</Box>;
};
