/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum TourEventStaffMemberPosition {
    ADMINISTRATOR = 'administrator',
    GUIDE = 'guide',
    DRIVER = 'driver',
}
