import React, { memo, useState } from 'react';
import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    Select as MuiSelect,
    Stack,
    Typography,
} from '@mui/material';
import { NumberField } from '../number-field';
import { useController, useFormContext } from 'react-hook-form';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SelectProps as MuiSelectProps } from '@mui/material/Select/Select';
import { get } from 'lodash';

export interface PaxSelectProps extends MuiSelectProps {
    width?: string;
    size?: 'small' | 'medium';
    label: string;
    placeholder?: string;
    required?: boolean;
    onClose?: () => void;
}

export interface PaxPickerProps {
    name: string;
    withDropdown?: boolean;
    selectProps?: PaxSelectProps;
    showErrors?: boolean;
}

const ageBands = ['Adults', 'Children', 'Infants', 'Youth', 'Seniors'];

const toPlural = (name: string, value: number) => {
    if (value > 1) return `${value} ${name}`;
    if (name === 'children') return `1 child`;
    // TODO remove s
    return `1 ${name.substring(0, name.length - 1)}`;
};

export const formatValue = (value: Record<string, number>) =>
    ageBands
        .map(name => name.toLowerCase())
        .filter(name => value[name])
        .map(name => toPlural(name, value[name]))
        .join(' | ');

export const PaxPicker: React.FC<PaxPickerProps> = memo(props => {
    const {
        name,
        showErrors,
        withDropdown,
        selectProps = {} as PaxSelectProps,
    } = props;
    const {
        width,
        size,
        required,
        label,
        placeholder,
        disabled,
        multiple,
        onClose,
        ...rest
    } = selectProps;
    const {
        watch,
        control,
        formState: { errors },
    } = useFormContext();

    const { field } = useController({
        name,
        control,
        defaultValue: {},
    });
    const value = watch(name);
    const [open, setOpen] = useState(false);

    const error =
        showErrors && (get(errors, name)?.message as string | undefined);

    const fields = (
        <Stack gap={1}>
            {ageBands.map(ageBand => (
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    key={ageBand}
                >
                    <Typography
                        sx={{ width: 60, color: '#949BAC', fontSize: '12px' }}
                    >
                        {ageBand}
                    </Typography>
                    <NumberField
                        size="small"
                        sx={{ flexGrow: 2 }}
                        name={`${name}.${ageBand.toLowerCase()}`}
                        label=""
                    />
                </Stack>
            ))}
        </Stack>
    );

    return withDropdown ? (
        <FormControl
            sx={{ width: width || '388px' }}
            error={!!error}
            size={size}
            disabled={disabled}
        >
            <MuiSelect
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => {
                    field.onChange(value);
                    setOpen(false);
                    onClose?.();
                }}
                required={!!required}
                inputProps={{ required: false }}
                label={label}
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                sx={{
                    ...(selectProps.sx ? selectProps.sx : {}),
                    '&&& legend': placeholder
                        ? {
                              maxWidth: '100%',
                          }
                        : {},
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #DFE1ED',
                    },
                    '&& .MuiOutlinedInput-notchedOutline': {
                        ...(open && {
                            borderBottom: 'none',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        }),
                    },
                }}
                renderValue={() => {
                    const selectedValue =
                        value && Object.values(value).find(v => v);
                    if (!selectedValue && placeholder) {
                        return (
                            <Box
                                component="span"
                                sx={{
                                    color: '#949BAC',
                                }}
                            >
                                {placeholder}
                            </Box>
                        );
                    }

                    return formatValue(value);
                }}
                disabled={disabled}
                {...rest}
                value={Object.values(value).join(',')}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            boxShadow: 0,
                            border: '1px solid #DFE1ED',
                            borderTop: 0,
                            borderRadius: '32px',
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            marginTop: 0,
                            p: 2,
                            pt: 0,
                        },
                    },
                    MenuListProps: {
                        sx: {
                            borderTop: '#DFE1ED 1px solid',
                            py: 1,
                        },
                    },
                }}
            >
                {fields}
            </MuiSelect>
            <InputLabel
                required={!!required}
                shrink
                sx={{
                    '&.Mui-focused': {
                        color: '#949BAC',
                    },
                }}
            >
                {label}
            </InputLabel>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    ) : (
        fields
    );
});
