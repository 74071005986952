import React, { memo, useEffect, useMemo } from 'react';
import { Badge, Button, Stack, Typography } from '@mui/material';
import { useForm } from '@travelity/form';
import { useWatch } from 'react-hook-form';
import { FilterItem } from './components/filter-item';
import { FilterOption, FilterValue } from './filters.types';
import { FilterButton } from './components/filter-button';

export interface FiltersProps {
    values: Record<string, FilterValue> | {};
    setValues: (v: Record<string, FilterValue>) => void;
    options: FilterOption[];
}

export const Filters: React.FC<FiltersProps> = memo((props: FiltersProps) => {
    const { values: appliedValues, setValues: applyValues, options } = props;
    const { Form, reset, control, getValues } = useForm({
        defaultValues: appliedValues,
        mode: 'onChange',
    });

    useWatch({ control });

    const values = getValues();
    const changed = useMemo(() => {
        const appliedString = JSON.stringify(appliedValues);
        const valuesString = JSON.stringify(values);

        return appliedString !== valuesString;
    }, [appliedValues, values]);

    useEffect(() => {
        if (appliedValues) reset(appliedValues);
    }, [appliedValues, reset]);

    const valuesArray = Object.keys(values)
        .map(name => ({
            name,
            value: values[name],
        }))
        .filter(
            ({ value, name }) => value && options.find(o => name === o.name)
        );

    useEffect(() => {
        if (changed && !valuesArray.length) applyValues({});
    }, [changed, valuesArray, applyValues]);

    return (
        <Stack
            direction="row"
            justifyContent={!valuesArray.length ? 'left' : 'space-between'}
            gap={1}
            alignItems="center"
            sx={{
                py: 1,
                height: '56px',
            }}
        >
            {!valuesArray.length && (
                <Typography
                    sx={{
                        color: '#B2B9CD',
                        fontSize: '20px',
                        fontWeight: '700',
                    }}
                >
                    Configure Filtering
                </Typography>
            )}
            {!!valuesArray.length && (
                <Stack sx={{ flexGrow: 2, borderRight: '#DFE2EC 1px solid' }}>
                    <Form>
                        <Stack direction="row" gap={1}>
                            {valuesArray.map(filter => (
                                <FilterItem
                                    key={filter.name}
                                    value={filter.value}
                                    filter={
                                        options.find(
                                            ({ name }) => name === filter.name
                                        ) as FilterOption
                                    }
                                />
                            ))}
                        </Stack>
                    </Form>
                </Stack>
            )}
            {!!valuesArray.length &&
                (changed ? (
                    <Badge
                        color="warning"
                        variant="dot"
                        badgeContent=" "
                        sx={{ '.MuiBadge-badge': { top: '4px', right: '4px' } }}
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => applyValues(values)}
                        >
                            Filter
                        </Button>
                    </Badge>
                ) : (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => applyValues(values)}
                    >
                        Filter
                    </Button>
                ))}

            <FilterButton
                selectedFilters={values}
                updateSelectedFilters={reset}
                options={options}
            />
        </Stack>
    );
});
