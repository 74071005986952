import React from 'react';
import {
    Button as MuiButton,
    ButtonProps as MuiButtonProps,
    Theme,
    Tooltip,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import WarningIcon from '@mui/icons-material/Warning';

export interface ButtonProps extends MuiButtonProps {
    requiredFields?: string[];
    errorMessage?: string;
    doNotDisable?: boolean;
    onIconClick?: () => void;
}

export const Button: React.FC<ButtonProps> = props => {
    const {
        requiredFields,
        errorMessage,
        children,
        disabled,
        endIcon,
        doNotDisable,
        color,
        onIconClick,
        sx = {},
        ...rest
    } = props;

    const hasError = !!requiredFields?.length || !!errorMessage;
    const disableError = !doNotDisable && hasError;

    return (
        <MuiButton
            disabled={disableError || !!disabled}
            color={hasError && doNotDisable ? 'neutral' : color}
            sx={{
                '&': sx as SystemStyleObject<Theme>,
                '&&&': {
                    pointerEvents: 'initial',
                    cursor: hasError && doNotDisable ? 'default' : undefined,
                    color:
                        hasError && doNotDisable
                            ? 'rgb(178, 185, 205)'
                            : undefined,
                },
            }}
            endIcon={
                hasError ? (
                    <Tooltip
                        enterDelay={100}
                        enterNextDelay={0}
                        title={
                            requiredFields ? (
                                <>
                                    The following{' '}
                                    {requiredFields.length > 1
                                        ? 'fields are '
                                        : 'field is '}
                                    required:
                                    {requiredFields.map(v => (
                                        <li key={v}>{v}</li>
                                    ))}
                                </>
                            ) : (
                                errorMessage
                            )
                        }
                    >
                        <WarningIcon
                            onClick={onIconClick}
                            sx={{ color: 'error.main', cursor: 'pointer' }}
                        />
                    </Tooltip>
                ) : (
                    endIcon
                )
            }
            {...rest}
        >
            {children}
        </MuiButton>
    );
};
