/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FreeSubscriptionUserFeaturesData } from './FreeSubscriptionUserFeaturesData';

export type FreeSubscriptionData = {
    /**
     * Name of the subscription
     */
    name?: FreeSubscriptionData.name;
    /**
     * Free subscription user configuration
     */
    user?: FreeSubscriptionUserFeaturesData;
};

export namespace FreeSubscriptionData {

    /**
     * Name of the subscription
     */
    export enum name {
        FREE = 'free',
    }


}
