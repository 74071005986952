import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
    SelectProps as MuiSelectProps,
    Theme,
} from '@mui/material';
import _ from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SystemStyleObject } from '@mui/system';
import { useUserContext } from '@travelity/web/src/contexts/user';
import { useCurrencyOptions } from '@travelity/web/src/hooks';

export interface SelectCurrencyProps extends MuiSelectProps {
    width?: string;
    size?: 'small' | 'medium';
    name: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
    showErrors?: boolean;
    formControlSx?: SystemStyleObject<Theme>;
}

const SelectCurrency: React.FC<SelectCurrencyProps> = ({
    width,
    size,
    name,
    required,
    label = 'Currency',
    placeholder = 'Select Currency',
    showErrors,
    disabled,
    formControlSx = {},
    ...selectProps
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const { user } = useUserContext();
    const defaultValue = user ? user.currency : undefined;
    const disableCurrencySelection = user?.subscription
        ? !user.subscription.user?.financial?.multi_currency
        : false;
    const options = useCurrencyOptions();

    const error =
        showErrors && (_.get(errors, name)?.message as string | undefined);

    console.log(disableCurrencySelection);
    return (
        <FormControl
            sx={{ width: width || '388px', '&&': formControlSx }}
            error={!!error}
            size={size}
            disabled={disabled}
        >
            <Controller
                render={({ field }: { field: Record<string, any> }) => (
                    <MuiSelect
                        required={!!required}
                        inputProps={{ required: false }}
                        label={label}
                        displayEmpty
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{
                            ...(selectProps.sx ? selectProps.sx : {}),
                            '&&& legend': placeholder
                                ? {
                                      maxWidth: '100%',
                                  }
                                : {},
                        }}
                        renderValue={selected => {
                            if (!selected && placeholder) {
                                return (
                                    <Box
                                        component="span"
                                        sx={{
                                            color: '#949BAC',
                                        }}
                                    >
                                        {placeholder}
                                    </Box>
                                );
                            }

                            return options.find(
                                ({ value }) => value === selected
                            )?.label;
                        }}
                        disabled={disableCurrencySelection || disabled}
                        {...selectProps}
                        {...field}
                        value={field.value || ''}
                        MenuProps={{
                            PaperProps: {
                                sx: { maxHeight: 180, maxWidth: width },
                            },
                        }}
                    >
                        {options.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </MuiSelect>
                )}
                name={name}
                control={control}
                defaultValue={defaultValue}
            />
            <InputLabel required={!!required} shrink>
                {label}
            </InputLabel>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};

export default SelectCurrency;
