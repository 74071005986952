import React from 'react';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import TimelineDot from '@mui/lab/TimelineDot';

export interface RoutePickUpIconProps {
    selected: boolean;
}

export const RoutePickUpIcon: React.FC<RoutePickUpIconProps> = ({
    selected,
}) => {
    return (
        <TimelineDot
            color={selected ? 'grey' : 'primary'}
            sx={{
                p: '2px',
                mt: '4px',
                mb: '1.5px',
                boxShadow: 0,
                border: 0,
                color: selected ? 'grey' : 'primary.main',
                backgroundColor: 'transparent',
                padding: 0,
            }}
        >
            <FmdGoodOutlinedIcon sx={{ fontSize: '15px' }} />
        </TimelineDot>
    );
};
