import { IconButton } from '@travelity/ui';
import Check from '@mui/icons-material/Check';
import { Stack } from '@mui/material';
import Close from '@mui/icons-material/Close';
import React from 'react';
import { TextField, useForm, TimePicker } from '@travelity/form';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export interface CreateRouteInputProps {
    initialValue?: { name: string; time: Date };
    start: Date;
    end: Date;
    setValue: (obj?: { name: string; time: Date }) => void;
}

export const CreateRouteInput: React.FC<CreateRouteInputProps> = props => {
    const { start, end, setValue, initialValue } = props;
    const { Form, watch } = useForm({
        defaultValues: initialValue,
    });
    const [step, setStep] = React.useState(0);

    return (
        <Form>
            <Stack direction="row" sx={{ width: 1, mt: '-4px' }}>
                {step === 1 ? (
                    <>
                        <TimePicker
                            name="time"
                            label=""
                            minTime={start}
                            maxTime={end}
                            slotProps={{
                                popper: {
                                    sx: { zIndex: 10000 },
                                },
                                textField: {
                                    size: 'small',
                                    label: '',
                                    InputProps: {
                                        sx: {
                                            '& > input': { p: '8px 12px' },
                                        },
                                    },
                                    sx: { width: '188px', flexGrow: 2 },
                                },
                            }}
                        />
                        <IconButton
                            icon={<Check fontSize="small" />}
                            color="success.main"
                            hoverColor="success.dark"
                            onClick={() => {
                                const time = watch('time');
                                if (
                                    time &&
                                    time.getTime() >= start.getTime() &&
                                    time.getTime() <= end.getTime()
                                ) {
                                    setValue({
                                        name: watch('name'),
                                        time: watch('time'),
                                    });
                                }
                            }}
                        />
                    </>
                ) : (
                    <>
                        <TextField
                            size="small"
                            name="name"
                            label=""
                            InputProps={{
                                sx: {
                                    '& > input': { p: '8px 12px' },
                                },
                            }}
                            sx={{ width: 'auto', flexGrow: 2 }}
                        />
                        <IconButton
                            icon={<ArrowForwardIcon fontSize="small" />}
                            color="success.main"
                            hoverColor="success.dark"
                            onClick={() => {
                                if (watch('name')) setStep(1);
                            }}
                        />
                    </>
                )}
                <IconButton
                    icon={<Close fontSize="small" />}
                    color="error.main"
                    hoverColor="error.dark"
                    onClick={() => setValue()}
                />
            </Stack>
        </Form>
    );
};
