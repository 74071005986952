import { has } from 'lodash';
import { Order, OrderItem, Price, PriceSummary } from './order.types';
import {
    ComputePriceBookingsResFinancialsPriceDto,
    type ComputePriceBookingsResFinancialsPriceMainPrice0Dto,
    ComputePriceBookingsResFinancialsPriceMainPrice1Dto,
    GetBookingsResItemDto,
    GetOrderResDto,
    GetOrdersResItemDto,
    PricingType,
    UpdateOrderReqDto,
} from '../../requests';
import { getBookingDtoToBooking } from '../booking/booking.converters';
import { convertNameDtoToFullName } from '../user/user.converters';

type PriceDto = ComputePriceBookingsResFinancialsPriceDto['main_price'];
const priceDtoToPrice = (price: PriceDto): Price => {
    if (has(price, 'type')) {
        const p = price as ComputePriceBookingsResFinancialsPriceMainPrice0Dto;
        const type =
            p.type === 'per-product'
                ? PricingType.PER_PRODUCT
                : PricingType.PER_PRODUCT;
        return {
            type,
            count: p.pax_count,
            base: p.base_amount,
            unitPrice: p.unit_amount,
        };
    }

    const { base_amount: base, ...other } =
        price as ComputePriceBookingsResFinancialsPriceMainPrice1Dto;
    return {
        type: PricingType.PER_AGEBAND,
        base,
        ...(Object.keys(other) as (keyof typeof other)[]).reduce(
            (obj, ageBand) =>
                other[ageBand]
                    ? {
                          ...obj,
                          [ageBand]: {
                              count: other[ageBand]?.pax_count,
                              unitPrice: other[ageBand]?.unit_amount,
                          },
                      }
                    : obj,
            {}
        ),
    };
};

export const PriceDtoToPriceSummary = (
    price: ComputePriceBookingsResFinancialsPriceDto
): PriceSummary => {
    const { base_amount: base, ...other } = price.main_price;

    return {
        currency: price.currency.abbr,
        beforeDiscount: price.price_before_reduction,
        final: price.price_after_reduction,
        options: price.option_prices
            ?.filter(o => o.price)
            .map(option => ({
                name: option.name || '',
                type: option.type || '',
                price: priceDtoToPrice(option.price as PriceDto),
            })),
        discount: price.discount
            ? {
                  amount: price.discount.amount,
                  type: price.discount.type,
              }
            : undefined,
        main: priceDtoToPrice(price.main_price),
    };
};

export const patchOrderToOrderDto = (
    order: Partial<OrderItem>
): UpdateOrderReqDto => ({
    financials: order.financials,
});

export const getOrderItemDtoToOrder = (
    order: GetOrdersResItemDto
): OrderItem => ({
    id: order.id,
    number: order.number,
    participants: {
        customer: {
            id: order.participants.customer.id,
            fullName: order.participants.customer.name
                ? convertNameDtoToFullName(order.participants.customer.name)
                : '',
        },
    },
    // bookings: order.bookings,
    financials: order.financials,
    status: order.status,
    bookingsCount: order.summary?.bookings_count,
    productsCount: order.summary?.products_count,
    travelersCount: order.summary?.travelers_count,
    reason: order.cancelled?.message,
    lifecycle: order.lifecycle || [],
});

export const getOrderDtoToOrder = (order: GetOrderResDto): Order => ({
    id: order.id,
    number: order.number,
    participants: {
        customer: {
            id: order.participants.customer.id,
            fullName: order.participants.customer.name
                ? convertNameDtoToFullName(order.participants.customer.name)
                : '',
        },
    },
    // bookings: order.bookings,
    financials: order.financials,
    status: order.status,
    bookings:
        order.bookings?.map(v =>
            getBookingDtoToBooking(v as GetBookingsResItemDto)
        ) || [],
    lifecycle: order.lifecycle || [],
});

export const orderFilterToRequestBodyConverter = (
    filters: Record<string, any>
) => ({
    customerName: filters.customerName,
    orderStatus: '',
});
