import React, { useCallback } from 'react';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NumberField, TextField, CardList } from '@travelity/form';
import { number, object, string } from 'yup';
import {
    AgeBands,
    CapacityItem,
    capacityItemToCapacityDto,
    CapacityOptionTypes,
    FlatFields,
    getCapacityDtoToCapacity,
} from '@travelity/api';
import { Tag } from '@travelity/ui';
import { useFormContext } from 'react-hook-form';
import { useCreateManyCapacities } from '@travelity/api/src/queries';
import { CapacityModal } from '../capacity-modal';

const schema = object({
    name: string().required('Name is a required field').uniqName(),
}).when('.type', ([type], sch) => {
    if (type === CapacityOptionTypes.FLAT) {
        return sch.shape({
            limit: object()
                .shape({
                    min: number().min(1).required('Min is required'),
                    max: number().when(['min'], ([min], s) => {
                        return min
                            ? s
                                  .min(
                                      min + 1,
                                      'Maximum should be more then minimum'
                                  )
                                  .required()
                            : s;
                    }),
                })
                .required(),
        });
    }
    return sch.shape({
        limit: object().when('.', ([limit], s) => {
            return Object.values(limit).find(v => v)
                ? s
                : s.shape({
                      infants: number()
                          .required('At least one ageband count required')
                          .min(1, 'At least one ageband count required'),
                  });
        }),
    });
});

export interface ProductCapacityFormProps {
    preview?: boolean;
    showFull?: boolean;
    showErrors?: boolean;
}

const ProductCapacityForm: React.FC<ProductCapacityFormProps> = ({
    preview,
    showFull,
    showErrors,
}) => {
    const { t } = useTranslation('common');
    const { watch, setValue, trigger } = useFormContext();

    const [showBrowse, setShowBrowse] = React.useState(false);
    const browse = useCallback(() => setShowBrowse(true), []);

    const addCapacities = useCallback(
        (capacities: CapacityItem[]) => {
            setValue('capacities', [...watch('capacities'), ...capacities]);
            trigger('capacities');
            setShowBrowse(false);
        },
        [watch, setValue]
    );

    const { mutateAsync: createCapacities } = useCreateManyCapacities();

    const onCapacityAdd = useCallback(async (capacity: Record<string, any>) => {
        const { items: [newCapacity] = [] } = await createCapacities({
            requestBody: {
                items: [capacityItemToCapacityDto(capacity as CapacityItem)],
            },
        });
        return getCapacityDtoToCapacity(newCapacity);
    }, []);

    return (
        <Stack spacing={2} sx={{ width: '453px' }}>
            <CardList
                showErrors={showErrors}
                disabled={preview}
                formParams={{ schema }}
                maxHeight={showFull ? undefined : 500}
                renderHeader={({ editing, item }) =>
                    editing ? (
                        <TextField
                            sx={{ mx: '10px' }}
                            autoFocus
                            InputProps={{
                                sx: { fontSize: '14px', pl: 1.5 },
                            }}
                            placeholder="Type Name"
                            name="name"
                            variant="standard"
                            showErrors
                            helperText=""
                            fullWidth
                        />
                    ) : (
                        <Box
                            component="span"
                            title={item.name}
                            sx={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                            }}
                        >
                            {item.name}
                        </Box>
                    )
                }
                renderContent={({ item, editing }) =>
                    editing ? (
                        <Stack gap={0.5} p={2}>
                            {Object.values(
                                item.type === CapacityOptionTypes.FLAT
                                    ? FlatFields
                                    : AgeBands
                            ).map(name => (
                                <Stack direction="row" alignItems="center">
                                    <Box
                                        sx={{
                                            width: '80px',
                                            color: '#949BAC',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {t(name)}
                                    </Box>
                                    <Box sx={{ flexGrow: 2 }}>
                                        <NumberField
                                            name={`limit.${name}`}
                                            showErrors
                                            label=""
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>
                                </Stack>
                            ))}
                        </Stack>
                    ) : (
                        <Stack
                            direction="row"
                            gap={1}
                            px={2}
                            py={1}
                            flexWrap="wrap"
                        >
                            {item.type === CapacityOptionTypes.FLAT
                                ? Object.values(FlatFields).map(
                                      name =>
                                          !!item.limit[name] && (
                                              <Tag
                                                  label={t(name)}
                                                  values={[item.limit[name]]}
                                              />
                                          )
                                  )
                                : Object.values(AgeBands).map(
                                      name =>
                                          !!item.limit[name] && (
                                              <Tag
                                                  label={t(name)}
                                                  values={[item.limit[name]]}
                                              />
                                          )
                                  )}
                        </Stack>
                    )
                }
                addButtonText="Add Capacity Option"
                onItemSave={onCapacityAdd}
                itemOptions={[
                    {
                        label: 'Flat Count',
                        type: CapacityOptionTypes.FLAT,
                        min: 0,
                        max: 0,
                    },
                    {
                        label: 'Ageband Count',
                        type: CapacityOptionTypes.AGE_BAND,
                    },
                    {
                        label: 'Browse',
                        onClick: browse,
                    },
                ]}
                disableItemEdit={() => true}
                name="capacities"
            />
            {showBrowse && (
                <CapacityModal
                    handleCancel={() => setShowBrowse(false)}
                    handleConfirm={addCapacities}
                    open={showBrowse}
                />
            )}
        </Stack>
    );
};

export default React.memo(ProductCapacityForm);
