import React, { FC } from 'react';
import { MemberRole, UserOccupation } from '@travelity/api';
import { Badge, Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
// import { ReactComponent as GuideIcon } from '../../assets/guide.svg';
// import { ReactComponent as DriverIcon } from '../../assets/driver.svg';
// import { ReactComponent as TourAgentIcon } from '../../assets/tour-agent.svg';

export interface OccupationIconProps {
    occupation?: UserOccupation;
    role?: MemberRole;
}

const occupationIconMap = {
    [UserOccupation.GUIDE]: PersonIcon,
    [UserOccupation.DRIVER]: PersonIcon,
    [UserOccupation.TOUR_AGENT]: PersonIcon,
    [UserOccupation.ASSOCIATE]: PersonIcon,
};

export const OccupationIcon: FC<OccupationIconProps> = ({
    occupation,
    role,
}) => {
    const Icon = occupation ? occupationIconMap[occupation] : PersonIcon;

    return role && role !== MemberRole.CONTRIBUTOR ? (
        <Badge
            badgeContent={role === MemberRole.LEAD ? 'Lead' : 'Manager'}
            sx={{
                '& .MuiBadge-badge': {
                    bgcolor: '#2B395B',
                    color: '#FFFFFF',
                    right: role === MemberRole.LEAD ? 'auto' : '-28px',
                    fontWeight: 200,
                },
            }}
        >
            <Box
                sx={{
                    color: '#6B748C',
                    fontSize: '24px',
                }}
                component={Icon}
            />
        </Badge>
    ) : (
        <Box
            sx={{
                color: '#6B748C',
                fontSize: '24px',
            }}
            component={Icon}
        />
    );
};
