/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ResourceType {
    USER = 'user',
    PRODUCT = 'product',
    CAPACITY = 'capacity',
    SCHEDULE = 'schedule',
    BOOKING = 'booking',
    EVENT = 'event',
    ORDER = 'order',
    CUSTOMER = 'customer',
}
