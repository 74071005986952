import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { Tag } from '@travelity/ui';
import {
    AgeBands,
    convertFullNameToNameDto,
    EventItem,
    Staff,
    useAddEventAsset,
    useAddEventStaff,
    useRemoveEventAsset,
    useRemoveEventStaff,
} from '@travelity/api';
import { CardList } from '@travelity/form';
import { has } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { Asset } from '@travelity/api/src/services/asset/asset.types';
import { useLocation } from 'react-router-dom';
import { capitalizeFirstLetter } from '@travelity/api/generator/common';
import { StaffModalData, useStaffModal } from '../staff-modal';
import { DataRow } from '../data-row';
import { useAssetModal } from '../asset-modal';

export interface EventOperationsProps {
    event: EventItem;
    preview?: boolean;
}

export const EventOperations: React.FC<EventOperationsProps> = props => {
    const { t } = useTranslation();
    const { event, preview } = props;
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const [centerModal, setCenterModal] = React.useState(false);

    const { watch, control } = useFormContext();

    // Staff
    const { mutate: removeStaffMember } = useRemoveEventStaff({
        onSuccess: () => {
            enqueueSnackbar(
                `Successfully removed staff member from the event`,
                {
                    variant: 'success',
                }
            );
        },
        onError: () => {
            enqueueSnackbar(`Failed to removed staff member from the event`, {
                variant: 'error',
            });
        },
    });

    const {
        StaffModal,
        addStaff,
        closeModal: closeStaffModal,
    } = useStaffModal();

    const onStaffClose = useCallback(() => {
        closeStaffModal();
        setCenterModal(false);
    }, []);

    const { mutate: addStaffMember, isLoading: isAddingStaff } =
        useAddEventStaff({
            onSuccess: () => {
                onStaffClose();
                enqueueSnackbar(
                    `Successfully added staff member to the event`,
                    {
                        variant: 'success',
                    }
                );
            },
            onError: () => {
                enqueueSnackbar(`Failed to add staff member to the event`, {
                    variant: 'error',
                });
            },
        });

    const onStaffConfirm = useCallback(
        ({ staff, position }: StaffModalData) => {
            addStaffMember({
                eventId: event.id,
                requestBody: {
                    position,
                    reference: {
                        id: staff.id,
                        name: staff.fullName
                            ? convertFullNameToNameDto(staff.fullName)
                            : undefined,
                        email: staff.email,
                    },
                },
            });
        },
        []
    );
    useEffect(() => {}, [event]);

    const eventTeamIds = useMemo(() => {
        return event.sharedTeams.map(({ id }) => id);
    }, [event]);

    const existingEmails = useWatch({ control, name: 'staff' }).map(
        ({ staff }: { staff: Staff }) => staff.email
    );

    // Assets
    const { mutate: removeAsset } = useRemoveEventAsset({
        onSuccess: () => {
            enqueueSnackbar(`Successfully removed asset from the event`, {
                variant: 'success',
            });
        },
        onError: () => {
            enqueueSnackbar(`Failed to removed asset from the event`, {
                variant: 'error',
            });
        },
    });

    const {
        AssetModal,
        addAsset,
        closeModal: closeAssetModal,
    } = useAssetModal();

    const onAssetClose = useCallback(() => {
        closeAssetModal();
        setCenterModal(false);
    }, []);

    const { mutate: addAssetMember, isLoading: isAddingAsset } =
        useAddEventAsset({
            onSuccess: () => {
                onAssetClose();
                enqueueSnackbar(`Successfully added asset to the event`, {
                    variant: 'success',
                });
            },
            onError: () => {
                enqueueSnackbar(`Failed to add asset to the event`, {
                    variant: 'error',
                });
            },
        });

    const onAssetConfirm = useCallback((asset: Asset) => {
        addAssetMember({
            eventId: event.id,
            requestBody: {
                // @ts-ignore
                type: 'vehicle',
                id: asset.id,
                make: asset.make,
                model: asset.model,
                year: asset.year,
                license_plate: asset.licensePlate,
                max_passengers: asset.maxPassengers,
                fuel_type: asset.fuelType,
            },
        });
    }, []);

    useEffect(() => {
        if (location.state && location.state.action === 'add') {
            if (location.state.type === 'staff') {
                addStaff({ position: location.state.value });
                setCenterModal(true);
            } else if (location.state.type === 'asset') {
                addAsset();
                setCenterModal(true);
            }
        }
    }, [location.state]);

    return (
        <Box>
            <Divider>Capacity</Divider>
            <CardList
                renderHeader={({ item }) => (
                    <Stack
                        direction="row"
                        sx={{ width: 1, pl: '10px' }}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box component="span">{item.name}</Box>
                    </Stack>
                )}
                renderContent={({ item: capacity }) => (
                    <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        sx={{ my: 1, mx: 2 }}
                    >
                        {has(capacity, 'max_count') ? (
                            <>
                                <Tag
                                    label="Min Count"
                                    values={[
                                        // @ts-ignore
                                        `${capacity.min_count}`,
                                    ]}
                                />
                                <Tag
                                    label="Max Count"
                                    values={[
                                        // @ts-ignore
                                        `${capacity.max_count}`,
                                    ]}
                                />
                            </>
                        ) : (
                            Object.values(AgeBands).map(
                                name =>
                                    // @ts-ignore
                                    !!capacity[name] && (
                                        <Tag
                                            label={t(name, { ns: 'product' })}
                                            values={[
                                                // @ts-ignore
                                                capacity[name],
                                            ]}
                                        />
                                    )
                            )
                        )}
                    </Stack>
                )}
                disabled={!!preview}
                name="capacity"
                addButtonText="Add"
                disableAdding
                disableEdit
                disableReorder
                disableRemove
            />
            {(!preview || !!watch('staff')?.length) && (
                <>
                    <Divider>Staff</Divider>
                    <CardList
                        renderContent={({ item }) => (
                            <Stack sx={{ px: 2, py: 2 }} gap={1.5}>
                                <Tag
                                    sx={{ alignSelf: 'flex-start' }}
                                    value={capitalizeFirstLetter(item.position)}
                                    valueProps={{
                                        sx: {
                                            py: 0.5,
                                            color: '#FFF',
                                            bgcolor: '#2B395B',
                                        },
                                    }}
                                />
                                <DataRow
                                    label="Name"
                                    darkValue
                                    value={item.staff.fullName || '-'}
                                />
                                <DataRow
                                    label="Phone Number"
                                    darkValue
                                    tagValue
                                    value={item.staff.phoneNumber || '-'}
                                />
                                <DataRow
                                    label="Email"
                                    darkValue
                                    tagValue
                                    value={item.staff.email || '-'}
                                />
                            </Stack>
                        )}
                        disabled={!!preview}
                        name="staff"
                        addButtonText="Add Staff"
                        disableReorder
                        customAdd={() => {
                            addStaff();
                        }}
                        onItemRemove={item =>
                            removeStaffMember({
                                eventId: event.id,
                                email: item.staff.email,
                            })
                        }
                        disableEdit
                    />
                </>
            )}
            <StaffModal
                onConfirm={onStaffConfirm}
                onClose={onStaffClose}
                teamIds={eventTeamIds}
                existingEmails={existingEmails}
                isLoading={isAddingStaff}
                position={centerModal ? 'center' : 'end'}
            />
            {(!preview || !!watch('assets')?.length) && (
                <>
                    <Divider>Assets</Divider>
                    <CardList
                        renderContent={({ item }) => (
                            <Stack sx={{ px: 2, py: 2 }} gap={1.5}>
                                <Tag
                                    sx={{ alignSelf: 'flex-start' }}
                                    value={capitalizeFirstLetter('vehicle')}
                                    valueProps={{
                                        sx: {
                                            py: 0.5,
                                            color: '#FFF',
                                            bgcolor: '#2B395B',
                                        },
                                    }}
                                />
                                <DataRow
                                    label="License Plate"
                                    darkValue
                                    value={item.licensePlate || '-'}
                                />
                                <DataRow
                                    label="Make/Model"
                                    darkValue
                                    tagValue
                                    value={`${item.make}/${item.model}`}
                                />
                                <DataRow
                                    label="Year"
                                    darkValue
                                    tagValue
                                    value={item.year || '-'}
                                />
                                <DataRow
                                    label="Max Passengers"
                                    darkValue
                                    tagValue
                                    value={item.maxPassengers || '-'}
                                />
                                <DataRow
                                    label="Fuel Type"
                                    darkValue
                                    tagValue
                                    value={
                                        item.fuelType
                                            ? t(item.fuelType, { ns: 'common' })
                                            : '-'
                                    }
                                />
                            </Stack>
                        )}
                        disabled={!!preview}
                        name="assets"
                        addButtonText="Add Asset"
                        disableReorder
                        customAdd={() => {
                            addAsset();
                        }}
                        onItemRemove={item =>
                            removeAsset({
                                eventId: event.id,
                                assetId: item.uuid,
                            })
                        }
                        disableEdit
                    />
                </>
            )}
            <AssetModal
                onConfirm={onAssetConfirm}
                onClose={onAssetClose}
                isLoading={isAddingAsset}
                position={centerModal ? 'center' : 'end'}
            />
        </Box>
    );
};
