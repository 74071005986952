import { array, object } from 'yup';

const schema = object({
    // name: string()
    //     .required('Route name is a required field')
    //     .max(50, 'Route name must be less than 50 characters'),
    // beginningLocation: string().required(
    //     'Beginning of Route is a required field'
    // ),
    // endingLocation: string().when('repeatLocation', {
    //     is: true,
    //     otherwise: scheme =>
    //         scheme.required('End of Route is a required field'),
    // }),
    stops: array()
        .of(object())
        .when(['repeatLocation'], (arr, sch, { value }) => {
            if (value?.[0]?.repeatLocation) {
                return sch.min(1, 'Route must have at least 1 stop');
            }
            return sch.min(2, 'Route must have at least 2 stops');
        })
        .notInEdit('Route item(s) are in edit mode'),
});

export default schema;
