import React, { useMemo } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAuth0 } from '@auth0/auth0-react';
import {
    useCreateCustomer,
    useGetCustomer,
    useGetCustomer1Key,
    useRemoveAttachmentCustomers,
    useUploadAttachmentCustomers,
} from '../../queries';
import { getCustomerDtoToCustomer } from './customer.converters';
import {
    AddTravelersBookingsResDto,
    ContentType,
    CreateCustomerReqDto,
    CreateCustomerResDto,
    CustomersService,
    GetCustomer1ResDto,
    type UpdateCustomerReqDto,
    UpdateCustomerResDto,
} from '../../requests';
import { Attachment } from '../booking/booking.types';
import { CustomMutationOptions } from '../common.types';

type UseGetCustomerParamTypes = Parameters<typeof useGetCustomer>;
type Filter = UseGetCustomerParamTypes[0];
export const useDebouncedCustomers = (
    search: string,
    debounce: number = 1000
) => {
    const [debouncedParams, setDebouncedParams] = React.useState<
        Filter | undefined
    >();

    React.useEffect(() => {
        const handler = setTimeout(() => {
            const filter: Filter = {};
            if (search.match(/^\+?[0-9 ]+$/)) filter.phoneNumber = search;
            else if (search.match(/^[^@]+@[^@.]+\.[a-zA-Z]+$/))
                filter.email = search;
            else if (search.match(/ /)) {
                filter.name = search;
            }
            setDebouncedParams(Object.keys(filter).length ? filter : undefined);
        }, debounce);

        return () => {
            clearTimeout(handler);
        };
    }, [search, debounce]);

    const { data, ...other } = useGetCustomer(
        debouncedParams || {},
        [debouncedParams],
        {
            keepPreviousData: true,
            staleTime: debounce,
            enabled: !!debouncedParams,
        }
    );

    const parsedData = useMemo(
        () =>
            debouncedParams && data?.items
                ? data?.items.map(getCustomerDtoToCustomer)
                : undefined,
        [debouncedParams, data]
    );

    return {
        data: parsedData,
        ...other,
        isLoading: other.isFetching,
    };
};

export const useAddCustomer = (
    options?: CustomMutationOptions<
        {
            customer: CreateCustomerReqDto;
            attachments: Attachment[];
        },
        CreateCustomerResDto
    >
) => {
    const { enqueueSnackbar } = useSnackbar();
    const { mutate: createCustomer } = useCreateCustomer();
    const { mutate: addAttachment } = useUploadAttachmentCustomers();

    return useMutation(
        async ({ customer, attachments }) => {
            return new Promise((resolve, reject) => {
                createCustomer(
                    { requestBody: customer },
                    {
                        onSuccess: (data: CreateCustomerResDto) => {
                            const promises = attachments.map(attachment => {
                                return new Promise<AddTravelersBookingsResDto>(
                                    res => {
                                        addAttachment(
                                            {
                                                requestBody: {
                                                    content_type:
                                                        attachment.contentType as ContentType,
                                                    name: attachment.name,
                                                    // @ts-ignore
                                                    content: attachment.content,
                                                    // @ts-ignore
                                                    width: attachment.width,
                                                    // @ts-ignore
                                                    height: attachment.height,
                                                },
                                                customerId: data.id,
                                            },
                                            {
                                                onSuccess: d => {
                                                    // @ts-ignore
                                                    res(d);
                                                },
                                                onError: () => {
                                                    enqueueSnackbar(
                                                        'Failed to upload file',
                                                        {
                                                            variant: 'error',
                                                        }
                                                    );
                                                },
                                            }
                                        );
                                    }
                                );
                            });

                            if (promises.length === 0) {
                                resolve(data);
                            } else {
                                Promise.allSettled(promises).then(() =>
                                    resolve(data)
                                );
                            }
                        },
                        onError: reject,
                    }
                );
            });
        },
        { ...options }
    );
};

export { useCreateCustomer };

export const useUpdateCustomer = (
    customer?: GetCustomer1ResDto,
    options?: CustomMutationOptions<{
        id: string;
        customer: UpdateCustomerReqDto;
        attachments?: Attachment[];
    }>
) => {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();

    const { mutateAsync: uploadFile } = useUploadAttachmentCustomers();
    const { mutateAsync: removeFile } = useRemoveAttachmentCustomers();

    return useMutation(
        async ({ id, customer: newCustomer, attachments = [] }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;

            const promises: Promise<UpdateCustomerResDto>[] = [
                CustomersService.updateCustomer(id, authorization, newCustomer),
            ];
            attachments?.forEach(attachment => {
                if (!attachment.uuid) {
                    promises.push(
                        uploadFile({
                            customerId: id,
                            requestBody: {
                                // @ts-ignore
                                content: attachment.content,
                                // @ts-ignore
                                content_type: attachment.contentType,
                                name: attachment.name,
                                // @ts-ignore
                                width: attachment.width,
                                // @ts-ignore
                                height: attachment.height,
                            },
                        })
                    );
                }
            });

            customer?.attachments?.forEach(attachment => {
                const exists = attachments?.find(b => b.uuid === attachment.id);
                if (!exists) {
                    promises.push(
                        removeFile({
                            customerId: id,
                            attachmentId: attachment.id,
                        })
                    );
                }
            });

            const [updateResult] = await Promise.allSettled(promises);

            let response: Partial<Omit<GetCustomer1ResDto, 'rbac'>>;
            if (updateResult.status === 'rejected') {
                response = await CustomersService.getCustomer1(
                    id,
                    authorization
                );
            } else {
                // @ts-ignore
                response = updateResult.value;
            }

            queryClient.setQueryData<GetCustomer1ResDto>(
                [useGetCustomer1Key, { customerId: id }],
                prevCustomer =>
                    prevCustomer
                        ? {
                              ...prevCustomer,
                              ...response,
                          }
                        : undefined
            );

            return response;
        },
        options
    );
};
