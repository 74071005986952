/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BirthDetailsData } from './BirthDetailsData';
import type { GetAssetsResItemOwnerAttachmentsItemDto } from './GetAssetsResItemOwnerAttachmentsItemDto';
import type { GetAssetsResItemOwnerContactDetailsDto } from './GetAssetsResItemOwnerContactDetailsDto';
import type { GetCustomerResItemAccessDto } from './GetCustomerResItemAccessDto';
import type { GetTeamsResItemCreatedDto } from './GetTeamsResItemCreatedDto';
import type { Language } from './Language';
import type { Passport } from './Passport';
import type { PersonNameData } from './PersonNameData';

export type GetCustomerResItemDto = {
    /**
     * Type of the resource
     */
    resource_type: GetCustomerResItemDto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetTeamsResItemCreatedDto;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetTeamsResItemCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle?: Array<GetTeamsResItemCreatedDto>;
    /**
     * Profile's (person, company) contact details.
     */
    contact_details: GetAssetsResItemOwnerContactDetailsDto;
    /**
     * List of paths in the storage where the passport scans/uploads are.
     */
    attachments?: Array<GetAssetsResItemOwnerAttachmentsItemDto>;
    /**
     * Person's name, separated into components.
     */
    name: PersonNameData;
    /**
     * Birth details of the person.
     */
    birth_details?: BirthDetailsData;
    /**
     * Passport details of the person.
     */
    passport?: Passport;
    /**
     * List of languages the traveler speaks/understands.
     */
    languages: Array<Language>;
    /**
     * Access to the shared product
     */
    access: GetCustomerResItemAccessDto;
};

export namespace GetCustomerResItemDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        CUSTOMER = 'customer',
    }


}
