import { useController, useFormContext } from 'react-hook-form';
import React, { useEffect } from 'react';
import MuiTextField from '@mui/material/TextField';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField/TextField';
import _ from 'lodash';
import { Box, InputAdornment, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useIsFormNested } from '../../nested-form-provider';

export type TextFieldProps = Partial<MuiTextFieldProps> & {
    name: string;
    label?: string;
    required?: boolean;
    fullWidth?: boolean;
    autoGenValue?: string;
    info?: string;
    showErrors?: boolean;
    hideHelperText?: boolean;
    regExp?: RegExp;
};

const TextField: React.FC<TextFieldProps> = ({
    name,
    required,
    label,
    fullWidth,
    autoGenValue,
    info,
    showErrors,
    hideHelperText,
    regExp,
    ...rest
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const { field } = useController({ control, name });

    const isNested = useIsFormNested();
    const onKeyDown = (e: React.KeyboardEvent) => {
        if (isNested && e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const error =
        showErrors && (_.get(errors, name)?.message as string | undefined);

    return (
        <MuiTextField
            onKeyDown={onKeyDown}
            fullWidth={fullWidth}
            margin="dense"
            InputLabelProps={{
                required: !!required,
                shrink: !!rest.placeholder,
            }}
            InputProps={{
                endAdornment:
                    autoGenValue && !field.value ? (
                        <InputAdornment position="end">
                            <Tooltip
                                title="Use generated name"
                                placement="right"
                            >
                                <Box
                                    href="#"
                                    component="a"
                                    onClick={e => {
                                        e.preventDefault();
                                        field.onChange(autoGenValue);
                                        field.onBlur();
                                    }}
                                    sx={{
                                        fontFamily: 'Lexend',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        lineHeight: '11px',

                                        textAlign: 'right',
                                        textDecorationLine: 'underline',
                                        color: '#949BAC',
                                    }}
                                >
                                    {autoGenValue}
                                </Box>
                            </Tooltip>
                        </InputAdornment>
                    ) : info ? (
                        <InputAdornment position="end">
                            <InfoIcon sx={{ color: '#B2B9CD' }} />
                        </InputAdornment>
                    ) : undefined,
                ...(rest.InputProps || {}),
            }}
            label={label}
            {...field}
            onChange={event => {
                if (regExp && !event.target.value.match(regExp)) return;
                field.onChange(event);
            }}
            value={field.value || ''}
            type="text"
            error={!!error}
            helperText={!hideHelperText ? error : undefined}
            {...rest}
            sx={{
                m: 0,
                width: fullWidth ? '100%' : '388px',
                ...(rest.sx || {}),
                ...(autoGenValue && { '&& input': { pr: 0 } }),
            }}
        />
    );
};

export default TextField;
