/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum RouteItemType {
    STARTPOINT = 'startpoint',
    STOP = 'stop',
    ENDPOINT = 'endpoint',
}
