import React, { ReactNode } from 'react';
import { Stack, StackProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export interface IconButtonGroupProps extends StackProps {
    children: ReactNode;
    sx?: SystemStyleObject<Theme>;
    reverse?: boolean;
}

export const IconButtonGroup: React.FC<IconButtonGroupProps> = props => {
    const { children, sx = {}, reverse, ...rest } = props;

    return (
        <Stack
            direction="column"
            sx={{
                width: '36px',
                background: '#FFFFFF',
                boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.6)',
                borderRadius: '12px',
                px: 0.25,
                '&': sx,
                ...(reverse && {
                    bgcolor: 'secondary.main',
                }),
            }}
            className="button-group"
            {...rest}
        >
            {children}
        </Stack>
    );
};
