import React, { useState } from 'react';
import { Skeleton, Stack, Theme, Typography } from '@mui/material';
import { Tag } from '@travelity/ui';
import { SystemStyleObject } from '@mui/system';

interface DataRowProps {
    sx?: SystemStyleObject<Theme>;
    dark?: boolean;
    darkValue?: boolean;
    tagValue?: boolean;
    label: string;
    onValueClick?: () => void;
    isLoading?: boolean;
    value: string;
    values?: string[];
}

export const DataRow: React.FC<DataRowProps> = props => {
    const {
        sx,
        dark,
        label,
        value,
        darkValue,
        tagValue,
        onValueClick,
        isLoading,
        values,
    } = props;

    const val = values?.join(', ') || value || '';
    const [randomWidth] = useState(Math.ceil(Math.random() * 20 + 40));

    return (
        <Stack
            direction="row"
            gap={0.5}
            justifyContent="space-between"
            alignItems="center"
            sx={{ '&': sx, maxHeight: isLoading ? '14px' : undefined }}
        >
            <Typography
                sx={{
                    color: dark ? '#2B395B' : '#9198AA',
                    fontSize: '14px',
                    lineHeight: 1,
                }}
            >
                {label}
            </Typography>

            {isLoading ? (
                <Skeleton width={randomWidth} sx={{ fontSize: '14px' }} />
            ) : tagValue ? (
                <Tag
                    value={val}
                    onClick={onValueClick}
                    valueProps={{
                        sx: {
                            py: 0.5,
                            ...(darkValue && { color: '#67728B' }),
                        },
                    }}
                />
            ) : (
                <Typography
                    sx={{
                        textAlign: 'right',
                        lineHeight: 1,
                        color: dark || darkValue ? '#2B395B' : '#9198AA',
                        fontSize: '14px',
                        ...(onValueClick
                            ? {
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                              }
                            : {}),
                    }}
                    onClick={onValueClick}
                >
                    {val}
                </Typography>
            )}
        </Stack>
    );
};
