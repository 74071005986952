/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ResourceType } from './ResourceType';
import type { UpdateUserReqAccessDto } from './UpdateUserReqAccessDto';
import type { UpdateUserReqAccountDto } from './UpdateUserReqAccountDto';
import type { UpdateUserReqProfileDto } from './UpdateUserReqProfileDto';

export type UpdateUserReqDto = {
    /**
     * Type of the resource
     */
    resource_type?: ResourceType;
    /**
     * Type of the user
     */
    type?: UpdateUserReqDto.type;
    /**
     * Account details of the user
     */
    account?: UpdateUserReqAccountDto;
    /**
     * Access to the shared product
     */
    access?: UpdateUserReqAccessDto;
    /**
     * Profile details of the user
     */
    profile?: UpdateUserReqProfileDto;
};

export namespace UpdateUserReqDto {

    /**
     * Type of the user
     */
    export enum type {
        HUMAN = 'human',
    }


}
