/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AgebandCapacityData = {
    /**
     * Price defined for infants.
     */
    infants?: number;
    /**
     * Price defined for children.
     */
    children?: number;
    /**
     * Price defined for youth.
     */
    youth?: number;
    /**
     * Price defined for adults.
     */
    adults?: number;
    /**
     * Price defined for seniors.
     */
    seniors?: number;
    /**
     * type of the capacity selected
     */
    type: AgebandCapacityData.type;
};

export namespace AgebandCapacityData {

    /**
     * type of the capacity selected
     */
    export enum type {
        AGEBAND = 'ageband',
    }


}
