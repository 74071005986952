/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetTeamsResItemMembersItemMembershipRoleAccessDto } from './GetTeamsResItemMembersItemMembershipRoleAccessDto';

export type GetTeamsResItemMembersItemMembershipRoleDto = {
    /**
     * Type of user's role
     */
    type: GetTeamsResItemMembersItemMembershipRoleDto.type;
    /**
     * Access details of the current role
     */
    access?: GetTeamsResItemMembersItemMembershipRoleAccessDto;
};

export namespace GetTeamsResItemMembersItemMembershipRoleDto {

    /**
     * Type of user's role
     */
    export enum type {
        OWNER = 'owner',
    }


}
