/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UpdateUserResAccountMembershipsItemRoleAccessDto } from './UpdateUserResAccountMembershipsItemRoleAccessDto';

export type UpdateUserResAccountMembershipsItemRoleDto = {
    /**
     * Type of user's role
     */
    type?: UpdateUserResAccountMembershipsItemRoleDto.type;
    /**
     * Access details of the current role
     */
    access?: UpdateUserResAccountMembershipsItemRoleAccessDto;
};

export namespace UpdateUserResAccountMembershipsItemRoleDto {

    /**
     * Type of user's role
     */
    export enum type {
        OWNER = 'owner',
    }


}
