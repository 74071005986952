/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum VehicleType {
    CAR = 'car',
    TRUCK = 'truck',
    BUS = 'bus',
    VAN = 'van',
    SUV = 'suv',
    RV = 'RV',
    MOTORCYCLE = 'motorcycle',
    BICYCLE = 'bicycle',
    SCOOTER = 'scooter',
    HOVERBOARD = 'hoverboard',
    AIRPLANE = 'airplane',
    HELICOPTER = 'helicopter',
}
