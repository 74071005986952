import React from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
    Checkbox,
    DatePicker,
    SelectTimes,
    StaticDatePicker,
    TabForms,
    WeekdayPicker,
} from '@travelity/form';
import { Tag } from '@travelity/ui';
import { minutesToTime } from '@travelity/utils';
import { useFormContext } from 'react-hook-form';
import { RecurrenceTypes, ScheduleOptionTypes } from '@travelity/api';

export interface ScheduleCardContentProps {
    item: Record<string, any>;
    editing: boolean;
}

const monthStartDate = new Date('2023-01-01');
const monthEndDate = new Date('2023-01-31');

const ScheduleCardContent: React.FC<ScheduleCardContentProps> = props => {
    const { item, editing } = props;
    const { t } = useTranslation('common');
    const {
        watch,
        formState: { errors },
    } = useFormContext();

    return item.type === ScheduleOptionTypes.CUSTOM ? (
        <Stack sx={{ p: 2 }} gap={1}>
            <Divider>Dates</Divider>
            {editing ? (
                <Box sx={{ pt: 1 }}>
                    <StaticDatePicker
                        multiple
                        showInput
                        name="startDates"
                        showErrors
                        TextFieldProps={{
                            helperText: '',
                            placeholder: 'Select Dates',
                            size: 'small',
                        }}
                    />
                </Box>
            ) : (
                <Box>
                    {item.startDates?.length > 0 && (
                        <Tag
                            label={t('Custom')}
                            values={item.startDates.map((d: Date) =>
                                format(d, 'd MMM, yyyy')
                            )}
                        />
                    )}
                </Box>
            )}
            <Stack direction="row" alignItems="center" gap={1}>
                <Divider
                    sx={{
                        flexGrow: 2,
                        '& .MuiDivider-wrapper': {
                            color: errors.times ? 'error.main' : '#2B395B',
                        },
                    }}
                >
                    Start Times
                </Divider>
                {editing && <Checkbox name="allDay" label="All-Day" />}
            </Stack>
            {editing ? (
                !watch('allDay') && (
                    <SelectTimes
                        name="times"
                        label=""
                        placeholder="Choose applicable start times"
                        InputProps={{ size: 'small' }}
                    />
                )
            ) : (
                <Stack direction="row" gap={1} flexWrap="wrap">
                    {item.allDay ? (
                        <Tag values={['All Day']} />
                    ) : (
                        item.times?.map((m: number) => (
                            <Tag values={[minutesToTime(m)]} />
                        ))
                    )}
                </Stack>
            )}
        </Stack>
    ) : (
        <Stack sx={{ p: 2 }} gap={1}>
            <Divider>Dates</Divider>
            {editing ? (
                <Stack direction="row" gap="6px">
                    <DatePicker
                        name="startDate"
                        label=""
                        showErrors
                        iconTooltip="Select start date"
                        TextFieldProps={{
                            helperText: '',
                            size: 'small',
                            placeholder: 'Start Date',
                        }}
                    />
                    <DatePicker
                        name="endDate"
                        label=""
                        showErrors
                        iconTooltip="Select end date"
                        disableBeforeField="startDate"
                        TextFieldProps={{
                            helperText: '',
                            size: 'small',
                            placeholder: 'End Date',
                        }}
                    />
                </Stack>
            ) : (
                <Stack direction="row" gap="6px">
                    {item.startDate && (
                        <Tag
                            label={t('Start')}
                            values={[format(item.startDate, 'dd.MM.yyyy')]}
                        />
                    )}
                    {item.endDate && (
                        <Tag
                            label={t('End')}
                            values={[format(item.endDate, 'dd.MM.yyyy')]}
                        />
                    )}
                </Stack>
            )}
            <Divider
                sx={{
                    mt: 1,
                    '& .MuiDivider-wrapper': {
                        color:
                            editing &&
                            (errors.weekDays ||
                                errors.monthDays ||
                                errors.dates)
                                ? 'error.main'
                                : '#2B395B',
                    },
                }}
            >
                Recurrence
            </Divider>
            {editing ? (
                <TabForms
                    name="recurrenceType"
                    defaultValue={RecurrenceTypes.WEEKLY}
                    options={[
                        {
                            label: 'Weekly',
                            value: RecurrenceTypes.WEEKLY,
                            children: (
                                <Box
                                    sx={{
                                        borderRadius: '8px',
                                        background: '#FFF',
                                    }}
                                >
                                    <WeekdayPicker name="weekDays" multiple />
                                </Box>
                            ),
                        },
                        {
                            label: 'Monthly',
                            value: RecurrenceTypes.MONTHLY,
                            children: (
                                <Box
                                    sx={{
                                        borderRadius: '8px',
                                        background: '#FFF',
                                    }}
                                >
                                    <StaticDatePicker
                                        multiple
                                        key={1}
                                        showInput={false}
                                        name="monthDays"
                                        minDate={monthStartDate}
                                        maxDate={monthEndDate}
                                        sx={{
                                            '&& .MuiDayCalendar-header': {
                                                display: 'none',
                                            },
                                            '&& .MuiPickersCalendarHeader-root':
                                                {
                                                    display: 'none',
                                                },
                                            '&& .MuiPickersSlideTransition-root':
                                                {
                                                    my: 1,
                                                    minHeight: '192px',
                                                },
                                        }}
                                    />
                                </Box>
                            ),
                        },
                        {
                            label: 'Yearly',
                            value: RecurrenceTypes.YEARLY,
                            children: (
                                <Box
                                    sx={{
                                        borderRadius: '8px',
                                        background: '#FFF',
                                    }}
                                >
                                    <StaticDatePicker
                                        key={2}
                                        multiple
                                        name="dates"
                                    />
                                </Box>
                            ),
                        },
                    ]}
                />
            ) : (
                <Box>
                    {item.recurrenceType === RecurrenceTypes.WEEKLY &&
                        item.weekDays?.length > 0 && (
                            <Tag
                                label={t('Weekly')}
                                values={
                                    item.weekDays.length === 7
                                        ? [t('Everyday')]
                                        : item.weekDays.map((v: string) =>
                                              t(v).substring(0, 3)
                                          )
                                }
                            />
                        )}
                    {item.recurrenceType === RecurrenceTypes.MONTHLY &&
                        item.monthDays?.length > 0 && (
                            <Tag
                                label={t('Monthly')}
                                values={item.monthDays.map((d: Date) =>
                                    format(d, 'd')
                                )}
                            />
                        )}
                    {item.recurrenceType === RecurrenceTypes.YEARLY &&
                        item.dates?.length > 0 && (
                            <Tag
                                label={t('Yearly')}
                                values={item.dates.map((d: Date) =>
                                    format(d, 'd MMM')
                                )}
                            />
                        )}
                </Box>
            )}
            <Stack direction="row" alignItems="center" gap={1}>
                <Divider
                    sx={{
                        flexGrow: 2,
                        '& .MuiDivider-wrapper': {
                            color: errors.times ? 'error.main' : '#2B395B',
                        },
                    }}
                >
                    Start Times
                </Divider>
                {editing && <Checkbox name="allDay" label="All-Day" />}
            </Stack>
            {editing ? (
                !watch('allDay') && (
                    <SelectTimes
                        name="times"
                        label=""
                        placeholder="Choose applicable start times"
                        InputProps={{ size: 'small' }}
                    />
                )
            ) : (
                <Stack direction="row" gap={1} flexWrap="wrap">
                    {item.allDay ? (
                        <Tag values={['All Day']} />
                    ) : (
                        item.times?.map((m: number) => (
                            <Tag values={[minutesToTime(m)]} />
                        ))
                    )}
                </Stack>
            )}
        </Stack>
    );
};

export default ScheduleCardContent;
