import React from 'react';
import { ActivityType } from '@travelity/api';
import { Box } from '@mui/material';
import { _UpdatedField } from '@travelity/api/src/requests';
import { capitalizeFirstLetter } from '@travelity/api/generator/common';

export interface ActionProps {
    action: ActivityType;
    entity: string;
    updates?: _UpdatedField[];
}

export const supportedActions = [
    ActivityType.UPDATE,
    ActivityType.CREATION,
    ActivityType.DELETION,
    ActivityType.CANCELLATION,
];

export const Action: React.FC<ActionProps> = ({ action, entity, updates }) => {
    switch (action) {
        case ActivityType.CREATION:
            return <Box component="span">created the {entity}</Box>;
        case ActivityType.UPDATE:
            return (
                <Box component="span">
                    updated {updates && updates.length > 1 ? 'fields' : 'field'}{' '}
                    {updates?.map(({ name }, index) => (
                        <Box
                            component="span"
                            key={name}
                            sx={{ fontWeight: 700, fontStyle: 'italic' }}
                        >
                            {name
                                .split('.')
                                .map(n =>
                                    capitalizeFirstLetter(
                                        n.replaceAll('_', ' ')
                                    )
                                )
                                .join('➝')}
                            {index < updates.length - 1 ? ', ' : ''}
                        </Box>
                    ))}
                </Box>
            );
        case ActivityType.DELETION:
            return <Box component="span">deleted the {entity}</Box>;
        case ActivityType.CANCELLATION:
            return <Box component="span">canceled the {entity}</Box>;
        default:
            return <Box component="span" />;
    }
};
