/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum TourEventAssetType {
    VEHICLE = 'vehicle',
}
