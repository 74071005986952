import { Booking } from './booking.types';
import {
    GetBookingsResItemDto,
    PersonNameData,
    UpdateBookingReqDto,
} from '../../requests';
import { SourceType } from '../order/order.types';
import { convertNameDtoToFullName } from '../user/user.converters';

export const getBookingDtoToBooking = (
    booking: GetBookingsResItemDto
): Booking => {
    let sourceName: string;
    // @ts-ignore
    if (booking.source.type === SourceType.DIRECT) {
        // @ts-ignore
        sourceName = booking.source.name;
    } else {
        // @ts-ignore
        const name = booking.source.name
            ? convertNameDtoToFullName(booking.source.name as PersonNameData)
            : undefined;
        // @ts-ignore
        sourceName = booking.source.company || name || booking.source.email;
    }

    return {
        id: booking.id,
        status: booking.status,
        notes: booking.notes,
        product: booking.product,
        date: booking.date,
        eventId: booking.event.id,
        financials: booking.financials,
        participants: booking.participants,
        orderId: booking.order.id,
        reason: booking.cancelled?.message,
        lifecycle: booking.lifecycle || [],
        source: {
            type: booking.source.type as unknown as SourceType,
            name: sourceName,
        },
        route: booking.event.route,
    };
};
export const bookingToGetBookingDto = (
    booking: Booking
): Partial<GetBookingsResItemDto> => {
    return {
        id: booking.id,
        // @ts-ignore
        product: booking.product,
    };
};

export const bookingToBookingDto = (
    booking: Booking
): Omit<GetBookingsResItemDto, 'created' | 'source'> => {
    return {
        id: booking.id,
        status: booking.status,
        notes: booking.notes,
        product: booking.product,
        date: booking.date,
        financials: booking.financials,
        participants: booking.participants,
        event: {
            id: booking.eventId,
        },
        order: {
            id: booking.orderId,
            number: '',
            customer: {
                id: '',
            },
        },
    };
};

export const patchBookingToBookingDto = (
    booking: Partial<Booking>
): UpdateBookingReqDto => ({
    notes: booking.notes,
});
