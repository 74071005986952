/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OrderStatus } from './OrderStatus';
import type { UpdateBookingResProductCustomersItemReferenceDto } from './UpdateBookingResProductCustomersItemReferenceDto';
import type { UpdateOrderResAccessDto } from './UpdateOrderResAccessDto';
import type { UpdateOrderResPriceDto } from './UpdateOrderResPriceDto';
import type { UpdateUserResLastUpdatedDto } from './UpdateUserResLastUpdatedDto';

export type UpdateOrderResDto = {
    /**
     * Type of the resource
     */
    resource_type?: UpdateOrderResDto.resource_type;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: UpdateUserResLastUpdatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: UpdateUserResLastUpdatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<UpdateUserResLastUpdatedDto>;
    /**
     * Information about the customer who booked the event.
     */
    customer?: UpdateBookingResProductCustomersItemReferenceDto;
    /**
     * Price information.
     */
    price?: UpdateOrderResPriceDto;
    /**
     * Status of the order
     */
    status?: OrderStatus;
    /**
     * Access to the shared product
     */
    access?: UpdateOrderResAccessDto;
};

export namespace UpdateOrderResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        ORDER = 'order',
    }


}
