import React, { useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from '@travelity/form';
import { Stack } from '@mui/material';
import { Customer } from '@travelity/api';
import { object } from 'yup';
import SelectCustomer from '../select-customer/select-customer';

const formId = 'customer-search-modal';

export interface CustomerSearchModalProps {
    handleCancel: () => void;
    handleConfirm: (v: Partial<Customer>) => void;
    open: boolean;
    item?: Customer;
}

const schema = object({
    customer: object().required('Customer is a required field'),
});

export const CustomerSearchModal: React.FC<CustomerSearchModalProps> = ({
    handleCancel,
    handleConfirm,
    open,
    item,
}) => {
    const {
        Form,
        trigger,
        reset,
        formState: { errors },
    } = useForm<{ customer: Customer | null }>({
        schema,
        isModal: true,
        mode: 'onChange',
        validateInitially: true,
        defaultValues: {
            customer: null,
        },
        onSubmit: useCallback(
            (data: { customer: Customer | null }) => {
                handleConfirm(data.customer as Customer);
            },
            [handleConfirm]
        ),
    });

    useEffect(() => {
        if (open) {
            reset({ customer: item || null });
            trigger('customer');
        }
    }, [open, reset, trigger, item]);

    const errorFields = Object.values(errors)
        .map(error => error?.message)
        .filter(v => v) as string[];

    const addNewCustomer = useCallback(
        (search: string) => {
            if (search?.match(/^[^@]+@[^@.]+\.[a-zA-Z]+$/)) {
                handleConfirm({ emails: [{ value: search }] });
            } else if (search?.match(/ /)) {
                handleConfirm({
                    fullName: search,
                });
            } else {
                handleConfirm({});
            }
        },
        [handleConfirm]
    );

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            sx={{
                '& .MuiDialog-container': { justifyContent: 'end' },
            }}
            PaperProps={{ sx: { py: 1, bgcolor: '#fff', maxWidth: '418px' } }}
        >
            <DialogTitle
                sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    fontWeight: 600,
                    color: '#2B395B',
                }}
                id="alert-dialog-title"
            >
                Select from customers
            </DialogTitle>
            <DialogContent
                sx={{
                    overflow: 'hidden',
                    py: 1,
                }}
            >
                <Form id={formId}>
                    <Stack
                        direction="column"
                        gap={2}
                        sx={{ pt: 2, width: '370px' }}
                    >
                        <SelectCustomer
                            name="customer"
                            label="Customer Name or Email"
                            placeholder="Type Customer Name or Email"
                            onAddNewClick={addNewCustomer}
                        />
                    </Stack>
                </Form>
            </DialogContent>
            <DialogActions sx={{ px: 3, py: 2 }}>
                <Button
                    color="neutral"
                    variant="contained"
                    onClick={handleCancel}
                    sx={{
                        px: '26px',
                        py: '10px',
                    }}
                >
                    Cancel
                </Button>

                <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    form={formId}
                    disabled={!!errorFields.length}
                    sx={{
                        py: '10px',
                        '&&&': { pointerEvents: 'initial' },
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
