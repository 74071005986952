import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Divider, Paper, Stack } from '@mui/material';
import { TextField, CardList, Select, Switch, TagsList } from '@travelity/form';
import { useFormContext } from 'react-hook-form';
import { number, object, string } from 'yup';
import {
    TransactionMethod,
    Pricing,
    PricingType,
    ProductCapacityItem,
    ProductScheduleItem,
} from '@travelity/api';
import { convertEnumToList } from '@travelity/utils';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FinancingCardContent } from './components/financing-card';
import { useUserContext } from '../../contexts/user';
import { FinancingCardHeader } from './components/financing-card-header';

export interface ProductFinancingFormProps {
    capacities: ProductCapacityItem[];
    schedules: ProductScheduleItem[];
    preview?: boolean;
    showFull?: boolean;
    showErrors?: boolean;
}

const schema = object({
    // name: string().required('Name is a required field').uniqName(),
    currency: string().required('Currency is a required field'),
    price: object().when(['type'], ([type], sch) => {
        if (type === PricingType.PER_AGEBAND) {
            return sch.shape({
                counts: object().when('.', ([counts], s) => {
                    return Object.values(counts).find(v => v)
                        ? s
                        : s.shape({
                              infants: number()
                                  .required(
                                      'At least one ageband price required'
                                  )
                                  .min(
                                      1,
                                      'At least one ageband price required'
                                  ),
                          });
                }),
            });
        }
        if (type === PricingType.PER_PERSON) {
            return sch.shape({
                perPerson: number()
                    .required('Price per person is required')
                    .min(1, 'Price per person is required'),
            });
        }
        return sch.shape({
            perProduct: number()
                .required('Price per product is required')
                .min(1, 'Price per product is required'),
        });
    }),
});

const ProductFinancingForm: React.FC<ProductFinancingFormProps> = props => {
    const { schedules, capacities, preview, showFull, showErrors } = props;
    const { t } = useTranslation('common');
    const {
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();
    const { user } = useUserContext();
    const items = watch('items') || [];
    const currency = items[0]?.currency || user?.currency || 'AMD';
    const paymentMethodOptions = useMemo(
        () => convertEnumToList<TransactionMethod>(TransactionMethod, t),
        [t]
    );

    const onPriceSave = useCallback((pricing: Record<string, any>) => {
        if (!pricing.name) {
            return {
                ...pricing,
                name: pricing.capacity
                    ? `${pricing.capacity} Price`
                    : 'Product Price',
            };
        }
        return pricing;
    }, []);

    const prepaymentTypeOptions = useMemo(
        () => [
            { value: 'relative', label: '%' },
            { value: 'absolute', label: user?.currency || 'ABS' },
        ],
        [user?.currency]
    );

    const adaptivePricing = watch('adaptivePricing');
    const defaultValue = adaptivePricing
        ? {
              capacity: capacities[watch('items')?.length || 0]?.name,
              currency,
          }
        : {
              currency,
          };

    const prepayment = watch('prepayment');
    useEffect(() => {
        if (prepayment && !watch('prepaymentType')) {
            setValue('prepaymentType', prepaymentTypeOptions[0].value);
        }
    }, [prepayment]);

    // const freeCapacities = useMemo(() => {
    //     return capacities?.filter(
    //         c => !items.find(i => i.capacity?.id === c.id)
    //     );
    // }, [capacities]);
    // const freeSchedule = useMemo(() => {
    //     return schedules?.filter(
    //         s => !items.find(i => i.schedule?.id === s.id)
    //     );
    // }, [schedules]);
    const usedDependencies = useMemo(() => {
        return items
            ?.filter((i: Pricing) => !!i.schedule || !!i.capacity)
            .map((i: Pricing) => ({
                schedule: i.schedule?.scheduleId,
                capacity: i.capacity?.capacityId,
            }));
    }, [items]);

    const paymentMethodsError =
        showErrors &&
        (_.get(errors, 'paymentMethods')?.message as string | undefined);

    return (
        <Stack spacing={2} sx={{ width: '453px' }}>
            <Divider sx={{ maxWidth: '388px' }} textAlign="left">
                Pricing
            </Divider>

            <CardList
                disabled={preview}
                maxHeight={showFull ? undefined : 500}
                renderHeader={({ editing, item }) => (
                    <FinancingCardHeader
                        item={item}
                        editing={editing}
                        usedDependencies={usedDependencies}
                        capacities={capacities}
                        schedules={schedules}
                    />
                )}
                renderContent={({ item, editing }) => (
                    <FinancingCardContent item={item} editing={editing} />
                )}
                defaultState={defaultValue}
                addButtonText={
                    watch('adaptivePricing') && watch('items')?.length
                        ? `Add Pricing (${
                              capacities.length - watch('items').length
                          } more)`
                        : 'Add Pricing'
                }
                // disableAdding={
                //     (!watch('adaptivePricing') && watch('items')?.length) ||
                //     (watch('adaptivePricing') &&
                //         watch('items')?.length === capacities.length)
                // }
                formParams={{
                    schema,
                }}
                showErrors={showErrors}
                onItemSave={onPriceSave}
                name="items"
            />

            <Divider sx={{ maxWidth: '388px' }} textAlign="left">
                Payment Requirements
            </Divider>

            <Paper sx={{ py: 1, px: 2, maxWidth: '388px' }}>
                <Box
                    sx={{
                        color: '#2B395B',
                        fontSize: '14px',
                        width: 1,
                        justifyContent: 'space-between',
                        margin: 0,
                        ...(showErrors &&
                            paymentMethodsError && {
                                color: 'error.main',
                            }),
                    }}
                >
                    Payment Methods
                </Box>

                <TagsList
                    multiple
                    name="paymentMethods"
                    selectAll={false}
                    disabled={preview}
                    options={paymentMethodOptions}
                />
            </Paper>

            <Paper
                sx={{
                    py: 1,
                    px: 2,
                    maxWidth: '388px',
                    ...(preview
                        ? {
                              opacity: 0.6,
                              pointerEvents: 'none',
                          }
                        : {}),
                }}
            >
                <Switch
                    name="prepayment"
                    label="Prepayment required?"
                    LabelProps={{ sx: { color: '#2B395B' } }}
                />
            </Paper>
            {watch('prepayment') && (
                <Stack
                    direction="row"
                    gap={1}
                    sx={{
                        '&&&': { mt: 1 },
                        bgcolor: '#FFFFFF',
                        borderRadius: '8px',
                        maxWidth: '388px',
                    }}
                >
                    <TextField
                        name="prepaymentAmount"
                        size="small"
                        sx={{ flexGrow: 2 }}
                    />
                    <Select
                        size="small"
                        width="140px"
                        name="prepaymentType"
                        label=""
                        options={prepaymentTypeOptions}
                    />
                </Stack>
            )}
        </Stack>
    );
};

export default React.memo(ProductFinancingForm);
