import React, { ReactNode } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { Theme } from '@mui/material/styles';

export interface PageTitleProps {
    children: ReactNode;
    buttons?: ReactNode;
    sx?: SystemStyleObject<Theme>;
}

const PageTitle: React.FC<PageTitleProps> = ({
    children,
    buttons,
    sx = {},
}) => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ mb: 2, mr: 1, '&': sx }}
        >
            <Typography
                sx={{
                    mb: 1,
                    fontWeight: 700,
                    fontSize: '24px',
                    lineHeight: 1.5,
                    color: '#C0C4CE',
                }}
                variant="h5"
            >
                {children}
            </Typography>
            {buttons && <Box>{buttons}</Box>}
        </Stack>
    );
};

export default React.memo(PageTitle);
