/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FlatPricingData = {
    /**
     * Type of the pricing used
     */
    type: FlatPricingData.type;
    /**
     * Unconditional base price/cost
     */
    base_amount?: number;
    /**
     * Cost or price, when type is per_person or per_product.
     */
    unit_amount: number;
};

export namespace FlatPricingData {

    /**
     * Type of the pricing used
     */
    export enum type {
        PER_PERSON = 'per_person',
        PER_PRODUCT = 'per_product',
    }


}
