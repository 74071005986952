import React from 'react';
import { Booking, BookingStatus } from '@travelity/api';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Card, Heading } from '@travelity/ui';
import { formatValue as formatPaxValue } from '@travelity/form';
import CircleIcon from '@mui/icons-material/Circle';
import BookingItemMinimalSkeleton from './components/booking-item-minimal-skeleton';
import { OverlayWithReason } from '../overlay-with-reason';
import { ProductTypeIcon } from '../product-type-icon';

export interface BookingItemProps {
    booking: Booking;
    isSelected: boolean;
    onSelect: () => void;
}

const BookingItemMinimal: React.FC<BookingItemProps> = ({
    booking,
    isSelected,
    onSelect,
}) => {
    const { t } = useTranslation('product');

    return (
        <Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                    mt: 0.5,
                    mb: 1,
                    ml: 4,
                    minWidth: 0,
                }}
            >
                <Stack
                    direction="row"
                    gap={1.5}
                    alignItems="center"
                    sx={{
                        minWidth: 0,
                    }}
                >
                    <Heading
                        sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                            pr: 1,
                            pl: 1,
                        }}
                        alpha={0.7}
                        ellipsis
                    >
                        {booking.product.name}
                    </Heading>
                    <CircleIcon sx={{ fontSize: '8px', color: '#D9D9D9' }} />
                    <ProductTypeIcon type={booking.product.type} />
                    <Typography
                        sx={{
                            ml: '-8px',
                            color: '#6B748C',
                            fontSize: '12px',
                            fontWeight: '500',
                            whiteSpace: 'nowrap',
                            pr: 1.5,
                        }}
                    >
                        {t(booking.product.type)}
                    </Typography>
                </Stack>
            </Stack>
            <Card
                flexGrow={2}
                sx={{
                    px: 2,
                    py: 1,
                }}
                direction="column"
                justifyContent="space-evenly"
                parentProps={{ alignItems: 'center' }}
                leftAdornment={
                    <Stack alignItems="center">
                        <Box
                            onClick={onSelect}
                            sx={{
                                mr: '14px',
                                cursor: 'pointer',
                                width: '18px',
                                height: '18px',
                                borderStyle: 'solid',
                                borderWidth: isSelected ? '6px' : '2px',
                                borderColor: isSelected ? '#6B748C' : '#B5B9C5',
                                borderRadius: '4px',
                            }}
                        />
                    </Stack>
                }
            >
                <Stack justifyContent="space-between" direction="row">
                    <Box
                        component="span"
                        sx={{
                            fontSize: 12,
                            color: '#989EAF',
                            pr: 1,
                        }}
                    >
                        {t('Start Date')}
                    </Box>
                    <Box
                        component="span"
                        sx={{
                            fontSize: 12,
                            color: '#2B395B',
                            pr: 1,
                        }}
                    >
                        {format(booking.date.start * 1000, 'dd MMM yyyy')}
                    </Box>
                </Stack>
                <Stack justifyContent="space-between" direction="row">
                    <Box
                        component="span"
                        sx={{
                            fontSize: 12,
                            color: '#989EAF',
                            pr: 1,
                        }}
                    >
                        {t('PAX')}
                    </Box>
                    <Box
                        component="span"
                        sx={{
                            fontSize: 12,
                            color: '#2B395B',
                            pr: 1,
                        }}
                    >
                        {formatPaxValue(booking.participants.pax)}
                    </Box>
                </Stack>
                {booking.status === BookingStatus.CANCELLED && (
                    <OverlayWithReason
                        title="Cancelled"
                        reason={booking.reason}
                    />
                )}
            </Card>
        </Box>
    );
};

export { BookingItemMinimalSkeleton };

export default BookingItemMinimal;
