/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateManySchedulesReqDto } from '../models/CreateManySchedulesReqDto';
import type { CreateManySchedulesResDto } from '../models/CreateManySchedulesResDto';
import type { GetSchedulesResDto } from '../models/GetSchedulesResDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SchedulesService {

    /**
     * List/Filter Schedules
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param ids List of ids to return
     * @param pageNumber ID/Number of the page to open
     * @param pageSize number of items returned
     * @returns GetSchedulesResDto Successful Response
     * @throws ApiError
     */
    public static getSchedules(
authorization: string,
ids?: Array<string>,
pageNumber?: number,
pageSize: number = 20,
): CancelablePromise<GetSchedulesResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/schedules',
            headers: {
                'authorization': authorization,
            },
            query: {
                'ids': ids,
                'page_number': pageNumber,
                'page_size': pageSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create schedules
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns CreateManySchedulesResDto Successful Response
     * @throws ApiError
     */
    public static createManySchedules(
authorization: string,
requestBody: CreateManySchedulesReqDto,
): CancelablePromise<CreateManySchedulesResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/schedules',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
