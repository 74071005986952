import React from 'react';
// import ResetPassword from '../views/reset-password';

import Callback from '../views/callback';

export const AuthenticationRoutes = [
    {
        path: '/callback',
        element: <Callback />,
    },
    // {
    //     path: '/reset-password',
    //     element: <ResetPassword />,
    // },
];

export default AuthenticationRoutes;
