import { ProductType } from '@travelity/api';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import React from 'react';

export interface ProductTypeIconProps {
    type: ProductType;
}

const productTypeMap: Record<ProductType, any> = {
    [ProductType.TRANSFER]: DriveEtaIcon,
    [ProductType.TOUR]: PeopleAltIcon,
};

export const ProductTypeIcon: React.FC<ProductTypeIconProps> = props => {
    const { type } = props;
    const Component = productTypeMap[type];

    return <Component sx={{ fontSize: '12px' }} />;
};
