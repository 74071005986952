import { object, string } from 'yup';

const schema = object({
    option: string().required('Option is a required field'),
    pax: object().test('Required', function paxRequired(value) {
        if (value && Object.values(value).find(v => v)) {
            return true;
        }

        return this.createError({ message: 'PAX' });
    }),
});

export default schema;
