import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Box, ClickAwayListener, Divider, Paper, Stack } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import { User, useRemoveCurrency, useUpdateCurrency } from '@travelity/api';
import { LoadingOverlay } from '@travelity/ui';
import { Popper } from '../../../../components/popper';
import Transitions from '../../../../components/transitions/transitions';
import { CurrencyTagItem } from './currency-tag-item';

export interface CurrencyTagProps {
    user?: User;
}

export const CurrencyTag: React.FC<CurrencyTagProps> = props => {
    const { user } = props;
    const { currencies = [] } = user || {};
    // const currencies: Currency[] = [
    //     {
    //         currency: 'USD',
    //         rate: 308,
    //     },
    //     {
    //         currency: 'RUR',
    //         rate: 3.98,
    //     },
    // ];
    const { mutate: update, isLoading: isUpdating } = useUpdateCurrency();
    const { mutate: remove, isLoading: isRemoving } = useRemoveCurrency();
    const usedCurrencies = useMemo(() => {
        return [user?.currency || '', ...currencies.map(c => c.name)];
    }, [user?.currency, currencies]);

    const [open, setOpen] = React.useState(false);
    const ref = useRef();

    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (
        event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
    ) => {
        if ((event.target as HTMLElement)?.localName === 'body') return;
        setOpen(false);
    };

    const [adding, setAdding] = useState(false);

    const onAdd = () => {
        setAdding(true);
    };

    const onUpdate = useCallback(
        (newCurrency: string, newRate: number, oldCurrency?: string) => {
            if (oldCurrency && oldCurrency !== newCurrency) {
                remove({
                    currency: oldCurrency,
                });
            }
            update({
                currency: newCurrency,
                rate: newRate,
            });
        },
        [update]
    );

    const disabled = !user?.subscription?.user?.financial?.multi_currency;

    return (
        <Box sx={{ borderLeft: '#DFE2EC 1px solid', px: 2, height: '16px' }}>
            <Stack
                ref={ref}
                direction="row"
                alignItems="center"
                sx={{
                    mt: '-8px',
                    bgcolor: '#F4F6FA',
                    whiteSpace: 'nowrap',
                    py: 1,
                    pl: 0.5,
                    pr: 1,
                    borderRadius: '12px',
                }}
            >
                {currencies.length ? (
                    currencies.map((currency, index) => (
                        <Stack
                            direction="row"
                            key={currency.name}
                            sx={{
                                px: 1,
                                borderLeft: index
                                    ? '#DFE2EC 1px solid'
                                    : 'none',
                                fontSize: '12px',
                            }}
                            gap={1}
                        >
                            <Box sx={{ color: '#9198AA' }}>{currency.name}</Box>
                            <Box sx={{ color: '#2B395B' }}>{currency.rate}</Box>
                        </Stack>
                    ))
                ) : (
                    <Stack sx={{ px: 1.5, color: '#9198AA', fontSize: '12px' }}>
                        No exchange rates
                    </Stack>
                )}
                <Box
                    sx={{
                        bgcolor: '#FFF',
                        color: '#36869C',
                        height: 20,
                        borderRadius: '16px',
                        px: 0.5,
                        cursor: 'pointer',
                        '&:hover': {
                            bgcolor: 'rgba(0, 0, 0, 0.04)',
                        },
                        ...(disabled && {
                            cursor: 'default',
                            opacity: 0.7,
                            '&:hover': {},
                        }),
                    }}
                    onClick={!disabled ? handleClick : undefined}
                >
                    <MoreHorizIcon fontSize="small" />
                </Box>
            </Stack>

            <Popper
                placement="bottom-start"
                role={undefined}
                transition
                sx={{ zIndex: 10000 }}
                anchorEl={ref.current}
                open={open}
                // anchorOrigin={{
                //     vertical: 'bottom',
                //     horizontal: 'right',
                // }}
                // transformOrigin={{
                //     vertical: 'top',
                //     horizontal: 'right',
                // }}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 8],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                borderRadius: '12px',
                                boxShadow:
                                    '0px 0px 16px 0px rgba(178, 185, 205, 0.40)',
                                background: '#FFF',
                                p: 2,
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <Stack gap={1}>
                                    {(isUpdating || isRemoving) && (
                                        <LoadingOverlay />
                                    )}
                                    <CurrencyTagItem
                                        base
                                        usedCurrencies={[]}
                                        currency={user?.currency || 'AMD'}
                                    />
                                    <Divider />
                                    {currencies.map(currency => (
                                        <CurrencyTagItem
                                            currency={currency.name}
                                            rate={currency.rate}
                                            usedCurrencies={usedCurrencies}
                                            onUpdate={(name, rate) => {
                                                onUpdate(
                                                    name,
                                                    rate,
                                                    currency.name
                                                );
                                            }}
                                            onRemove={() =>
                                                remove({
                                                    currency: currency.name,
                                                })
                                            }
                                        />
                                    ))}
                                    {adding && (
                                        <CurrencyTagItem
                                            currency=""
                                            isNew
                                            usedCurrencies={usedCurrencies}
                                            onUpdate={(name, rate) => {
                                                onUpdate(name, rate);
                                                setAdding(false);
                                            }}
                                            onRemove={() => setAdding(false)}
                                        />
                                    )}
                                    <Button
                                        sx={{
                                            bgcolor: '#FFFFFF',
                                        }}
                                        variant="outlined"
                                        className="add-button"
                                        fullWidth
                                        onClick={onAdd}
                                        data-testid="add-card"
                                    >
                                        <Add /> Add Exchange Rate
                                    </Button>
                                </Stack>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};
