import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

export const useSideFormAccordion = () => {
    const { tabName, mode } = useParams();
    const navigate = useNavigate();

    const toggle = useCallback(
        (id: string) => {
            if (mode === 'edit') return;
            let path = `${id}/view`;
            if (tabName === id) path = '../..';
            else if (tabName) path = `../../${path}`;

            navigate(path, {
                replace: true,
                relative: 'path',
            });
        },
        [navigate, mode, tabName]
    );

    const edit = useCallback(() => {
        navigate('../edit', {
            replace: true,
            relative: 'path',
        });
    }, [navigate]);

    const view = useCallback(() => {
        navigate('../view', {
            replace: true,
            relative: 'path',
        });
    }, [navigate]);

    return useMemo(
        () => ({
            toggle,
            edit,
            view,
            openTab: tabName,
            isEdit: mode === 'edit',
        }),
        [toggle, edit, view, tabName, mode]
    );
};
