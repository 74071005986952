import React, { forwardRef, ReactNode } from 'react';
import { Stack, StackProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { LoadingOverlay } from '../loading-overlay';

export interface CardProp extends Partial<StackProps> {
    children: ReactNode;
    buttons?: ReactNode;
    loadingOverlay?: boolean;
    parentProps?: Partial<StackProps>;
    isSelected?: boolean;
    leftAdornment?: ReactNode;
    sx?: SystemStyleObject<Theme>;
}

export const Card = forwardRef<HTMLDivElement, CardProp>((props, ref) => {
    const {
        children,
        buttons,
        parentProps = {},
        leftAdornment,
        isSelected,
        loadingOverlay,
        sx,
        ...rest
    } = props;

    return (
        <Stack
            ref={ref}
            direction="row"
            gap={1}
            {...parentProps}
            sx={{
                position: 'relative',
                width: 1,
                ...(parentProps.sx || {}),
                alignItems: parentProps.alignItems || 'flex-start',
            }}
        >
            {leftAdornment}
            <Stack
                flexGrow={2}
                sx={{
                    '&&': sx,
                    border: isSelected
                        ? '1px solid #55B5CF'
                        : '1px solid transparent',
                    // transition: 'border 0.5s ease',
                    bgcolor: '#FFFFFF',
                    position: 'relative',
                    boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.3)',
                    borderRadius: '12px',
                    minWidth: '0',
                }}
                {...rest}
            >
                {children}
                {loadingOverlay && (
                    <LoadingOverlay sx={{ borderRadius: '12px' }} />
                )}
            </Stack>
            {!!buttons && (
                <Stack
                    flexShrink={0}
                    direction="column"
                    gap={1}
                    justifyContent="space-between"
                    sx={{
                        width: '36px',
                        ...(loadingOverlay && {
                            pointerEvents: 'none',
                            opacity: 0.7,
                        }),
                    }}
                >
                    {buttons}
                </Stack>
            )}
        </Stack>
    );
});
