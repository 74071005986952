/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum MemberRole {
    REFERRAL_PARTNER = 'referral_partner',
    CONTRIBUTOR = 'contributor',
    LEAD = 'lead',
    MANAGER = 'manager',
}
