import React, { useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { Product } from '@travelity/api';
import DirectionsRoundedIcon from '@mui/icons-material/DirectionsRounded';
import { RouteTooltip } from '../route-tooltip/route-tooltip';

export type SelectItemProductProps = {
    name: string;
    route: Product['route'];
};

export const SelectItemProduct: React.FC<SelectItemProductProps> = props => {
    const { name, route } = props;

    const stops = useMemo(() => {
        const list = [route.beginningLocation];

        route.stops.forEach(stop => {
            list.push(stop.name);
        });

        list.push(route.endingLocation || route.beginningLocation);
        return list;
    }, [route]);

    return (
        <Stack
            justifyContent="space-between"
            direction="row"
            sx={{
                width: 1,
                py: '4px',
                px: '4px',
            }}
        >
            <Box
                sx={{
                    lineHeight: '12px',
                    py: '4px',
                    px: '4px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}
            >
                {name}
            </Box>
            <RouteTooltip stops={stops}>
                <DirectionsRoundedIcon
                    fontSize="small"
                    sx={{
                        color: '#B2B9CD',
                        '&:hover': {
                            color: '#55B5CF',
                        },
                    }}
                />
            </RouteTooltip>
        </Stack>
    );
};
