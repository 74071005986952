import React from 'react';
import { Typography, TypographyProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export interface HeadingProps extends TypographyProps {
    sx?: SystemStyleObject<Theme>;
    color?: 'main' | 'secondary' | 'white';
    alpha?: 0 | 0.1 | 0.2 | 0.3 | 0.4 | 0.5 | 0.6 | 0.7 | 0.8 | 0.9 | 1;
    ellipsis?: boolean;
}

// const allColors = [
//     '#2B395B', // 15 main contrast text
//     '#3B4D7D', // 1 -> replace with main
//
//     // 0.7
//     '#6B758D', // 1 // 0.7
//     '#6B748C', // 4 // darker grey
//
//     // 0.5
//     '#949BAC', // 12 // 0.5
//     '#9198AA', // 5 // grey
//
//     // 0.4
//     '#B2B9CD', // 8 // lighter grey
//     '#B7BCC7', // 4 -> replace with prev
//
//     // 0.3
//     '#C0C4CE', // 17 // 0.3
//     '#CACEDC', // 5 // 0.7 lighter grey
//
//     '#36869C', // 6 // secondary color
// ];
// const fontSizes = ['12px', '14px', '16px', '20px', '24px'];
// const fontWeight = ['300', '400', '500', '600', '700'];

const colors = {
    main: '43, 57, 91',
    secondary: '54, 134, 156',
    white: '255, 255, 255',
};

export const Heading: React.FC<HeadingProps> = props => {
    const {
        sx = {},
        ellipsis,
        color = 'main',
        alpha = 1,
        children,
        ...rest
    } = props;

    return (
        <Typography
            sx={{
                color:
                    alpha !== 1
                        ? `rgba(${colors[color]}, ${alpha})`
                        : `rgb(${colors[color]})`,
                ...(ellipsis && {
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }),
                '&': sx,
            }}
            {...rest}
        >
            {children}
        </Typography>
    );
};
