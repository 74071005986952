import React from 'react';
import { Chip, ChipProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export interface TagListItemProps extends Partial<ChipProps> {
    sx?: SystemStyleObject<Theme>;
}

export const TagListItem: React.FC<TagListItemProps> = ({ sx, ...rest }) => {
    return (
        <Chip
            {...rest}
            tabIndex={-1}
            size="small"
            sx={{
                '&&&': sx || {},
                m: '5px 2px',
                height: 'auto',
                border: 0,
                p: '4px 8px',
                bgcolor: '#DDF0F5',
                '& > .MuiChip-label': {
                    fontSize: '14px',
                    color: '#2B395B',
                    lineHeight: '14px',
                },
                '& > .MuiChip-deleteIconSmall': {
                    ml: 0.5,
                    mr: 0,
                    fontSize: '14px',
                    color: '#9198AA',
                    lineHeight: '14px',
                    '&: hover': {
                        color: '#9198AA',
                    },
                },
            }}
            color="primary"
            variant="filled"
        />
    );
};
