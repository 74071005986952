import React, { useRef, useState } from 'react';
import { Slider, Button } from '@mui/material';
import AvatarEditor from 'react-avatar-editor';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingOverlay } from '@travelity/ui';
import DialogActions from '@mui/material/DialogActions';
import MuiButton from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';

export interface CropperModalProps {
    isLoading?: boolean;
    src?: string;
    modalOpen: boolean;
    closeModal: () => void;
    setPreview: (v: string) => void;
}

export const CropperModal: React.FC<CropperModalProps> = props => {
    const { isLoading, src, modalOpen, closeModal, setPreview } = props;
    const [slideValue, setSlideValue] = useState(10);
    const cropRef = useRef<AvatarEditor | null>(null);

    // handle save
    const handleSave = async () => {
        if (cropRef.current) {
            const dataUrl = cropRef.current?.getImage().toDataURL();
            setPreview(dataUrl);
            closeModal();
        }
    };

    return (
        <Dialog
            open={modalOpen}
            onClose={closeModal}
            PaperProps={{
                sx: {
                    py: 1,
                    bgcolor: '#FFF',
                    maxWidth: 450,
                    position: 'relative',
                },
            }}
        >
            <DialogTitle
                sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    fontWeight: 600,
                    color: '#2B395B',
                    mr: 2,
                }}
                id="alert-dialog-title"
            >
                Crop avatar image
            </DialogTitle>
            <DialogContent sx={{ p: 3 }}>
                {src && (
                    <AvatarEditor
                        ref={cropRef}
                        image={src}
                        style={{ width: '100%', height: '100%' }}
                        border={50}
                        borderRadius={150}
                        color={[0, 0, 0, 0.72]}
                        scale={slideValue / 10}
                        rotate={0}
                    />
                )}

                <Slider
                    min={10}
                    max={50}
                    sx={{
                        ml: 1,
                        width: 'calc(100% - 8px)',
                    }}
                    size="medium"
                    defaultValue={slideValue}
                    value={slideValue}
                    onChange={(e, value) => setSlideValue(value as number)}
                />
            </DialogContent>
            {isLoading && (
                <LoadingOverlay
                    title="Please wait..."
                    subTitle="It’ll just take a moment."
                />
            )}
            <DialogActions sx={{ px: 3, py: 2 }}>
                <MuiButton
                    color="neutral"
                    variant="contained"
                    disabled={!!isLoading}
                    onClick={() => closeModal()}
                >
                    Cancel
                </MuiButton>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleSave}
                    disabled={!!isLoading}
                    autoFocus
                >
                    Crop
                </Button>
            </DialogActions>
        </Dialog>
    );
};
