/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AgebandPricingData = {
    /**
     * Type of the pricing used
     */
    type: AgebandPricingData.type;
    /**
     * Unconditional base price/cost
     */
    base_amount?: number;
    /**
     * Price defined for infants.
     */
    infants?: number;
    /**
     * Price defined for children.
     */
    children?: number;
    /**
     * Price defined for youth.
     */
    youth?: number;
    /**
     * Price defined for adults.
     */
    adults?: number;
    /**
     * Price defined for seniors.
     */
    seniors?: number;
};

export namespace AgebandPricingData {

    /**
     * Type of the pricing used
     */
    export enum type {
        PER_AGEBAND = 'per_ageband',
    }


}
