/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateUserReqAccountMembershipsItemRoleDto = {
    /**
     * Type of user's role
     */
    type?: UpdateUserReqAccountMembershipsItemRoleDto.type;
};

export namespace UpdateUserReqAccountMembershipsItemRoleDto {

    /**
     * Type of user's role
     */
    export enum type {
        OWNER = 'owner',
    }


}
