/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PersonNameData } from './PersonNameData';

export type GetBookingsResItem0Source1Dto = {
    /**
     * type of the source
     */
    type: GetBookingsResItem0Source1Dto.type;
    /**
     * ID of the referenced user
     */
    id?: string;
    /**
     * name of the referenced user
     */
    name?: PersonNameData;
    /**
     * email of the referenced user
     */
    email: string;
    /**
     * name of the member's company
     */
    company?: string;
};

export namespace GetBookingsResItem0Source1Dto {

    /**
     * type of the source
     */
    export enum type {
        REFERRAL = 'referral',
    }


}
