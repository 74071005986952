import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import {
    Language,
    Booking,
    Customer,
    getCustomerDtoToCustomer,
    useAddCustomer,
    useAddTraveler,
    useUpdateTraveler,
    useRemoveTraveler,
    travelerToTravelerDto,
    Traveler,
    useUpdateCustomer,
    convertNameDtoToFullName,
    TravelerPickUp,
    RouteStop,
} from '@travelity/api';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import {
    Card,
    IconButton,
    IconButtonGroup,
    LoadingOverlay,
    Tag,
} from '@travelity/ui';
import { useGetCustomer1 } from '@travelity/api/src/queries';
import Edit from '@mui/icons-material/Edit';
import DoNotDisturbOn from '@mui/icons-material/DoNotDisturbOn';
import Check from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Close from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    CreatableTags,
    NumberField,
    SearchSelect,
    TagsList,
    TextField,
    useForm,
} from '@travelity/form';
import { convertEnumToList, emailRefEx } from '@travelity/utils';
import Attachments from '@travelity/form/src/controls/attachments/attachments';
import { useSnackbar } from 'notistack';
import { SelectPickupPoint } from '../../select-pickup-point/select-pickup-point';
import { TravelerForm } from '../booking-participants.types';
import { DataRow } from '../../data-row';
import SiblingBookingList from '../../../views/bookings/components/sibling-booking-list';
import { SingleLineTextField } from './single-line-text-field';
import { customerFormToCustomer } from '../booking-participants.converters';
import { schema } from './booking-traveler.schema';

export interface BookingTravelerCardProps {
    traveler: Partial<Traveler>;
    bookingId: string;
    siblingBookings: Booking[];
    stops: RouteStop[];
    updateTmp?: (tmp?: Partial<Traveler>) => void;
    travelerCap?: number;
}

const BookingTravelerCard: React.FC<BookingTravelerCardProps> = ({
    traveler,
    bookingId,
    siblingBookings,
    stops,
    updateTmp,
    travelerCap = 0,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const languages = useMemo(() => convertEnumToList<Language>(Language), []);

    const [showDetails, setShowDetails] = React.useState(!traveler.customerId);
    const [editing, setEditing] = React.useState(!traveler.travelerId);
    const [showSiblings, setShowSiblings] = React.useState(false);

    const [selectedSiblings, setSelectedSiblings] = React.useState<string[]>(
        []
    );

    // Traveler APIs
    const { mutate: addTraveler, isLoading: adding } = useAddTraveler(
        {
            onSuccess: () => {
                enqueueSnackbar('Traveler has been added', {
                    variant: 'success',
                });
            },
            onError: () => {
                enqueueSnackbar('Failed to add traveler', {
                    variant: 'error',
                });
            },
        },
        true
    );

    const { mutate: updateTraveler, isLoading: updating } = useUpdateTraveler(
        {
            onSuccess: () => {
                setEditing(false);
                enqueueSnackbar('Traveler saved', {
                    variant: 'success',
                });
            },
            onError: () => {
                enqueueSnackbar('Failed to update traveler', {
                    variant: 'error',
                });
            },
        },
        true
    );

    const { mutate: removeTraveler, isLoading: removing } = useRemoveTraveler(
        {
            onSuccess: () => {
                enqueueSnackbar('Traveler was removed', {
                    variant: 'success',
                });
            },
            onError: () => {
                enqueueSnackbar('Failed to remove traveler', {
                    variant: 'error',
                });
            },
        },
        true
    );

    // Handle sibling copy
    const addTravelerSiblings = useCallback(
        (t: TravelerForm) => {
            selectedSiblings.forEach(siblingId => {
                addTraveler({
                    traveler: travelerToTravelerDto(t),
                    bookingId: siblingId,
                });
            });
        },
        [selectedSiblings]
    );

    const { data: customer, isLoading: isCustomerFetching } = useGetCustomer1(
        {
            customerId: traveler.travelerId || (traveler.customerId as string),
        },
        undefined,
        {
            enabled:
                (!!traveler.travelerId || !!traveler.customerId) && showDetails,
        }
    );

    const { mutate: addCustomer, isLoading: addingCustomer } = useAddCustomer({
        onSuccess: data => {
            setShowDetails(false);
            updateTmp?.({
                customerId: data.id,
                fullName: convertNameDtoToFullName(data.name),
                guestCount: traveler.guestCount,
            });
            enqueueSnackbar('New customer profile created', {
                variant: 'success',
            });
        },
    });

    const { mutate: updateCustomer, isLoading: updatingCustomer } =
        useUpdateCustomer(customer, {
            onError: error => {
                // @ts-ignore
                if (error?.status !== 409) {
                    enqueueSnackbar('Failed to update the customer', {
                        variant: 'error',
                    });
                }
            },
        });

    const { Form, handleSubmit, reset, watch, setValue, getValues } =
        useForm<TravelerForm>({
            onSubmit: useCallback(() => {}, []),
            schema,
            context: { customer: showDetails },
        });

    useEffect(() => {
        if (customer) {
            // TODO reset undefined
            reset({ ...getValues(), ...getCustomerDtoToCustomer(customer) });
        }
    }, [customer]);

    const onSubmit = handleSubmit(() => {
        const item = getValues();

        // Step 1: Customer Creation
        if (!traveler.customerId) {
            return addCustomer({
                customer: customerFormToCustomer({
                    ...item,
                    id: item.customerId,
                    attachments: item.attachments || [],
                    fullName: item.fullName,
                } as Customer),
                // @ts-ignore
                attachments: item.attachments || [],
            });
        }

        // Step 2: Customer Update
        if (!showSiblings && showDetails) {
            updateCustomer({
                id: traveler.customerId,
                customer: customerFormToCustomer({
                    ...item,
                    id: item.customerId,
                    attachments: item.attachments || [],
                    fullName: item.fullName,
                } as Customer),
                attachments: item.attachments,
            });
        }

        // Step 2: Traveler Creation
        if (!showSiblings && !item.travelerId) {
            return addTraveler(
                { traveler: travelerToTravelerDto(item), bookingId },
                {
                    onSuccess: () => {
                        if (siblingBookings.length) {
                            setShowSiblings(true);
                        } else {
                            updateTmp?.(undefined);
                        }
                    },
                }
            );
        }

        // Step 2: Traveler Update
        if (!showSiblings && item.travelerId) {
            const { pickUp, ...other } = item;
            return updateTraveler({
                traveler: travelerToTravelerDto(other),
                bookingId,
                pickUp,
                oldPickUp: traveler.pickUp,
            });
        }

        // Step 3: Traveler Siblings
        updateTmp?.(undefined);
        return addTravelerSiblings(item);
    });

    useEffect(() => {
        reset(traveler, { keepValues: true });
    }, [traveler, reset]);

    const hasNext =
        !traveler.customerId ||
        (siblingBookings.length && !showSiblings && !traveler.travelerId);

    const isCustomerLoading =
        (!!traveler.customerId || !!traveler.travelerId) && isCustomerFetching;

    const emails = customer?.contact_details?.emails || [];
    const phoneNumbers =
        customer?.contact_details?.phone_numbers?.map(
            ({ calling_code, number }) => `+${calling_code} ${number}`
        ) || [];

    const saving =
        addingCustomer || updatingCustomer || removing || adding || updating;

    return (
        <>
            {traveler.customerId ? (
                <SelectPickupPoint
                    selected={editing ? watch('pickUp') : traveler.pickUp}
                    setSelected={(v?: TravelerPickUp) => setValue('pickUp', v)}
                    editing={editing}
                    stops={stops}
                    disabled={saving}
                />
            ) : (
                <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    sx={{
                        width: 'calc(100% - 40px)',
                        flexGrow: 2,
                        alignItems: 'flex-start',
                        padding: '10px 12px',
                        borderRadius: '12px',
                        mr: 5,
                        border: '1px solid rgba(30, 41, 120, 0.10)',
                        background: '#FFF',
                        boxShadow: '0px 1px 12px 0px rgba(178, 185, 205, 0.30)',
                    }}
                >
                    <Typography
                        sx={{
                            whiteSpace: 'nowrap',
                            color: '#2B395B',
                            fontSize: '14px',
                            lineHeight: '100%',
                        }}
                    >
                        Create new Customer
                    </Typography>
                </Stack>
            )}
            <Card
                sx={{
                    minHeight: '116px',
                    maxHeight: showDetails || showSiblings ? '1000px' : '116px',
                    transition: 'max-height 0.2s ease-in',
                }}
                parentProps={{ sx: { opacity: saving ? 0.7 : 1 } }}
                buttons={
                    <>
                        <IconButtonGroup flexShrink={0}>
                            {!editing && (
                                <>
                                    <IconButton
                                        icon={<Edit fontSize="small" />}
                                        onClick={() => setEditing(true)}
                                        tooltip="Edit"
                                        tooltipPlacement="right"
                                        disabled={saving}
                                    />
                                    <Divider sx={{ mx: 1 }} />
                                    <IconButton
                                        icon={
                                            <DoNotDisturbOn fontSize="small" />
                                        }
                                        hoverColor="error.main"
                                        onClick={() => {
                                            removeTraveler({
                                                bookingId,
                                                travelerId:
                                                    (traveler.travelerId ||
                                                        traveler.customerId) as string,
                                            });
                                        }}
                                        tooltip="Remove"
                                        disabled={saving}
                                    />
                                </>
                            )}
                            {editing && (
                                <>
                                    {!hasNext ? (
                                        <IconButton
                                            icon={<Check fontSize="small" />}
                                            color="success.main"
                                            hoverColor="success.dark"
                                            onClick={onSubmit}
                                            tooltip="Save"
                                            data-testid="save"
                                        />
                                    ) : (
                                        <IconButton
                                            icon={
                                                <ArrowForwardIcon fontSize="small" />
                                            }
                                            color="primary.main"
                                            hoverColor="primary.dark"
                                            onClick={onSubmit}
                                            tooltip="Next"
                                            data-testid="next"
                                        />
                                    )}
                                    <Divider sx={{ mx: 1 }} />
                                    {!showSiblings ? (
                                        <IconButton
                                            icon={<Close fontSize="small" />}
                                            color="error.main"
                                            hoverColor="error.dark"
                                            onClick={() => {
                                                setEditing(false);
                                                if (!traveler.travelerId) {
                                                    updateTmp?.();
                                                }
                                            }}
                                            tooltip="Cancel"
                                            data-testid="cancel"
                                        />
                                    ) : (
                                        <IconButton
                                            icon={
                                                <ArrowBackIcon fontSize="small" />
                                            }
                                            color="primary.main"
                                            hoverColor="primary.dark"
                                            onClick={() =>
                                                setShowSiblings(false)
                                            }
                                            tooltip="Back"
                                            data-testid="prev"
                                        />
                                    )}
                                </>
                            )}
                        </IconButtonGroup>
                        {traveler.customerId && !showSiblings ? (
                            <IconButton
                                icon={<AccountBoxIcon fontSize="small" />}
                                withShadow
                                onClick={() => setShowDetails(!showDetails)}
                                tooltip="Customer"
                                tooltipPlacement="left"
                                pressed={showDetails}
                                disabled={saving}
                            />
                        ) : null}
                    </>
                }
            >
                <>
                    <Form>
                        <Box
                            sx={{
                                py: '22px',
                                px: 0.75,
                                color: '#9298ab',
                                bgcolor: '#f7f8fa',
                                height: '36px',
                                fontWeight: 600,
                                borderRadius: '12px 12px 0px 0px',
                                lineHeight: '10px',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            {editing && showDetails ? (
                                <Stack direction="row" sx={{ width: 1 }}>
                                    <TextField
                                        sx={{ mx: '10px' }}
                                        autoFocus
                                        InputProps={{
                                            sx: { fontSize: '14px', pl: 0.5 },
                                        }}
                                        placeholder="Full Name"
                                        name="fullName"
                                        variant="standard"
                                        showErrors
                                        helperText=""
                                        fullWidth
                                    />
                                    <Tag label="Type" value="Traveler" />
                                </Stack>
                            ) : (
                                <Stack
                                    direction="row"
                                    sx={{ width: 1, pl: '10px' }}
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Box component="span">
                                        {traveler.fullName}
                                    </Box>
                                    <Tag label="Type" value="Traveler" />
                                </Stack>
                            )}
                        </Box>
                        {editing && showSiblings ? (
                            <SiblingBookingList
                                bookings={siblingBookings}
                                selected={selectedSiblings}
                                setSelected={setSelectedSiblings}
                            />
                        ) : (
                            <Stack gap={0.5} p={2}>
                                {editing && traveler.customerId && (
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        gap={1}
                                        sx={{ mt: -0.75 }}
                                    >
                                        <Typography
                                            sx={{
                                                color: '#949BAC',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Accompanying Travelers
                                        </Typography>
                                        <NumberField
                                            size="small"
                                            name="guestCount"
                                            label=""
                                            showZero
                                            max={
                                                traveler.travelerId
                                                    ? travelerCap
                                                    : Math.max(
                                                          0,
                                                          travelerCap - 1
                                                      )
                                            }
                                        />
                                    </Stack>
                                )}
                                {!editing && (
                                    <DataRow
                                        label="Accompanying Travelers"
                                        value={`${traveler.guestCount || 0}`}
                                        darkValue
                                        tagValue
                                        sx={{ mb: 1, mt: '3px' }}
                                    />
                                )}
                                {showDetails && (
                                    <>
                                        <Divider
                                            sx={{
                                                mt: editing ? 1 : 0,
                                            }}
                                        >
                                            Contact Details
                                        </Divider>
                                        {editing && showDetails ? (
                                            <>
                                                <Typography
                                                    sx={{
                                                        color: '#2B395B',
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    Emails
                                                </Typography>
                                                <CreatableTags
                                                    name="emails"
                                                    regEx={emailRefEx}
                                                    inputProps={{
                                                        size: 'small',
                                                        sx: {
                                                            '&& .MuiInputBase-root':
                                                                {
                                                                    paddingRight:
                                                                        '10px',
                                                                },
                                                        },
                                                        placeholder:
                                                            'Type email and press enter',
                                                    }}
                                                    showErrors
                                                />
                                                <Typography
                                                    sx={{
                                                        color: '#2B395B',
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    Phone Numbers
                                                </Typography>
                                                <CreatableTags
                                                    name="numbers"
                                                    regEx={/^\+?\d+ \d+$/}
                                                    inputProps={{
                                                        size: 'small',
                                                        sx: {
                                                            '&& .MuiInputBase-root':
                                                                {
                                                                    paddingRight:
                                                                        '10px',
                                                                },
                                                        },
                                                        placeholder:
                                                            'Type number and press enter',
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <Stack
                                                direction="column"
                                                gap={2}
                                                sx={{ pt: 1 }}
                                            >
                                                <DataRow
                                                    label="Emails"
                                                    value={emails.join(', ')}
                                                    isLoading={
                                                        isCustomerLoading
                                                    }
                                                />
                                                <DataRow
                                                    label="Phone Numbers"
                                                    value={phoneNumbers.join(
                                                        ', '
                                                    )}
                                                    isLoading={
                                                        isCustomerLoading
                                                    }
                                                />
                                            </Stack>
                                        )}
                                        {editing && (
                                            <>
                                                <Divider
                                                    sx={{ mt: 1, mb: 0.5 }}
                                                >
                                                    Languages
                                                </Divider>
                                                <SearchSelect
                                                    placeholder="Select Language"
                                                    name="languages"
                                                    multiple
                                                    label=""
                                                    size="small"
                                                    width="100%"
                                                    options={languages}
                                                />
                                            </>
                                        )}
                                        {!editing &&
                                            !!customer?.languages?.length && (
                                                <>
                                                    <Divider
                                                        sx={{ mt: 1, mb: 0.5 }}
                                                    >
                                                        Languages
                                                    </Divider>
                                                    <TagsList
                                                        multiple
                                                        sx={{
                                                            ml: -1,
                                                            mb: -0.5,
                                                        }}
                                                        tagSx={{
                                                            opacity: 1,
                                                            py: 0.75,
                                                            bgcolor:
                                                                'rgba(85, 181, 207, 0.2)',
                                                            color: '#9198AA',
                                                        }}
                                                        name="languages"
                                                        selectAll={false}
                                                        disabled
                                                        hideUnselected
                                                        options={languages}
                                                    />
                                                </>
                                            )}
                                        <Divider sx={{ mt: 1.5 }}>
                                            Personal Information
                                        </Divider>
                                        <SingleLineTextField
                                            label="Date of Birth"
                                            name="birthDate"
                                            width="138px"
                                            readOnly={!editing}
                                            isLoading={isCustomerLoading}
                                        />
                                        <SingleLineTextField
                                            label="Place of Birth"
                                            name="birthPlace"
                                            width="138px"
                                            readOnly={!editing}
                                            isLoading={isCustomerLoading}
                                        />
                                        <SingleLineTextField
                                            label="Nationality"
                                            name="nationality"
                                            width="138px"
                                            readOnly={!editing}
                                            isLoading={isCustomerLoading}
                                        />
                                        <Divider sx={{ mt: 1.5 }}>
                                            Identification Document
                                        </Divider>
                                        <Stack direction="row" gap={1}>
                                            <Box sx={{ width: 0.5 }}>
                                                <SingleLineTextField
                                                    label="Passport №"
                                                    name="passportN"
                                                    readOnly={!editing}
                                                    isLoading={
                                                        isCustomerLoading
                                                    }
                                                />
                                                <SingleLineTextField
                                                    label="Issued at"
                                                    name="issuedAt"
                                                    readOnly={!editing}
                                                    isLoading={
                                                        isCustomerLoading
                                                    }
                                                />
                                                <SingleLineTextField
                                                    label="Citizenship"
                                                    name="citizenship"
                                                    readOnly={!editing}
                                                    isLoading={
                                                        isCustomerLoading
                                                    }
                                                />
                                            </Box>
                                            <Box sx={{ width: 0.5 }}>
                                                <SingleLineTextField
                                                    label="Issued by"
                                                    name="issuedBy"
                                                    readOnly={!editing}
                                                    isLoading={
                                                        isCustomerLoading
                                                    }
                                                />
                                                <SingleLineTextField
                                                    label="Expires at"
                                                    name="expiresAt"
                                                    readOnly={!editing}
                                                    isLoading={
                                                        isCustomerLoading
                                                    }
                                                />
                                            </Box>
                                        </Stack>
                                        {(!!customer?.attachments?.length ||
                                            editing) && (
                                            <>
                                                <Divider sx={{ mt: 1.5 }}>
                                                    Attachments
                                                </Divider>
                                                <Attachments
                                                    readOnly={!editing}
                                                    name="attachments"
                                                />
                                            </>
                                        )}
                                    </>
                                )}
                            </Stack>
                        )}
                    </Form>
                    {saving && <LoadingOverlay />}
                </>
            </Card>
        </>
    );
};

export default React.memo(BookingTravelerCard);
