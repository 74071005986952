import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import theme from './theme';
import routes from './routes';
import { SnackbarProvider } from './contexts/snackbar';

// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme()}>
                <CssBaseline />
                <SnackbarProvider>
                    <QueryClientProvider client={queryClient}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <RouterProvider router={routes} />
                        </LocalizationProvider>
                    </QueryClientProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
