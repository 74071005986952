/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum BaseEventStaffMemberPosition {
    ADMINISTRATOR = 'administrator',
}
