/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FlatCapacityData = {
    /**
     * type of the capacity selected
     */
    type: FlatCapacityData.type;
    /**
     * Minimum flat count that defines the capacity
     */
    min: number;
    /**
     * Maximum flat count that defines the capacity
     */
    max: number;
};

export namespace FlatCapacityData {

    /**
     * type of the capacity selected
     */
    export enum type {
        FLAT = 'flat',
    }


}
