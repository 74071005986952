import React, { useState } from 'react';
import { TextField, TextFieldProps } from '@travelity/form';
import { Skeleton, Stack, Typography } from '@mui/material';

export interface SingleLineTextFieldProps {
    label: string;
    name: string;
    width?: string;
    readOnly?: boolean;
    isLoading?: boolean;
    textFieldProps?: Partial<TextFieldProps>;
}

export const SingleLineTextField: React.FC<
    SingleLineTextFieldProps
> = props => {
    const {
        label,
        name,
        width,
        isLoading,
        readOnly,
        textFieldProps = {},
    } = props;
    const [randomWidth] = useState(Math.ceil(Math.random() * 20 + 50));

    return (
        <Stack direction="row" gap={0.5}>
            <Typography
                sx={{
                    pt: 1,
                    color: '#9198AA',
                    fontSize: '14px',
                    width: width || '50%',
                }}
            >
                {label}
            </Typography>
            {isLoading ? (
                <Stack
                    sx={{
                        mx: '4px',
                        flexGrow: 2,
                        width: '50%',
                        alignItems: 'end',
                        justifyContent: 'center',
                    }}
                >
                    <Skeleton width={randomWidth} sx={{ fontSize: '14px' }} />
                </Stack>
            ) : (
                <TextField
                    name={name}
                    variant="standard"
                    showErrors
                    helperText=""
                    {...textFieldProps}
                    sx={{
                        mx: '4px',
                        flexGrow: 2,
                        width: '50%',
                        ...(readOnly ? { pointerEvents: 'none' } : {}),
                    }}
                    InputProps={{
                        sx: {
                            '& input': {
                                textAlign: 'right',
                                ...(readOnly
                                    ? {
                                          paddingBottom: '0',
                                          paddingTop: '8px',
                                      }
                                    : {}),
                            },
                            fontSize: '14px',
                            pl: 0,
                            '&:before': {
                                borderColor: readOnly
                                    ? 'transparent'
                                    : '#D5D7DE',
                            },
                            '&:after': {
                                borderColor: '#2B395B',
                                borderWidth: '1px',
                            },
                            '&:hover': {
                                '&&&:not(.Mui-error):before': {
                                    borderColor: '#2B395B',
                                    borderWidth: '1px',
                                },
                            },
                        },
                    }}
                />
            )}
        </Stack>
    );
};
