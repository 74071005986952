/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetUserResAccountMembershipsItemRoleAccessDto } from './GetUserResAccountMembershipsItemRoleAccessDto';

export type GetUserResAccountMembershipsItemRoleDto = {
    /**
     * Type of user's role
     */
    type: GetUserResAccountMembershipsItemRoleDto.type;
    /**
     * Access details of the current role
     */
    access?: GetUserResAccountMembershipsItemRoleAccessDto;
};

export namespace GetUserResAccountMembershipsItemRoleDto {

    /**
     * Type of user's role
     */
    export enum type {
        OWNER = 'owner',
    }


}
