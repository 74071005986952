import { useFieldArray, useFormContext, UseFormReturn } from 'react-hook-form';
import React, { ReactElement, useCallback } from 'react';
import { ButtonProps, Theme } from '@mui/material';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { Sortable, CardListContainer } from '@travelity/ui';
import CardItem from './card-item';
import { UseFormParams } from '../../use-form';
import { SystemStyleObject } from '@mui/system';
import _ from 'lodash';

export interface RenderContentProps {
    editing: boolean;
    item: Record<string, any>;
    listeners?: SyntheticListenerMap[];
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
    form?: UseFormReturn<Record<string, any>>;
}

export interface CardItemOption {
    label: string;

    [k: string]: any;
}

export interface CardListProps extends Partial<ButtonProps> {
    disabled?: boolean;
    maxHeight?: number;
    renderPreComponent?: (p: RenderContentProps) => ReactElement | null;
    renderHeader?: (
        p: RenderContentProps,
        index: number
    ) => ReactElement | null;
    renderContent: (p: RenderContentProps) => ReactElement | null;
    renderCustomButtons?: (p: RenderContentProps) => ReactElement | null;
    onNextStep?: (p: RenderContentProps) => void;
    name: string;
    showErrors?: boolean;
    headerSx?: Record<string, any>;
    sx?: SystemStyleObject<Theme>;
    addButtonText: string;
    itemOptions?: CardItemOption[];
    formParams?: Partial<UseFormParams<Record<string, any>>>;
    defaultState?: Record<string, any>;
    disableAdding?: boolean;
    disableReorder?: boolean;
    disableEdit?: boolean;
    disableItemEdit?: (v: Record<string, any>) => boolean;
    disableRemove?: boolean;
    noEmptyState?: boolean;
    onItemSave?: (
        v: Record<string, any>,
        o?: Record<string, any>
    ) => Promise<Record<string, any>> | Record<string, any> | void;
    onItemRemove?: (v: Record<string, any>) => void;
    customAdd?: (c: (newItem: Record<string, any>) => void) => void;
    customEdit?: (
        item: Record<string, any>,
        c: (newItem: Record<string, any>) => void
    ) => void;
}

const CardList: React.FC<CardListProps> = ({
    disabled,
    maxHeight,
    name,
    showErrors,
    renderPreComponent,
    renderHeader,
    renderContent,
    renderCustomButtons,
    onNextStep,
    addButtonText,
    headerSx,
    itemOptions,
    formParams,
    defaultState,
    disableAdding,
    disableReorder,
    disableEdit,
    disableItemEdit,
    disableRemove,
    noEmptyState,
    onItemSave,
    onItemRemove,
    customAdd,
    customEdit,
    sx,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const {
        fields: items,
        update,
        replace,
        remove,
        append,
    } = useFieldArray<Record<string, any>, string, string>({
        control,
        name,
    });

    const error =
        showErrors && (_.get(errors, name)?.message as string | undefined);

    const sortableIndexes = items.map(v => v.id);

    const setSorting = (newSorting: string[]) => {
        replace(newSorting.map(id => items.find(item => item.id === id)));
    };

    const onEdit = useCallback(
        (index: number) => {
            if (customEdit) {
                return customEdit(items[index], newItem =>
                    update(index, newItem)
                );
            }
            const newItem = { ...items[index], isEdit: true };
            update(index, newItem);
        },
        [customEdit, items, update]
    );

    const onAdd = useCallback(
        (initial = defaultState) => {
            if (customAdd) {
                return customAdd(newItem => append(newItem));
            }
            append({ isNew: true, ...initial });
        },
        [defaultState, append, customAdd]
    );

    const onSave = useCallback(
        (index: number, item: Record<string, any>) => {
            const newItem = { ...item, isNew: false, isEdit: false };
            if (onItemSave) {
                const res = onItemSave(newItem, items[index]);
                if (res && 'then' in res) {
                    update(index, { ...items[index], isFetching: true });
                    res.then((r: Record<string, any>) => update(index, r));
                } else if (res) {
                    update(index, res);
                }
            } else {
                update(index, newItem);
            }
        },
        [items, onItemSave, update]
    );

    const onCancel = useCallback(
        (index: number) => {
            const item = items[index];
            if (item?.isNew) {
                remove(index);
            } else {
                update(index, { ...item, isEdit: false });
            }
        },
        [items, remove, update]
    );

    const onDelete = useCallback(
        (index: number) => {
            if (onItemRemove) onItemRemove(items[index]);
            remove(index);
        },
        [items, remove, onItemRemove]
    );

    const hasAnyEdit = items.some(item => item.isEdit || item.isNew);

    return (
        <CardListContainer
            items={items.length}
            addButtonText={addButtonText}
            disableAdding={disableAdding || hasAnyEdit}
            noPlaceholder={noEmptyState}
            readonly={disabled}
            maxHeight={maxHeight}
            addOptions={itemOptions}
            sx={sx}
            onAdd={onAdd}
            error={!!error}
        >
            <Sortable
                items={sortableIndexes}
                setItems={setSorting}
                renderItem={obj => (
                    <>
                        <CardItem
                            index={obj.index}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            onEditSave={onSave}
                            onEditDiscard={onCancel}
                            editing={
                                !!items[obj.index]?.isEdit ||
                                !!items[obj.index]?.isNew
                            }
                            items={items}
                            item={items[obj.index]}
                            setActivatorNodeRef={obj.setActivatorNodeRef}
                            listeners={obj.listeners}
                            style={obj.style}
                            placeholder={obj.dragging}
                            renderHeader={renderHeader}
                            renderPreComponent={renderPreComponent}
                            headerSx={headerSx}
                            renderContent={renderContent}
                            renderCustomButtons={renderCustomButtons}
                            onNextStep={onNextStep}
                            ref={obj.ref}
                            formParams={formParams}
                            disableReorder={disableReorder}
                            disableEdit={disableEdit}
                            disableItemEdit={disableItemEdit}
                            disableRemove={disableRemove}
                        />
                    </>
                )}
            />
        </CardListContainer>
    );
};

export default CardList;
