import { useController, useFormContext } from 'react-hook-form';
import React from 'react';
import {
    OutlinedInputProps,
    IconButton,
    InputAdornment,
    OutlinedInput,
} from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';

export type NumberFieldProps = OutlinedInputProps & {
    name: string;
    label: string;
    showErrors?: boolean;
    required?: boolean;
    showZero?: boolean;
    max?: number;
};

const numberCheck = (val: string | number, max = 999, min = 0) =>
    String(val).match(/[0-9]+/) && Number(val) <= max && Number(val) >= min;

const NumberField: React.FC<NumberFieldProps> = ({
    name,
    required,
    label,
    showErrors,
    showZero,
    max = 999,
    ...rest
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const error =
        showErrors && (_.get(errors, name)?.message as string | undefined);
    const { field } = useController({ name, control });

    const numberValue = field.value || 0;
    return (
        <OutlinedInput
            error={!!error}
            name={name}
            disabled={false}
            onKeyDown={evt => {
                if (
                    evt.key === 'e' ||
                    evt.key === 'E' ||
                    evt.key === '.' ||
                    evt.key === '+' ||
                    evt.key === '-'
                ) {
                    evt.preventDefault();
                }
            }}
            inputProps={{
                min: 0,
                max: 99,
                sx: {
                    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
                        {
                            '-webkit-appearance': 'none',
                            margin: 0,
                        },
                    '-moz-appearance': 'textfield',

                    textAlign: 'center',
                    fontWeight: '700',
                },
            }}
            type="number"
            startAdornment={
                <InputAdornment position="start">
                    <IconButton
                        tabIndex={-1}
                        size="small"
                        aria-label="toggle password visibility"
                        disabled={!field.value}
                        onClick={() => {
                            if (numberCheck(field.value - 1, max)) {
                                field.onChange(Number(field.value - 1));
                            }
                        }}
                        sx={{
                            ...(!field.value && {
                                opacity: 0.3,
                            }),
                        }}
                        edge="start"
                    >
                        <RemoveIcon sx={{ color: '#6B758D' }} />
                    </IconButton>
                </InputAdornment>
            }
            value={field.value || (showZero ? 0 : '')}
            onChange={e => {
                if (numberCheck(e.target.value, max)) {
                    field.onChange(Number(e.target.value));
                } else if (e.target.value === '') {
                    field.onChange('');
                }
            }}
            {...rest}
            sx={{
                ...(rest.sx || {}),
                '&&&': {
                    px: rest.size === 'small' ? '8px' : '12px',
                },
                '&::before, &::after': {
                    border: 'none!important',
                },
            }}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        size="small"
                        disabled={numberValue >= max}
                        onClick={() => {
                            if (
                                numberCheck(field.value + 1, max) ||
                                !field.value
                            ) {
                                field.onChange(
                                    Number(field.value ? field.value + 1 : 1)
                                );
                            }
                        }}
                        sx={{
                            ...(numberValue >= max && {
                                opacity: 0.3,
                            }),
                        }}
                        edge="end"
                    >
                        <AddIcon sx={{ color: '#6B758D' }} />
                    </IconButton>
                </InputAdornment>
            }
        />
    );
};

export default NumberField;
