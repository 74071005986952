import { useMemo } from 'react';
import {
    InfiniteData,
    useMutation,
    UseMutationOptions,
    useQueryClient,
} from '@tanstack/react-query';
import {
    useCreateProduct,
    useGetProducts1,
    useGetProductsLazy,
    useUpdateProduct1,
    useUpdateProduct11,
    useUpdateProduct111,
} from '../../queries';
import {
    compareTimeOffset,
    convertOptionToOptionDto,
    getProduct1DtoToProduct1,
    getProductDtoToProduct,
    pricingToPricingDto,
    routeStopsToStopsDto,
} from './product.converters';
import {
    CreateProductReqDto,
    GetProductsResDto,
    ProductsService,
    UpdateProductResDto,
} from '../../requests';
import { CustomMutationOptions } from '../common.types';
import { Option, ProductFinancial, Stop } from './product.types';
import { convertItemsToActionItemsDto } from '../common.converters';

export { useCreateProduct };

type UseGetProductParamTypes = Parameters<typeof useGetProductsLazy>;
export const useProductsLazy = (
    params: UseGetProductParamTypes[0] = {},
    options: UseGetProductParamTypes[2] = {}
) => {
    const queryKey = ['ProductsServiceGetProducts', params];
    const { data, ...other } = useGetProductsLazy(params, [params], options);

    const queryClient = useQueryClient();
    const update = (productId: string, product: UpdateProductResDto) => {
        queryClient.setQueryData<InfiniteData<GetProductsResDto>>(
            queryKey,
            // @ts-ignore
            products => {
                if (!products?.pages) return undefined;
                const pages = products.pages.map(page => {
                    const items =
                        page.items?.map(prd =>
                            prd.id === productId ? { ...prd, ...product } : prd
                        ) || [];
                    return { ...products, items };
                });
                return { ...products, pages };
            }
        );
    };

    const parsedData = useMemo(
        () =>
            data?.pages
                ? data.pages
                      .map(page =>
                          // @ts-ignore
                          (page.items || []).map(getProductDtoToProduct)
                      )
                      .reduce((arr, cur) => [...arr, ...cur], [])
                : undefined,
        [data]
    );

    return {
        update,
        data: parsedData,
        ...other,
    };
};

type UseGetProduct1ParamTypes = Parameters<typeof useGetProducts1>;
export const useProducts = (
    params: UseGetProduct1ParamTypes[0] = {},
    options: UseGetProduct1ParamTypes[2] = {}
) => {
    const queryKey = ['GetProducts', params];
    const { data, ...other } = useGetProducts1(
        { ...params, pageSize: 1000 },
        [params],
        {
            staleTime: 30 * 60 * 1000,
            cacheTime: 5 * 60 * 1000,
            ...options,
        }
    );

    return {
        data: data?.items.map(getProduct1DtoToProduct1),
        ...other,
    };
};

export const useAddProduct = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof ProductsService.createProduct> | undefined
            >,
            unknown,
            {
                requestBody: CreateProductReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const queryClient = useQueryClient();
    // @ts-ignore
    return useCreateProduct({
        ...options,
        onSuccess: (...arg) => {
            queryClient.invalidateQueries({
                queryKey: ['ProductsServiceGetProducts'],
                exact: false,
            });
            // @ts-ignore
            options?.onSuccess?.(...arg);
        },
    });
};

export const useUpdateProductRoutes = (
    options: CustomMutationOptions<{
        items: Stop[];
    }>,
    productId: string,
    oldItems: Stop[]
) => {
    const { mutateAsync: update } = useUpdateProduct1();

    return useMutation(async ({ items }) => {
        const requestItems = convertItemsToActionItemsDto(
            routeStopsToStopsDto(oldItems),
            routeStopsToStopsDto(items),
            'id',
            (oldItem, newItem) =>
                oldItem.location?.name !== newItem.location?.name ||
                !compareTimeOffset(
                    oldItem.schedule?.stop_duration,
                    newItem.schedule?.stop_duration
                ) ||
                !compareTimeOffset(
                    oldItem.schedule?.arrival_offset,
                    newItem.schedule?.arrival_offset
                )
        );

        if (requestItems.length) {
            return update({
                productId,
                requestBody: {
                    round_trip: items[0]?.repeatLocation,
                    items: requestItems,
                },
            });
        }
        return {};
    }, options);
};

export const useUpdateProductPricing = (
    options: CustomMutationOptions<ProductFinancial>,
    productId: string,
    oldFinancials?: ProductFinancial
) => {
    const { mutateAsync: update } = useUpdateProduct11();

    return useMutation(async ({ items, ...financial }) => {
        const requestItems = convertItemsToActionItemsDto(
            oldFinancials?.items.map(pricingToPricingDto) || [],
            items.map(pricingToPricingDto),
            'id',
            (oldItem, newItem) =>
                JSON.stringify(oldItem) !== JSON.stringify(newItem)
        );

        if (requestItems.length) {
            return update({
                productId,
                requestBody: {
                    payment_requirements: {
                        methods: financial.paymentMethods,
                        prepayment: {
                            required: !!financial.prepayment,
                            type: financial.prepaymentType,
                            amount: financial.prepaymentAmount,
                        },
                    },
                    items: requestItems,
                },
            });
        }
        return {};
    }, options);
};

export const useUpdateProductOptions = (
    options: CustomMutationOptions<{
        items: Option[];
    }>,
    productId: string,
    oldItems: Option[]
) => {
    const { mutateAsync: update } = useUpdateProduct111();

    return useMutation(async ({ items, ...financial }) => {
        const requestItems = convertItemsToActionItemsDto(
            oldItems.map(convertOptionToOptionDto),
            items.map(convertOptionToOptionDto),
            'id',
            (oldItem, newItem) =>
                JSON.stringify(oldItem) !== JSON.stringify(newItem)
        );

        if (requestItems.length) {
            return update({
                productId,
                requestBody: {
                    items: requestItems,
                },
            });
        }
        return {};
    }, options);
};
