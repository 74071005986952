import React from 'react';
import { Box, Divider, Stack } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Route, Routes } from 'react-router-dom';
import NavItem from './components/nav-item';
import PersonalDetails from './components/personal-details';
import Settings from './components/settings';
import FinancialSettings from './components/financial-settings';

function UserProfile() {
    return (
        <Stack direction="row" sx={{ height: 1 }} spacing={1} p={2}>
            <Box
                sx={{
                    flexGrow: 1,
                    bgcolor: '#FFFFFF',
                    py: 3,
                    px: 2,
                    overflow: 'auto',
                    position: 'relative',
                }}
            >
                <PerfectScrollbar>
                    <Routes>
                        <Route
                            path="/personal-details"
                            element={<PersonalDetails />}
                        />
                        <Route path="/settings" element={<Settings />} />
                        <Route
                            path="/financial-settings"
                            element={<FinancialSettings />}
                        />
                    </Routes>
                </PerfectScrollbar>
            </Box>
            <Box
                sx={{
                    p: 3,
                    flexShrink: 0,
                    width: 276,
                    bgcolor: '#FFFFFF',
                    overflow: 'auto',
                }}
            >
                <Divider
                    sx={{ fontWeight: 700, '& > span': { color: '#B2B9CD' } }}
                >
                    Profile Settings
                </Divider>
                <NavItem
                    title="Personal Details"
                    icon={ManageAccountsIcon}
                    url="/me/personal-details"
                    pattern="/me/personal-details"
                />

                <Divider
                    sx={{ fontWeight: 700, '& > span': { color: '#B2B9CD' } }}
                >
                    App Settings
                </Divider>
                <NavItem
                    title="Vendibility Settings"
                    icon={SettingsApplicationsIcon}
                    url="/me/settings"
                    pattern="/me/settings"
                />
                <NavItem
                    title="Financial Settings"
                    icon={MonetizationOnIcon}
                    url="/me/financial-settings"
                    pattern="/me/financial-settings"
                />
            </Box>
        </Stack>
    );
}

export default UserProfile;
