import { string, object } from 'yup';

const schema = object({
    name: string().required('Team name'),
});

export const schemaInvite = object({
    email: string().required('Email'),
});

export default schema;
