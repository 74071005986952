import React, { useState } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupsIcon from '@mui/icons-material/Groups';
import {
    OrderItem as IOrderItem,
    OrderStatuses as OrderStatusEnum,
} from '@travelity/api';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import OutboundIcon from '@mui/icons-material/Outbound';
import { formatMoney } from '@travelity/utils';
import { Card, IconButton, IconButtonGroup, SquareCard } from '@travelity/ui';

import { useCancelOrders } from '@travelity/api/src/queries';
import { useSnackbar } from 'notistack';
import { OrderStatus } from '../order-status';
import { PaymentStatusTag } from '../payment-status';
import { OverlayWithReason } from '../overlay-with-reason';
import { ReasonModal } from '../booking-item/components/reason-modal';

export interface OrderItemProps {
    order: IOrderItem;
    refetch: () => void;
    isSelected?: boolean;
}

const OrderItem: React.FC<OrderItemProps> = ({
    order,
    refetch,
    isSelected,
}) => {
    // const { t } = useTranslation('order');
    const { financials } = order;
    const { enqueueSnackbar } = useSnackbar();

    const [deletingOrder, setDeletingOrder] = useState<string>();
    const { mutate: removeOrder, isLoading: isRemoveOrderLoading } =
        useCancelOrders({
            onSuccess: () => {
                setDeletingOrder(undefined);
                refetch();
                enqueueSnackbar(
                    `Cancelled the order #${order.number} of ${order.participants.customer.fullName}`,
                    {
                        variant: 'success',
                    }
                );
            },
            onError: () => {
                enqueueSnackbar(
                    `Failed to cancel the order #${order.number} of ${order.participants.customer.fullName}`,
                    {
                        variant: 'error',
                    }
                );
            },
        });
    const discountFormatted =
        financials?.price?.discount?.type === 'absolute'
            ? formatMoney(
                  financials.price.discount?.amount,
                  financials.price.currency_conversion?.base_currency.abbr
              )
            : `${financials?.price?.discount?.amount}%`;

    const finalPrice =
        financials?.price?.price_after_reduction ||
        financials?.price?.price_after_reduction === 0
            ? formatMoney(
                  financials?.price.price_after_reduction,
                  financials?.price?.currency_conversion?.base_currency.abbr
              )
            : '';

    return (
        <>
            <Card
                isSelected={isSelected}
                sx={{
                    height: '190px',
                }}
                direction="row"
                justifyContent="space-evenly"
                buttons={
                    order.status !== OrderStatusEnum.CANCELLED ? (
                        <>
                            <IconButtonGroup>
                                <IconButton
                                    icon={
                                        <PaymentsOutlinedIcon fontSize="small" />
                                    }
                                    disabled
                                    color="#37C16E"
                                    tooltip="Pay"
                                />
                                <Divider sx={{ mx: 0.75 }} />
                                <IconButton
                                    icon={<CachedIcon fontSize="small" />}
                                    disabled
                                    tooltip="Refund"
                                />
                            </IconButtonGroup>

                            <IconButtonGroup>
                                <IconButton
                                    icon={<OutboundIcon fontSize="small" />}
                                    href={`/order/${order.id}`}
                                    tooltip="Go to Order"
                                />
                                <Divider sx={{ mx: 0.75 }} />
                                <IconButton
                                    icon={<VisibilityIcon fontSize="small" />}
                                    href={`/orders/${order.id}`}
                                    tooltip="Preview"
                                />
                                <Divider sx={{ mx: 0.75 }} />
                                <IconButton
                                    icon={<DeleteIcon fontSize="small" />}
                                    hoverColor="error.main"
                                    onClick={() => setDeletingOrder(order.id)}
                                    tooltip="Cancel"
                                />
                            </IconButtonGroup>
                        </>
                    ) : (
                        <IconButtonGroup>
                            <IconButton
                                icon={<VisibilityIcon fontSize="small" />}
                                href={`/orders/${order.id}`}
                                tooltip="Preview"
                            />
                        </IconButtonGroup>
                    )
                }
            >
                <Stack
                    sx={{
                        width: '50%',
                        filter:
                            order.status === OrderStatusEnum.CANCELLED
                                ? 'blur(2px)'
                                : undefined,
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            px: 2,
                            height: '46px',
                            py: '10px',
                            bgcolor: '#F4F6FA',
                            borderRadius: '12px 0px 0px 0px',
                        }}
                    >
                        <Box component="span" sx={{ color: '#8F97AA' }}>
                            Order #{order.number}
                        </Box>
                    </Stack>
                    <Stack
                        sx={{
                            grow: 2,
                            pl: 2,
                            pr: 2 + (3 - 1),
                            py: '10px',
                            height: '144px',
                        }}
                        direction="row"
                        justifyContent="space-evenly"
                        gap={1}
                    >
                        <SquareCard
                            title="Products"
                            value={`${order.productsCount}`}
                            Icon={ListAltIcon}
                        />
                        <SquareCard
                            title="Bookings"
                            value={`${order.bookingsCount}`}
                            Icon={CalendarMonthIcon}
                        />
                        <SquareCard
                            title="Travelers"
                            value={`${order.travelersCount}`}
                            Icon={GroupsIcon}
                        />
                    </Stack>
                </Stack>
                <Stack
                    sx={{
                        width: '50%',
                        borderLeft: '2px solid #C9CEDC',
                        position: 'relative',
                        filter:
                            order.status === OrderStatusEnum.CANCELLED
                                ? 'blur(2px)'
                                : undefined,
                    }}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="end"
                        gap={2}
                        sx={{
                            px: 2,
                            height: '46px',
                            py: '10px',
                            bgcolor: '#F4F6FA',
                            borderRadius: '0px 12px 0px 0px',
                        }}
                    >
                        <OrderStatus status={order.status} />
                        {order.financials?.status && (
                            <PaymentStatusTag
                                status={order.financials?.status}
                            />
                        )}
                    </Stack>
                    <Stack sx={{ height: '144px', overflow: 'hidden', p: 2 }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}
                        >
                            <Box
                                sx={{
                                    color: '#6B748C',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                }}
                            >
                                Customer
                            </Box>
                            <Box
                                sx={{
                                    color: '#6B758D',
                                    textAlign: 'right',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                }}
                            >
                                {`${order.participants.customer.fullName}`}
                            </Box>
                        </Stack>
                        {financials?.price && (
                            <>
                                {!!financials?.price
                                    ?.price_before_reduction && (
                                    <>
                                        <Divider sx={{ mb: 1 }} />
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            sx={{ mb: 1 }}
                                        >
                                            <Box
                                                sx={{
                                                    color: '#6B748C',
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                Total Before Discount
                                            </Box>
                                            <Box
                                                sx={{
                                                    color: '#6B758D',
                                                    textAlign: 'right',
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                }}
                                            >
                                                {formatMoney(
                                                    financials.price
                                                        .price_before_reduction,
                                                    financials.price
                                                        .currency_conversion
                                                        ?.base_currency.abbr
                                                )}
                                            </Box>
                                        </Stack>
                                    </>
                                )}
                                {!!financials.price.discount?.amount && (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        sx={{ mb: 1 }}
                                    >
                                        <Box
                                            sx={{
                                                color: '#6B748C',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                            }}
                                        >
                                            Discount
                                        </Box>
                                        <Box
                                            sx={{
                                                color: '#6B758D',
                                                textAlign: 'right',
                                                fontSize: '14px',
                                                fontWeight: '600',
                                            }}
                                        >
                                            {discountFormatted}
                                        </Box>
                                    </Stack>
                                )}
                                <Divider />
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    sx={{ mt: 1, mb: 1.5 }}
                                >
                                    <Box
                                        sx={{
                                            color: '#6B748C',
                                            fontSize: '15px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        {financials.price.discount?.amount
                                            ? 'Total after discount'
                                            : 'Total'}
                                    </Box>
                                    <Box
                                        sx={{
                                            color: '#2B395B',
                                            textAlign: 'right',
                                            fontSize: '15px',
                                            fontWeight: '600',
                                        }}
                                    >
                                        {finalPrice}
                                    </Box>
                                </Stack>
                            </>
                        )}
                    </Stack>
                </Stack>
                {order.status === OrderStatusEnum.CANCELLED && (
                    <OverlayWithReason
                        title="Cancelled"
                        reason={order.reason}
                    />
                )}
            </Card>
            <ReasonModal
                title="Are you sure you want to cancel this order?"
                content="Provide the reason for cancelling this order"
                confirmText="Confirm"
                open={!!deletingOrder}
                isLoading={isRemoveOrderLoading}
                handleCancel={() => setDeletingOrder(undefined)}
                handleConfirm={message => {
                    removeOrder({
                        orderId: deletingOrder as string,
                        requestBody: { message },
                    });
                }}
            />
        </>
    );
};

export default OrderItem;
