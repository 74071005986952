/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingStatus } from './BookingStatus';
import type { UpdateBookingResAccessDto } from './UpdateBookingResAccessDto';
import type { UpdateBookingResEventDto } from './UpdateBookingResEventDto';
import type { UpdateBookingResFinancialsDto } from './UpdateBookingResFinancialsDto';
import type { UpdateBookingResOrderDto } from './UpdateBookingResOrderDto';
import type { UpdateBookingResProductDto } from './UpdateBookingResProductDto';
import type { UpdateBookingResSource0Dto } from './UpdateBookingResSource0Dto';
import type { UpdateBookingResSource1Dto } from './UpdateBookingResSource1Dto';
import type { UpdateBookingResTransactionsDto } from './UpdateBookingResTransactionsDto';
import type { UpdateUserResLastUpdatedDto } from './UpdateUserResLastUpdatedDto';

export type UpdateBookingResDto = {
    /**
     * Type of the resource
     */
    resource_type?: UpdateBookingResDto.resource_type;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: UpdateUserResLastUpdatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: UpdateUserResLastUpdatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<UpdateUserResLastUpdatedDto>;
    /**
     * Source of the booking
     */
    source?: (UpdateBookingResSource0Dto | UpdateBookingResSource1Dto);
    /**
     * Order reference
     */
    product?: UpdateBookingResProductDto;
    /**
     * Booked event
     */
    event?: UpdateBookingResEventDto;
    /**
     * Order reference
     */
    order?: UpdateBookingResOrderDto;
    /**
     * List of transactions
     */
    transactions?: UpdateBookingResTransactionsDto;
    /**
     * List of transactions
     */
    financials?: UpdateBookingResFinancialsDto;
    /**
     * List of notes of some importance to the booking
     */
    notes?: Array<string>;
    /**
     * Automatically computed status of the booking.
     */
    status: BookingStatus;
    /**
     * Access to the shared product
     */
    access?: UpdateBookingResAccessDto;
};

export namespace UpdateBookingResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        BOOKING = 'booking',
    }


}
