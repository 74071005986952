/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserGroupType {
    INDIVIDUAL = 'individual',
    TEAM = 'team',
    DEPARTMENT = 'department',
    ORGANIZATION = 'organization',
}
