/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetProductResAccessDto } from './GetProductResAccessDto';
import type { GetProductResCapacityDto } from './GetProductResCapacityDto';
import type { GetProductResConfigurationDto } from './GetProductResConfigurationDto';
import type { GetProductResOptionsDto } from './GetProductResOptionsDto';
import type { GetProductResPricingDto } from './GetProductResPricingDto';
import type { GetProductResRouteDto } from './GetProductResRouteDto';
import type { GetProductResScheduleDto } from './GetProductResScheduleDto';
import type { GetUserResCreatedDto } from './GetUserResCreatedDto';
import type { ProductTag } from './ProductTag';

export type GetProductRes1Dto = {
    /**
     * Type of the resource
     */
    resource_type: GetProductRes1Dto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetUserResCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetUserResCreatedDto;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetUserResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<GetUserResCreatedDto>;
    /**
     * Name of the product.
     */
    name: string;
    /**
     * Detailed textual description of the product.
     */
    description: string;
    /**
     * Tags that characterize the product
     */
    tags?: Array<ProductTag>;
    /**
     * Type of the product
     */
    type: GetProductRes1Dto.type;
    /**
     * Indicates whether the product is active or not
     */
    active: boolean;
    /**
     * Defines the number and groups of people that can be served by a unit of product
     */
    configuration: GetProductResConfigurationDto;
    /**
     * Defines the number and groups of people that can be served by a unit of product
     */
    capacity: GetProductResCapacityDto;
    /**
     * Defines the recurrent and non-recurrent items of the schedule
     */
    schedule: GetProductResScheduleDto;
    /**
     * Financial information of the product.
     */
    pricing?: GetProductResPricingDto;
    /**
     * Optional items and services for this product
     */
    options?: GetProductResOptionsDto;
    /**
     * Access to the shared product
     */
    access: GetProductResAccessDto;
    /**
     * Route of the tour/transfer
     */
    route: GetProductResRouteDto;
};

export namespace GetProductRes1Dto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        PRODUCT = 'product',
    }

    /**
     * Type of the product
     */
    export enum type {
        TRANSFER = 'transfer',
    }


}
