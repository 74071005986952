import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    SearchSelect,
    Select,
    TextField,
    useForm,
    WeekdayPicker,
    Weekdays,
} from '@travelity/form';
import { Language, User, userDetailsToUserDetailsDto } from '@travelity/api';
import { convertEnumToList } from '@travelity/utils';
import { useGetProject, useRegisterUsers } from '@travelity/api/src/queries';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@travelity/ui';
import { useSnackbar } from 'notistack';
import { RegisterUsersReqDto } from '@travelity/api/src/requests';
import { getLastCompletedStep } from './register-steps.utils';
import OccupationSelect from './components/occupation-select';
import WeekdayRow from './components/weekday-row';
import { PersonalInfo } from './components/personal-info';
import SelectTimezone from '../../components/select-timezone/select-timezone';
import { useCurrencyOptions } from '../../hooks';

export interface RegisterStepsProps {
    user?: User;
}

export const RegisterSteps: React.FC<RegisterStepsProps> = props => {
    const { user } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { user: auth0User } = useAuth0();
    const [currentStep, setCurrentStep] = React.useState(
        getLastCompletedStep(user) + 1
    );

    const languages = useMemo(() => convertEnumToList<Language>(Language), []);
    const { mutate: create } = useRegisterUsers({
        onSuccess: () => {
            setCurrentStep(currentStep + 1);
        },
        onError: () => {
            enqueueSnackbar('Failed to register', {
                variant: 'error',
            });
        },
    });

    const navigate = useNavigate();

    const onBack = useCallback(() => {
        setCurrentStep(prev => (prev === 7 ? prev - 2 : prev - 1));
    }, []);

    const { Form, getValues, setValue, watch } = useForm({
        onSubmit: useCallback(
            (data: Partial<User>) => {
                if (currentStep < 7) {
                    setCurrentStep(currentStep + 1);
                } else if (auth0User?.email) {
                    create({
                        requestBody: userDetailsToUserDetailsDto({
                            ...data,
                            subscription: 'free',
                        }) as RegisterUsersReqDto,
                    });
                }
                // if (currentStep === 1) {
                //     if (auth0User?.email) {
                //         if (lastStep === 1) {
                //             create({
                //                 requestBody: {
                //                     contact_details: {
                //                         emails: [auth0User.email],
                //                     },
                //                     name: convertFullNameToNameDto(
                //                         data.fullName as string
                //                     ),
                //                 },
                //             });
                //         } else {
                //             update({
                //                 requestBody: {
                //                     name: convertFullNameToNameDto(
                //                         data.fullName as string
                //                     ),
                //                 },
                //             });
                //         }
                //     }
                // } else {
                //     update({
                //         requestBody:
                //             currentStep === 2
                //                 ? {
                //                       languages: data.languages,
                //                   }
                //                 : currentStep === 3
                //                 ? {
                //                       app_settings: {
                //                           financials: {
                //                               currency: {
                //                                   name:
                //                                       currencies.find(
                //                                           ({ abbr }) =>
                //                                               abbr ===
                //                                               data.currency
                //                                       )?.name || '',
                //                                   abbr: data.currency as string,
                //                               },
                //                           },
                //                       },
                //                   }
                //                 : currentStep === 4
                //                 ? {
                //                       app_settings: {
                //                           timezone: {
                //                               name: data.timeZone as TimezoneName,
                //                               offset: 0,
                //                           },
                //                       },
                //                   }
                //                 : currentStep === 5
                //                 ? {
                //                       occupation: data.occupation,
                //                   }
                //                 : currentStep === 7
                //                 ? {
                //                       app_settings: {
                //                           vendibility: {
                //                               workweek:
                //                                   userWeekdaysToWorkweek(data),
                //                           },
                //                       },
                //                   }
                //                 : userDetailsToUserDetailsDto(data),
                //     });
                // }
            },
            [currentStep, auth0User]
        ),
        defaultValues: user || { subscription: 'free' },
        mode: 'onChange',
        validateInitially: true,
    });

    useEffect(() => {
        if (auth0User) {
            const userForm = getValues();
            if (!userForm.emails?.length) {
                setValue('emails', [{ value: auth0User.email as string }]);
            }
        }
    }, [auth0User]);

    useEffect(() => {
        if (currentStep === 6) {
            setCurrentStep(7);
        }
    }, [currentStep]);

    const { data: project } = useGetProject(
        {
            subscriptionKey:
                'gAAAAABmtkVv8zbURLb4RSaCTA2x3OZovgETwWKzMMRgvcEqZ3F0d-YkdJuYpbactV7SQdpYWnVFwiRljO7xVVu51QayTMFiEQ==',
        },
        undefined,
        {
            enabled: currentStep > 3,
        }
    );
    const disableWeekdays =
        project?.user?.calendar &&
        !project.user.calendar?.workweek?.customizable;

    const weekdays = useMemo(
        () =>
            disableWeekdays
                ? [
                      Weekdays.MONDAY,
                      Weekdays.TUESDAY,
                      Weekdays.WEDNESDAY,
                      Weekdays.THURSDAY,
                      Weekdays.FRIDAY,
                      Weekdays.SATURDAY,
                      Weekdays.SUNDAY,
                  ]
                : watch('weekdays'),
        [disableWeekdays]
    );

    const weekdayHours = watch('weekdayHours');
    useEffect(() => {
        if (weekdays) {
            const values = getValues();
            const currentWeekdayHours = values.weekdayHours;
            const weekdayHoursNew: Partial<{
                [v in Weekdays]: { start: number; end: number }[];
            }> = {};

            weekdays.forEach(w => {
                weekdayHoursNew[w] = currentWeekdayHours?.[w] || [
                    {
                        start: 0,
                        end: 1439,
                    },
                ];
            });

            setValue('weekdayHours', weekdayHoursNew);
        }
    }, [weekdays]);

    const currencyOptions = useCurrencyOptions();

    return (
        <Box
            sx={{
                bgcolor: '#fff',
                mt: 2,
                height: 'calc(100% - 16px)',
                '> form': { height: 1 },
            }}
        >
            <PerfectScrollbar>
                <Form>
                    <Stack
                        sx={{
                            py: 5,
                            height: 1,
                            minHeight: 'calc(100vh - 84px)',
                        }}
                        gap={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        {currentStep === 1 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    What do you do?
                                </Typography>
                                <Stack>
                                    <OccupationSelect name="occupation" />
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            px: 10,
                                            py: 1,
                                        }}
                                        requiredFields={
                                            !watch('occupation')
                                                ? ['Occupation']
                                                : undefined
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 2 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    What is your full name?
                                </Typography>
                                <TextField
                                    name="fullName"
                                    label=""
                                    placeholder="Type Full Name"
                                />
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ width: '388px' }}
                                >
                                    <Button
                                        variant="contained"
                                        color="neutral"
                                        sx={{ flexGrow: 1 }}
                                        onClick={onBack}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            px: 10,
                                            py: 1,
                                        }}
                                        requiredFields={
                                            !watch('fullName')?.match(
                                                /\s[a-zA-Z]/
                                            )
                                                ? ['Full Name']
                                                : undefined
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 3 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    What languages you speak?
                                </Typography>
                                <SearchSelect
                                    placeholder="Select Language"
                                    name="languages"
                                    label="Languages"
                                    multiple
                                    options={languages}
                                />
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ width: '388px' }}
                                >
                                    <Button
                                        variant="contained"
                                        color="neutral"
                                        sx={{ flexGrow: 1 }}
                                        onClick={onBack}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            px: 10,
                                            py: 1,
                                        }}
                                        requiredFields={
                                            !watch('languages')?.length
                                                ? ['Languages']
                                                : undefined
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 4 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    What is your base currency?
                                </Typography>
                                <Select
                                    placeholder="Select base currency"
                                    name="currency"
                                    label="Currency"
                                    options={currencyOptions}
                                />
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ width: '388px' }}
                                >
                                    <Button
                                        variant="contained"
                                        color="neutral"
                                        sx={{ flexGrow: 1 }}
                                        onClick={onBack}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            px: 10,
                                            py: 1,
                                        }}
                                        requiredFields={
                                            !watch('currency')?.length
                                                ? ['Currency']
                                                : undefined
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 5 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    Which time zone you are in?
                                </Typography>
                                <SelectTimezone
                                    name="timeZone"
                                    label=""
                                    placeholder="Select time zone"
                                />
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ width: '388px' }}
                                >
                                    <Button
                                        variant="contained"
                                        color="neutral"
                                        sx={{ flexGrow: 1 }}
                                        onClick={onBack}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            px: 10,
                                            py: 1,
                                        }}
                                        requiredFields={
                                            !watch('timeZone')?.length
                                                ? ['Time Zone']
                                                : undefined
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 7 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    Setup Your Workweek
                                </Typography>

                                <Box
                                    sx={{
                                        width: '560px',
                                        ...(disableWeekdays && {
                                            pointerEvents: 'none',
                                            opacity: 0.8,
                                        }),
                                    }}
                                >
                                    <WeekdayPicker
                                        name="weekdays"
                                        chipSx={{
                                            py: '12px',
                                            px: '16px',
                                            borderRadius: '26px',
                                            background: '#F4F6FA',
                                            border: '1px solid transparent',
                                            '&.MuiChip-colorPrimary': {
                                                border: '1px solid #55B5CF',
                                                background: '#DDF0F5',
                                                color: '#2B395B',
                                            },
                                        }}
                                    />
                                    <Divider sx={{ mx: 10, my: 3 }} />
                                    {weekdayHours &&
                                        Object.keys(weekdayHours).map(w => (
                                            <WeekdayRow
                                                key={w}
                                                name={`weekdayHours.${w}`}
                                                weekday={w as Weekdays}
                                            />
                                        ))}
                                </Box>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ width: '388px' }}
                                >
                                    <Button
                                        variant="contained"
                                        color="neutral"
                                        sx={{ flexGrow: 1 }}
                                        onClick={onBack}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            px: 10,
                                            py: 1,
                                        }}
                                        requiredFields={
                                            !weekdays?.length
                                                ? ['Weekdays']
                                                : undefined
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 8 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    Personal Information
                                </Typography>
                                <PersonalInfo
                                    onSkip={() => {
                                        navigate('/products');
                                        navigate(0);
                                    }}
                                />
                            </>
                        )}
                    </Stack>
                </Form>
            </PerfectScrollbar>
        </Box>
    );
};
