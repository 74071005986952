import React from 'react';
import { Paper, Stack } from '@mui/material';
import { Switch } from '@travelity/form';

export interface ProductConfigurationFormProps {
    preview?: boolean;
}

const ProductConfigurationForm: React.FC<ProductConfigurationFormProps> = ({
    preview,
}) => {
    return (
        <Stack spacing={2}>
            <Paper
                sx={{
                    py: 1,
                    px: 2,
                    maxWidth: '388px',
                    ...(preview
                        ? {
                              opacity: 0.6,
                              pointerEvents: 'none',
                          }
                        : {}),
                }}
            >
                <Switch
                    name="shared"
                    label="Group Tour"
                    LabelProps={{ sx: { color: '#2B395B' } }}
                />
            </Paper>
            <Paper
                sx={{
                    '&&': { mt: 1.5 },
                    py: 1,
                    px: 2,
                    maxWidth: '388px',
                    ...(preview
                        ? {
                              opacity: 0.6,
                              pointerEvents: 'none',
                          }
                        : {}),
                }}
            >
                <Switch
                    name="autoscaled"
                    label="Autoscaled"
                    LabelProps={{ sx: { color: '#2B395B' } }}
                />
            </Paper>
        </Stack>
    );
};

export default React.memo(ProductConfigurationForm);
