import React from 'react';
import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { Card, Heading, IconButtonGroup } from '@travelity/ui';

export interface EventItemProps {}

const TeamItemSkeleton: React.FC<EventItemProps> = () => {
    return (
        <Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                    mt: 2,
                    mb: 1,
                    pr: 5,
                    minWidth: 0,
                }}
            >
                <Stack
                    direction="row"
                    gap={1.5}
                    alignItems="center"
                    sx={{
                        minWidth: 0,
                    }}
                >
                    <Heading
                        sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                            pr: 1,
                        }}
                        alpha={0.7}
                        ellipsis
                    >
                        <Skeleton width={150} sx={{ fontSize: '24px' }} />
                    </Heading>
                    <Typography
                        sx={{
                            ml: '-8px',
                            color: '#6B748C',
                            fontSize: '12px',
                            fontWeight: '500',
                            whiteSpace: 'nowrap',
                            pr: 1.5,
                        }}
                    >
                        <Skeleton width={80} sx={{ fontSize: '24px' }} />
                    </Typography>
                </Stack>
                <Stack direction="row" gap={1}>
                    <Skeleton width={160} sx={{ fontSize: '24px' }} />
                </Stack>
            </Stack>
            <Card
                sx={{
                    height: '148px',
                }}
                justifyContent="space-evenly"
                buttons={
                    <>
                        <IconButtonGroup
                            flexShrink={0}
                            sx={{ px: 0.75, py: 1 }}
                            gap={1.5}
                        >
                            <Skeleton
                                variant="circular"
                                width={24}
                                height={24}
                            />
                            <Skeleton
                                variant="circular"
                                width={24}
                                height={24}
                            />
                        </IconButtonGroup>
                        <IconButtonGroup
                            flexShrink={0}
                            sx={{ px: 0.75, py: 1 }}
                            gap={1.5}
                        >
                            <Skeleton
                                variant="circular"
                                width={24}
                                height={24}
                            />
                        </IconButtonGroup>
                    </>
                }
            >
                <Stack
                    gap={0.5}
                    sx={{
                        py: 1,
                    }}
                >
                    <Stack
                        direction="row"
                        gap={2}
                        justifyContent="space-between"
                        sx={{
                            width: 1,
                            px: 2,
                        }}
                    >
                        <Skeleton width={90} sx={{ fontSize: '24px' }} />
                        <Skeleton width={30} sx={{ fontSize: '24px' }} />
                    </Stack>
                    <Divider />
                    <Stack
                        direction="row"
                        gap={2}
                        justifyContent="space-between"
                        sx={{
                            width: 1,
                            px: 2,
                        }}
                    >
                        <Skeleton width={100} sx={{ fontSize: '24px' }} />
                        <Stack direction="row" gap={1}>
                            <Skeleton width={100} sx={{ fontSize: '24px' }} />
                            <Skeleton width={100} sx={{ fontSize: '24px' }} />
                            <Skeleton width={40} sx={{ fontSize: '24px' }} />
                        </Stack>
                    </Stack>
                    <Divider />
                    <Stack
                        direction="row"
                        gap={2}
                        justifyContent="start"
                        sx={{
                            width: 1,
                            px: 2,
                        }}
                    >
                        <Skeleton width={60} sx={{ fontSize: '24px' }} />
                        <Skeleton width={90} sx={{ fontSize: '24px' }} />
                        <Skeleton width={110} sx={{ fontSize: '24px' }} />
                    </Stack>
                </Stack>
            </Card>
        </Box>
    );
};

export default TeamItemSkeleton;
