/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateAssetReqDto } from '../models/CreateAssetReqDto';
import type { CreateAssetResDto } from '../models/CreateAssetResDto';
import type { GetAssetsResDto } from '../models/GetAssetsResDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AssetsService {

    /**
     * List assets
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param type Type of the asset
     * @param byOwner Filter results by asset owner
     * @param byAssignee Filter results by asset assignee
     * @param id Id of the owner or assignee
     * @param email email of the owner or assignee
     * @param name Name of the owner or assignee
     * @param usagePeriodStart start point of the period
     * @param usagePeriodEnd end point of the period
     * @param vehicleLicensePlate vehicle license plate
     * @param vehicleMake vehicle make
     * @param vehicleModel vehicle model
     * @param vehicleMinYear vehicle min year
     * @param vehicleMaxYear vehicle max year
     * @returns GetAssetsResDto Successful Response
     * @throws ApiError
     */
    public static getAssets(
authorization: string,
type?: string,
byOwner?: boolean,
byAssignee?: boolean,
id?: string,
email?: string,
name?: string,
usagePeriodStart?: number,
usagePeriodEnd?: number,
vehicleLicensePlate?: string,
vehicleMake?: string,
vehicleModel?: string,
vehicleMinYear?: string,
vehicleMaxYear?: string,
): CancelablePromise<GetAssetsResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/assets',
            headers: {
                'authorization': authorization,
            },
            query: {
                'type': type,
                'by_owner': byOwner,
                'by_assignee': byAssignee,
                'id': id,
                'email': email,
                'name': name,
                'usage_period_start': usagePeriodStart,
                'usage_period_end': usagePeriodEnd,
                'vehicle__license_plate': vehicleLicensePlate,
                'vehicle__make': vehicleMake,
                'vehicle__model': vehicleModel,
                'vehicle__min_year': vehicleMinYear,
                'vehicle__max_year': vehicleMaxYear,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Asset
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns CreateAssetResDto Successful Response
     * @throws ApiError
     */
    public static createAsset(
authorization: string,
requestBody: CreateAssetReqDto,
): CancelablePromise<CreateAssetResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/assets',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
