import {
    GetBookingResParticipantsTravelersItemDto,
    TimezoneName,
} from '../../requests';
import { Traveler } from './traveler.types';
import {
    convertFullNameToNameDto,
    convertNameDtoToFullName,
} from '../user/user.converters';

export const travelerToTravelerDto = (
    traveler: Traveler
): Pick<
    GetBookingResParticipantsTravelersItemDto,
    'id' | 'guest_count' | 'name' | 'pickup_info'
> => ({
    id: traveler.travelerId || traveler.customerId || '',
    guest_count: traveler.guestCount,
    name: convertFullNameToNameDto(traveler.fullName),
    // @ts-ignore
    pickup_info: traveler.pickUp
        ? {
              id: traveler.pickUp.id,
              date: {
                  tz_name: Intl.DateTimeFormat().resolvedOptions()
                      .timeZone as TimezoneName,
                  start: Math.round(traveler.pickUp.time.getTime() / 1000),
              },
              location: {
                  name: traveler.pickUp.name,
              },
          }
        : undefined,
});

export const travelerDtoToTraveler = (
    traveler: Pick<
        GetBookingResParticipantsTravelersItemDto,
        'id' | 'guest_count' | 'name' | 'pickup_info'
    >
): Traveler => ({
    customerId: traveler.id,
    travelerId: traveler.id,
    fullName: convertNameDtoToFullName(traveler.name),
    pickUp: traveler.pickup_info
        ? {
              id: traveler.pickup_info.id,
              name: traveler.pickup_info.location.name,
              time: new Date(traveler.pickup_info.date.start * 1000),
          }
        : undefined,
    guestCount: traveler.guest_count,
});
