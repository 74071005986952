/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FreeSubscriptionData } from '../models/FreeSubscriptionData';
import type { ProfessionalSubscriptionData } from '../models/ProfessionalSubscriptionData';
import type { TeamSubscriptionData } from '../models/TeamSubscriptionData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectService {

    /**
     * Fetch Subscription details by key
     * @param subscriptionKey Name of the subscription
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getProject(
subscriptionKey: string,
): CancelablePromise<(FreeSubscriptionData | ProfessionalSubscriptionData | TeamSubscriptionData)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/project/subscriptions/{subscription_key}',
            path: {
                'subscription_key': subscriptionKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
