import React, { useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { TextField, useForm } from '@travelity/form';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton } from '@travelity/ui';
import Edit from '@mui/icons-material/Edit';
import DoNotDisturbOn from '@mui/icons-material/DoNotDisturbOn';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { currencies } from '@travelity/api';

export interface CurrencyTagItemProps {
    isNew?: boolean;
    currency: string;
    usedCurrencies: string[];
    rate?: number;
    base?: boolean;
    onRemove?: () => void;
    onUpdate?: (currency: string, rate: number) => void;
}

export const CurrencyTagItem: React.FC<CurrencyTagItemProps> = props => {
    const { currency, rate, base, usedCurrencies, isNew, onRemove, onUpdate } =
        props;
    const { Form, watch, setValue, reset } = useForm({});
    const [isEditing, setIsEditing] = useState(!!isNew);

    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'demoMenu',
    });

    const currencyOptions = useMemo(() => {
        return currencies
            .filter(c => {
                return c.abbr === currency || !usedCurrencies.includes(c.abbr);
            })
            .map(c => ({
                label: c.abbr,
                value: c.abbr,
            }));
    }, [usedCurrencies, currency]);

    return (
        <Form>
            <Stack
                direction="row"
                alignItems="center"
                sx={{
                    pr: 1,
                    width: '280px',
                    borderRadius: '12px',
                    bgcolor: '#F8F9FA',
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="end"
                    sx={{
                        borderRadius: '12px 0 0 12px',
                        color: '#9198AA',
                        bgcolor: '#EAEDF1',
                        pl: 2,
                        pr: 1,
                        py: 1.5,
                        lineHeight: '26px',
                        width: '84px',
                    }}
                    {...(isEditing ? bindTrigger(popupState) : {})}
                >
                    {isEditing ? watch('currency') : currency}
                    <KeyboardArrowDownIcon
                        sx={{
                            opacity: isEditing ? 1 : 0,
                        }}
                    />
                </Stack>
                {base || !isEditing ? (
                    <Typography
                        variant="body2"
                        sx={{
                            textAlign: 'right',
                            flexGrow: 2,
                            py: '3px',
                            px: 1.5,
                            color: '#2B395B',
                            fontSize: '14px',
                        }}
                    >
                        {base ? 'is your base currency' : rate}
                    </Typography>
                ) : (
                    <TextField
                        sx={{
                            flexShrink: 2,
                            mx: '10px',
                            width: 'auto',
                        }}
                        autoFocus
                        InputProps={{
                            sx: {
                                fontSize: '14px',
                                pl: 1.5,
                            },
                        }}
                        inputProps={{
                            sx: {
                                textAlign: 'right',
                            },
                            size: 10,
                        }}
                        placeholder="Type Rate"
                        name="rate"
                        variant="standard"
                        showErrors
                        helperText=""
                        fullWidth
                    />
                )}
                {isEditing ? (
                    <Stack gap={1} direction="row" alignItems="center">
                        <Box
                            sx={{
                                width: '8px',
                                height: '20px',
                                borderLeft: '#DFE2EC 1px solid',
                            }}
                        />
                        <IconButton
                            icon={<Check fontSize="small" />}
                            color="success.main"
                            hoverColor="success.dark"
                            onClick={() => {
                                const data = {
                                    currency: watch('currency'),
                                    rate: watch('rate'),
                                };
                                if (
                                    currency !== watch('currency') ||
                                    rate !== watch('rate')
                                ) {
                                    onUpdate?.(data.currency, data.rate);
                                }
                                setIsEditing(false);
                            }}
                            withShadow
                        />
                        <IconButton
                            icon={<Close fontSize="small" />}
                            color="error.main"
                            hoverColor="error.dark"
                            onClick={() => {
                                if (isNew) onRemove?.();
                                else setIsEditing(false);
                            }}
                            withShadow
                        />
                    </Stack>
                ) : (
                    !base && (
                        <Stack gap={1} direction="row" alignItems="center">
                            <Box
                                sx={{
                                    width: '8px',
                                    height: '20px',
                                    borderLeft: '#DFE2EC 1px solid',
                                }}
                            />
                            <IconButton
                                icon={<Edit fontSize="small" />}
                                onClick={() => {
                                    reset({ currency, rate });
                                    setIsEditing(true);
                                }}
                                withShadow
                            />
                            <IconButton
                                icon={<DoNotDisturbOn fontSize="small" />}
                                hoverColor="error.main"
                                withShadow
                                onClick={onRemove}
                            />
                        </Stack>
                    )
                )}
            </Stack>
            <Menu
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                MenuListProps={{
                    sx: {
                        width: popupState.anchorEl?.clientWidth,
                        maxHeight: '150px',
                    },
                }}
                sx={{
                    zIndex: 99999,
                }}
                PaperProps={{
                    sx: {
                        zIndex: 99999,
                    },
                }}
                {...bindMenu(popupState)}
            >
                {currencyOptions?.map(({ label, value }, index) => (
                    <MenuItem
                        key={value}
                        sx={{
                            justifyContent: 'center',
                            color: '#2B395B',
                            fontSize: '12px',
                            margin: '0 16px',
                            borderRadius: '4px',
                            '&:before': {
                                borderTop: index ? '1px solid #DFE1ED' : 0,
                            },
                            '&:hover': {
                                backgroundColor: '#D7E7EB',
                            },
                        }}
                        onClick={() => {
                            setValue('currency', value);
                            popupState.close();
                        }}
                    >
                        {label}
                    </MenuItem>
                ))}
            </Menu>
        </Form>
    );
};
