import { useController, useFormContext } from 'react-hook-form';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { get } from 'lodash';
import {
    Box,
    FormLabel,
    InputAdornment,
    OutlinedInput,
    OutlinedInputProps,
    Stack,
} from '@mui/material';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { object } from 'yup';

// export type DurationValue = Record<DurationUnits, number>;

enum DurationUnits {
    DAY = 'day',
    HOUR = 'hour',
    MINUTE = 'minute',
}

export type DurationFieldProps = {
    name: string;
    label?: string;
    required?: boolean;
    showErrors?: boolean;
    units?: DurationUnits[];
    InputProps?: OutlinedInputProps;
};
export const defaultUnits = [
    DurationUnits.DAY,
    DurationUnits.HOUR,
    DurationUnits.MINUTE,
];

const validNumber = (v: any) => v && v !== '0' && v !== 0;
export const requiredDuration = object<Record<DurationUnits, number>>()
    .required()
    .test(
        'duration validation fn',
        'Duration validation error',
        // @ts-ignore
        function duration(value: Record<DurationUnits, number>) {
            if (
                !validNumber(value?.hour) &&
                !validNumber(value?.day) &&
                !validNumber(value?.minute)
            ) {
                return this.createError({
                    message: 'Duration is a required field',
                });
            }
            return true;
        }
    );

const DurationField: React.FC<DurationFieldProps> = ({
    name,
    required,
    label,
    showErrors,
    units = defaultUnits,
    InputProps = {},
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const defaultValue = useMemo(
        () =>
            units.reduce(
                (obj, cur) => ({
                    ...obj,
                    [cur]: 0,
                }),
                {} as Record<DurationUnits, number>
            ),
        [units]
    );

    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
        defaultValue,
    });

    const error =
        showErrors && (get(errors, name)?.message as string | undefined);
    const onUpClick = (s: DurationUnits) => {
        onChange({
            ...value,
            [s]: value[s] + 1,
        });
    };
    const onDownClick = (s: DurationUnits) => {
        onChange({
            ...value,
            [s]: Math.max(value[s] - 1, 0),
        });
    };
    const onInputChange = (
        s: DurationUnits,
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (!e.target.value.match(/^(\d+)?$/)) return;
        onChange({
            ...value,
            [s]: e.target.value ? parseInt(e.target.value) : null,
        });
    };

    const [pressed, setPressed] = useState<
        | {
              direction: 1 | -1;
              unit: DurationUnits;
          }
        | undefined
    >();
    const timer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        if (pressed) {
            timeout = setTimeout(() => {
                pressed.direction === 1
                    ? onChange({
                          ...value,
                          [pressed.unit]: value[pressed.unit] + 1,
                      })
                    : onChange({
                          ...value,
                          [pressed.unit]: Math.max(value[pressed.unit] - 1, 0),
                      });
            }, 100);
        }
        return () => clearTimeout(timeout);
    }, [onChange, pressed, value]);

    const startTimer = (unit: DurationUnits, direction: 1 | -1) => {
        timer.current = setTimeout(() => {
            setPressed({ direction, unit });
        }, 300);
    };

    const stopTimer = () => {
        clearTimeout(timer.current);
        setPressed(undefined);
    };

    return (
        <>
            {label && (
                <FormLabel
                    sx={{
                        '&&&': { mb: 1 },
                    }}
                    required={required}
                >
                    {label}
                </FormLabel>
            )}
            <Stack direction="row" gap="4px" sx={{ '&&&': { mt: 0 } }}>
                {units.map(unit => (
                    <OutlinedInput
                        key={unit}
                        value={value[unit] === null ? '' : value[unit]}
                        onChange={e => onInputChange(unit, e)}
                        {...InputProps}
                        error={!!error}
                        name={`${name}.${unit}`}
                        sx={{
                            ...(InputProps.sx || {}),
                            color: '#2B395B',
                            '&&&': { pr: '10px' },
                            '& input': {
                                p: 1.5,
                                pl: 0.75,
                                pr: 0.25,
                                width: '18px',
                                textAlign: 'right',
                            },
                        }}
                        endAdornment={
                            <InputAdornment position="end" sx={{ ml: 0 }}>
                                <Box sx={{ pr: 0.25 }} className="unit">
                                    {unit[0]}
                                </Box>
                                <Stack>
                                    <Box
                                        sx={{
                                            height: '14px',
                                            mt: '2px',
                                            cursor: 'pointer',
                                            '&:hover > *': {
                                                opacity: '0.7',
                                            },
                                        }}
                                        onMouseDown={() => startTimer(unit, 1)}
                                        onMouseUp={stopTimer}
                                        onMouseLeave={stopTimer}
                                        onClick={() => onUpClick(unit)}
                                    >
                                        <ArrowDropUp
                                            sx={{
                                                fontSize: '20px',
                                                color: '#2B395B',
                                                opacity: '0.4',
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            height: '14px',
                                            mb: '8px',
                                            cursor: value[unit]
                                                ? 'pointer'
                                                : 'default',
                                            '&:hover > *': {
                                                opacity: value[unit]
                                                    ? '0.7'
                                                    : '0.2',
                                            },
                                        }}
                                        onMouseDown={() => startTimer(unit, -1)}
                                        onMouseUp={stopTimer}
                                        onMouseLeave={stopTimer}
                                        onClick={() => onDownClick(unit)}
                                    >
                                        <ArrowDropDown
                                            sx={{
                                                fontSize: '20px',
                                                color: '#2B395B',
                                                opacity: value[unit]
                                                    ? '0.4'
                                                    : '0.2',
                                            }}
                                        />
                                    </Box>
                                </Stack>
                            </InputAdornment>
                        }
                    />
                ))}
            </Stack>
        </>
    );
};

export default DurationField;
