/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PersonNameData } from './PersonNameData';

export type UpdateBookingResSource1Dto = {
    /**
     * type of the source
     */
    type?: UpdateBookingResSource1Dto.type;
    /**
     * ID of the referenced user
     */
    id?: string;
    /**
     * name of the referenced user
     */
    name?: PersonNameData;
    /**
     * email of the referenced user
     */
    email?: string;
    /**
     * name of the member's company
     */
    company?: string;
};

export namespace UpdateBookingResSource1Dto {

    /**
     * type of the source
     */
    export enum type {
        REFERRAL = 'referral',
    }


}
