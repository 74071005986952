import React from 'react';
import {
    SnackbarProvider as NotistackSnackbarProvider,
    CustomContentProps,
    useSnackbar,
} from 'notistack';
import { Alert } from '@mui/material';
import { TaskAlt } from '@mui/icons-material';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';

const CustomComponent = React.forwardRef<HTMLDivElement, CustomContentProps>(
    (props, ref) => {
        const { id, message, variant } = props;

        const { closeSnackbar } = useSnackbar();

        const severity = variant === 'default' ? 'info' : variant;
        return (
            <Alert
                variant="filled"
                ref={ref}
                severity={severity}
                iconMapping={{
                    error: <ReportProblemRoundedIcon />,
                    success: <TaskAlt />,
                }}
                sx={{ color: 'white', fontWeight: 400 }}
                onClose={() => closeSnackbar(id)}
            >
                {message}
            </Alert>
        );
    }
);

export const SnackbarProvider: React.FC<{ children: React.ReactElement }> = ({
    children,
}) => {
    return (
        <NotistackSnackbarProvider
            maxSnack={3}
            Components={{
                success: CustomComponent,
                error: CustomComponent,
            }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            onClose={() => {}}
        >
            {children}
        </NotistackSnackbarProvider>
    );
};
