import { object, string } from 'yup';

const schema = object({
    phoneNumber: string().matches(
        /^\+?\d+ \d[\d ]+$/,
        'Phone number should have a country code separated by a space from the number'
    ),
    email: string()
        .email('Email must be a valid email')
        .when('phoneNumber', {
            is: phoneNumber => !phoneNumber,
            then: sch =>
                sch.required('Either phone number or email must be specified'),
        }),
    fullName: string()
        .matches(/^\S+ \S/, 'Please Type Full Name')
        .required(),
});

export default schema;
