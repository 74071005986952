import {
    Dispatch,
    RefObject,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import _, { get } from 'lodash';
import { currencies } from '@travelity/api';

export function useExpand<T>(): [T | null, (v: T | null) => void] {
    const [expanded, setExpanded] = useState<T | null>(null);
    const toggleExpand = useCallback((id: T | null) => {
        setExpanded(prev => (prev === id ? null : id));
    }, []);
    return [expanded, toggleExpand];
}

export function useChange<T>(
    initialValue: T | null = null
): [T | null, (e: any, v: T | null) => void] {
    const [value, setValue] = useState<T | null>(initialValue);
    const changeValue = useCallback((e: any, newValue: T | null) => {
        setValue(newValue);
    }, []);

    return [value, changeValue];
}

export function useResizeRef<T extends HTMLElement = HTMLDivElement>(
    callback: (w: number, h: number) => void,
    debounce = 0
): RefObject<T> {
    const elRef = useRef<T>(null);

    const callbackWithDebounce = useCallback(
        debounce ? _.debounce(callback, debounce) : callback,
        [callback, debounce]
    );

    useEffect(() => {
        if (!elRef.current) return () => null;
        const el = elRef.current;

        const resizeObserver = new ResizeObserver(entries => {
            // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
            // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
            window.requestAnimationFrame(() => {
                if (!Array.isArray(entries) || !entries.length) return;
                // eslint-disable-next-line no-restricted-syntax
                for (const entry of entries) {
                    callbackWithDebounce(
                        entry.contentRect.width,
                        entry.contentRect.height
                    );
                }
            });
        });

        resizeObserver.observe(el);

        return () => resizeObserver.unobserve(el);
    }, [callbackWithDebounce, debounce]);

    return elRef;
}

export const useSelectOptions = <
    T extends object = Record<string, any>,
    ExtendsSelf extends boolean = false
>(
    options: T[] | undefined,
    labelKey: string,
    valueKey: string,
    extendSelf: ExtendsSelf
): ExtendsSelf extends false
    ? { label: string; value: string }[]
    : (T & { label: string; value: string })[] => {
    return useMemo(
        () =>
            options?.map(option => ({
                ...(extendSelf && option),
                label: get(option, labelKey || 'name') || '',
                value: get(option, valueKey || 'id') || '',
            })) || [],
        [options, labelKey, valueKey]
    );
};

export const useDynamicState = <T>(
    value: T
): [state: T, setState: Dispatch<SetStateAction<T>>] => {
    const [state, setState] = useState<T>(value);
    useEffect(() => {
        if (value && value !== state) {
            setState(value);
        }
    }, [value]);

    return [state, setState];
};

export const useIsChanged = (value: any, skipInitial?: boolean): boolean => {
    const ref = useRef(skipInitial ? value : undefined);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current !== value;
};

export const useLog = (name = 'Component', extraParams: any[] = []) => {
    // eslint-disable-next-line no-console
    console.log(`${name} rendered`, ...extraParams);
    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log(`${name} mounted`);
        return () => {
            // eslint-disable-next-line no-console
            console.log(`${name} unmounted`);
        };
    }, []);

    useEffect(() => {
        if (extraParams.length > 0) {
            // eslint-disable-next-line no-console
            console.log(`${name} params changed`, ...extraParams);
        }
    }, [...extraParams]);
};

export const useCurrencyOptions = () => {
    return useMemo(
        () =>
            currencies.map(c => ({
                label: `${c.abbr} - ${c.name}`,
                value: c.abbr,
            })),
        []
    );
};
