import { Controller, useFormContext } from 'react-hook-form';
import React, { forwardRef, ReactElement } from 'react';
import {
    ToggleButtonProps as MuiToggleButtonProps,
    ToggleButton as MuiToggleButton,
} from '@mui/material';

export interface ToggleButtonProps extends Partial<MuiToggleButtonProps> {
    children: ReactElement;
    name: string;
    tooltip?: string;
}

const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
    ({ name, children, tooltip, ...rest }, ref) => {
        const { control } = useFormContext();

        return (
            <Controller
                render={({ field }: { field: Record<string, any> }) => (
                    <MuiToggleButton
                        ref={ref}
                        sx={{
                            borderRadius: '12px',
                            padding: 1,
                        }}
                        value="check"
                        selected={field.value}
                        onChange={() => field.onChange(!field.value)}
                        {...rest}
                    >
                        {children}
                    </MuiToggleButton>
                )}
                name={name}
                control={control}
            />
        );
    }
);

export default ToggleButton;
