/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetAssetsResItemOwnerReferenceDto = {
    /**
     * User's occupation
     */
    type: GetAssetsResItemOwnerReferenceDto.type;
    /**
     * ID of the referenced user
     */
    id?: string;
};

export namespace GetAssetsResItemOwnerReferenceDto {

    /**
     * User's occupation
     */
    export enum type {
        HUMAN = 'human',
    }


}
