import React from 'react';
import { CapacityItem } from '@travelity/api';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Card } from '@travelity/ui';
import CapacityItemMinimalSkeleton from './capacity-item-minimal-skeleton';

export interface TeamItemProps {
    capacity: CapacityItem;
    isSelected: boolean;
    isDisabled?: boolean;
    onSelect: () => void;
}

const CapacityItemMinimal: React.FC<TeamItemProps> = ({
    capacity,
    isSelected,
    isDisabled,
    onSelect,
}) => {
    const { t } = useTranslation('product');
    const counts = Object.entries(capacity.limit)
        .filter(([, v]) => v)
        .map(([k, v]) => `${k} ${v}`);

    return (
        <Box>
            <Card
                flexGrow={2}
                sx={{
                    px: 2,
                    py: 1,
                }}
                direction="column"
                justifyContent="space-evenly"
                parentProps={{ alignItems: 'center' }}
                leftAdornment={
                    <Stack alignItems="center">
                        <Box
                            onClick={isDisabled ? undefined : onSelect}
                            sx={{
                                mr: '14px',
                                cursor: 'pointer',
                                width: '18px',
                                height: '18px',
                                borderStyle: 'solid',
                                borderWidth: isSelected ? '6px' : '2px',
                                borderColor: isSelected ? '#6B748C' : '#B5B9C5',
                                borderRadius: '4px',
                                ...(isDisabled && { opacity: 0.5 }),
                            }}
                        />
                    </Stack>
                }
            >
                <Stack justifyContent="space-between" direction="row">
                    <Box
                        component="span"
                        sx={{
                            fontSize: 12,
                            color: '#989EAF',
                            pr: 1,
                        }}
                    >
                        {t('Name')}
                    </Box>
                    <Box
                        component="span"
                        sx={{
                            fontSize: 12,
                            color: '#2B395B',
                            pr: 1,
                        }}
                    >
                        {capacity.name}
                    </Box>
                </Stack>
                <Stack justifyContent="space-between" direction="row">
                    <Box
                        component="span"
                        sx={{
                            fontSize: 12,
                            color: '#989EAF',
                            pr: 1,
                        }}
                    />
                    <Box
                        component="span"
                        sx={{
                            fontSize: 12,
                            color: '#2B395B',
                            pr: 1,
                        }}
                    >
                        {counts.join(' | ')}
                    </Box>
                </Stack>
            </Card>
        </Box>
    );
};

export { CapacityItemMinimalSkeleton };

export default CapacityItemMinimal;
