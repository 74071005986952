/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTeamReqDto } from '../models/CreateTeamReqDto';
import type { CreateTeamResDto } from '../models/CreateTeamResDto';
import type { GetTeamsResDto } from '../models/GetTeamsResDto';
import type { InviteMemberTeamsReqDto } from '../models/InviteMemberTeamsReqDto';
import type { InviteMemberTeamsResDto } from '../models/InviteMemberTeamsResDto';
import type { UpdateMemberTeamsReqDto } from '../models/UpdateMemberTeamsReqDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TeamsService {

    /**
     * List user's teams
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param teamId Ids of teams to look in
     * @param memberEmail Email of peer to filter teams with
     * @param memberName Name of the peer to filter teams with
     * @param company Name of the user's company
     * @returns GetTeamsResDto Successful Response
     * @throws ApiError
     */
    public static getTeams(
authorization: string,
teamId?: Array<string>,
memberEmail?: string,
memberName?: string,
company?: string,
): CancelablePromise<GetTeamsResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams',
            headers: {
                'authorization': authorization,
            },
            query: {
                'team_id': teamId,
                'member_email': memberEmail,
                'member_name': memberName,
                'company': company,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Team
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns CreateTeamResDto Successful Response
     * @throws ApiError
     */
    public static createTeam(
authorization: string,
requestBody: CreateTeamReqDto,
): CancelablePromise<CreateTeamResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/teams',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Invite a member to the team
     * @param teamId ID of the team being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns InviteMemberTeamsResDto Successful Response
     * @throws ApiError
     */
    public static inviteMemberTeams(
teamId: string,
authorization: string,
requestBody: InviteMemberTeamsReqDto,
): CancelablePromise<InviteMemberTeamsResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/teams/{team_id}/invite_member',
            path: {
                'team_id': teamId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Accept an invitation
     * @param teamId ID of the team being patched
     * @param key Invitation key, used for validation
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static acceptInvitationTeams(
teamId: string,
key: string,
authorization: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/teams/{team_id}/accept',
            path: {
                'team_id': teamId,
            },
            headers: {
                'authorization': authorization,
            },
            query: {
                'key': key,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove a member from the team
     * @param teamId ID of the team being patched
     * @param email Email of the member being removed
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeMemberTeams(
teamId: string,
email: string,
authorization: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/teams/{team_id}/remove_member',
            path: {
                'team_id': teamId,
            },
            headers: {
                'authorization': authorization,
            },
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update a member from the team
     * @param teamId ID of the team being patched
     * @param email Email of the member being removed
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateMemberTeams(
teamId: string,
email: string,
authorization: string,
requestBody: UpdateMemberTeamsReqDto,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/teams/{team_id}/update_member',
            path: {
                'team_id': teamId,
            },
            headers: {
                'authorization': authorization,
            },
            query: {
                'email': email,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
