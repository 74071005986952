import {
    GetTeamsResItemDto,
    type GetTeamsResItemMembersItemDto,
    ProductType,
} from '../../requests';
import { Referral, Staff, Team } from './team.types';
import { convertNameDtoToFullName } from '../user/user.converters';

export const teamDtoToTeam = (team: GetTeamsResItemDto): Team => ({
    id: team.id,
    created: team.created,
    lifecycle: team.lifecycle,
    isolated: team.isolated,
    name: team.name,
    permissions: team.rbac?.permissions || {},
    members: team.members,
    productTypes: {
        [ProductType.TRANSFER]: team.products?.transfers || 0,
        [ProductType.TOUR]: 0,
    },
});

export const memberToStaff = (
    member: GetTeamsResItemMembersItemDto
): Staff => ({
    id: member.id,
    fullName: member.name ? convertNameDtoToFullName(member.name) : undefined,
    email: member.email,
});

export const memberToReferral = (
    member: GetTeamsResItemMembersItemDto
): Referral => ({
    id: member.id,
    name: member.name,
    email: member.email,
    company: member.company,
});

export const memberToDetailsForm = (member: GetTeamsResItemMembersItemDto) => ({
    numbers:
        member.phone_numbers?.map(number => ({
            value: `${number.calling_code} ${number.number}`,
        })) || [],
    fullName: member.name ? convertNameDtoToFullName(member.name) : undefined,
});
