/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddTransactionBookingsReqDto } from '../models/AddTransactionBookingsReqDto';
import type { AddTransactionBookingsRes1Dto } from '../models/AddTransactionBookingsRes1Dto';
import type { AddTransactionBookingsResDto } from '../models/AddTransactionBookingsResDto';
import type { AddTravelersBookingsReqDto } from '../models/AddTravelersBookingsReqDto';
import type { AddTravelersBookingsRes1Dto } from '../models/AddTravelersBookingsRes1Dto';
import type { AddTravelersBookingsResDto } from '../models/AddTravelersBookingsResDto';
import type { CancelBookingsReqDto } from '../models/CancelBookingsReqDto';
import type { CancelBookingsRes1Dto } from '../models/CancelBookingsRes1Dto';
import type { CancelBookingsResDto } from '../models/CancelBookingsResDto';
import type { ComputePriceBookingsReq1Dto } from '../models/ComputePriceBookingsReq1Dto';
import type { ComputePriceBookingsReqDto } from '../models/ComputePriceBookingsReqDto';
import type { ComputePriceBookingsRes1Dto } from '../models/ComputePriceBookingsRes1Dto';
import type { ComputePriceBookingsResDto } from '../models/ComputePriceBookingsResDto';
import type { CreateBookingReq1Dto } from '../models/CreateBookingReq1Dto';
import type { CreateBookingReqDto } from '../models/CreateBookingReqDto';
import type { CreateBookingRes1Dto } from '../models/CreateBookingRes1Dto';
import type { CreateBookingResDto } from '../models/CreateBookingResDto';
import type { DiscardTransactionBookingsReqDto } from '../models/DiscardTransactionBookingsReqDto';
import type { DiscardTransactionBookingsResDto } from '../models/DiscardTransactionBookingsResDto';
import type { GetBookingRes1Dto } from '../models/GetBookingRes1Dto';
import type { GetBookingResDto } from '../models/GetBookingResDto';
import type { GetBookingsResDto } from '../models/GetBookingsResDto';
import type { PaxData } from '../models/PaxData';
import type { ProductType } from '../models/ProductType';
import type { RemoveTravelersBookingsReqDto } from '../models/RemoveTravelersBookingsReqDto';
import type { RemoveTravelersBookingsRes1Dto } from '../models/RemoveTravelersBookingsRes1Dto';
import type { RemoveTravelersBookingsResDto } from '../models/RemoveTravelersBookingsResDto';
import type { SetTravelerPickupInfoBookingsReqDto } from '../models/SetTravelerPickupInfoBookingsReqDto';
import type { SetTravelersParticipationBookingsReqDto } from '../models/SetTravelersParticipationBookingsReqDto';
import type { SetTravelersParticipationBookingsRes1Dto } from '../models/SetTravelersParticipationBookingsRes1Dto';
import type { SetTravelersParticipationBookingsResDto } from '../models/SetTravelersParticipationBookingsResDto';
import type { UpdateBooking111ReqDto } from '../models/UpdateBooking111ReqDto';
import type { UpdateBooking1Res1Dto } from '../models/UpdateBooking1Res1Dto';
import type { UpdateBooking1ResDto } from '../models/UpdateBooking1ResDto';
import type { UpdateBookingReq1Dto } from '../models/UpdateBookingReq1Dto';
import type { UpdateBookingReqDto } from '../models/UpdateBookingReqDto';
import type { UpdateBookingRes1Dto } from '../models/UpdateBookingRes1Dto';
import type { UpdateBookingResDto } from '../models/UpdateBookingResDto';
import type { UpdateTravelersBookingsReqDto } from '../models/UpdateTravelersBookingsReqDto';
import type { UpdateTravelersBookingsRes1Dto } from '../models/UpdateTravelersBookingsRes1Dto';
import type { UpdateTravelersBookingsResDto } from '../models/UpdateTravelersBookingsResDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BookingsService {

    /**
     * List/Filter Bookings
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param orderNumber Number of the order to which the bookings belong
     * @param eventId ID of the event to which the booking belongs
     * @param customerName Name of the customer
     * @param customerEmail Email of the customer
     * @param customerPhoneNumber Phone number of the customer
     * @param productType Type of the product(s) being searched
     * @param productName name of the product(s) being searched
     * @param startTs Timestamp of start datetime
     * @param endTs Timestamp of end datetime
     * @param location location that is in the route of the search product(s)
     * @param pageNumber ID/Number of the page to open
     * @param pageSize number of items returned
     * @returns GetBookingsResDto Successful Response
     * @throws ApiError
     */
    public static getBookings(
authorization: string,
orderNumber?: string,
eventId?: string,
customerName?: string,
customerEmail?: string,
customerPhoneNumber?: string,
productType?: ProductType,
productName?: string,
startTs?: number,
endTs?: number,
location?: string,
pageNumber?: number,
pageSize: number = 20,
): CancelablePromise<GetBookingsResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bookings',
            headers: {
                'authorization': authorization,
            },
            query: {
                'order_number': orderNumber,
                'event_id': eventId,
                'customer_name': customerName,
                'customer_email': customerEmail,
                'customer_phone_number': customerPhoneNumber,
                'product_type': productType,
                'product_name': productName,
                'start_ts': startTs,
                'end_ts': endTs,
                'location': location,
                'page_number': pageNumber,
                'page_size': pageSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Booking
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createBooking(
authorization: string,
requestBody: (CreateBookingReqDto | CreateBookingReq1Dto),
): CancelablePromise<(CreateBookingResDto | CreateBookingRes1Dto)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bookings',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Fetch Booking
     * @param bookingId ID of the booking being fetched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getBooking(
bookingId: string,
authorization: string,
): CancelablePromise<(GetBookingResDto | GetBookingRes1Dto)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bookings/{booking_id}',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Patch Booking by ID
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateBooking(
bookingId: string,
authorization: string,
requestBody: (UpdateBookingReqDto | UpdateBookingReq1Dto),
): CancelablePromise<(UpdateBookingResDto | UpdateBookingRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Compute price of a booking
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static computePriceBookings(
authorization: string,
requestBody: (ComputePriceBookingsReqDto | ComputePriceBookingsReq1Dto),
): CancelablePromise<(ComputePriceBookingsResDto | ComputePriceBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bookings/compute_price',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel the Booking
     * @param bookingId ID of the booking being cancelled
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static cancelBookings(
bookingId: string,
authorization: string,
requestBody: CancelBookingsReqDto,
): CancelablePromise<(CancelBookingsResDto | CancelBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/cancel',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add transaction for Booking by ID
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addTransactionBookings(
bookingId: string,
authorization: string,
requestBody: AddTransactionBookingsReqDto,
): CancelablePromise<(AddTransactionBookingsResDto | AddTransactionBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/add_transaction',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Discard transaction for Booking by ID
     * @param bookingId ID of the booking being patched
     * @param transactionId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns DiscardTransactionBookingsResDto Successful Response
     * @throws ApiError
     */
    public static discardTransactionBookings(
bookingId: string,
transactionId: string,
authorization: string,
requestBody: DiscardTransactionBookingsReqDto,
): CancelablePromise<DiscardTransactionBookingsResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/discard_transaction/{transaction_id}',
            path: {
                'booking_id': bookingId,
                'transaction_id': transactionId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Hold availability Booking by ID
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static holdAvailabilityBookings(
bookingId: string,
authorization: string,
): CancelablePromise<(UpdateBookingResDto | UpdateBookingRes1Dto)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bookings/{booking_id}/hold_availability',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reserve Booking by ID
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static reserveBookings(
bookingId: string,
authorization: string,
): CancelablePromise<(UpdateBookingResDto | UpdateBookingRes1Dto)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bookings/{booking_id}/reserve',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add a list of travellers
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addTravelersBookings(
bookingId: string,
authorization: string,
requestBody: AddTravelersBookingsReqDto,
): CancelablePromise<(AddTravelersBookingsResDto | AddTravelersBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/add_travelers',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update a list of travelers for Booking by ID
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateTravelersBookings(
bookingId: string,
authorization: string,
requestBody: UpdateTravelersBookingsReqDto,
): CancelablePromise<(UpdateTravelersBookingsResDto | UpdateTravelersBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/update_travelers',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove a list of transaction for Booking by ID
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeTravelersBookings(
bookingId: string,
authorization: string,
requestBody: RemoveTravelersBookingsReqDto,
): CancelablePromise<(RemoveTravelersBookingsResDto | RemoveTravelersBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/remove_travelers',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set participation type for travelers
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setTravelersParticipationBookings(
bookingId: string,
authorization: string,
requestBody: SetTravelersParticipationBookingsReqDto,
): CancelablePromise<(SetTravelersParticipationBookingsResDto | SetTravelersParticipationBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/set_travelers_participation',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Move Booking from one event to another
     * @param bookingId ID of the booking being moved
     * @param eventId ID of the event the booking is moved to
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateBooking1(
bookingId: string,
eventId: string,
authorization: string,
): CancelablePromise<(UpdateBooking1ResDto | UpdateBooking1Res1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/move/{event_id}',
            path: {
                'booking_id': bookingId,
                'event_id': eventId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update booking pax
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateBooking11(
bookingId: string,
authorization: string,
requestBody: PaxData,
): CancelablePromise<(UpdateBookingResDto | UpdateBookingRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/update_pax',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update booked product options
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateBooking111(
bookingId: string,
authorization: string,
requestBody: UpdateBooking111ReqDto,
): CancelablePromise<(UpdateBookingResDto | UpdateBookingRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/update_product_options',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set pickup info of the traveler on the booking
     * @param bookingId ID of the booking being patched
     * @param travelerId ID of the traveler being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setTravelerPickupInfoBookings(
bookingId: string,
travelerId: string,
authorization: string,
requestBody: SetTravelerPickupInfoBookingsReqDto,
): CancelablePromise<(UpdateTravelersBookingsResDto | UpdateTravelersBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/set_traveler_pickup_info/{traveler_id}',
            path: {
                'booking_id': bookingId,
                'traveler_id': travelerId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unset pickup info of the traveler on the booking
     * @param bookingId ID of the booking being patched
     * @param travelerId ID of the traveler being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static unsetTravelerPickupInfoBookings(
bookingId: string,
travelerId: string,
authorization: string,
): CancelablePromise<(UpdateTravelersBookingsResDto | UpdateTravelersBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/unset_traveler_pickup_info/{traveler_id}',
            path: {
                'booking_id': bookingId,
                'traveler_id': travelerId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
