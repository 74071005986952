/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ListItemAction {
    ADD = 'add',
    UPDATE = 'update',
    REMOVE = 'remove',
    REORDER = 'reorder',
}
