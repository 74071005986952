import {
    GetEventsResItem0BookingsSummaryBookingsItemFinancialsDto,
    PeriodData,
    ProductType,
    PersonNameData,
    ActivityType,
    BookingStatus,
    TransactionType,
    PaxData,
    GetEventsResItem0BookingsSummaryBookingsItemOrderCustomerDto,
    GetEventsResItem0BookingsSummaryTravelersSummaryTravelersItemDto,
    GetEventsResItem0BookingsSummaryBookingsItemProductFinancialsDto,
    GetProductsResItem0RouteDto,
    type GetEventsResItem0BookingsSummaryBookingsItemProductOptionsItemDto,
    GetProductResRouteDto,
} from '../../requests';
import { Activity } from '../common.types';
import { SourceType } from '../order/order.types';

export { BookingStatus, TransactionType, ActivityType };
export type { PersonNameData, PaxData };

export interface BookingProduct {
    id: string;
    type: ProductType;
    name: string;
    route: GetProductsResItem0RouteDto;
    financials: GetEventsResItem0BookingsSummaryBookingsItemProductFinancialsDto;
    options?: GetEventsResItem0BookingsSummaryBookingsItemProductOptionsItemDto[];
}

export interface Booking {
    id: string;
    status: BookingStatus;
    notes?: string[];
    orderId: string;
    eventId: string;
    product: BookingProduct;
    date: PeriodData;
    reason?: string;
    financials: GetEventsResItem0BookingsSummaryBookingsItemFinancialsDto;
    participants: {
        pax: PaxData;
        customer: GetEventsResItem0BookingsSummaryBookingsItemOrderCustomerDto;
        travelers?: GetEventsResItem0BookingsSummaryTravelersSummaryTravelersItemDto[];
    };
    lifecycle: Activity[];
    source: {
        type: SourceType;
        name: string;
    };
    route?: GetProductResRouteDto;
}

export interface Attachment {
    uuid?: string;
    name: string;
    contentType: string;
    content?: string;
    url?: string;
}

export enum PaymentStatus {
    PAID = 'paid',
    PARTIALLY_PAID = 'partially_paid',
    PENDING_PAYMENT = 'pending_payment',
    REFUNDED = 'refunded',
    PARTIALLY_REFUNDED = 'partially_refunded',
}
