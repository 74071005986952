import React, { useEffect, useRef, useState, ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { CSS } from '@dnd-kit/utilities';
import {
    Announcements,
    closestCenter,
    DragOverlay,
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
    defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import {
    arrayMove,
    useSortable,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {
    restrictToFirstScrollableAncestor,
    restrictToVerticalAxis,
    restrictToWindowEdges,
} from '@dnd-kit/modifiers';

interface SortableItemProps {
    id: string;
    index: number;
    renderItem: (v: Record<string, any>) => ReactElement;
}

const SortableItem: React.FC<SortableItemProps> = ({
    id,
    index,
    renderItem,
}) => {
    const {
        isDragging,
        isSorting,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
    } = useSortable({
        id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return renderItem({
        dragging: isDragging,
        sorting: isSorting,
        index,
        id,
        ref: setNodeRef,
        style,
        setActivatorNodeRef,
        listeners,
    });
};

export interface SortableProps {
    items: string[];
    setItems: (n: string[]) => void;
    renderItem: (v: Record<string, any>) => ReactElement;
}

export const Sortable: React.FC<SortableProps> = ({
    items,
    setItems,
    renderItem,
}) => {
    const [activeId, setActiveId] = useState<string | null>(null);
    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const isFirstAnnouncement = useRef(true);
    const getIndex = (id: string) => items.indexOf(id);
    const getPosition = (id: string) => getIndex(id) + 1;
    const activeIndex = activeId ? getIndex(activeId) : -1;
    const announcements: Announcements = {
        onDragStart({ active: { id } }) {
            return `Picked up sortable item ${String(
                id
            )}. Sortable item ${id} is in position ${getPosition(
                id as string
            )} of ${items.length}`;
        },
        onDragOver({ active, over }) {
            // In this specific use-case, the picked up item's `id` is always the same as the first `over` id.
            // The first `onDragOver` event therefore doesn't need to be announced, because it is called
            // immediately after the `onDragStart` announcement and is redundant.
            if (isFirstAnnouncement.current) {
                isFirstAnnouncement.current = false;
                return;
            }

            if (over) {
                // eslint-disable-next-line consistent-return
                return `Sortable item ${
                    active.id
                } was moved into position ${getPosition(
                    over.id as string
                )} of ${items.length}`;
            }
        },
        // eslint-disable-next-line consistent-return
        onDragEnd({ active, over }) {
            if (over) {
                return `Sortable item ${
                    active.id
                } was dropped at position ${getPosition(
                    over.id as string
                )} of ${items.length}`;
            }
        },
        onDragCancel({ active: { id } }) {
            return `Sorting was cancelled. Sortable item ${id} was dropped and returned to position ${getPosition(
                id as string
            )} of ${items.length}.`;
        },
    };

    useEffect(() => {
        if (!activeId) {
            isFirstAnnouncement.current = true;
        }
    }, [activeId]);

    return (
        <DndContext
            accessibility={{
                announcements,
                screenReaderInstructions: {
                    draggable: `
    To pick up a sortable item, press the space bar.
    While sorting, use the arrow keys to move the item.
    Press space again to drop the item in its new position, or press escape to cancel.
  `,
                },
            }}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={({ active }) => {
                if (!active) {
                    return;
                }

                setActiveId(active.id as string);
            }}
            onDragEnd={({ over }) => {
                setActiveId(null);

                if (over) {
                    const overIndex = getIndex(over.id as string);
                    if (activeIndex !== overIndex) {
                        setItems(arrayMove(items, activeIndex, overIndex));
                    }
                }
            }}
            onDragCancel={() => setActiveId(null)}
            modifiers={[
                restrictToFirstScrollableAncestor,
                restrictToVerticalAxis,
                restrictToWindowEdges,
            ]}
        >
            <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
            >
                {items.map((value, index) => (
                    <SortableItem
                        key={value}
                        id={value}
                        index={index}
                        renderItem={renderItem}
                    />
                ))}
            </SortableContext>
            {createPortal(
                <DragOverlay
                    dropAnimation={{
                        sideEffects: defaultDropAnimationSideEffects({
                            styles: {
                                active: {
                                    opacity: '0.5',
                                },
                            },
                        }),
                    }}
                >
                    {activeId ? renderItem({ index: activeIndex }) : null}
                </DragOverlay>,
                document.body
            )}
        </DndContext>
    );
};
