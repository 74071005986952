import { addDays, startOfDay } from 'date-fns';
import {
    type ComputeAvailabilityReqProductsItem0Dto,
    type ComputeAvailabilityReqProductsItem1Dto,
    type ComputeAvailabilityReqProductsItem2Dto,
    ProductOptionType,
    TimezoneName,
} from '../../requests';
import { ComputeAvailabilityResMatrixObjItem0EventsItemDto } from '../../other/ComputeAvailabilityResMatrixObjItem0EventsItemDto';
import { ComputeAvailabilityResMatrixObjItem0Dto } from '../../other/ComputeAvailabilityResMatrixObjItem0Dto';

import {
    Availability,
    AvailabilityFilterValues,
    AvailableEvent,
    AvailableProduct,
} from './availability.types';

export const availabilityEventDtoToAvailableEvent = (
    event: ComputeAvailabilityResMatrixObjItem0EventsItemDto
): AvailableEvent => ({
    id: event.id || '',
    time: event.date.start * 1000,
    availableSeats: event.available_seats,
    rawDto: event,
});

export const availabilityProductDtoToAvailabilityProduct = (
    product: ComputeAvailabilityResMatrixObjItem0Dto
): AvailableProduct => ({
    id: product.id,
    type: product.type,
    name: product.name,
    minPrice: product.min_price || 0,
    currency: product.financials.currency.abbr,
    options: product.options?.map(o => ({
        name: o.name as string,
        type: o.type as ProductOptionType,
        pax: {},
        hasPrice: !!o.financials?.pricing,
        pricing: o.financials?.pricing,
    })),
    route: product.route.name
        ? {
              name: product.route.name,
              stops:
                  product.route.stops?.map(stop => ({
                      name: stop?.location?.name as string,
                      arrivalOffset: { day: 0, hour: 0, minute: 0 },
                      duration: { day: 0, hour: 0, minute: 0 },
                  })) || [],
          }
        : undefined,
    sharedTeams:
        product.rbac?.acl.map(({ group }) => ({
            id: group.id,
            type: group.type,
        })) || [],
    events:
        product.events
            ?.sort((a, b) => a.date.start - b.date.start)
            .map(availabilityEventDtoToAvailableEvent) || [],
});

export const computeAvailabilityDtoToAvailability = (
    datetime: string,
    availability: ComputeAvailabilityResMatrixObjItem0Dto[]
): Availability => ({
    date: parseInt(datetime, 10) * 1000,
    products: availability.map(availabilityProductDtoToAvailabilityProduct),
});

export const filterToRequestBodyConverter = (
    filters: AvailabilityFilterValues
) => {
    const products: (
        | ComputeAvailabilityReqProductsItem0Dto
        | ComputeAvailabilityReqProductsItem1Dto
        | ComputeAvailabilityReqProductsItem2Dto
    )[] = [];

    if (filters.products) {
        filters.products.forEach(id => {
            products.push({ id });
        });
    }

    if (filters.productTypes) {
        filters.productTypes.forEach(type => {
            products.push({ type });
        });
    }

    return {
        date_range:
            filters.dates && filters.dates.startDate && filters.dates.endDate
                ? {
                      tz_name: Intl.DateTimeFormat().resolvedOptions()
                          .timeZone as TimezoneName,
                      start: Math.round(
                          filters.dates.startDate.getTime() / 1000
                      ),
                      end:
                          Math.round(filters.dates.endDate.getTime() / 1000) +
                          24 * 60 * 60 -
                          1,
                  }
                : {
                      tz_name: Intl.DateTimeFormat().resolvedOptions()
                          .timeZone as TimezoneName,
                      start: Math.round(
                          startOfDay(new Date()).getTime() / 1000
                      ),
                      end:
                          Math.round(
                              addDays(startOfDay(new Date()), 7).getTime() /
                                  1000
                          ) +
                          24 * 60 * 60 -
                          1,
                  },
        pax: filters.pax,
        products: products.length > 0 ? products : undefined,
        pricing: filters.pricing
            ? {
                  currency: {
                      abbr: filters.pricing.currency || 'USD',
                      name: filters.pricing.currency || 'USD',
                  },
                  min_price: filters.pricing.minPrice,
                  max_price: filters.pricing.maxPrice,
              }
            : undefined,
    };
};
