import React, { useCallback, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Switch, TextField, TextFieldCategory, useForm } from '@travelity/form';
import {
    Alert,
    Avatar,
    Box,
    Chip,
    Divider,
    IconButton,
    Stack,
} from '@mui/material';
import { Button, LoadingOverlay } from '@travelity/ui';
import {
    useCreateTeam,
    useInviteMemberTeams,
} from '@travelity/api/src/queries';
import { MemberRole } from '@travelity/api';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';
import schema, { schemaInvite } from './team-modal.schema';

const formId = 'team-modal';

export interface TeamModalProps {
    handleCancel: (v?: { id: string; name: string; isolated: boolean }) => void;
    open: boolean;
    disableEnterprise?: boolean;
    team?: { id: string; name: string; isolated: boolean };
}

const roles = [
    {
        label: 'Contributor',
        value: MemberRole.CONTRIBUTOR,
    },
    {
        label: 'Lead',
        value: MemberRole.LEAD,
    },
    {
        label: 'Referral Partner',
        value: MemberRole.REFERRAL_PARTNER,
    },
];

const TeamModal: React.FC<TeamModalProps> = ({
    handleCancel,
    open,
    disableEnterprise,
    team: defaultTeam,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [team, setTeam] = React.useState<
        { id: string; name: string; isolated: boolean } | undefined
    >(defaultTeam);

    const handleClose = useCallback((event: Object, reason: string) => {
        if (reason && reason === 'backdropClick') return;
        handleCancel(team);
    }, []);

    const { mutate: createTeam, isLoading } = useCreateTeam({
        onSuccess: data => {
            setTeam({ id: data.id, name: data.name, isolated: data.isolated });
        },
        onError: () => {
            enqueueSnackbar('Failed to create team', {
                variant: 'error',
            });
        },
    });

    const {
        Form,
        trigger,
        reset,
        formState: { errors },
    } = useForm<{ name?: string; isolated: boolean; company?: string }>({
        schema,
        mode: 'onChange',
        reValidateMode: 'onChange',
        onSubmit: useCallback((data: { name?: string; isolated: boolean }) => {
            if (!data.name) return;
            createTeam({
                requestBody: { name: data.name, isolated: data.isolated },
            });
        }, []),
    });

    // Invite team members
    const [invitedPeople, setInvitedPeople] = React.useState<
        { email: string; role?: MemberRole }[]
    >([]);

    const { mutate: inviteTeamMember, isLoading: isInviting } =
        useInviteMemberTeams({
            onSuccess: data => {
                setInvitedPeople([
                    {
                        email: data.email,
                        role: data.membership.role || MemberRole.CONTRIBUTOR,
                    },
                    ...invitedPeople,
                ]);
            },
            onError: () => {
                enqueueSnackbar('Failed to invite member', {
                    variant: 'error',
                });
            },
        });

    const {
        Form: FormInvite,
        reset: resetInvite,
        watch,
        formState: { errors: errorsInvite },
    } = useForm<{ email?: string; role: MemberRole }>({
        schema: schemaInvite,
        mode: 'onChange',
        reValidateMode: 'onChange',
        validateInitially: true,
        onSubmit: useCallback(
            (data: { email?: string; role: MemberRole; company?: string }) => {
                if (!data.email || !team) return;
                inviteTeamMember(
                    {
                        teamId: team.id,
                        requestBody: {
                            email: data.email,
                            company:
                                data.role === MemberRole.REFERRAL_PARTNER
                                    ? data.company
                                    : undefined,
                            membership: {
                                role: data.role || MemberRole.CONTRIBUTOR,
                            },
                        },
                    },
                    {
                        onSuccess: () => {
                            resetInvite(
                                { email: '' },
                                {
                                    keepDirty: true,
                                    keepTouched: true,
                                    keepErrors: false,
                                }
                            );
                        },
                    }
                );
            },
            [team]
        ),
    });

    useEffect(() => {
        reset({ isolated: false, company: '' });
        if (open) trigger();
    }, [open, reset]);

    const errorFields = [];
    if (errors.name?.message) errorFields.push(errors.name?.message);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    py: 1,
                    bgcolor: '#fff',
                    position: 'relative',
                    minHeight: 256,
                },
            }}
        >
            <DialogTitle
                sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    fontWeight: 600,
                    color: '#2B395B',
                }}
                id="alert-dialog-title"
            >
                {!team ? 'Create New Team' : 'Invite Team Members'}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => handleCancel(team)}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: theme => theme.palette.grey[400],
                }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
            <DialogContent
                sx={{
                    pr: 3,
                    pb: 2,
                }}
            >
                {!team ? (
                    <Form id={formId}>
                        <Stack gap={2} sx={{ pt: 1 }}>
                            <TextField
                                name="name"
                                label="Team name*"
                                placeholder="Type team name"
                            />
                            <Switch
                                name="isolated"
                                label="Isolated"
                                disabled={disableEnterprise}
                                LabelProps={{
                                    sx: {
                                        color: '#2B395B',
                                    },
                                }}
                            />
                        </Stack>
                        {isLoading && (
                            <LoadingOverlay
                                title="Please wait..."
                                subTitle="It’ll just take a moment."
                            />
                        )}
                    </Form>
                ) : (
                    <>
                        <FormInvite>
                            <Stack sx={{ width: 388, mb: 2 }} direction="row">
                                <TextFieldCategory
                                    name="email"
                                    categories={roles}
                                    categoryName="role"
                                    placeholder="Type Your Email"
                                    sx={{ width: 306, mr: 1 }}
                                />
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    type="submit"
                                    sx={{
                                        py: '14px',
                                        px: '16px',
                                    }}
                                    disabled={
                                        !!errorsInvite.email ||
                                        !watch('email') ||
                                        isInviting
                                    }
                                >
                                    Invite
                                </Button>
                            </Stack>
                            <TextField
                                name="company"
                                label="Company"
                                disabled={
                                    watch('role') !==
                                    MemberRole.REFERRAL_PARTNER
                                }
                                placeholder="Type Company Name"
                            />
                        </FormInvite>
                        <>
                            <Divider
                                sx={{
                                    mt: 1,
                                    mb: 1,
                                    '.MuiDivider-wrapper': {
                                        color: '#CACEDC',
                                    },
                                    fontWeight: '700',
                                }}
                            >
                                Invited Members
                            </Divider>
                            {invitedPeople.length ? (
                                <Box
                                    component={PerfectScrollbar}
                                    sx={{ maxHeight: '58px' }}
                                >
                                    <Stack gap={1}>
                                        {invitedPeople.map(
                                            ({ email, role }) => (
                                                <Stack
                                                    key={email}
                                                    justifyContent="space-between"
                                                    direction="row"
                                                >
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        gap={1}
                                                    >
                                                        <Avatar
                                                            sx={{
                                                                width: '25px',
                                                                height: '25px',
                                                                bgcolor:
                                                                    '#2B395B',
                                                                lineHeight:
                                                                    '15px',
                                                                color: '#FFF',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            {email[0]}
                                                        </Avatar>
                                                        <Box>{email}</Box>
                                                    </Stack>
                                                    <Chip
                                                        label={role}
                                                        color="secondary"
                                                        sx={{
                                                            color: '#67728B',
                                                            bgcolor: '#DDF0F5',
                                                            px: '12px',
                                                            py: '6px',
                                                            '& .MuiChip-label':
                                                                {
                                                                    px: 0.5,
                                                                },
                                                        }}
                                                    />
                                                </Stack>
                                            )
                                        )}
                                    </Stack>
                                </Box>
                            ) : (
                                <Alert severity="info">
                                    {team.name} doesn&apos;t have new invitees
                                    yet
                                </Alert>
                            )}
                        </>
                    </>
                )}
            </DialogContent>
            {!team && (
                <DialogActions sx={{ px: 3, py: 2 }}>
                    <Button
                        color="neutral"
                        variant="contained"
                        onClick={() => handleCancel()}
                        sx={{
                            px: '26px',
                            py: '10px',
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        form={formId}
                        sx={{
                            py: '10px',
                        }}
                        requiredFields={errorFields}
                    >
                        Create
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default TeamModal;
