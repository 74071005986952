import { object, string } from 'yup';

const schema = object().shape({
    position: string().required(),
    staff: object().required().shape({
        email: string().required(),
    }),
});

export default schema;
