import { array, object, string } from 'yup';
import { SourceType } from '@travelity/api';

const schema = object({
    customer: object().required('Customer is a required field'),
    sourceName: string().when(['sourceType'], ([sourceType], sch) => {
        return sourceType === SourceType.DIRECT
            ? sch.required('Booking Source is a required field')
            : sch;
    }),
    referral: object().when(['sourceType'], ([sourceType], sch) => {
        return sourceType === SourceType.REFERRAL
            ? sch.required('Booking Source is a required field')
            : sch;
    }),
    notes: array(),
});

export default schema;
