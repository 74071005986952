/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProductType {
    TOUR = 'tour',
    TRANSFER = 'transfer',
    PACKAGE = 'package',
}
