/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RegisterUsersResAccountMembershipsItemRoleAccessDto } from './RegisterUsersResAccountMembershipsItemRoleAccessDto';

export type RegisterUsersResAccountMembershipsItemRoleDto = {
    /**
     * Type of user's role
     */
    type: RegisterUsersResAccountMembershipsItemRoleDto.type;
    /**
     * Access details of the current role
     */
    access?: RegisterUsersResAccountMembershipsItemRoleAccessDto;
};

export namespace RegisterUsersResAccountMembershipsItemRoleDto {

    /**
     * Type of user's role
     */
    export enum type {
        OWNER = 'owner',
    }


}
