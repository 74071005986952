import React from 'react';
import { Stack } from '@mui/material';
import { CreatableTags, TextField } from '@travelity/form';
import { useFormContext } from 'react-hook-form';
import { DataRow } from '../../../components/data-row';

export interface MemberPersonalDetailsFormProps {
    preview?: boolean;
}

const MemberPersonalDetailsForm: React.FC<MemberPersonalDetailsFormProps> = ({
    preview,
}) => {
    const { watch } = useFormContext();
    const numbers = watch('numbers') as { value: string }[];
    const fullName = watch('fullName');

    return !preview ? (
        <Stack spacing={2} direction="column" gap={2}>
            <TextField
                placeholder="Type Full Name"
                name="fullName"
                label="Full Name"
                fullWidth
            />
            <CreatableTags
                name="numbers"
                regEx={/^\+?\d+ \d+$/}
                inputProps={{
                    sx: {
                        '&& .MuiInputBase-root': {
                            paddingRight: '10px',
                        },
                    },
                    label: 'Phone Numbers',
                    placeholder: 'Type number and press enter',
                }}
            />
        </Stack>
    ) : (
        <Stack sx={{ py: '3px', ml: '-2px' }} gap={4}>
            <DataRow label="Full Name" value={fullName || '-'} />
            <DataRow
                label="Phone Numbers"
                values={
                    numbers.length
                        ? numbers.map(({ value }) => value)
                        : undefined
                }
                value="-"
            />
        </Stack>
    );
};

export default React.memo(MemberPersonalDetailsForm);
