import React from 'react';
import { Box, Chip, Divider, FormLabel, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    TabForms,
    CurrencyField,
    CreatableTags,
    SelectCurrency,
} from '@travelity/form';
import { Tag } from '@travelity/ui';
import { PricingType } from '@travelity/api';
import { useFormContext } from 'react-hook-form';
import { Carousel } from '../../../carousel';

export interface FinancingCardContentProps {
    item: Record<string, any>;
    editing: boolean;
    sx?: Record<string, any>;
    nested?: boolean;
    prefix?: string;
}

enum Agebands {
    Adults = 'adults',
    Children = 'children',
    Infants = 'infants',
    Youth = 'youth',
    Seniors = 'seniors',
}

const FinancingCardContent: React.FC<FinancingCardContentProps> = props => {
    const { item, editing, nested, prefix, sx = {} } = props;
    const { t } = useTranslation('common');
    const { watch } = useFormContext();
    const formPrefix = prefix ? `${prefix}.` : '';
    const currency = watch(`${formPrefix}currency`);

    return (
        <Stack sx={{ p: 2, pt: !editing ? 1 : 2, ...sx }} gap={1}>
            {editing ? (
                <>
                    <SelectCurrency
                        name={`${formPrefix}currency`}
                        size="small"
                        width={nested ? '311px' : '343px'}
                    />
                    <TabForms
                        name={`${formPrefix}type`}
                        defaultValue={PricingType.PER_PERSON}
                        options={[
                            {
                                label: 'Per Person',
                                value: PricingType.PER_PERSON,
                                children: (
                                    <Carousel
                                        key={0}
                                        options={[
                                            {
                                                label: 'Price',
                                                value: 0,
                                                children: (
                                                    <Stack
                                                        key={0}
                                                        sx={{
                                                            p: 1,
                                                            mt: 0.5,
                                                            borderRadius: '8px',
                                                            background: '#FFF',
                                                        }}
                                                        gap={0.75}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                        >
                                                            <FormLabel
                                                                sx={{
                                                                    width: 80,
                                                                }}
                                                            >
                                                                Base
                                                            </FormLabel>
                                                            <Box
                                                                sx={{
                                                                    flexGrow: 2,
                                                                }}
                                                            >
                                                                <CurrencyField
                                                                    currency={
                                                                        currency
                                                                    }
                                                                    size="small"
                                                                    name={`${formPrefix}price.base`}
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                        </Stack>
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                        >
                                                            <FormLabel
                                                                sx={{
                                                                    width: 80,
                                                                }}
                                                            >
                                                                Per Person
                                                            </FormLabel>
                                                            <Box
                                                                sx={{
                                                                    flexGrow: 2,
                                                                }}
                                                            >
                                                                <CurrencyField
                                                                    currency={
                                                                        currency
                                                                    }
                                                                    showErrors
                                                                    helperText=""
                                                                    size="small"
                                                                    name={`${formPrefix}price.perPerson`}
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                        </Stack>
                                                    </Stack>
                                                ),
                                            },
                                            {
                                                label: 'Cost',
                                                value: 1,
                                                children: (
                                                    <Stack
                                                        key={1}
                                                        sx={{
                                                            p: 1,
                                                            mt: 0.5,
                                                            borderRadius: '8px',
                                                            background: '#FFF',
                                                        }}
                                                        gap={0.75}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                        >
                                                            <FormLabel
                                                                sx={{
                                                                    width: 80,
                                                                }}
                                                            >
                                                                Base
                                                            </FormLabel>
                                                            <Box
                                                                sx={{
                                                                    flexGrow: 2,
                                                                }}
                                                            >
                                                                <CurrencyField
                                                                    currency={
                                                                        currency
                                                                    }
                                                                    size="small"
                                                                    name={`${formPrefix}cost.base`}
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                        </Stack>
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                        >
                                                            <FormLabel
                                                                sx={{
                                                                    width: 80,
                                                                }}
                                                            >
                                                                Per Person
                                                            </FormLabel>
                                                            <Box
                                                                sx={{
                                                                    flexGrow: 2,
                                                                }}
                                                            >
                                                                <CurrencyField
                                                                    currency={
                                                                        currency
                                                                    }
                                                                    size="small"
                                                                    name={`${formPrefix}cost.perPerson`}
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                        </Stack>
                                                    </Stack>
                                                ),
                                            },
                                        ]}
                                    />
                                ),
                            },
                            {
                                label: 'Per Product',
                                value: PricingType.PER_PRODUCT,
                                children: (
                                    <Carousel
                                        key={1}
                                        options={[
                                            {
                                                label: 'Price',
                                                value: 0,
                                                children: (
                                                    <Stack
                                                        key={0}
                                                        sx={{
                                                            p: 1,
                                                            mt: 0.5,
                                                            borderRadius: '8px',
                                                            background: '#FFF',
                                                        }}
                                                        gap={0.75}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                        >
                                                            <FormLabel
                                                                sx={{
                                                                    width: 80,
                                                                }}
                                                            >
                                                                Base
                                                            </FormLabel>
                                                            <Box
                                                                sx={{
                                                                    flexGrow: 2,
                                                                }}
                                                            >
                                                                <CurrencyField
                                                                    currency={
                                                                        currency
                                                                    }
                                                                    size="small"
                                                                    name={`${formPrefix}price.base`}
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                        </Stack>
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                        >
                                                            <FormLabel
                                                                sx={{
                                                                    width: 80,
                                                                }}
                                                            >
                                                                Per Product
                                                            </FormLabel>
                                                            <Box
                                                                sx={{
                                                                    flexGrow: 2,
                                                                }}
                                                            >
                                                                <CurrencyField
                                                                    currency={
                                                                        currency
                                                                    }
                                                                    size="small"
                                                                    showErrors
                                                                    helperText=""
                                                                    name={`${formPrefix}price.perProduct`}
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                        </Stack>
                                                    </Stack>
                                                ),
                                            },
                                            {
                                                label: 'Cost',
                                                value: 1,
                                                children: (
                                                    <Stack
                                                        key={1}
                                                        sx={{
                                                            p: 1,
                                                            mt: 0.5,
                                                            borderRadius: '8px',
                                                            background: '#FFF',
                                                        }}
                                                        gap={0.75}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                        >
                                                            <FormLabel
                                                                sx={{
                                                                    width: 80,
                                                                }}
                                                            >
                                                                Base
                                                            </FormLabel>
                                                            <Box
                                                                sx={{
                                                                    flexGrow: 2,
                                                                }}
                                                            >
                                                                <CurrencyField
                                                                    currency={
                                                                        currency
                                                                    }
                                                                    size="small"
                                                                    name={`${formPrefix}cost.base`}
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                        </Stack>
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                        >
                                                            <FormLabel
                                                                sx={{
                                                                    width: 80,
                                                                }}
                                                            >
                                                                Per Product
                                                            </FormLabel>
                                                            <Box
                                                                sx={{
                                                                    flexGrow: 2,
                                                                }}
                                                            >
                                                                <CurrencyField
                                                                    currency={
                                                                        currency
                                                                    }
                                                                    size="small"
                                                                    name={`${formPrefix}cost.perProduct`}
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                        </Stack>
                                                    </Stack>
                                                ),
                                            },
                                        ]}
                                    />
                                ),
                            },
                            {
                                label: 'Per Ageband',
                                value: PricingType.PER_AGEBAND,
                                children: (
                                    <Carousel
                                        key={2}
                                        options={[
                                            {
                                                label: 'Price',
                                                value: 0,
                                                children: (
                                                    <Stack
                                                        key={0}
                                                        sx={{
                                                            p: 1,
                                                            mt: 0.5,
                                                            borderRadius: '8px',
                                                            background: '#FFF',
                                                        }}
                                                        gap={0.75}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                        >
                                                            <FormLabel
                                                                sx={{
                                                                    width: 80,
                                                                }}
                                                            >
                                                                Base
                                                            </FormLabel>
                                                            <Box
                                                                sx={{
                                                                    flexGrow: 2,
                                                                }}
                                                            >
                                                                <CurrencyField
                                                                    currency={
                                                                        currency
                                                                    }
                                                                    size="small"
                                                                    name={`${formPrefix}price.base`}
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                        </Stack>
                                                        {Object.values(
                                                            Agebands
                                                        ).map(ageband => (
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                            >
                                                                <FormLabel
                                                                    sx={{
                                                                        width: 80,
                                                                    }}
                                                                >
                                                                    Per{' '}
                                                                    {ageband}
                                                                </FormLabel>
                                                                <Box
                                                                    sx={{
                                                                        flexGrow: 2,
                                                                    }}
                                                                >
                                                                    <CurrencyField
                                                                        currency={
                                                                            currency
                                                                        }
                                                                        showErrors
                                                                        helperText=""
                                                                        size="small"
                                                                        name={`${formPrefix}price.counts.${ageband}`}
                                                                        fullWidth
                                                                    />
                                                                </Box>
                                                            </Stack>
                                                        ))}
                                                    </Stack>
                                                ),
                                            },
                                            {
                                                label: 'Cost',
                                                value: 1,
                                                children: (
                                                    <Stack
                                                        key={1}
                                                        sx={{
                                                            p: 1,
                                                            mt: 0.5,
                                                            borderRadius: '8px',
                                                            background: '#FFF',
                                                        }}
                                                        gap={0.75}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                        >
                                                            <FormLabel
                                                                sx={{
                                                                    width: 80,
                                                                }}
                                                            >
                                                                Base
                                                            </FormLabel>
                                                            <Box
                                                                sx={{
                                                                    flexGrow: 2,
                                                                }}
                                                            >
                                                                <CurrencyField
                                                                    currency={
                                                                        currency
                                                                    }
                                                                    size="small"
                                                                    name={`${formPrefix}cost.base`}
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                        </Stack>
                                                        {Object.values(
                                                            Agebands
                                                        ).map(ageband => (
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                            >
                                                                <FormLabel
                                                                    sx={{
                                                                        width: 80,
                                                                    }}
                                                                >
                                                                    Per{' '}
                                                                    {ageband}
                                                                </FormLabel>
                                                                <Box
                                                                    sx={{
                                                                        flexGrow: 2,
                                                                    }}
                                                                >
                                                                    <CurrencyField
                                                                        currency={
                                                                            currency
                                                                        }
                                                                        size="small"
                                                                        name={`${formPrefix}cost.counts.${ageband}`}
                                                                        fullWidth
                                                                    />
                                                                </Box>
                                                            </Stack>
                                                        ))}
                                                    </Stack>
                                                ),
                                            },
                                        ]}
                                    />
                                ),
                            },
                        ]}
                    />
                </>
            ) : (
                <Box>
                    <Carousel
                        options={[
                            {
                                label: 'Price',
                                value: 0,
                                children: (
                                    <Stack
                                        direction="row"
                                        flexWrap="wrap"
                                        sx={{ py: '3px', ml: '-2px' }}
                                    >
                                        {!!item.price?.base && (
                                            <Tag
                                                label={t('Base')}
                                                sx={{ m: '2px' }}
                                                values={[
                                                    `${item.price?.base} ${
                                                        currency || ''
                                                    }`,
                                                ]}
                                            />
                                        )}
                                        {item.type === PricingType.PER_PERSON &&
                                            !!item.price?.perPerson && (
                                                <Tag
                                                    label={t('Per Person')}
                                                    sx={{ m: '2px' }}
                                                    values={[
                                                        `${
                                                            item.price
                                                                ?.perPerson
                                                        } ${currency || ''}`,
                                                    ]}
                                                />
                                            )}
                                        {item.type ===
                                            PricingType.PER_PRODUCT &&
                                            !!item.price?.perProduct && (
                                                <Tag
                                                    label={t('Per Product')}
                                                    sx={{ m: '2px' }}
                                                    values={[
                                                        `${
                                                            item.price
                                                                ?.perProduct
                                                        } ${currency || ''}`,
                                                    ]}
                                                />
                                            )}
                                        {item.type ===
                                            PricingType.PER_AGEBAND &&
                                            Object.values(Agebands).map(
                                                ageband =>
                                                    !!item.price?.counts?.[
                                                        ageband
                                                    ] && (
                                                        <Tag
                                                            sx={{ m: '2px' }}
                                                            label={t(
                                                                `Per ${ageband}`
                                                            )}
                                                            values={[
                                                                `${
                                                                    item.price
                                                                        ?.counts?.[
                                                                        ageband
                                                                    ]
                                                                } ${
                                                                    currency ||
                                                                    ''
                                                                }`,
                                                            ]}
                                                        />
                                                    )
                                            )}
                                    </Stack>
                                ),
                            },
                            {
                                label: 'Cost',
                                value: 1,
                                children: (
                                    <Stack
                                        direction="row"
                                        flexWrap="wrap"
                                        sx={{ py: '3px', ml: '-2px' }}
                                    >
                                        {!!item.cost?.base && (
                                            <Tag
                                                label={t('Base')}
                                                sx={{ m: '2px' }}
                                                values={[
                                                    `${item.cost?.base} ${
                                                        currency || ''
                                                    }`,
                                                ]}
                                            />
                                        )}
                                        {item.type === PricingType.PER_PERSON &&
                                            !!item.cost?.perPerson && (
                                                <Tag
                                                    label={t('Per Person')}
                                                    sx={{ m: '2px' }}
                                                    values={[
                                                        `${
                                                            item.cost?.perPerson
                                                        } ${currency || ''}`,
                                                    ]}
                                                />
                                            )}
                                        {item.type ===
                                            PricingType.PER_PRODUCT &&
                                            !!item.cost?.perProduct && (
                                                <Tag
                                                    label={t('Per Product')}
                                                    sx={{ m: '2px' }}
                                                    values={[
                                                        `${
                                                            item.cost
                                                                ?.perProduct
                                                        } ${currency || ''}`,
                                                    ]}
                                                />
                                            )}
                                        {item.type ===
                                            PricingType.PER_AGEBAND &&
                                            Object.values(Agebands).map(
                                                ageband =>
                                                    item.cost?.counts?.[
                                                        ageband
                                                    ] && (
                                                        <Tag
                                                            label={t(
                                                                `Per ${ageband}`
                                                            )}
                                                            sx={{ m: '2px' }}
                                                            values={[
                                                                `${
                                                                    item.cost
                                                                        ?.counts?.[
                                                                        ageband
                                                                    ]
                                                                } ${
                                                                    currency ||
                                                                    ''
                                                                }`,
                                                            ]}
                                                        />
                                                    )
                                            )}
                                    </Stack>
                                ),
                            },
                        ]}
                        inPaper
                    />
                </Box>
            )}
            {(editing || !!item.inclusions?.length) && (
                <Divider>Inclusions</Divider>
            )}
            {editing ? (
                <CreatableTags
                    name={`${formPrefix}inclusions`}
                    inputProps={{
                        size: 'small',
                    }}
                />
            ) : (
                !!item.inclusions?.length && (
                    <Stack
                        direction="row"
                        flexWrap="wrap"
                        sx={{ py: '3px', ml: '-2px' }}
                    >
                        {item.inclusions?.map((field: any) => (
                            <Chip
                                key={field.id}
                                label={field.value}
                                size="small"
                                sx={{
                                    m: '5px 2px',
                                    height: 'auto',
                                    border: 0,
                                    p: '4px 8px',
                                    bgcolor: '#DDF0F5',
                                    '& > .MuiChip-label': {
                                        fontSize: '14px',
                                        color: '#9198AA',
                                        lineHeight: '14px',
                                    },
                                    '& > .MuiChip-deleteIconSmall': {
                                        ml: 0.5,
                                        mr: 0,
                                        fontSize: '14px',
                                        color: '#9198AA',
                                        lineHeight: '14px',
                                        '&: hover': {
                                            color: '#9198AA',
                                        },
                                    },
                                }}
                                color="primary"
                                variant="filled"
                            />
                        ))}
                    </Stack>
                )
            )}
        </Stack>
    );
};

export default FinancingCardContent;
