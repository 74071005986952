/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Language } from './Language';
import type { PersonNameData } from './PersonNameData';
import type { RegisterUsersReqProfileContactDetailsDto } from './RegisterUsersReqProfileContactDetailsDto';

export type CreateOrderReqCustomerDto = {
    /**
     * Schedule option which corresponds to the booked date
     */
    type?: CreateOrderReqCustomerDto.type;
    /**
     * Type of the capacity of the product
     */
    id?: string;
    /**
     * Profile's (person, company) contact details.
     */
    contact_details?: RegisterUsersReqProfileContactDetailsDto;
    /**
     * Person's name, separated into components.
     */
    name: PersonNameData;
    /**
     * List of languages the traveler speaks/understands.
     */
    languages: Array<Language>;
};

export namespace CreateOrderReqCustomerDto {

    /**
     * Schedule option which corresponds to the booked date
     */
    export enum type {
        PERSON = 'person',
    }


}
