import { useMemo } from 'react';
import {
    useMutation,
    UseMutationOptions,
    useQueryClient,
} from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import {
    useGetUser,
    useGetUserKey,
    useUpdateUser as useUpdateUser1,
    useUploadAvatarUsers,
} from '../../queries';
import { User } from './user.types';
import { currencyAbbrToCurrencyName, userDtoToUser } from './user.converters';
import {
    UpdateUserReqDto,
    UploadAvatarUsersReq1Dto,
    UsersService,
} from '../../requests';

export const useUser = () => {
    const { data: userDto, ...rest } = useGetUser(undefined, {
        retry: false,
        cacheTime: 10,
    });

    const updateDate = userDto?.last_updated?.at || userDto?.created?.at;
    const data: User | undefined = useMemo(() => {
        if (!userDto) {
            return undefined;
        }

        return userDtoToUser(userDto);
    }, [updateDate]);

    return {
        data,
        ...rest,
    };
};

export const useUpdateUser = (
    options: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.updateUser>>,
            unknown,
            {
                requestBody: UpdateUserReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const queryClient = useQueryClient();

    return useUpdateUser1({
        ...options,
        onSuccess: (...args) => {
            queryClient.setQueryData([useGetUserKey], args[0]);
            options.onSuccess?.(...args);
        },
    });
};

export const useUploadAvatarUser = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.uploadAvatarUsers>>,
            unknown,
            {
                requestBody: UploadAvatarUsersReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const queryClient = useQueryClient();

    return useUploadAvatarUsers({
        ...options,
        onSuccess: (...args) => {
            queryClient.setQueryData([useGetUserKey], args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useUpdateCurrency = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.updateCurrencyUsers>>,
            unknown,
            {
                currency: string;
                rate: number;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const queryClient = useQueryClient();
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(
        async ({ currency, rate }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return UsersService.updateCurrencyUsers(authorization, {
                name: currencyAbbrToCurrencyName(currency),
                abbr: currency,
                rate,
            });
        },
        {
            ...options,
            onSuccess: (...args) => {
                queryClient.setQueryData([useGetUserKey], args[0]);
                options?.onSuccess?.(...args);
            },
        }
    );
};

export const useRemoveCurrency = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.updateCurrencyUsers>>,
            unknown,
            {
                currency: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const queryClient = useQueryClient();
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(
        async ({ currency }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return UsersService.updateCurrencyUsers(authorization, {
                name: currencyAbbrToCurrencyName(currency),
                abbr: currency,
                rate: 0,
            });
        },
        {
            ...options,
            onSuccess: (...args) => {
                queryClient.setQueryData([useGetUserKey], args[0]);
                options?.onSuccess?.(...args);
            },
        }
    );
};
