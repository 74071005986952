import React, {
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react';
import {
    useForm as useReactHookForm,
    FormProvider,
    useFormContext,
    FieldValues,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormProps } from 'react-hook-form/dist/types';
import NestedFormProvider from './nested-form-provider/nested-form-provider';

export interface UseFormParams<T extends FieldValues> extends UseFormProps<T> {
    // TODO fix this type
    onSubmit?: (data: T, e?: React.BaseSyntheticEvent) => Promise<void> | void;
    schema?: any;
    resetValues?: boolean;
    validateInitially?: boolean;
    isModal?: boolean;
    onError?: (errors: Object, e?: React.BaseSyntheticEvent) => Promise<void>;
}

export interface FormProps extends React.HTMLProps<HTMLFormElement> {
    children: ReactNode;
}

export function useForm<T extends FieldValues>({
    schema,
    onSubmit,
    onError,
    resetValues,
    validateInitially,
    isModal,
    ...params
}: UseFormParams<T>) {
    const newParams = { ...params };
    const context = useFormContext();
    const nestedForm = !isModal && !!context;

    if (schema) {
        newParams.resolver = async (data, context, options) => {
            return yupResolver(schema)(data, context, options);
        };
    }
    const form = useReactHookForm<T>(newParams);

    // TODO if we are already in form we should use div and pass the state
    // with provider so any control can handle submit custom
    // Also we can have a custom button submit that will not add type submit in case no form tag is used.
    // Maybe we can have a hidden button submit here
    // We can even move this inside Form component if needed.

    const { reset, trigger, formState } = form;
    const valuesRef = useRef<any>();
    valuesRef.current = params.values;
    useEffect(() => {
        if (resetValues) {
            reset(valuesRef.current);
        }
    }, [resetValues, reset]);

    useEffect(() => {
        if (validateInitially) {
            trigger();
        }
    }, [validateInitially, trigger]);

    const submit = useCallback(
        onSubmit ? form.handleSubmit(onSubmit, onError) : () => {},
        [form, onSubmit, onError]
    );

    const Form = useCallback(
        ({ children, ...props }: FormProps) => {
            const onFormSubmit = (e: React.BaseSyntheticEvent) => {
                e.preventDefault();
                e.stopPropagation();
                return submit(e);
            };

            return nestedForm ? (
                <div>
                    <NestedFormProvider submit={submit}>
                        <FormProvider {...form}>{children}</FormProvider>
                    </NestedFormProvider>
                </div>
            ) : (
                <form onSubmit={onFormSubmit} {...props}>
                    <FormProvider {...form}>{children}</FormProvider>
                </form>
            );
        },

        [form, nestedForm, submit]
    );

    const errorFields = useMemo(
        () =>
            Object.keys(formState.errors).filter(
                v => !!formState.errors[v]
            ) as string[],
        [formState]
    );

    return {
        ...form,
        submit,
        errorFields,
        Form,
    };
}
