import React, { useCallback, useEffect } from 'react';
import { Box, Chip, Stack } from '@mui/material';
import { convertDurationToDurationDto, EventItem } from '@travelity/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useForm } from '@travelity/form';
import { travelersToTravelersForm } from '../../../components/booking-travelers/booking-travelers.converters';
import travelersSchema from '../../../components/booking-travelers/booking-travelers.schema';
import { BookingTravelersForm } from '../../../components/booking-travelers';
import { BookingProductForm } from '../../../components/booking-product';
import { BookingScheduleForm } from '../../../components/booking-schedule';
import { EventStatus } from '../../../components/event-status';
import { EventEnd } from '../../../components/event-end/event-end';
import { ActivityHistory } from '../../../components/activity-history';
import { EventOperations } from '../../../components/event-operations';
import { SideFormAccordion } from '../../../components/side-form-accordion';
import { useSideFormAccordion } from '../../../components/side-form-accordion/side-form-accordion.hooks';

export interface EventPreviewProps {
    event: EventItem;
    // updateEvent: () => void;
    // updateBooking: (id: string, booking: Booking) => void;
}

const EventPreview: React.FC<EventPreviewProps> = ({
    event,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // updateBooking,
}) => {
    const { view, openTab, isEdit } = useSideFormAccordion();

    const {
        Form: TravelersForm,
        reset: resetTravelersForm,
        // formState: { errors: travelersErrors },
    } = useForm({
        defaultValues: travelersToTravelersForm(
            event.bookingsSummary?.travelers_summary?.travelers || []
        ),
        // onSubmit,
        mode: 'onChange',
        schema: travelersSchema,
    });

    const onSubmit = useCallback(() => {
        console.log('submit');
    }, []);
    const {
        Form: OperationsForm,
        reset: resetOperationsForm,
        // formState: { errors: travelersErrors },
    } = useForm({
        // defaultValues: travelersToTravelersForm(
        //     event.bookingsSummary?.travelers_summary?.travelers || []
        // ),
        defaultValues: {
            capacity: [event.capacitySelection.capacity],
            staff: event.staff.map(v => ({ ...v, id: v.staff.email })),
            assets: event.assets,
        },
        onSubmit,
        mode: 'onChange',
        // schema: travelersSchema,
    });

    const onTravelersEditCancel = useCallback(() => {
        resetTravelersForm(
            travelersToTravelersForm(
                event.bookingsSummary?.travelers_summary?.travelers || []
            )
        );
        view();
    }, [event]);

    useEffect(() => {
        resetOperationsForm({
            capacity: [event.capacitySelection.capacity],
            staff: event.staff.map(v => ({ ...v, id: v.staff.email })),
            assets: event.assets,
        });
        resetTravelersForm(
            travelersToTravelersForm(
                event.bookingsSummary?.travelers_summary?.travelers || []
            )
        );
    }, [event]);

    return (
        <Box
            sx={{
                height: 1,
                '& > div:last-child': {
                    height: 'calc(100% - 30px)',
                },
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Box
                    sx={{
                        display: 'inline-flex',
                        padding: '8px 18px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '4px',
                        borderRadius: '0px 0px 12px 0px',
                        background: '#6B748C',
                        color: '#FFF',
                        fontFamily: 'Lexend',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '100%',
                        textTransform: 'capitalize',
                    }}
                >
                    Event Preview
                </Box>
            </Stack>
            <PerfectScrollbar>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={0.75}
                    sx={{
                        px: 2,
                        height: '46px',
                        py: '10px',
                        borderRadius: '0px 12px 0px 0px',
                    }}
                >
                    <EventStatus status={event.status} />
                    <EventEnd date={event.date} />
                    {event.ephemeral && (
                        <Chip
                            label="Virtual"
                            sx={{
                                borderRadius: '8px',
                                background: '#EC8031',
                                padding: '6px 8px',
                                color: '#FFF',
                                fontSize: '14px',
                            }}
                        />
                    )}
                </Stack>
                <Box
                    sx={{
                        '&&& .MuiAccordionSummary-root': {
                            borderBottom: '1px solid #DFE1ED',
                        },
                        '&&& .MuiAccordionDetails-root': {
                            py: 3,
                        },
                    }}
                >
                    <SideFormAccordion id="schedule" title="Date and Time">
                        <BookingScheduleForm
                            date={{
                                duration: convertDurationToDurationDto(
                                    event.date.duration
                                ),
                                start: event.date.start.getTime() / 1000,
                                end: event.date.end
                                    ? event.date.end.getTime() / 1000
                                    : undefined,
                            }}
                            preview
                        />
                    </SideFormAccordion>
                    <SideFormAccordion id="product" title="Product">
                        <BookingProductForm product={event.product} preview />
                    </SideFormAccordion>
                    <SideFormAccordion
                        id="travelers"
                        title="Travelers"
                        onCancel={onTravelersEditCancel}
                    >
                        <TravelersForm id="travelers">
                            <BookingTravelersForm eventId={event.id} />
                        </TravelersForm>
                    </SideFormAccordion>
                    <SideFormAccordion
                        id="operations"
                        title="Operations"
                        isEditable
                        onDone={view}
                    >
                        <OperationsForm id="operations">
                            <EventOperations
                                event={event}
                                preview={!isEdit || openTab !== 'operations'}
                            />
                        </OperationsForm>
                    </SideFormAccordion>

                    <SideFormAccordion id="activity-log" title="Activity Log">
                        <ActivityHistory
                            entity="event"
                            lifecycle={event.lifecycle}
                        />
                    </SideFormAccordion>
                </Box>
            </PerfectScrollbar>
        </Box>
    );
};

export default React.memo(EventPreview);
