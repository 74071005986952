import React, { useEffect } from 'react';
import { TextField, useForm } from '@travelity/form';
import { ConfirmationDialog } from '@travelity/ui';

export interface ReasonModalProps {
    handleCancel: () => void;
    handleConfirm: (v: string) => void;
    isLoading?: boolean;
    open: boolean;
    title?: string;
    content?: string;
    confirmText?: string;
}

export const ReasonModal: React.FC<ReasonModalProps> = props => {
    const { handleConfirm, ...other } = props;

    const { Form, reset, watch } = useForm({
        isModal: true,
        mode: 'onChange',
        defaultValues: { reason: '' },
    });

    useEffect(() => {
        reset({});
    }, [other.open]);

    return (
        <ConfirmationDialog
            {...other}
            handleConfirm={() => {
                const reason = watch('reason');
                handleConfirm(reason || '');
            }}
            disableConfirm={!watch('reason')}
        >
            <Form>
                <TextField
                    sx={{ mt: 4 }}
                    name="reason"
                    label="Reason"
                    placeholder="Type Reason"
                />
            </Form>
        </ConfirmationDialog>
    );
};
