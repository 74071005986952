import React from 'react';
import { Divider, Stack } from '@mui/material';
import { PeriodData, convertDurationDtoToDuration } from '@travelity/api';
import { format } from 'date-fns';
import { durationToString } from '@travelity/utils';

import { SingleLineKeyValue } from '../booking-participants/components/single-line-key-value';

export interface BookingScheduleFormProps {
    date: Omit<PeriodData, 'tz_name'>;
    preview?: boolean;
}

const BookingScheduleForm: React.FC<BookingScheduleFormProps> = ({
    date,
    preview,
}) => {
    const { start, duration } = date;
    const d = new Date(start * 1000);

    return preview ? (
        <Stack spacing={2}>
            {duration && (
                <SingleLineKeyValue
                    dark
                    label="Duration"
                    value={durationToString(
                        convertDurationDtoToDuration(duration)
                    )}
                />
            )}
            <Divider />
            <SingleLineKeyValue
                dark
                label="Date"
                value={format(d, 'dd MMM yyyy')}
            />
            <Divider />
            <SingleLineKeyValue dark label="Time" value={format(d, 'HH:mm')} />
        </Stack>
    ) : (
        <Stack spacing={3} />
    );
};

export default React.memo(BookingScheduleForm);
