import React from 'react';
import { Chip } from '@mui/material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { format } from 'date-fns';
import { Period } from '@travelity/api';

export interface EventEndProps {
    date: Period;
}

export const EventEnd: React.FC<EventEndProps> = props => {
    const { date } = props;
    const day =
        date.end &&
        format(date.start, 'dd MMMM yyyy') !== format(date.end, 'dd MMMM yyyy')
            ? ` on ${format(date.end, 'MMM d')}`
            : '';

    return date.end ? (
        <Chip
            sx={{
                color: '#6B748C',
                bgcolor: '#FFF',
                borderRadius: '8px',
                px: 0.5,
                py: 0.5,
                boxShadow: '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                '& .MuiChip-label': {
                    pl: 1.5,
                    pr: 0.5,
                    lineHeight: '18px',
                },
            }}
            icon={
                <AccessTimeFilledIcon
                    sx={{ fontSize: '14px', color: '#6B748C' }}
                />
            }
            label={`Ends${day} at ${format(date.end, 'HH:mm')}`}
        />
    ) : null;
};
