import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useGetCapacitiesLazy, useUpdateCapacityProducts } from '../../queries';
import { getCapacityDtoToCapacity } from './capacity.converters';
import { CustomMutationOptions } from '../common.types';
import { Product, ProductCapacityItem } from '../product/product.types';
import { productCapacityToCapacityDto } from '../product/product.converters';
import { convertItemsToActionItemsDto } from '../common.converters';
import type { UpdateProductResDto } from '../../requests';

type UseGetCapacitiesLazyParamTypes = Parameters<typeof useGetCapacitiesLazy>;
export const useCapacitiesLazy = (
    params: UseGetCapacitiesLazyParamTypes[0] = {},
    options: UseGetCapacitiesLazyParamTypes[2] = {}
) => {
    const queryKey = ['OrdersServiceGetOrdersLazy', params];
    const { data, ...other } = useGetCapacitiesLazy(params, queryKey, options);

    const parsedData = useMemo(
        () =>
            data?.pages
                ? data.pages
                      .map(page =>
                          (page.items || []).map(getCapacityDtoToCapacity)
                      )
                      .reduce((arr, cur) => [...arr, ...cur], [])
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

export const useUpdateProductCapacities = (
    options: CustomMutationOptions<
        {
            items: ProductCapacityItem[];
        },
        UpdateProductResDto | {}
    >,
    product: Product,
    oldItems: ProductCapacityItem[]
) => {
    const { mutateAsync: updateCapacities } = useUpdateCapacityProducts();

    return useMutation(async ({ items }) => {
        const requestItems = convertItemsToActionItemsDto(
            oldItems.map(productCapacityToCapacityDto),
            items.map(productCapacityToCapacityDto),
            'reference.id'
        );

        if (requestItems.length) {
            return updateCapacities({
                productId: product.id,
                requestBody: {
                    items: requestItems,
                },
            });
        }
        return {};
    }, options);
};
