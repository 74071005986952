import { useMemo } from 'react';
import {
    InfiniteData,
    useMutation,
    UseMutationOptions,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { uniqWith } from 'lodash';
import {
    useAddAssetEvents,
    useGetEvents,
    useGetEventsLazy,
    useRemoveAssetEvents,
    useRemoveStaffMemberEvents,
    useSetTravelersParticipationBookings,
} from '../../queries';
import { getEventItemDtoToEvent } from './event.converters';
import { EventItem } from './event.types';
import {
    ActivityType,
    GetEventsResDto,
    GetEventsResItem0Dto,
    GetEventsResItem0BookingsSummaryBookingsItemDto,
    GetEventsResItem1Dto,
    GetEventsResItem2Dto,
    SetTravelersParticipationBookingsResParticipantsTravelersItemDto,
    AssignStaffMemberEventsReqDto,
    AddAssetEventsReqDto,
    AvailabilityService,
    PaxData,
    TimezoneName,
} from '../../requests';

export const useAssignStaffMemberEvents = () => {
    return useMutation({});
};

export const useDayProductEvents = (
    params: {
        startTs: number;
        endTs: number;
        productId: string[];
        pax: PaxData;
    },
    enabled = true
) => {
    const { data, ...other } = useGetEvents(params, [params], {
        cacheTime: 0,
        enabled,
    });
    const { getAccessTokenSilently } = useAuth0();

    const { data: availability, isFetching } = useQuery({
        queryKey: [
            'Availability',
            params.startTs,
            params.pax,
            params.productId,
        ],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return AvailabilityService.computeAvailability(authorization, {
                date_range: {
                    tz_name: Intl.DateTimeFormat().resolvedOptions()
                        .timeZone as TimezoneName,
                    start: params.startTs,
                    end: params.endTs,
                },
                products: params.productId.map(id => ({ id })),
                pax: params.pax,
            });
        },
        cacheTime: 0,
        enabled,
    });

    const events = useMemo(() => {
        const eventsObj = availability?.[0]?.matrix.events as Record<
            string,
            any[]
        >;
        const products = availability?.[0]?.matrix.products;

        return eventsObj
            ? Object.values(eventsObj)[0]?.map(event => ({
                  ...event,
                  product: products[event.product.id],
              })) || []
            : undefined;
    }, [availability]);

    const parsedData: EventItem[] | undefined = useMemo(
        () =>
            data?.items && events
                ? // @ts-ignore
                  uniqWith(
                      [...data.items, ...events],
                      ({ id: id1 }, { id: id2 }) => id1 && id2 && id1 === id2
                  )
                      .sort((a, b) => a.date.start - b.date.start)
                      .map(getEventItemDtoToEvent)
                : undefined,
        [data, events]
    );

    return {
        data: parsedData,
        ...other,
        isFetching: other.isFetching || isFetching,
    };
};

type UseGetEventsParamTypes = Parameters<typeof useGetEvents>;
export const useGetEventsKey = 'EventsServiceGetEvents';
export const useEvents = (
    params: UseGetEventsParamTypes[0] = {},
    enabled = true
) => {
    const { data, ...other } = useGetEvents(params, [params], {
        cacheTime: 0,
        enabled,
    });

    const parsedData: EventItem[] | undefined = useMemo(
        () =>
            data?.items
                ? data?.items
                      ?.sort((a, b) => a.date.start - b.date.start)
                      .map(getEventItemDtoToEvent)
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

export const useEventsLazy = (
    params: UseGetEventsParamTypes[0] = {},
    enabled = true
) => {
    const { data, ...other } = useGetEventsLazy(params, [params], {
        cacheTime: 0,
        enabled,
    });

    const parsedData: EventItem[] | undefined = useMemo(
        () =>
            data?.pages
                ? data.pages
                      .map(page =>
                          (page.items || [])
                              .sort((a, b) => a.date.start - b.date.start)
                              .map(getEventItemDtoToEvent)
                      )
                      .reduce((arr, cur) => [...arr, ...cur], [])
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

export const useUpdateEventSilent = () => {
    const queryClient = useQueryClient();
    const queriesData = queryClient.getQueriesData({
        queryKey: [useGetEventsKey],
        exact: false,
    });

    return (
        eventId: string,
        callback: (b: GetEventsResItemDto) => GetEventsResItemDto
    ) => {
        queriesData.forEach(([queryKey]) => {
            queryClient.setQueryData<InfiniteData<GetEventsResDto>>(
                queryKey,
                events => {
                    if (!events) return undefined;
                    const pages = events.pages.map(page => {
                        const items =
                            page.items?.map(ev =>
                                ev.id === eventId ? callback(ev) : ev
                            ) || [];
                        return { ...events, items };
                    });
                    return { ...events, pages };
                }
            );
        });
    };
};

const updateBookingTravelerParticipation = (
    b: GetEventsResItem0BookingsSummaryBookingsItemDto,
    traveler: SetTravelersParticipationBookingsResParticipantsTravelersItemDto
): GetEventsResItem0BookingsSummaryBookingsItemDto => ({
    ...b,
    participants: {
        ...b.participants,
        travelers: b.participants.travelers?.map(t =>
            t.id === traveler.id
                ? {
                      ...t,
                      participation: traveler.participation,
                  }
                : t
        ),
    },
});

type GetEventsResItemDto =
    | GetEventsResItem0Dto
    | GetEventsResItem1Dto
    | GetEventsResItem2Dto;
const updateTravelerParticipation = (
    event: GetEventsResItemDto,
    bookingId: string,
    traveler: SetTravelersParticipationBookingsResParticipantsTravelersItemDto
): GetEventsResItemDto => ({
    ...event,
    bookings_summary: event.bookings_summary
        ? {
              ...event.bookings_summary,
              bookings: event.bookings_summary.bookings.map(b =>
                  b.id === bookingId
                      ? updateBookingTravelerParticipation(b, traveler)
                      : b
              ),
          }
        : undefined,
});

export const useSetTravelerParticipation = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<any>>,
            unknown,
            {
                eventId: string;
                bookingId: string;
                travelerId: string;
                activityType: ActivityType;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { mutate: setParticipation } = useSetTravelersParticipationBookings();

    const queryClient = useQueryClient();
    return useMutation(
        ({ eventId, bookingId, travelerId, activityType }) =>
            new Promise((resolve, reject) => {
                setParticipation(
                    {
                        bookingId,
                        requestBody: {
                            travelers: [
                                {
                                    id: travelerId,
                                    participation: {
                                        type: activityType,
                                    },
                                },
                            ],
                        },
                    },
                    {
                        onSuccess: data => {
                            if (data.participants.travelers[0]) {
                                const traveler = data.participants.travelers[0];
                                const queriesData = queryClient.getQueriesData({
                                    queryKey: [useGetEventsKey],
                                    exact: false,
                                });
                                queriesData.forEach(([queryKey]) => {
                                    queryClient.setQueryData<
                                        InfiniteData<GetEventsResDto>
                                    >(queryKey, events => {
                                        if (!events?.pages) return undefined;
                                        const pages = events.pages.map(page => {
                                            const items =
                                                page.items?.map(ev =>
                                                    ev.id === eventId
                                                        ? updateTravelerParticipation(
                                                              ev,
                                                              bookingId,
                                                              traveler
                                                          )
                                                        : ev
                                                ) || [];
                                            return { ...events, items };
                                        });
                                        return { ...events, pages };
                                    });
                                });
                            }
                            resolve(data);
                        },
                        onError: reject,
                    }
                );
            }),
        options
    );
};

export const useAddEventStaff = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<any>>,
            unknown,
            {
                eventId: string;
                requestBody: AssignStaffMemberEventsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { mutate: addStaffMember } = useAssignStaffMemberEvents();

    const queryClient = useQueryClient();
    return useMutation(
        ({ eventId, requestBody }) =>
            new Promise((resolve, reject) => {
                addStaffMember(
                    {
                        eventId,
                        requestBody,
                    },
                    {
                        onSuccess: data => {
                            if (data.operations.staff) {
                                const { operations, ...event } = data;
                                const queriesData = queryClient.getQueriesData({
                                    queryKey: [useGetEventsKey],
                                    exact: false,
                                });
                                queriesData.forEach(([queryKey]) => {
                                    queryClient.setQueryData<
                                        InfiniteData<GetEventsResDto>
                                        // @ts-ignore
                                    >(queryKey, events => {
                                        if (!events?.pages) return undefined;
                                        const pages = events.pages.map(page => {
                                            const items =
                                                page.items?.map(ev =>
                                                    ev.id === eventId
                                                        ? {
                                                              ...ev,
                                                              ...event,
                                                              operations: {
                                                                  ...ev.operations,
                                                                  staff: operations.staff,
                                                              },
                                                          }
                                                        : ev
                                                ) || [];
                                            return { ...page, items };
                                        });
                                        return { ...events, pages };
                                    });
                                });
                            }
                            resolve(data);
                        },
                        onError: reject,
                    }
                );
            }),
        options
    );
};

export const useRemoveEventStaff = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<any>>,
            unknown,
            {
                eventId: string;
                email: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { mutate: removeStaff } = useRemoveStaffMemberEvents();

    const queryClient = useQueryClient();
    return useMutation(
        ({ eventId, email }) =>
            new Promise((resolve, reject) => {
                removeStaff(
                    {
                        eventId,
                        email,
                    },
                    {
                        onSuccess: data => {
                            if (data.operations.staff) {
                                const { operations, ...event } = data;
                                const queriesData = queryClient.getQueriesData({
                                    queryKey: [useGetEventsKey],
                                    exact: false,
                                });
                                queriesData.forEach(([queryKey]) => {
                                    queryClient.setQueryData<
                                        InfiniteData<GetEventsResDto>
                                    >(queryKey, events => {
                                        if (!events?.pages) return undefined;
                                        const pages = events.pages.map(page => {
                                            const items =
                                                page.items?.map(ev =>
                                                    ev.id === eventId
                                                        ? {
                                                              ...ev,
                                                              ...event,
                                                              operations: {
                                                                  ...ev.operations,
                                                                  staff: operations.staff,
                                                              },
                                                          }
                                                        : ev
                                                ) || [];
                                            return { ...page, items };
                                        });
                                        return { ...events, pages };
                                    });
                                });
                            }
                            resolve(data);
                        },
                        onError: reject,
                    }
                );
            }),
        options
    );
};

export const useAddEventAsset = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<any>>,
            unknown,
            {
                eventId: string;
                requestBody: AddAssetEventsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { mutate: addAssetMember } = useAddAssetEvents();

    const queryClient = useQueryClient();
    return useMutation(
        ({ eventId, requestBody }) =>
            new Promise((resolve, reject) => {
                addAssetMember(
                    {
                        eventId,
                        requestBody,
                    },
                    {
                        onSuccess: data => {
                            if (data.operations.assets) {
                                const { operations, ...event } = data;
                                const queriesData = queryClient.getQueriesData({
                                    queryKey: [useGetEventsKey],
                                    exact: false,
                                });
                                queriesData.forEach(([queryKey]) => {
                                    queryClient.setQueryData<
                                        InfiniteData<GetEventsResDto>
                                        // @ts-ignore
                                    >(queryKey, events => {
                                        if (!events?.pages) return undefined;
                                        const pages = events.pages.map(page => {
                                            const items =
                                                page.items?.map(ev =>
                                                    ev.id === eventId
                                                        ? {
                                                              ...ev,
                                                              ...event,
                                                              operations: {
                                                                  ...ev.operations,
                                                                  assets: operations.assets,
                                                              },
                                                          }
                                                        : ev
                                                ) || [];
                                            return { ...page, items };
                                        });
                                        return { ...events, pages };
                                    });
                                });
                            }
                            resolve(data);
                        },
                        onError: reject,
                    }
                );
            }),
        options
    );
};

export const useRemoveEventAsset = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<any>>,
            unknown,
            {
                eventId: string;
                assetId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { mutate: removeAsset } = useRemoveAssetEvents();

    const queryClient = useQueryClient();
    return useMutation(
        ({ eventId, assetId }) =>
            new Promise((resolve, reject) => {
                removeAsset(
                    {
                        eventId,
                        assetId,
                    },
                    {
                        onSuccess: data => {
                            if (data.operations.assets) {
                                const { operations, ...event } = data;
                                const queriesData = queryClient.getQueriesData({
                                    queryKey: [useGetEventsKey],
                                    exact: false,
                                });
                                queriesData.forEach(([queryKey]) => {
                                    queryClient.setQueryData<
                                        InfiniteData<GetEventsResDto>
                                        // @ts-ignore
                                    >(queryKey, events => {
                                        if (!events?.pages) return undefined;
                                        const pages = events.pages.map(page => {
                                            const items =
                                                page.items?.map(ev =>
                                                    ev.id === eventId
                                                        ? {
                                                              ...ev,
                                                              ...event,
                                                              operations: {
                                                                  ...ev.operations,
                                                                  assets: operations.assets,
                                                              },
                                                          }
                                                        : ev
                                                ) || [];
                                            return { ...page, items };
                                        });
                                        return { ...events, pages };
                                    });
                                });
                            }
                            resolve(data);
                        },
                        onError: reject,
                    }
                );
            }),
        options
    );
};

export const useUpdateEventBooking = () => {
    const updateEvent = useUpdateEventSilent();

    return (
        eventId: string,
        bookingId: string,
        callback: (
            b: GetEventsResItem0BookingsSummaryBookingsItemDto
        ) => GetEventsResItem0BookingsSummaryBookingsItemDto
    ) => {
        updateEvent(eventId, event => {
            const bookings =
                event.bookings_summary?.bookings.map(b =>
                    b.id === bookingId ? callback(b) : b
                ) || [];
            return {
                ...event,
                bookings_summary: {
                    ...event.bookings_summary,
                    bookings,
                },
            };
        });
    };
};
