import { useController, useFormContext } from 'react-hook-form';
import React, { useEffect, useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
// @ts-ignore
import SwipeViews from 'react-swipeable-views-react-18-fix';
import { Box, Chip, FormHelperText, Stack, StackProps } from '@mui/material';
import _, { chunk } from 'lodash';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useIsChanged } from '@travelity/web/src/hooks';

export interface TagsListProps extends StackProps {
    tagSx?: Record<string, any>;
    selectAll?: boolean;
    multiple?: boolean;
    name: string;
    showErrors?: boolean;
    options: { value: string | number; label: string }[];
    disabled?: boolean;
    hideUnselected?: boolean;
    gap?: number;
    maxItems?: number;
}

const findIndex = (
    chunks: { value: string | number; label: string }[][],
    value: string | number
) => {
    const index = chunks.findIndex(ch => ch.find(o => o.value === value));

    return index === -1 ? 0 : index;
};

const TagsList: React.FC<TagsListProps> = ({
    tagSx,
    selectAll,
    multiple,
    options,
    name,
    showErrors,
    disabled,
    hideUnselected,
    gap = 0.5,
    maxItems,
    ...rest
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const { field } = useController({
        control,
        name,
        defaultValue: multiple ? [] : undefined,
    });

    const error =
        showErrors && (_.get(errors, name)?.message as string | undefined);
    const visibleOptions = useMemo(() => {
        return hideUnselected
            ? options.filter(o =>
                  multiple
                      ? field.value.includes(o.value)
                      : field.value === o.value
              )
            : options;
    }, [field.value, hideUnselected, multiple, options]);

    const arrayValue = multiple ? field.value : [field.value];

    // Swipe
    const chunks = useMemo(() => {
        return maxItems ? chunk(visibleOptions, maxItems) : [];
    }, [maxItems, visibleOptions]);
    const [value, setValue] = React.useState(
        field.value && maxItems && chunks ? findIndex(chunks, field.value) : 0
    );
    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    const isValueChanged = useIsChanged(field.value, true);
    useEffect(() => {
        if (isValueChanged && field.value && maxItems && chunks) {
            const index = chunks.findIndex(ch =>
                ch.find(o => o.value === field.value)
            );
            if (index !== -1 && index !== value) {
                setValue(index);
            }
        }
    }, [isValueChanged, maxItems, chunks, field.value, value]);

    return (
        <>
            <Stack
                direction="row"
                flexWrap="wrap"
                sx={{ py: '3px', mx: 1, ml: '9px', position: 'relative' }}
                {...rest}
            >
                {!hideUnselected && selectAll && (
                    <Chip
                        label="Select All"
                        clickable
                        sx={{
                            '&&&': tagSx || {},
                            my: '5px',
                            mx: gap / 2,
                        }}
                        color={
                            field.value?.length === options.length
                                ? 'primary'
                                : 'light'
                        }
                        variant="filled"
                        onClick={() =>
                            field.value?.length === options.length
                                ? field.onChange([])
                                : field.onChange(
                                      options.map(({ value }) => value)
                                  )
                        }
                    />
                )}
                {maxItems ? (
                    <>
                        <SwipeViews
                            axis="x"
                            index={value}
                            enableMouseEvents
                            onChangeIndex={handleChangeIndex}
                        >
                            {chunks.map((ch, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Box key={index.toString()}>
                                    {ch.map(option => (
                                        <Chip
                                            key={option.value}
                                            label={option.label}
                                            size="small"
                                            clickable
                                            disabled={disabled}
                                            sx={{
                                                '&&&': tagSx || {},
                                                width: '66px',
                                                my: '5px',
                                                mx: gap / 2,
                                                height: 'auto',
                                                border: 0,
                                                p: '10px',
                                                '& > .MuiChip-label': {
                                                    fontSize: '12px',
                                                    lineHeight: '12px',
                                                },
                                            }}
                                            color={
                                                arrayValue?.includes(
                                                    option.value
                                                )
                                                    ? 'primary'
                                                    : 'light'
                                            }
                                            variant="filled"
                                            onClick={() =>
                                                multiple
                                                    ? field.onChange(
                                                          _.xor(field.value, [
                                                              option.value,
                                                          ])
                                                      )
                                                    : field.onChange(
                                                          option.value
                                                      )
                                            }
                                        />
                                    ))}
                                </Box>
                            ))}
                        </SwipeViews>
                        {value > 0 && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: -14,
                                    top: 54,
                                    cursor: 'pointer',
                                    color: '#949BAC',
                                    ':hover': {
                                        color: '#3B4D7D',
                                    },
                                }}
                                onClick={() => setValue(value - 1)}
                            >
                                <KeyboardArrowLeftIcon />
                            </Box>
                        )}
                        {value !== chunks.length - 1 && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    right: -14,
                                    top: 54,
                                    cursor: 'pointer',
                                    color: '#949BAC',
                                    ':hover': {
                                        color: '#3B4D7D',
                                    },
                                }}
                                onClick={() => setValue(value + 1)}
                            >
                                <KeyboardArrowRightIcon />
                            </Box>
                        )}
                    </>
                ) : (
                    visibleOptions.map(option => (
                        <Chip
                            key={option.value}
                            label={option.label}
                            size="small"
                            clickable
                            disabled={disabled}
                            sx={{
                                '&&&': tagSx || {},
                                my: '5px',
                                mx: gap / 2,
                                height: 'auto',
                                border: 0,
                                p: '10px',
                                '& > .MuiChip-label': {
                                    fontSize: '12px',
                                    lineHeight: '12px',
                                },
                            }}
                            color={
                                arrayValue?.includes(option.value)
                                    ? 'primary'
                                    : 'light'
                            }
                            variant="filled"
                            onClick={() =>
                                multiple
                                    ? field.onChange(
                                          _.xor(field.value, [option.value])
                                      )
                                    : field.onChange(option.value)
                            }
                        />
                    ))
                )}
            </Stack>
            {error && <FormHelperText>{error}</FormHelperText>}
        </>
    );
};

export default TagsList;
