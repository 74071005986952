import { pickBy } from 'lodash';
import {
    AgebandPricingData,
    CapacityType,
    CreateProductReqDto,
    CreateProductReqPricingItemDto,
    FlatPricingData,
    GetProductsResItem0Dto,
    GetProductsResItem0PricingItemDto,
    type GetProductsResItem0RouteItemDto,
    Language,
    ListItemAction,
    PricingType,
    ProductTagType,
    RouteItemType,
    ScheduleType,
    TimeOffset,
    type UpdateCapacityProductsReqItemDto,
    UpdateProduct111ReqItemDto,
    type UpdateProduct1ReqItemDto,
    UpdateProductReqDto,
} from '../../requests';
import {
    AddProductForm,
    DurationValue,
    Option,
    Pricing,
    Product,
    ProductCapacityItem,
    ScheduleOptionTypes,
    Stop,
} from './product.types';
import { getCapacityItemDtoToProductCapacityItem } from '../capacity/capacity.converters';
import { scheduleItemDtoToProductScheduleItem } from '../schedule/schedule.converters';
import { UserGroupType } from '../../other/UserGroupType';

export const deviationToSwingString = (deviation: TimeOffset): string => {
    const { days = 0, hours = 0, minutes = 0 } = deviation;
    return (days * 24 + hours + minutes / 60).toString();
};

export const swingToDeviation = (swing: string): TimeOffset => {
    const swingNumber = parseFloat(swing);
    return {
        days: Math.floor(swingNumber / 24),
        hours: Math.floor(swingNumber % 24),
        minutes: (swingNumber % 1) * 60,
    };
};

export const convertDurationToDurationDto = (d: DurationValue): TimeOffset => ({
    days: d.day || 0,
    hours: d.hour || 0,
    minutes: d.minute || 0,
});

export const convertDurationDtoToDuration = (
    d: Partial<TimeOffset>
): DurationValue => ({
    day: d.days || 0,
    hour: d.hours || 0,
    minute: d.minutes || 0,
});

// export const capacityToCapacityDto = (
//     capacity: Capacity
// ): FlatCapacityData | AgebandCapacityData => ({
//     name: capacity.name,
//     ...(capacity.type === CapacityOptionTypes.AGE_BAND
//         ? Object.values(AgebandName)
//               .map(ageband => ({
//                   ageband,
//                   count: capacity.counts?.[ageband] || 0,
//               }))
//               .filter(({ count }) => count)
//               .reduce(
//                   (obj, { ageband, count }) => ({
//                       ...obj,
//                       [ageband]: count,
//                   }),
//                   {}
//               )
//         : {
//               min_count: capacity.min,
//               max_count: capacity.max,
//           }),
// });
export const productCapacityToCapacityDto = (
    capacity: ProductCapacityItem
): Omit<UpdateCapacityProductsReqItemDto, 'action'> => {
    return {
        id: capacity.id,
        reference: {
            type: CapacityType.VEHICLE,
            id: capacity.capacityId,
            name: capacity.name,
        },
    };
};

export const pricingToPricingDto = (
    pricing: Pricing
): CreateProductReqPricingItemDto => ({
    // name: pricing.name,
    id: pricing.id,
    currency: {
        abbr: pricing.currency,
        // TODO fix this
        name: '',
    },
    inclusions: pricing.inclusions?.map(({ value }) => value),
    dependencies:
        pricing.capacity || pricing.schedule
            ? {
                  capacity: pricing.capacity
                      ? {
                            id: pricing.capacity.capacityId,
                            type: CapacityType.VEHICLE,
                            name: pricing.capacity.name,
                        }
                      : undefined,
                  schedule: pricing.schedule
                      ? {
                            id: pricing.schedule.scheduleId,
                            type:
                                pricing.schedule.type ===
                                ScheduleOptionTypes.SEASON
                                    ? ScheduleType.RECURRING
                                    : ScheduleType.NON_RECURRING,
                            name: pricing.schedule.name,
                        }
                      : undefined,
              }
            : undefined,
    type: pricing.type,
    price: {
        original:
            pricing.type === PricingType.PER_PERSON
                ? ({
                      base_amount: pricing.price.base || undefined,
                      unit_amount: pricing.price.perPerson || undefined,
                  } as FlatPricingData)
                : pricing.type === PricingType.PER_PRODUCT
                ? ({
                      base_amount: pricing.price.base || undefined,
                      unit_amount: pricing.price.perProduct || undefined,
                  } as FlatPricingData)
                : ({
                      base_amount: pricing.price.base || undefined,
                      ...pickBy(
                          pricing.price.counts || {},
                          // @ts-ignore
                          v => v !== '' && v !== undefined && v !== null
                      ),
                  } as AgebandPricingData),
    },
    cost: pricing.cost
        ? {
              original:
                  pricing.type === PricingType.PER_PERSON
                      ? ({
                            base_amount: pricing.cost.base || undefined,
                            unit_amount: pricing.cost.perPerson || undefined,
                        } as FlatPricingData)
                      : pricing.type === PricingType.PER_PRODUCT
                      ? ({
                            base_amount: pricing.cost.base || undefined,
                            unit_amount: pricing.cost.perProduct || undefined,
                        } as FlatPricingData)
                      : ({
                            base_amount: pricing.cost.base || undefined,
                            ...pickBy(
                                pricing.cost.counts || {},
                                // @ts-ignore
                                v => v !== '' && v !== undefined && v !== null
                            ),
                        } as AgebandPricingData),
          }
        : undefined,
});

export const durationToDurationDto = (
    duration?: DurationValue,
    swing?: string
) => ({
    estimated: convertDurationToDurationDto(
        duration || { day: 0, hour: 0, minute: 0 }
    ),
    deviation: swing ? swingToDeviation(swing) : undefined,
});

export const compareTimeOffset = (
    duration?: TimeOffset,
    oldDuration?: TimeOffset
) => {
    if (duration && oldDuration) {
        return (
            (duration.days || 0) === (oldDuration.days || 0) &&
            (duration.hours || 0) === (oldDuration.hours || 0) &&
            (duration.minutes || 0) === (oldDuration.minutes || 0)
        );
    }
    return !duration && !oldDuration;
};

export const addProductFormToAddProductDto = (
    product: AddProductForm
): CreateProductReqDto => ({
    name: product.productInfo.name,
    // @ts-ignore
    type: product.type,
    description: product.productInfo.description,
    languages: product.productInfo?.languages as Language[],
    configuration: {
        autoscaled: product.configuration.autoscaled,
        shared: product.configuration.shared,
    },
    tags: [
        ...(product.productInfo.interests?.map(name => ({
            name,
            type: ProductTagType.INTEREST,
        })) || []),
        ...(product.productInfo.productCategories?.map(name => ({
            name,
            type: ProductTagType.CATEGORY,
        })) || []),
        ...(product.productInfo.accessibility?.map(name => ({
            name,
            type: ProductTagType.ACCESSIBILITY,
        })) || []),
    ],
    route: {
        // TODO name: product.route.name,
        // @ts-ignore
        round_trip: !!product.route.stops[0]?.repeatLocation,
        // @ts-ignore
        items: product.route.stops.map((stop, i) => ({
            // id: stop.id as string,
            type:
                i === 0
                    ? RouteItemType.STARTPOINT
                    : i === product.route.stops.length - 1 &&
                      !product.route.stops[0]?.repeatLocation
                    ? RouteItemType.ENDPOINT
                    : RouteItemType.STOP,
            schedule: {
                arrival_offset: convertDurationToDurationDto(
                    stop.arrivalOffset
                ),
                stop_duration: convertDurationToDurationDto(stop.duration),
            },
            location: { name: stop.name },
        })),
    },
    capacity: {
        items: product.capacity.capacities.map(capacity => ({
            id: capacity.id,
            reference: {
                id: capacity.capacityId,
                name: capacity.name,
                type: CapacityType.VEHICLE,
            },
        })),
    },
    schedule: {
        items: product.schedule.items.map(schedule => ({
            id: schedule.id,
            duration: durationToDurationDto(
                product.schedule.duration,
                product.schedule.swing
            ),
            reference: {
                id: schedule.scheduleId,
                name: schedule.name,
                type:
                    schedule.type === ScheduleOptionTypes.SEASON
                        ? ScheduleType.RECURRING
                        : ScheduleType.NON_RECURRING,
            },
        })),
    },
    pricing: product.financial
        ? {
              payment_requirements: {
                  methods: product.financial.paymentMethods,
                  prepayment: {
                      required: !!product.financial.prepayment,
                      type: product.financial.prepaymentType,
                      amount: product.financial.prepaymentAmount,
                  },
              },
              items: product.financial.items.map(pricingToPricingDto),
          }
        : undefined,
    options: {
        items: product.options.items.map(o => ({
            id: o.id,
            name: o.name,
            type: o.optionType,
            description: o.description,
            pricing:
                o.hasPricing && !!o.prices.length
                    ? {
                          items: o.prices.map(pricingToPricingDto),
                          payment_requirements: {
                              methods: [],
                              prepayment: { required: false },
                          },
                      }
                    : undefined,
        })),
    },
});

export const patchProductToAddProductDto = (
    product: Partial<AddProductForm>
): UpdateProductReqDto => ({
    name: product.productInfo?.name,
    description: product.productInfo?.description,
    languages: product.productInfo?.languages as Language[],
    tags: product.productInfo
        ? [
              ...(product.productInfo.interests?.map(name => ({
                  name,
                  type: ProductTagType.INTEREST,
              })) || []),
              ...(product.productInfo.productCategories?.map(name => ({
                  name,
                  type: ProductTagType.CATEGORY,
              })) || []),
              ...(product.productInfo.accessibility?.map(name => ({
                  name,
                  type: ProductTagType.ACCESSIBILITY,
              })) || []),
          ]
        : undefined,
    // route: product.route
    //     ? {
    //           // TODO name: product.route.name,
    //           round_trip: product.route.repeatLocation,
    //           items: product.route.stops.map(stop => ({
    //               schedule: {
    //                   arrival_offset: convertDurationToDurationDto(
    //                       stop.arrivalOffset
    //                   ),
    //                   stop_duration: convertDurationToDurationDto(
    //                       stop.duration
    //                   ),
    //               },
    //               location: { name: stop.name },
    //           })),
    //       }
    //     : undefined,
    // financials: product.financial
    //     ? {
    //           currency: {
    //               name:
    //                   currencies.find(
    //                       ({ abbr }) => abbr === product.financial?.currency
    //                   )?.name || '',
    //               abbr: product.financial.currency,
    //           },
    //           payment_requirements: {
    //               methods: product.financial.paymentMethods,
    //               prepayment: {
    //                   required: !!product.financial.prepayment,
    //                   type: product.financial.prepaymentType,
    //                   amount: product.financial.prepaymentAmount,
    //               },
    //           },
    //           pricing: {
    //               options: product.financial.items.map(pricingToPricingDto),
    //           },
    //       }
    //     : undefined,
    // options: product.options
    //     ? product.options.items.map(o => ({
    //           name: o.name,
    //           type: o.optionType,
    //           description: o.description,
    //           financials:
    //               o.price && o.type && o.hasPricing
    //                   ? {
    //                         pricing: pricingToPricingDto(o as Pricing),
    //                     }
    //                   : undefined,
    //       }))
    //     : undefined,
});

export const routeStopsToStopsDto = (
    stops: Stop[]
): Omit<UpdateProduct1ReqItemDto, 'action'>[] => {
    return stops.map((stop, i) => ({
        id: stop.id,
        type:
            i === 0
                ? RouteItemType.STARTPOINT
                : i === stops.length - 1 && !stops[0]?.repeatLocation
                ? RouteItemType.ENDPOINT
                : RouteItemType.STOP,
        schedule: {
            arrival_offset: convertDurationToDurationDto(stop.arrivalOffset),
            stop_duration: convertDurationToDurationDto(stop.duration),
        },
        location: { name: stop.name },
    }));
};

export const pricingDtoToPricing = ({
    id,
    currency,
    price,
    cost,
    inclusions,
    dependencies,
    type,
}: GetProductsResItem0PricingItemDto):
    | Pricing
    | Omit<Pricing, 'name' | 'capacity'> => {
    if (type === PricingType.PER_AGEBAND) {
        const { base_amount: baseCost, ...costOther } = (cost ||
            {}) as AgebandPricingData;
        const { base_amount: base, ...other } =
            price.original as AgebandPricingData;

        return {
            id,
            currency: currency.abbr,
            name: '',
            type,
            price: {
                base,
                counts: type === PricingType.PER_AGEBAND ? other : undefined,
            },
            cost: cost
                ? {
                      base: baseCost,
                      counts:
                          type === PricingType.PER_AGEBAND
                              ? costOther
                              : undefined,
                  }
                : undefined,
            capacity: dependencies?.capacity
                ? getCapacityItemDtoToProductCapacityItem(dependencies.capacity)
                : undefined,
            schedule: dependencies?.schedule
                ? scheduleItemDtoToProductScheduleItem(dependencies.schedule)
                : undefined,
            inclusions:
                inclusions?.map((value: string) => ({
                    value,
                })) || [],
        };
    }

    const { base_amount: baseCost, unit_amount: unitAmountCost } = (cost ||
        {}) as FlatPricingData;
    const { base_amount: base, unit_amount: unitAmount } =
        price.original as FlatPricingData;

    return {
        id,
        currency: currency.abbr,
        name: '',
        type,
        price: {
            base,
            perProduct: unitAmount,
            perPerson: unitAmount,
        },
        cost: cost
            ? {
                  base: baseCost,
                  perProduct: unitAmountCost,
                  perPerson: unitAmountCost,
              }
            : undefined,
        capacity: dependencies?.capacity
            ? getCapacityItemDtoToProductCapacityItem(dependencies.capacity)
            : undefined,
        schedule: dependencies?.schedule
            ? scheduleItemDtoToProductScheduleItem(dependencies.schedule)
            : undefined,
        inclusions: inclusions?.map((value: string) => ({ value })) || [],
    };
};

export const getProduct1DtoToProduct1 = (product: GetProductsResItem0Dto) => ({
    // @ts-ignore
    id: product.id,
    type: product.type,
    name: product.name,
    route: {
        name: '',
        // TODO name: product.route.name,
        repeatLocation: product.route.round_trip,
        stops:
            product.route.items?.map(
                (stop: GetProductsResItem0RouteItemDto) => ({
                    name: stop.location.name,
                    arrivalOffset: convertDurationDtoToDuration(
                        stop.schedule.arrival_offset || {}
                    ),
                    duration: convertDurationDtoToDuration(
                        stop.schedule.stop_duration || {}
                    ),
                })
            ) || [],
    },
});

export const getProductDtoToProduct = (
    product: GetProductsResItem0Dto
): Product => ({
    // @ts-ignore
    id: product.id,
    // @ts-ignore
    type: product.type,
    created: product.created,
    active: product.active,
    lifecycle: product.lifecycle || [],
    permissions: {
        canEdit: !!product.access?.permissions?.update,
        canDelete: !!product.access?.permissions?.delete,
        canShare: !!product.access?.permissions?.move,
    },
    sharedTeams:
        product.access?.groups.items.map(({ reference }) => ({
            id: reference.id,
            type: reference.type as unknown as UserGroupType,
        })) || [],
    configuration: {
        autoscaled: product.configuration.autoscaled,
        shared: product.configuration.shared,
    },
    productInfo: {
        name: product.name,
        description: product.description || 'Test desc',
        languages: product.languages || [],
        interests:
            product.tags
                ?.filter(({ type }) => type === ProductTagType.INTEREST)
                .map(({ name }) => name) || [],
        productCategories:
            product.tags
                ?.filter(({ type }) => type === ProductTagType.CATEGORY)
                .map(({ name }) => name) || [],
        accessibility:
            product.tags
                ?.filter(({ type }) => type === ProductTagType.ACCESSIBILITY)
                .map(({ name }) => name) || [],
    },
    route: {
        name: '',
        stops:
            (product.route.round_trip
                ? product.route.items.filter(
                      r => r.type !== RouteItemType.ENDPOINT
                  )
                : product.route.items
            )?.map((stop: GetProductsResItem0RouteItemDto, i) => ({
                name: stop.location.name,
                repeatLocation: i === 0 && product.route.round_trip,
                arrivalOffset: convertDurationDtoToDuration(
                    stop.schedule.arrival_offset || {}
                ),
                duration: convertDurationDtoToDuration(
                    stop.schedule.stop_duration || {}
                ),
            })) || [],
    },
    schedule: {
        swing: product.schedule.items[0]?.duration.deviation
            ? deviationToSwingString(
                  product.schedule.items[0]?.duration.deviation
              )
            : undefined,
        duration: product.schedule.items[0]?.duration.estimated
            ? convertDurationDtoToDuration(
                  product.schedule.items[0]?.duration.estimated
              )
            : undefined,
        items:
            product.schedule.items?.map(item => ({
                id: item.id,
                scheduleId: item.reference.id,
                name: item.reference.name,
            })) || [],
    },
    capacity: {
        capacities: product.capacity.items.map(capacity => ({
            id: capacity.id,
            capacityId: capacity.reference.id,
            name: capacity.reference.name,
        })),
    },
    financial: product.pricing
        ? {
              currency: product.pricing.items[0]?.currency.abbr,
              items: product.pricing.items.map(
                  ({
                      id,
                      currency,
                      price,
                      cost,
                      type,
                      inclusions,
                      dependencies,
                  }) => {
                      if (type === PricingType.PER_AGEBAND) {
                          const { base_amount: baseCost, ...costOther } =
                              (cost || {}) as AgebandPricingData;
                          const { base_amount: base, ...other } =
                              price.original as AgebandPricingData;

                          return {
                              id,
                              currency: currency.abbr,
                              name: '',
                              type,
                              price: {
                                  base,
                                  counts:
                                      type === PricingType.PER_AGEBAND
                                          ? other
                                          : undefined,
                              },
                              cost: cost
                                  ? {
                                        base: baseCost,
                                        counts:
                                            type === PricingType.PER_AGEBAND
                                                ? costOther
                                                : undefined,
                                    }
                                  : undefined,
                              capacity: dependencies?.capacity
                                  ? getCapacityItemDtoToProductCapacityItem(
                                        dependencies.capacity
                                    )
                                  : undefined,
                              schedule: dependencies?.schedule
                                  ? scheduleItemDtoToProductScheduleItem(
                                        dependencies.schedule
                                    )
                                  : undefined,
                              inclusions:
                                  inclusions?.map((value: string) => ({
                                      value,
                                  })) || [],
                          };
                      }

                      const {
                          base_amount: baseCost,
                          unit_amount: unitAmountCost,
                      } = (cost || {}) as FlatPricingData;
                      const { base_amount: base, unit_amount: unitAmount } =
                          price.original as FlatPricingData;

                      return {
                          id,
                          currency: currency.abbr,
                          name: '',
                          type,
                          price: {
                              base,
                              perProduct: unitAmount,
                              perPerson: unitAmount,
                          },
                          cost: cost
                              ? {
                                    base: baseCost,
                                    perProduct: unitAmountCost,
                                    perPerson: unitAmountCost,
                                }
                              : undefined,
                          capacity: dependencies?.capacity
                              ? getCapacityItemDtoToProductCapacityItem(
                                    dependencies.capacity
                                )
                              : undefined,
                          schedule: dependencies?.schedule
                              ? scheduleItemDtoToProductScheduleItem(
                                    dependencies.schedule
                                )
                              : undefined,
                          inclusions:
                              inclusions?.map((value: string) => ({ value })) ||
                              [],
                      };
                  }
              ),
              adaptivePricing: false,
              paymentMethods: product.pricing.payment_requirements.methods,
              prepayment:
                  product.pricing.payment_requirements.prepayment.required,
              prepaymentAmount:
                  product.pricing.payment_requirements.prepayment.amount,
              prepaymentType:
                  product.pricing.payment_requirements.prepayment.type,
          }
        : undefined,
    options: {
        items:
            product.options?.items.map(option => ({
                id: option.id,
                optionType: option.type,
                name: option.name,
                description: option.description,
                hasPricing: !!option?.pricing,
                ...(option?.pricing?.items.length
                    ? {
                          prices: option.pricing.items.map(
                              i => pricingDtoToPricing(i) as Pricing
                          ),
                      }
                    : { prices: [] }),
            })) || [],
    },
});

export const productFilterToRequestBodyConverter = (
    filters: Record<string, any>
) => ({
    type: filters?.productType,
    ...(filters.pricing
        ? {
              currency: filters.pricing.currency,
              minPrice: filters.pricing.minPrice,
              maxPrice: filters.pricing.maxPrice,
          }
        : {}),
});

export const convertOptionToOptionDto = (
    option: Option
): Omit<UpdateProduct111ReqItemDto, 'action'> => ({
    id: option.id,
    name: option.name,
    type: option.optionType,
    description: option.description,
    pricing:
        option.hasPricing && !!option.prices.length
            ? {
                  items: option.prices.map(p => ({
                      ...pricingToPricingDto(p),
                      action: ListItemAction.REORDER,
                  })),
                  payment_requirements: {
                      methods: [],
                      prepayment: { required: false },
                  },
              }
            : undefined,
});
