export interface FilterPaxValue {
    infants?: number;
    children?: number;
    youth?: number;
    adults?: number;
    seniors?: number;
}

export interface FilterDateValue {
    startDate: Date;
    endDate: Date;
}

export interface FilterPriceValue {
    currency?: string;
    minPrice?: number;
    maxPrice?: number;
}

export type FilterValue =
    | FilterDateValue
    | FilterPaxValue
    | FilterPriceValue
    | Date
    | string
    | string[];

export interface FilterForm {
    value: FilterValue;
}

export enum FilterTypes {
    PAX,
    DROPDOWN,
    SEARCH,
    DATES,
    DATE,
    PRICE,
    TAGS,
}

export interface FilterOption<T extends FilterTypes = FilterTypes> {
    name: string;
    label: string;
    type: T;
    required?: boolean;
    multiple?: boolean;
    preventPast?: boolean;
    selectText?: string;
    options?: {
        value: string;
        label: string;
    }[];
}
