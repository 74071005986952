import React from 'react';
import { Box } from '@mui/material';
import { addHours, format, formatDistance } from 'date-fns';

export interface DateTimeProps {
    date: Date;
}

export const DateTime: React.FC<DateTimeProps> = ({ date }) => {
    const nearDate = addHours(new Date(), -12) < date;

    return nearDate ? (
        <Box sx={{ whiteSpace: 'nowrap' }}>
            {formatDistance(date, new Date(), {
                addSuffix: true,
            })}
        </Box>
    ) : (
        <Box sx={{ whiteSpace: 'nowrap' }}>{format(date, 'MMM d HH:mm')}</Box>
    );
};
