import React, { useMemo } from 'react';
import { useCreateAsset, useGetAssets } from '../../queries';
import { getAssetDtoToAsset } from './asset.converters';

type UseGetAssetParamTypes = Parameters<typeof useGetAssets>;
type Filter = UseGetAssetParamTypes[0];
export const useDebouncedAssets = (search: string, debounce: number = 1000) => {
    const [debouncedParams, setDebouncedParams] = React.useState<
        Filter | undefined
    >();

    React.useEffect(() => {
        const handler = setTimeout(() => {
            const filter: Filter = {
                type: 'vehicle',
            };
            if (search.match(/^[^@]+@[^@.]+\.[a-zA-Z]+$/))
                filter.email = search;
            else if (search) {
                filter.vehicleMake = search;
                filter.vehicleModel = search;
                filter.vehicleLicensePlate = search;
            }
            setDebouncedParams(
                Object.keys(filter).length > 1 ? filter : undefined
            );
        }, debounce);

        return () => {
            clearTimeout(handler);
        };
    }, [search, debounce]);

    const { data, ...other } = useGetAssets(
        debouncedParams || {},
        [debouncedParams],
        {
            keepPreviousData: true,
            staleTime: debounce,
            enabled: !!debouncedParams,
        }
    );

    const parsedData = useMemo(
        () =>
            debouncedParams && data?.items
                ? data?.items.map(getAssetDtoToAsset)
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
        isLoading: other.isFetching,
    };
};

export { useCreateAsset };
