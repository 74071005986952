import {
    AgebandCapacityData,
    CapacityType,
    CreateManyCapacitiesReqItemDto,
    FlatCapacityData,
    GetCapacitiesResItemDto,
    GetProductsResItem0CapacityItemReferenceDto,
} from '../../requests';
import { CapacityItem } from './capacity.types';
import {
    CapacityOptionTypes,
    ProductCapacityItem,
} from '../product/product.types';

export const getCapacityDtoToCapacity = (
    capacityDto: GetCapacitiesResItemDto
): CapacityItem => {
    const { type, ...limit } = capacityDto.limit;

    return {
        capacityId: capacityDto.id,
        name: capacityDto.name,
        type:
            type === 'flat'
                ? CapacityOptionTypes.FLAT
                : CapacityOptionTypes.AGE_BAND,
        limit,
    };
};

export const getCapacityItemDtoToProductCapacityItem = (
    capacityDto: GetProductsResItem0CapacityItemReferenceDto
): ProductCapacityItem => {
    return {
        capacityId: capacityDto.id,
        name: capacityDto.name,
    };
};

export const capacityItemToCapacityDto = (
    capacity: CapacityItem
): CreateManyCapacitiesReqItemDto => ({
    id: capacity.capacityId,
    name: capacity.name,
    type: CapacityType.VEHICLE,

    // @ts-ignore
    limit: {
        ...capacity.limit,
        type:
            capacity.type === CapacityOptionTypes.FLAT
                ? FlatCapacityData.type.FLAT
                : AgebandCapacityData.type.AGEBAND,
    },
});
