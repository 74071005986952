import { DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import React from 'react';
import _ from 'lodash';
import {
    PickersDay as MuiPickersDay,
    PickersDayProps,
    StaticDatePicker,
} from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { Box, Popover } from '@mui/material';

export type DateChangeProps = MuiDatePickerProps<Date> & {
    selectedDate: Date;
    handleDateChange: (d: Date) => void;
    availableDates?: number[];
};

const timeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000;

const PickerDay: React.FC<PickersDayProps<Date>> = props => {
    // @ts-ignore
    const { values, ...rest } = props;
    const dateTime = rest.day.getTime();
    const selected = !!values?.find(
        (item: Date | undefined) => item?.getTime() === dateTime
    );
    return <MuiPickersDay {...rest} selected={selected} />;
};

const DateChange: React.FC<DateChangeProps> = ({
    selectedDate,
    handleDateChange,
    availableDates = [],
}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Box
                sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    textAlign: 'center',
                    fontWeight: 600,
                    color: '#2B395B',
                    cursor: 'pointer',
                }}
                onClick={handleClick}
            >
                {format(selectedDate, 'MMM d, EEE', {
                    locale: enUS,
                }).toUpperCase()}
            </Box>
            <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                slotProps={{
                    backdrop: {
                        onClick: handleClose,
                    },
                }}
            >
                <StaticDatePicker
                    value={selectedDate}
                    onChange={d => {
                        if (d) handleDateChange(d);
                        handleClose();
                    }}
                    sx={{
                        '& input': {
                            width: '204px',
                        },
                        '& .MuiPickersToolbar-root': {
                            display: 'none',
                        },
                        '& .MuiDialogActions-root': {
                            display: 'none',
                        },
                        '& .MuiPickersArrowSwitcher-root': {
                            display: 'none',
                        },
                        '& .MuiPickersCalendarHeader-switchViewButton': {
                            display: 'none',
                        },
                        '& .MuiPickersCalendarHeader-labelContainer': {
                            pointerEvents: 'none',
                        },
                        '& .MuiDayCalendar-weekContainer': {
                            justifyContent: 'space-between',
                        },
                        '& .MuiDayCalendar-header': {
                            justifyContent: 'space-between',
                        },
                    }}
                    slots={{
                        day: PickerDay,
                    }}
                    shouldDisableDate={date => {
                        return !availableDates.find(
                            d => d === date.getTime() - timeZoneOffset
                        );
                    }}
                    slotProps={_.defaultsDeep({
                        popper: {
                            sx: {
                                zIndex: 100000,
                            },
                        },
                        openPickerIcon: {
                            sx: {
                                color: '#B2B9CD',
                            },
                        },
                        textField: {
                            fullWidth: true,
                            sx: { width: 'auto' },
                        },
                        day: {
                            values: [selectedDate],
                            sx: {
                                '&:active': {
                                    backgroundColor: 'primary.main',
                                    color: '#FFF',
                                },
                                '&.Mui-selected': {
                                    backgroundColor: 'primary.main',

                                    '&:focus': {
                                        backgroundColor: 'primary.main',
                                    },
                                    '&:hover': {
                                        backgroundColor: 'primary.main',
                                    },
                                },
                                '&.MuiPickersDay-today': {
                                    border: 0,
                                    backgroundColor: 'transparent',
                                },
                            },
                        },
                    })}
                />
            </Popover>
        </>
    );
};

export default DateChange;
