import { useController, useFormContext } from 'react-hook-form';
import React, { useMemo } from 'react';
import { InputAdornment, MenuItem, Select } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TextField, TextFieldProps } from '../text-field';

export type TextFieldCategoryProps = TextFieldProps & {
    categories: { value: string; label: string }[];
    categoryName: string;
};

const TextFieldCategory: React.FC<TextFieldCategoryProps> = ({
    categories,
    categoryName,
    ...rest
}) => {
    const { control } = useFormContext();
    const { field } = useController({ control, name: categoryName });
    const selectedValue = useMemo(() => {
        return categories.find(c => c.value === field.value) || categories[0];
    }, [categories, field.value]);

    return (
        <TextField
            InputProps={{
                sx: { '&&&': { pr: 2 }, '.MuiInputBase-input': { pr: 0 } },
                endAdornment: (
                    <InputAdornment position="end">
                        <Select
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                                '.MuiOutlinedInput-notchedOutline': {
                                    display: 'none',
                                },
                                '.MuiSvgIcon-root': {
                                    right: '-8px',
                                },
                                '.MuiSelect-select': {
                                    pl: 1,
                                },
                            }}
                            MenuProps={{
                                slotProps: {
                                    backdrop: {
                                        sx: { background: 'transparent' },
                                    },
                                },
                            }}
                            value={selectedValue.value}
                            renderValue={() => selectedValue.label}
                            onChange={field.onChange}
                        >
                            {categories.map((category, index) => (
                                <MenuItem
                                    key={category.value}
                                    value={category.value}
                                >
                                    {category.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </InputAdornment>
                ),
            }}
            {...rest}
        />
    );
};

export default TextFieldCategory;
