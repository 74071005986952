/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateManyCapacitiesReqDto } from '../models/CreateManyCapacitiesReqDto';
import type { CreateManyCapacitiesResDto } from '../models/CreateManyCapacitiesResDto';
import type { GetCapacitiesResDto } from '../models/GetCapacitiesResDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CapacitiesService {

    /**
     * List/Filter Capacities
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param ids List of ids to return
     * @param pageNumber ID/Number of the page to open
     * @param pageSize number of items returned
     * @returns GetCapacitiesResDto Successful Response
     * @throws ApiError
     */
    public static getCapacities(
authorization: string,
ids?: Array<string>,
pageNumber?: number,
pageSize: number = 20,
): CancelablePromise<GetCapacitiesResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/capacities',
            headers: {
                'authorization': authorization,
            },
            query: {
                'ids': ids,
                'page_number': pageNumber,
                'page_size': pageSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create capacities
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns CreateManyCapacitiesResDto Successful Response
     * @throws ApiError
     */
    public static createManyCapacities(
authorization: string,
requestBody: CreateManyCapacitiesReqDto,
): CancelablePromise<CreateManyCapacitiesResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/capacities',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
