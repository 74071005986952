import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Stack,
} from '@mui/material';
import {
    Booking,
    bookingToBookingDto,
    convertNameDtoToFullName,
    DiscountType,
    EventBookingItem as IEventBookingItem,
    patchBookingToBookingDto,
    ProductOptionType,
    travelerDtoToTraveler,
} from '@travelity/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
    useGetCustomer1,
    useGetProduct,
    useUpdateBooking,
} from '@travelity/api/src/queries';
import { useForm } from '@travelity/form';

import { IconButton } from '@travelity/ui';
import EditIcon from '@mui/icons-material/Edit';
import { GetProductResRouteDto } from '@travelity/api/src/requests';
import AccordionButtons from '../../orders/components/accordion-buttons';
import participantsSchema from '../../../components/booking-participants/booking-participants.schema';
import { BookingParticipantsForm } from '../../../components/booking-participants';
import financialInformationSchema from '../../../components/booking-financial-information/booking-financial-information.schema';
import { BookingFinancialInformationForm } from '../../../components/booking-financial-information';
import productSchema from '../../../components/booking-product/booking-product.schema';
import productOptionsSchema from '../../../components/booking-product-options/booking-product-options.schema';
import { BookingProductForm } from '../../../components/booking-product';
import scheduleSchema from '../../../components/booking-schedule/booking-schedule.schema';
import { BookingScheduleForm } from '../../../components/booking-schedule';
import notesSchema from '../../../components/booking-notes/booking-notes.schema';
import { BookingNotesForm } from '../../../components/booking-notes';
import { PaymentStatusTag } from '../../../components/payment-status';
import { BookingStatusTag } from '../../../components/booking-status-tag';
import { participantsToParticipantsForm } from '../../../components/booking-participants/booking-participants.converters';
import { useLayoutContext } from '../../../layouts/main-layout/main-layout';
import { ActivityHistory } from '../../../components/activity-history';
import { BookingProductOptionsForm } from '../../../components/booking-product-options';
import { MoveBookingModal } from '../../../components/move-booking-modal';

export interface BookingEditFormProps {
    booking: Booking;
    siblingBookings: Booking[];
    updateBooking: () => void;
    route?: GetProductResRouteDto;
    closeForm?: () => void;
    // updateBooking: (id: string, booking: Booking) => void;
}

const BookingEditForm: React.FC<BookingEditFormProps> = ({
    booking,
    siblingBookings,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateBooking,
    route,
    closeForm,
}) => {
    const location = useLocation();
    const [expanded, setExpanded] = useState<number | undefined>(
        location.state || 0
    );

    const [editing, setEditing] = useState(false);
    const toggleExpanded = (number: number) => {
        if (editing) return;
        setExpanded(s => (s === number ? undefined : number));
    };

    const { data: product } = useGetProduct({ productId: booking.product.id });

    useEffect(() => {
        if (location.state && !editing) setExpanded(location.state);
    }, [location.state]);

    const { enqueueSnackbar } = useSnackbar();
    const { mutate: update, isLoading: isSaving } = useUpdateBooking({
        onSuccess: () => {
            setEditing(false);
        },
        onError: (error: any) => {
            if (error?.status === 409) {
                enqueueSnackbar('Nothing to save', { variant: 'error' });
            } else if (error?.body) {
                enqueueSnackbar(error.body, { variant: 'error' });
            }
        },
    });

    const bookingId = booking.id;
    const onSubmit = useCallback(
        (data: any, e?: React.BaseSyntheticEvent) => {
            e?.preventDefault();
            const key = e?.target.id;
            if (key === 'participants') {
                updateBooking();
            } else if (key === 'financialInformation') {
                update(
                    {
                        bookingId,
                        requestBody: {
                            financials: {
                                price: {
                                    discount: data,
                                },
                            },
                        },
                    },
                    {
                        onSuccess: () => {
                            updateBooking();
                        },
                    }
                );
            } else {
                update(
                    {
                        bookingId,
                        requestBody: patchBookingToBookingDto({
                            [key]:
                                // eslint-disable-next-line no-nested-ternary
                                key === 'notes'
                                    ? data.notes?.map(
                                          ({ value }: { value: string }) =>
                                              value
                                      )
                                    : data,
                        }),
                    },
                    {
                        onSuccess: () => {
                            updateBooking();
                        },
                    }
                );
            }
        },
        [bookingId]
    );
    const {
        Form: ScheduleForm,
        reset: resetSchedule,
        // formState: { errors: scheduleErrors },
    } = useForm({
        defaultValues: {
            start: new Date(booking.date.start * 1000),
        },
        onSubmit,
        mode: 'onChange',
        schema: scheduleSchema,
    });
    const { Form: ParticipantsForm, reset: resetParticipants } = useForm({
        defaultValues: participantsToParticipantsForm(booking.participants),
        onSubmit,
        mode: 'onChange',
        schema: participantsSchema,
    });
    const {
        Form: FinancialInformationForm,
        reset: resetFinancialInformation,
        formState: { errors: financialInformationErrors },
    } = useForm({
        defaultValues: booking.financials.price.discount || {
            type: DiscountType.RELATIVE,
            amount: 0,
        },
        onSubmit,
        mode: 'onChange',
        schema: financialInformationSchema,
    });
    const {
        Form: ProductForm,
        reset: resetProduct,
        // formState: { errors: productErrors },
    } = useForm({
        defaultValues: { values: [] },
        onSubmit,
        mode: 'onChange',
        schema: productSchema,
    });
    const {
        Form: ProductOptionsForm,
        reset: resetProductOptions,
        // formState: { errors: productErrors },
    } = useForm({
        defaultValues: {
            options:
                booking.product.options?.map(o => ({
                    name: o.name as string,
                    type: o.type as ProductOptionType,
                    pax: o.participants?.pax || {},
                    hasPrice: !!o.financials?.pricing,
                    pricing: o.financials?.pricing,
                })) || [],
        },
        onSubmit,
        mode: 'onChange',
        schema: productOptionsSchema,
    });
    const {
        Form: NotesForm,
        reset: resetNotes,
        formState: { errors: notesErrors },
    } = useForm({
        defaultValues: {
            notes:
                booking.notes?.map(value => ({
                    value,
                })) || [],
        },
        onSubmit,
        mode: 'onChange',
        schema: notesSchema,
    });

    const customerFullName = booking?.participants?.customer?.name
        ? convertNameDtoToFullName(booking.participants.customer.name)
        : '';

    const reset = useCallback(() => {
        switch (expanded) {
            case 0:
                resetSchedule({
                    start: new Date(booking.date.start * 1000),
                });
                break;
            case 1:
                resetParticipants(
                    participantsToParticipantsForm(booking.participants)
                );
                break;
            case 2:
                resetFinancialInformation(
                    booking.financials.price.discount || {
                        type: DiscountType.RELATIVE,
                        amount: 0,
                    }
                );
                break;
            case 3:
                resetProduct({ values: [] });
                break;
            case 4:
                resetProductOptions({
                    options:
                        booking.product.options?.map(o => ({
                            name: o.name as string,
                            type: o.type as ProductOptionType,
                            pax: o.participants?.pax || {},
                            hasPrice: !!o.financials?.pricing,
                            pricing: o.financials?.pricing,
                        })) || [],
                });
                break;
            case 5:
                resetNotes({
                    notes: booking.notes?.map(value => ({
                        value,
                    })),
                });
                break;
            default:
        }
    }, [expanded, booking]);

    const onCancel = useCallback(() => {
        reset();
        setEditing(false);
    }, [reset]);

    useEffect(() => {
        reset();
        // setEditing(false);
    }, [booking]);

    const { setMainIsLoading } = useLayoutContext();
    useEffect(() => {
        setMainIsLoading(isSaving);
    }, [isSaving]);

    const { data: customer } = useGetCustomer1({
        customerId: booking.participants.customer.id,
    });

    const travelers = useMemo(() => {
        return (
            booking.participants.travelers?.map(t =>
                travelerDtoToTraveler(t)
            ) || []
        );
    }, [booking]);

    const [moveBooking, setMoveBooking] = useState(false);
    const bookingDto = useMemo(() => bookingToBookingDto(booking), [booking]);

    return (
        <Box
            sx={{
                height: 1,
                '& > div:last-child': {
                    height: 'calc(100% - 30px)',
                },
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Box
                    sx={{
                        display: 'inline-flex',
                        padding: '8px 18px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '4px',
                        borderRadius: '0px 0px 12px 0px',
                        background: '#6B748C',
                        color: '#FFF',
                        fontFamily: 'Lexend',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '100%',
                        textTransform: 'capitalize',
                    }}
                >
                    Booking Preview
                </Box>
            </Stack>
            <PerfectScrollbar>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={2}
                    sx={{
                        px: 2,
                        height: '46px',
                        py: '10px',
                        borderRadius: '0px 12px 0px 0px',
                    }}
                >
                    <BookingStatusTag status={booking.status} />
                    {booking.financials?.status && (
                        <PaymentStatusTag status={booking.financials?.status} />
                    )}
                </Stack>
                <Box
                    sx={{
                        '&&& .MuiAccordionSummary-root': {
                            borderBottom: '1px solid #DFE1ED',
                        },
                        '&&& .MuiAccordionDetails-root': {
                            py: 3,
                        },
                    }}
                >
                    <ScheduleForm id="schedule">
                        <Accordion
                            disableGutters
                            expanded={expanded === 0}
                            onChange={() => toggleExpanded(0)}
                        >
                            <AccordionSummary>
                                Booking Date and Time
                                {expanded === 0 && (
                                    <IconButton
                                        withShadow
                                        sx={{ ml: 2 }}
                                        icon={<EditIcon fontSize="small" />}
                                        onClick={() => setMoveBooking(true)}
                                        tooltip="Edit"
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <BookingScheduleForm
                                    date={booking.date}
                                    preview={!editing || expanded !== 0}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </ScheduleForm>
                    <ParticipantsForm id="participants">
                        <Accordion
                            disableGutters
                            expanded={expanded === 1}
                            onChange={() => toggleExpanded(1)}
                        >
                            <AccordionSummary>Participants</AccordionSummary>
                            <AccordionDetails>
                                <BookingParticipantsForm
                                    eventId={booking.eventId}
                                    preview={!editing || expanded !== 1}
                                    bookingId={booking.id}
                                    route={
                                        route ||
                                        (booking.route as GetProductResRouteDto)
                                    }
                                    siblingBookings={siblingBookings}
                                    customer={customer}
                                    travelers={travelers}
                                    booking={booking}
                                    pax={booking.participants.pax}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </ParticipantsForm>
                    <FinancialInformationForm id="financialInformation">
                        <Accordion
                            disableGutters
                            expanded={expanded === 2}
                            onChange={() => toggleExpanded(2)}
                        >
                            <AccordionSummary>
                                Pricing and Financials
                                {expanded === 2 && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={isSaving}
                                        errors={financialInformationErrors}
                                        onEditCancel={onCancel}
                                        onEdit={() => setEditing(true)}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <BookingFinancialInformationForm
                                    operator={customerFullName}
                                    financials={booking.financials}
                                    bookingId={booking.id}
                                    preview={!editing || expanded !== 2}
                                    refetch={updateBooking}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </FinancialInformationForm>
                    <ProductForm id="product">
                        <Accordion
                            disableGutters
                            expanded={expanded === 3}
                            onChange={() => toggleExpanded(3)}
                        >
                            <AccordionSummary>Product</AccordionSummary>
                            <AccordionDetails>
                                <BookingProductForm
                                    product={booking.product}
                                    preview={!editing || expanded !== 3}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </ProductForm>
                    <ProductOptionsForm id="product-options">
                        <Accordion
                            disableGutters
                            expanded={expanded === 4}
                            disabled={product && !product.options?.length}
                            onChange={() => toggleExpanded(4)}
                        >
                            <AccordionSummary>Product Options</AccordionSummary>
                            <AccordionDetails>
                                {product && (
                                    <BookingProductOptionsForm
                                        booking={booking}
                                        product={product}
                                    />
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </ProductOptionsForm>
                    <NotesForm id="notes">
                        <Accordion
                            disableGutters
                            expanded={expanded === 5}
                            onChange={() => toggleExpanded(5)}
                        >
                            <AccordionSummary>
                                Notes
                                {expanded === 5 && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={isSaving}
                                        errors={notesErrors}
                                        onEditCancel={onCancel}
                                        onEdit={() => setEditing(true)}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <BookingNotesForm
                                    preview={!editing || expanded !== 5}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </NotesForm>
                    <Accordion
                        disableGutters
                        expanded={expanded === 6}
                        onChange={() => toggleExpanded(6)}
                    >
                        <AccordionSummary>Activity Log</AccordionSummary>
                        <AccordionDetails>
                            <ActivityHistory
                                entity="booking"
                                lifecycle={booking.lifecycle}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </PerfectScrollbar>

            <MoveBookingModal
                handleCancel={() => setMoveBooking(false)}
                handleConfirm={() => {
                    updateBooking();
                    setMoveBooking(false);
                    closeForm?.();
                }}
                booking={bookingDto as IEventBookingItem}
                open={moveBooking}
            />
        </Box>
    );
};

export default React.memo(BookingEditForm);
