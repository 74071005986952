import React, { useMemo, useState } from 'react';
import { Box, Chip, Collapse, Divider, Stack, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    AgeBands,
    BookingStatus,
    EventItem as IEventItem,
    EventStatuses,
} from '@travelity/api';
import {
    Card,
    Heading,
    IconButton,
    IconButtonGroup,
    Tag,
    TagStatus,
} from '@travelity/ui';

import ExpandIcon from '@mui/icons-material/Expand';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import WarningIcon from '@mui/icons-material/Warning';
import { has } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { useCancelEvents } from '@travelity/api/src/queries';
import { useSnackbar } from 'notistack';
import { TourEventStaffMemberPosition } from '@travelity/api/src/requests';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { EventStatus as EventStatusComponent } from '../event-status';
import { ProductTypeIcon } from '../product-type-icon';
import { useExpand } from '../../hooks';
import { EventBookingItem } from '../event-booking-item';
import { EventEnd } from '../event-end/event-end';
import { ReasonModal } from '../booking-item/components/reason-modal';
import { OverlayWithReason } from '../overlay-with-reason';

export interface EventItemProps {
    event: IEventItem;
    isSelected: boolean;
    bookingId?: string;
    refetch: () => void;
}

const EventItem: React.FC<EventItemProps> = ({
    event,
    isSelected,
    bookingId,
    refetch,
}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [deletingEvent, setDeletingEvent] = useState<string>();
    const [expanded, toggleExpand] = useExpand<boolean>();
    const { mutate: cancelEvent, isLoading: isRemoveBookingLoading } =
        useCancelEvents({
            onSuccess: () => {
                setDeletingEvent(undefined);
                refetch();
                enqueueSnackbar(
                    `Cancelled the event for the product "${event.product.name}"`,
                    {
                        variant: 'success',
                    }
                );
            },
            onError: () => {
                enqueueSnackbar(
                    `Failed to cancel the event for the product "${event.product.name}"`,
                    {
                        variant: 'error',
                    }
                );
            },
        });
    const cantDelete = useMemo(() => {
        return event.bookingsSummary?.bookings.some(booking => {
            return (
                booking.status === BookingStatus.RESERVED ||
                booking.status === BookingStatus.HOLD
            );
        });
    }, [event]);

    const options = event.bookingsSummary?.preferences_summary?.booked_options;

    const attentionNeeded = useMemo(() => {
        if (event.bookingsSummary?.bookings) {
            return false;
            // TODO update this
            // return event.bookingsSummary?.bookings.some(
            //     booking =>
            //         booking.status !== BookingStatus.RESERVED ||
            //         booking.financials.status !== PaymentStatus.PAID
            // );
        }
        return false;
    }, [event]);

    const staffAndAssets = useMemo(() => {
        return [
            ...Object.values(TourEventStaffMemberPosition).map(position => ({
                name: t(position, { ns: 'common' }),
                type: 'staff',
                value: position,
                count: event.staff.filter(s => s.position === position).length,
            })),
            ...['Vehicle'].map(name => ({
                name,
                type: 'asset',
                value: 'vehicle',
                count: event.assets.length,
            })),
        ];
    }, [event]);

    return (
        <Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                    mt: 2,
                    mb: 1,
                    pr: 5,
                    minWidth: 0,
                }}
            >
                <Stack
                    direction="row"
                    gap={1.5}
                    alignItems="center"
                    sx={{
                        minWidth: 0,
                    }}
                >
                    <Heading
                        sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                            pr: 1,
                        }}
                        alpha={0.7}
                        ellipsis
                    >
                        {event.product.name}
                    </Heading>
                    <CircleIcon sx={{ fontSize: '8px', color: '#D9D9D9' }} />
                    <ProductTypeIcon type={event.product.type} />
                    <Typography
                        sx={{
                            ml: '-8px',
                            color: '#6B748C',
                            fontSize: '12px',
                            fontWeight: '500',
                            whiteSpace: 'nowrap',
                            pr: 1.5,
                        }}
                    >
                        {t(event.product.type, { ns: 'product' })}
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    gap={1}
                    sx={{
                        filter:
                            event.status === EventStatuses.CANCELLED
                                ? 'blur(2px)'
                                : undefined,
                    }}
                >
                    <EventStatusComponent status={event.status} />
                    <EventEnd date={event.date} />
                </Stack>
            </Stack>
            <Card
                isSelected={isSelected}
                sx={{
                    zIndex: 3,
                }}
                parentProps={{ alignItems: 'stretch' }}
                leftAdornment={
                    <Box>
                        {event.status !== EventStatuses.CANCELLED &&
                            event.ephemeral && (
                                <Chip
                                    label="Virtual"
                                    sx={{
                                        width: 1,
                                        borderRadius: '8px',
                                        background: '#EC8031',
                                        padding: '6px 8px',
                                        color: '#FFF',
                                        fontSize: '14px',
                                        mb: 0.5,
                                    }}
                                />
                            )}
                        <Box
                            sx={{
                                width: '88px',
                                height: '88px',
                                p: 1,
                                borderRadius: '12px',
                                bgcolor: '#FFF',
                                boxShadow:
                                    '0px 0px 16px 0px rgba(178, 185, 205, 0.50)',
                            }}
                        >
                            <Stack
                                sx={{
                                    height: 1,
                                    p: 1,
                                    borderRadius: '12px',
                                    bgcolor: '#F4F6FA',
                                }}
                                justifyContent="center"
                                alignItems="center"
                                gap={0.5}
                            >
                                {event.status === EventStatuses.CANCELLED ? (
                                    <CloseIcon
                                        sx={{
                                            fontSize: '36px',
                                            color: '#B2B9CD',
                                        }}
                                    />
                                ) : attentionNeeded ? (
                                    <>
                                        <WarningIcon
                                            sx={{
                                                color: '#EC8031',
                                                fontSize: '24px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                color: '#B2B9CD',
                                            }}
                                        >
                                            Attention
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <CheckCircleIcon
                                            sx={{
                                                color: '#2CAC60',
                                                fontSize: '24px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                color: '#B2B9CD',
                                            }}
                                        >
                                            All Set
                                        </Typography>
                                    </>
                                )}
                            </Stack>
                        </Box>
                    </Box>
                }
                buttons={
                    <>
                        <IconButtonGroup>
                            <IconButton
                                href={`/events/${event.id}`}
                                icon={<VisibilityIcon fontSize="small" />}
                                tooltip="Preview"
                            />
                            {event.status !== EventStatuses.CANCELLED && (
                                <>
                                    <Divider sx={{ mx: 0.75 }} />
                                    <IconButton
                                        hoverColor="error.main"
                                        onClick={() =>
                                            setDeletingEvent(event.id)
                                        }
                                        disabled={cantDelete}
                                        icon={<DeleteIcon fontSize="small" />}
                                        tooltip="Cancel"
                                    />
                                </>
                            )}
                        </IconButtonGroup>
                        <IconButtonGroup>
                            <IconButton
                                onClick={() => toggleExpand(true)}
                                icon={<ExpandIcon fontSize="small" />}
                                tooltip={expanded ? 'Collapse' : 'Expand'}
                            />
                        </IconButtonGroup>
                    </>
                }
            >
                <Stack
                    sx={{
                        pb: 2,
                        pt: 1,
                        filter:
                            event.status === EventStatuses.CANCELLED
                                ? 'blur(2px)'
                                : undefined,
                    }}
                    gap={1}
                >
                    <Stack
                        direction="row"
                        gap={1}
                        sx={{
                            px: 2,
                            py: 1,
                        }}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography sx={{ color: '#B7BCC7', fontSize: '12px' }}>
                            Info badges
                        </Typography>
                        <Tag
                            label="Total PAX"
                            value={`${event.bookingsSummary?.travelers_summary?.count}`}
                        />
                    </Stack>
                    <Divider />

                    <Box
                        component={PerfectScrollbar}
                        sx={{
                            '&&': { height: '46px' },
                            top: 0,
                            left: 0,
                            right: 0,
                            zIndex: 1,
                        }}
                        option={{
                            suppressScrollY: true,
                        }}
                    >
                        <Stack
                            direction="row"
                            gap={1}
                            sx={{
                                px: 2,
                            }}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Stack
                                direction="row"
                                gap={1}
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ mt: 1 }}
                            >
                                <Typography
                                    sx={{ color: '#B7BCC7', fontSize: '12px' }}
                                >
                                    Capacity
                                </Typography>
                                {!!event.availableSeats && (
                                    <Tag
                                        label="Available Seats"
                                        values={[`${event.availableSeats}`]}
                                        valueProps={{
                                            sx: { backgroundColor: '#CDEFDB' },
                                        }}
                                    />
                                )}
                            </Stack>
                            <Stack
                                direction="row"
                                gap={1}
                                alignItems="center"
                                sx={{ mt: 1 }}
                            >
                                {has(
                                    event.capacitySelection.capacity,
                                    'max_count'
                                ) ? (
                                    <>
                                        <Tag
                                            label="Min Count"
                                            values={[
                                                // @ts-ignore
                                                `${event.capacitySelection.capacity.min_count}`,
                                            ]}
                                        />
                                        <Tag
                                            label="Max Count"
                                            values={[
                                                // @ts-ignore
                                                `${event.capacitySelection.capacity.max_count}`,
                                            ]}
                                        />
                                    </>
                                ) : (
                                    Object.values(AgeBands).map(
                                        name =>
                                            // @ts-ignore
                                            !!event.capacitySelection.capacity[
                                                name
                                            ] && (
                                                <Tag
                                                    key={name}
                                                    label={t(name, {
                                                        ns: 'product',
                                                    })}
                                                    values={[
                                                        // @ts-ignore
                                                        event.capacitySelection
                                                            .capacity[name],
                                                    ]}
                                                />
                                            )
                                    )
                                )}

                                <Typography
                                    sx={{
                                        borderLeft: '1px solid #C9CEDC',
                                        pl: 1.5,
                                        color: '#B7BCC7',
                                        fontSize: '12px',
                                    }}
                                >
                                    {event.capacitySelection.capacity.name}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
                {!!options?.length && (
                    <>
                        <Divider />
                        <Stack
                            direction="row"
                            gap={0.5}
                            sx={{
                                px: 2,
                                py: 1.5,
                                filter:
                                    event.status === EventStatuses.CANCELLED
                                        ? 'blur(2px)'
                                        : undefined,
                            }}
                        >
                            {options.map(option => (
                                <Tag
                                    label={option.name}
                                    key={option.name}
                                    values={[`${option.count}`]}
                                />
                            ))}
                        </Stack>
                    </>
                )}
                {!!staffAndAssets?.length && (
                    <>
                        <Divider />
                        <Stack
                            direction="row"
                            gap={0.5}
                            sx={{
                                px: 2,
                                py: 1.5,
                                filter:
                                    event.status === EventStatuses.CANCELLED
                                        ? 'blur(2px)'
                                        : undefined,
                            }}
                        >
                            {staffAndAssets.map(
                                ({ name, count, type, value }) => (
                                    <Tag
                                        label={name}
                                        status={
                                            count === 0
                                                ? TagStatus.ERROR
                                                : count === 1
                                                ? TagStatus.SUCCESS
                                                : undefined
                                        }
                                        value={count > 1 ? count : undefined}
                                        key={name}
                                        onClick={() =>
                                            navigate(
                                                `/events/${event.id}/operations/edit`,
                                                {
                                                    state:
                                                        count > 0
                                                            ? undefined
                                                            : {
                                                                  action: 'add',
                                                                  type,
                                                                  value,
                                                              },
                                                }
                                            )
                                        }
                                        valueProps={{
                                            sx:
                                                count > 1
                                                    ? {
                                                          bgcolor:
                                                              'success.main',
                                                          color: '#FFF',
                                                          fontWeight: 300,
                                                          minWidth: '23px',
                                                      }
                                                    : undefined,
                                        }}
                                    />
                                )
                            )}
                        </Stack>
                    </>
                )}
                {event.status === EventStatuses.CANCELLED && (
                    <OverlayWithReason
                        title="Cancelled"
                        reason={event.reason}
                    />
                )}
            </Card>
            <Collapse in={!!expanded}>
                <Stack gap={1} sx={{ mt: 1 }}>
                    {event.bookingsSummary?.bookings.map(booking => (
                        <EventBookingItem
                            key={booking.id}
                            booking={booking}
                            isSelected={booking.id === bookingId}
                            eventId={event.id}
                            refetch={refetch}
                        />
                    ))}
                </Stack>
            </Collapse>
            {!expanded &&
                !!event.bookingsSummary?.bookings.length &&
                event.bookingsSummary.bookings.slice(0, 3).map((b, i) => {
                    return (
                        <Stack
                            direction="row"
                            gap={1}
                            key={b.id}
                            sx={{
                                width: 1,
                                alignItems: 'flex-start',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '88px',
                                }}
                            />
                            <Stack flexGrow={2}>
                                <Box
                                    sx={{
                                        mx: i * 2 + 2,
                                        boxShadow:
                                            '0px 0px 16px 0px rgba(178, 185, 205, 0.50)',
                                        height: '8px',
                                        borderRadius: '0px 0px 12px 12px',
                                    }}
                                />
                            </Stack>

                            <Stack
                                flexShrink={0}
                                direction="column"
                                gap={1}
                                sx={{
                                    width: '34px',
                                }}
                            />
                        </Stack>
                    );
                })}

            <ReasonModal
                title="Are you sure you want to cancel this event?"
                content="Provide the reason for cancelling this event"
                confirmText="Confirm"
                open={!!deletingEvent}
                isLoading={isRemoveBookingLoading}
                handleCancel={() => setDeletingEvent(undefined)}
                handleConfirm={message => {
                    cancelEvent({
                        eventId: deletingEvent as string,
                        requestBody: { message },
                    });
                }}
            />
        </Box>
    );
};

export default EventItem;
