import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { ComponentType, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useLog } from '../hooks';

interface AuthenticationGuardProps {
    component: ComponentType;
}

const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({
    component,
}) => {
    const location = useLocation();
    const returnTo = location.pathname.startsWith('/accept_invitation')
        ? location.pathname
        : undefined;
    const options = useMemo(() => ({ returnTo }), [returnTo]);

    useLog('AuthenticationGuard', []);

    const Component = useCallback(
        withAuthenticationRequired(component, options),
        [component, options]
    );

    return <Component />;
};

export default AuthenticationGuard;
