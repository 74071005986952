import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';
import { Card, IconButtonGroup } from '@travelity/ui';

export interface ProductItemProps {}

const BookingItemSkeleton: React.FC<ProductItemProps> = () => {
    return (
        <Card
            sx={{
                height: '190px',
            }}
            direction="row"
            justifyContent="space-evenly"
            buttons={
                <>
                    <IconButtonGroup
                        flexShrink={0}
                        sx={{ px: 0.75, py: 1 }}
                        gap={1.5}
                    >
                        <Skeleton variant="circular" width={24} height={24} />
                        <Skeleton variant="circular" width={24} height={24} />
                    </IconButtonGroup>
                    <IconButtonGroup
                        flexShrink={0}
                        sx={{ px: 0.75, py: 1 }}
                        gap={1.5}
                    >
                        <Skeleton variant="circular" width={24} height={24} />
                        <Skeleton variant="circular" width={24} height={24} />
                        <Skeleton variant="circular" width={24} height={24} />
                    </IconButtonGroup>
                </>
            }
        >
            <Stack sx={{ width: '50%' }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        px: 2,
                        height: '46px',
                        py: '10px',
                        bgcolor: '#F4F6FA',
                        borderRadius: '12px 0px 0px 0px',
                    }}
                >
                    <Skeleton width={150} sx={{ fontSize: '24px' }} />
                    <Skeleton width={60} sx={{ fontSize: '24px' }} />
                </Stack>
                <Stack
                    sx={{ grow: 2, p: 2, pt: '10px', height: '139px' }}
                    direction="row"
                    justifyContent="space-evenly"
                    gap={1}
                >
                    <Skeleton variant="rectangular" width="33%" height={100} />
                    <Skeleton variant="rectangular" width="33%" height={100} />
                    <Skeleton variant="rectangular" width="33%" height={100} />
                </Stack>
            </Stack>
            <Stack
                sx={{
                    width: '50%',
                    borderLeft: '2px solid rgba(201, 206, 220, 0.5)',
                    position: 'relative',
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={2}
                    sx={{
                        px: 2,
                        height: '46px',
                        py: '10px',
                        bgcolor: '#F4F6FA',
                        borderRadius: '0px 12px 0px 0px',
                    }}
                >
                    <Skeleton variant="circular" width={24} height={24} />
                    <Box
                        component="span"
                        sx={{
                            color: '#8F97AA',
                        }}
                    >
                        <Skeleton width={90} sx={{ fontSize: '24px' }} />
                    </Box>
                </Stack>
                <Box sx={{ p: 2 }}>
                    <Skeleton width={120} />
                    <Skeleton width={100} />
                    <Skeleton width={140} />
                </Box>
            </Stack>
        </Card>
    );
};

export default BookingItemSkeleton;
