import React, { createContext, useContext, useMemo, ReactNode } from 'react';
import { User } from '@travelity/api';

export interface IUserContext {
    user?: User;
}

export interface HelperProviderProps {
    user?: User;
    children: ReactNode;
}

const UserContext = createContext<IUserContext>({});

export function useUserContext() {
    return useContext(UserContext) as IUserContext;
}

function UserProvider({ children, user }: HelperProviderProps) {
    const context = useMemo(
        () => ({
            user,
        }),
        [user]
    );

    return (
        <UserContext.Provider value={context}>{children}</UserContext.Provider>
    );
}

export default UserProvider;
