import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    eventsFilterToRequestBodyConverter,
    getBookingDtoToBooking,
    useEventsLazy,
    useProducts,
} from '@travelity/api';
import { groupBy } from 'lodash';
import { format } from 'date-fns';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { EventItem } from '../../components/event-item';
import { ReactComponent as PreviewSvg } from '../products/assets/preview.svg';
import EventEditForm from './components/event-preview';
import { EventItemSkeleton } from '../../components/event-item-skeleton';
import { DailyViewHeader } from './components/daily-view-header';
import { Filters } from '../../components/filters';
import { useSelectOptions } from '../../hooks';
import {
    FilterOption,
    FilterTypes,
} from '../../components/filters/filters.types';
import BookingEditForm from '../bookings/components/booking-edit-form';

const eventFilters: FilterOption[] = [
    {
        name: 'dates',
        label: 'Dates',
        type: FilterTypes.DATES,
        selectText: 'Please, select the value for date filters',
    },
    {
        name: 'products',
        label: 'Products',
        type: FilterTypes.DROPDOWN,
        multiple: true,
        selectText: 'Please, select products',
        options: [],
    },
];

export interface EventsProps {}

const Events: React.FC<EventsProps> = () => {
    const { eventId, bookingId } = useParams();

    // Filters
    const { data: products } = useProducts();
    const productFilterOptions = useSelectOptions(products, 'name', 'id', true);
    const [filters, setFilters] = useState({});
    const filledFilters = useMemo(() => {
        return eventFilters.map(filter =>
            filter.name === 'products'
                ? {
                      ...filter,
                      options: productFilterOptions,
                  }
                : filter
        );
    }, [productFilterOptions]);

    const {
        data: events,
        isLoading,
        // update: updateEvent,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        refetch,
    } = useEventsLazy(
        useMemo(() => eventsFilterToRequestBodyConverter(filters), [filters])
    );
    const event = useMemo(() => {
        return events?.find(e => e.id === eventId);
    }, [events, eventId]);
    const bookingItem = useMemo(() => {
        const booking =
            event?.bookingsSummary && bookingId
                ? event.bookingsSummary.bookings?.find(b => b.id === bookingId)
                : undefined;
        return booking ? getBookingDtoToBooking(booking) : undefined;
    }, [event, bookingId]);

    const [firstVisibleDate, setFirstVisibleDate] = useState<
        number | undefined
    >(undefined);
    useEffect(() => {
        if (events?.length) setFirstVisibleDate(events[0].date.start.getTime());
    }, [events]);

    const groupByDay = useMemo(() => {
        if (events) {
            return groupBy(events, ({ date }) =>
                format(date.start, 'dd-MMM-yyyy')
            );
        }
        return {};
    }, [events]);

    const refs = useRef<Record<number, HTMLDivElement | null>>({});
    const setDayRef = useCallback((ref: HTMLDivElement) => {
        const day = ref?.getAttribute('data-day');
        if (day) {
            refs.current[parseInt(day, 10)] = ref;
        }
    }, []);

    const loadMoreRef = useRef({ hasNextPage, isFetchingNextPage });
    loadMoreRef.current = { hasNextPage, isFetchingNextPage };
    const onScroll = useCallback(
        (container: HTMLElement) => {
            const days = Object.keys(refs.current)
                .map(k => ({
                    date: k,
                    // @ts-ignore
                    offset: refs.current[k].offsetTop + 30,
                }))
                .sort((a, b) => b.offset - a.offset);
            const nearest =
                days.find(({ offset }) => offset <= container.scrollTop) ||
                days[days.length - 1];
            setFirstVisibleDate(parseInt(nearest.date, 10));

            if (
                container.scrollHeight -
                    container.scrollTop -
                    container.clientHeight <
                    180 &&
                loadMoreRef.current.hasNextPage &&
                !loadMoreRef.current.isFetchingNextPage
            )
                fetchNextPage();
        },
        [fetchNextPage]
    );

    const [currentDate, setCurrentDate] = useState<string>(
        format(new Date(), 'HH:mm')
    );
    useEffect(() => {
        setInterval(() => {
            setCurrentDate(format(new Date(), 'HH:mm'));
        }, 1000);
    }, []);

    const navigate = useNavigate();

    // useLog('Events', [filters]);

    return (
        <Stack
            direction="row"
            sx={{ height: 'calc(100vh - 60px)', overflow: 'hidden' }}
        >
            <Box
                sx={{
                    bgcolor: '#FFF',
                    flexGrow: 2,
                    ml: 2,
                    my: 2,
                    px: 2,
                    height: 'calc(100% - 32px)',
                    minWidth: '0',
                }}
            >
                <Box>
                    <Filters
                        values={filters}
                        setValues={setFilters}
                        options={filledFilters}
                    />
                </Box>
                <Box
                    sx={{
                        py: 1,
                        bgcolor: events?.length ? '#F8F9FA' : undefined,
                        height: isLoading ? 'auto' : 'calc(100% - 56px)',
                        '& > div': {
                            py: 1,
                        },
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            py: 1.5,
                            px: 2,
                            height: '72px',
                        }}
                    >
                        <Box>
                            {firstVisibleDate && (
                                <DailyViewHeader
                                    date={new Date(firstVisibleDate)}
                                />
                            )}
                        </Box>
                        <Stack>
                            <Typography
                                sx={{
                                    color: '#9198AA',
                                    fontSize: '12px',
                                }}
                            >
                                Current time
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#B2B9CD',
                                    fontSize: '20px',
                                    fontWeight: '700',
                                    textAlign: 'right',
                                }}
                            >
                                {currentDate}
                            </Typography>
                        </Stack>
                    </Stack>
                    {events && (
                        <PerfectScrollbar
                            onScrollY={onScroll}
                            style={{ height: 'calc(100% - 72px)' }}
                        >
                            {events.length ? (
                                <Stack
                                    sx={{
                                        px: 2,
                                    }}
                                    gap={1}
                                >
                                    {Object.values(groupByDay).map(
                                        (dayEvents, i) => {
                                            let lastTime: number;
                                            return (
                                                <React.Fragment
                                                    key={dayEvents[0].date.start.toString()}
                                                >
                                                    <DailyViewHeader
                                                        date={
                                                            dayEvents[0].date
                                                                .start
                                                        }
                                                        sx={
                                                            i === 0
                                                                ? {
                                                                      display:
                                                                          'none',
                                                                  }
                                                                : {}
                                                        }
                                                        ref={setDayRef}
                                                    />
                                                    {dayEvents.map(ev => {
                                                        const newTime =
                                                            lastTime !==
                                                            ev.date.start.getTime();
                                                        lastTime =
                                                            ev.date.start.getTime();

                                                        return (
                                                            <React.Fragment
                                                                key={ev.id}
                                                            >
                                                                {newTime && (
                                                                    <Divider
                                                                        sx={{
                                                                            my: 1,
                                                                            '.MuiDivider-wrapper':
                                                                                {
                                                                                    color: '#9198AA',
                                                                                },
                                                                        }}
                                                                    >
                                                                        {format(
                                                                            ev
                                                                                .date
                                                                                .start,
                                                                            'HH:mm'
                                                                        )}
                                                                    </Divider>
                                                                )}
                                                                <EventItem
                                                                    bookingId={
                                                                        bookingId
                                                                    }
                                                                    isSelected={
                                                                        eventId ===
                                                                        ev.id
                                                                    }
                                                                    event={ev}
                                                                    refetch={
                                                                        refetch
                                                                    }
                                                                />
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            );
                                        }
                                    )}
                                    {hasNextPage && <EventItemSkeleton />}
                                </Stack>
                            ) : (
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        height: 1,
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CalendarMonthIcon
                                        sx={{
                                            fontSize: '164px',
                                            color: '#949BAC',
                                        }}
                                    />
                                    <Typography sx={{ color: '#949BAC' }}>
                                        No events found in the given range
                                    </Typography>
                                </Stack>
                            )}
                        </PerfectScrollbar>
                    )}
                </Box>
                {isLoading && (
                    <>
                        <Divider>
                            <Skeleton width={100} />
                        </Divider>

                        <Box
                            sx={{
                                py: 2,
                                bgcolor: '#F8F9FA',
                                height: 'calc(100% - 72px - 21px)',
                            }}
                        >
                            <Stack
                                sx={{
                                    px: 2,
                                }}
                                gap={1}
                            >
                                {[0, 1, 2].map(v => (
                                    <EventItemSkeleton key={v} />
                                ))}
                            </Stack>
                        </Box>
                    </>
                )}
            </Box>
            <Box
                sx={{
                    bgcolor: '#FFF',
                    my: 2,
                    ml: 2,
                    width: '483px',
                    minWidth: '483px',
                    height: 'calc(100% - 32px)',
                    ...(!eventId
                        ? {
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: 2,
                          }
                        : {}),
                }}
            >
                {!eventId && (
                    <>
                        <PreviewSvg />
                        <Typography sx={{ color: '#949BAC' }}>
                            No event is selected for preview
                        </Typography>
                    </>
                )}
                {event && !bookingItem && (
                    <EventEditForm
                        event={event}
                        // updateEvent={updateEvent}
                        // isLoading={false}
                    />
                )}
                {bookingItem && (
                    <BookingEditForm
                        siblingBookings={[]}
                        route={event?.rawDto.operations.route}
                        booking={bookingItem}
                        updateBooking={refetch}
                        closeForm={() => navigate('/')}
                    />
                )}
            </Box>
        </Stack>
    );
};

export default Events;
