import React from 'react';
import { Paper, Stack } from '@mui/material';
import { Switch, TextArea } from '@travelity/form';
import { useFormContext } from 'react-hook-form';
import { ProductCapacityItem, ProductScheduleItem } from '@travelity/api';
import { ReadonlyTextarea } from '../../../readonly-textarea';
import { PricingList } from '../pricing-list';

export interface OptionCardContentProps {
    item: Record<string, any>;
    editing: boolean;
    capacities: ProductCapacityItem[];
    schedules: ProductScheduleItem[];
}

const OptionCardContent: React.FC<OptionCardContentProps> = props => {
    const { item, editing, capacities, schedules } = props;
    const { watch } = useFormContext();

    return (
        <Stack sx={{ p: 2 }} gap={1}>
            {editing ? (
                <TextArea
                    name="description"
                    fullWidth
                    label="Description"
                    placeholder="Type Description"
                    showErrors
                    helperText=""
                />
            ) : (
                <ReadonlyTextarea content={item.description} />
            )}

            {editing && (
                <>
                    <Paper sx={{ py: 1, px: 2, maxWidth: '388px' }}>
                        <Switch
                            name="hasPricing"
                            label="Has Pricing?"
                            LabelProps={{ sx: { color: '#2B395B' } }}
                        />
                    </Paper>
                    {watch('hasPricing') && (
                        <PricingList
                            item={item}
                            editing
                            capacities={capacities}
                            schedules={schedules}
                        />
                    )}
                </>
            )}

            {!editing && item.hasPricing && (
                <PricingList
                    item={item}
                    editing={false}
                    capacities={capacities}
                    schedules={schedules}
                />
            )}
        </Stack>
    );
};

export default OptionCardContent;
