import React from 'react';
import TimelineDot from '@mui/lab/TimelineDot';

export interface RouteStopProps {
    selected: boolean;
}

export const RouteStopIcon: React.FC<RouteStopProps> = ({ selected }) => {
    return (
        <TimelineDot
            color={selected ? 'grey' : 'primary'}
            sx={{
                p: '2px',
                mt: '4px',
                mb: '1.5px',
                boxShadow: 0,
                borderColor: selected ? 'gray' : '#55B5CF',
                borderWidth: '1px',
                color: '#FFFFFF',
                backgroundColor: '#FFF',
                padding: '6px',
            }}
        />
    );
};
