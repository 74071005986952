/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum OrderStatus {
    OPEN = 'open',
    CONFIRMED = 'confirmed',
    CANCELLED = 'cancelled',
}
