/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateProductReqAccessGroupsItemReferenceDto = {
    /**
     * Type of the user group
     */
    type: CreateProductReqAccessGroupsItemReferenceDto.type;
    /**
     * Type of the capacity of the product
     */
    id?: string;
};

export namespace CreateProductReqAccessGroupsItemReferenceDto {

    /**
     * Type of the user group
     */
    export enum type {
        INDIVIDUAL = 'individual',
    }


}
