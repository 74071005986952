/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BirthDetailsData } from './BirthDetailsData';
import type { Language } from './Language';
import type { Passport } from './Passport';
import type { PersonNameData } from './PersonNameData';
import type { UpdateCustomerReqAccessDto } from './UpdateCustomerReqAccessDto';
import type { UpdateUserReqProfileAttachmentsItemDto } from './UpdateUserReqProfileAttachmentsItemDto';
import type { UpdateUserReqProfileContactDetailsDto } from './UpdateUserReqProfileContactDetailsDto';

export type UpdateCustomerReqDto = {
    /**
     * Type of the resource
     */
    resource_type?: UpdateCustomerReqDto.resource_type;
    /**
     * Profile's (person, company) contact details.
     */
    contact_details?: UpdateUserReqProfileContactDetailsDto;
    /**
     * List of paths in the storage where the passport scans/uploads are.
     */
    attachments?: Array<UpdateUserReqProfileAttachmentsItemDto>;
    /**
     * Person's name, separated into components.
     */
    name?: PersonNameData;
    /**
     * Birth details of the person.
     */
    birth_details?: BirthDetailsData;
    /**
     * Passport details of the person.
     */
    passport?: Passport;
    /**
     * List of languages the traveler speaks/understands.
     */
    languages?: Array<Language>;
    /**
     * Access to the shared product
     */
    access?: UpdateCustomerReqAccessDto;
};

export namespace UpdateCustomerReqDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        CUSTOMER = 'customer',
    }


}
