import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from '@travelity/form';
import { AddProductForm, ProductRoute } from '@travelity/api';
import { Box } from '@mui/material';
import { ProductRouteForm } from '../../../components/product-route-form';
import schema from '../../../components/product-route-form/product-route-form.schema';
import StepContent from './step-content';
import { useStepperContext } from '../../../components/stepper';
import { useHelperContext } from '../../../contexts/helper-context';

export interface ProductRouteStepProps {}

const ProductRouteStep: React.FC<ProductRouteStepProps> = () => {
    const { state, next, showErrors } =
        useStepperContext<Pick<AddProductForm, 'productInfo' | 'route'>>();
    const onSubmit = useCallback((data: ProductRoute) => {
        next({ ...state, route: data });
    }, []);
    const { Form, formState, watch } = useForm<ProductRoute>({
        onSubmit,
        schema,
        mode: 'onChange',
        validateInitially: true,
        defaultValues: state.route,
    });
    const errors = useMemo(() => {
        return Object.values(formState.errors)
            .map(error => error?.message)
            .filter(v => v) as string[];
    }, [formState]);

    // Handle helper texts
    const { setInfo } = useHelperContext();

    useEffect(() => {
        const callback = () => {
            setInfo(
                <>
                    <Box sx={{ fontWeight: 'bold' }}>Beginning of Route</Box>
                    Any name/address that will describe the location where the
                    route starts as precisely as possible
                </>
            );
        };
        const el = document.querySelector('input[name=beginningLocation]');
        el?.addEventListener('focus', callback);
        return () => el?.removeEventListener('focus', callback);
    }, []);

    // const repeatLocation = watch('repeatLocation');
    // useEffect(() => {
    //     const callback = () => {
    //         setInfo(
    //             <>
    //                 <Box sx={{ fontWeight: 'bold' }}>End of Route</Box>
    //                 Any name/address that will describe the location where the
    //                 route ends as precisely as possible. In case of a round
    //                 trip, Travelity will use the “Beginning of Route“ for this
    //                 value.
    //             </>
    //         );
    //     };
    //     const el = document.querySelector('input[name=endingLocation]');
    //     el?.addEventListener('focus', callback);
    //     return () => el?.removeEventListener('focus', callback);
    // }, [repeatLocation]);

    const stops = watch('stops');
    useEffect(() => {
        if (stops?.length) {
            setInfo(
                <>
                    <Box sx={{ fontWeight: 'bold' }}>Stops</Box>
                    Stops are usually the main destinations of your tour, places
                    to be visited.
                    <br />
                    You can optionally define how long after the tour’s start
                    traveler(s) will reach the destination (Arrival Offset) and
                    how long they will stay in that destintation (Stop Duration)
                </>
            );
        }
    }, [stops]);

    return (
        <StepContent title="Route" errors={errors}>
            <Form id="step">
                <ProductRouteForm showErrors={showErrors} />
            </Form>
        </StepContent>
    );
};

export default React.memo(ProductRouteStep);
