/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DeleteProductResLastUpdatedDto } from './DeleteProductResLastUpdatedDto';

export type DeleteProductResDto = {
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: DeleteProductResLastUpdatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted: DeleteProductResLastUpdatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<DeleteProductResLastUpdatedDto>;
    /**
     * Name of the product.
     */
    name: string;
    /**
     * Type of the product
     */
    type: DeleteProductResDto.type;
};

export namespace DeleteProductResDto {

    /**
     * Type of the product
     */
    export enum type {
        TOUR = 'tour',
    }


}
