import * as React from 'react';
import MuiButton from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, LoadingOverlay } from '@travelity/ui';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface ConfirmationDialogProps {
    handleCancel: () => void;
    handleReserve: () => void;
    handleHold: () => void;
    isLoading?: boolean;
    open: boolean;
    disableHold?: boolean;
    disableReserve?: boolean;
}

const HoldAvailabilityDialog: React.FC<ConfirmationDialogProps> = ({
    handleCancel,
    handleReserve,
    handleHold,
    isLoading,
    open,
    disableHold,
    disableReserve,
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            PaperProps={{
                sx: {
                    py: 1,
                    bgcolor: '#FFF',
                    maxWidth: 450,
                    position: 'relative',
                },
            }}
        >
            <DialogTitle
                sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    fontWeight: 600,
                    color: '#2B395B',
                    mr: 2,
                }}
                id="alert-dialog-title"
            >
                Please, select one of the following actions for the booking
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleCancel}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: theme => theme.palette.grey[400],
                }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
            {isLoading && (
                <LoadingOverlay
                    title="Please wait..."
                    subTitle="It’ll just take a moment."
                />
            )}
            <DialogActions sx={{ px: 3, py: 2 }}>
                <MuiButton
                    color="neutral"
                    variant="contained"
                    disabled={!!disableHold || !!isLoading}
                    onClick={handleHold}
                >
                    Hold availability
                </MuiButton>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleReserve}
                    disabled={!!disableReserve || !!isLoading}
                    autoFocus
                    errorMessage={
                        disableReserve
                            ? 'At least one traveler needs to be registered'
                            : undefined
                    }
                >
                    Reserve
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HoldAvailabilityDialog;
