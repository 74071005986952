import React, {
    forwardRef,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    format,
    startOfWeek,
    addDays,
    startOfMonth,
    endOfMonth,
    endOfWeek,
    isSameDay,
} from 'date-fns';
import { Box, Stack } from '@mui/material';
import { useResizeRef } from '../../hooks';

export interface CalendarProps {
    month: Date;
    render: (o: {
        day: number;
        date: Date;
        active: boolean;
        isToday: boolean;
    }) => ReactNode;
}

const getWeekDaysNames = (month: Date) => {
    const weekStartDate = startOfWeek(month, { weekStartsOn: 1 });
    const weekDays = [];
    for (let day = 0; day < 7; day += 1) {
        weekDays.push(format(addDays(weekStartDate, day), 'E'));
    }
    return weekDays;
};

const generateDatesForCurrentWeek = (date: Date) => {
    let currentDate = date;
    const week = [];
    for (let day = 0; day < 7; day += 1) {
        week.push(currentDate);
        currentDate = addDays(currentDate, 1);
    }
    return week;
};

const today = new Date();

const Calendar = forwardRef<HTMLDivElement, CalendarProps>(
    ({ month, render }, ref) => {
        const { start, end } = useMemo(
            () => ({
                start: startOfMonth(month),
                end: endOfMonth(month),
            }),
            [month]
        );

        const monthString = format(month, 'MMMM');

        const dates = useMemo(() => {
            const startDate = startOfWeek(start, { weekStartsOn: 1 });
            const endDate = endOfWeek(end, { weekStartsOn: 1 });

            let currentDate = startDate;

            const allWeeks = [];

            while (currentDate <= endDate) {
                allWeeks.push(generateDatesForCurrentWeek(currentDate));
                currentDate = addDays(currentDate, 7);
            }

            return allWeeks;
        }, [start, end]);

        const [singleItem, setSingleItem] = useState<{
            width: number;
            height: number;
        }>({ width: 0, height: 0 });
        const onResize = useCallback(
            (w: number, h: number) => {
                setSingleItem({
                    width: w / 7 - 2,
                    height: h / dates.length - 2,
                });
            },
            [dates]
        );

        const containerRef = useResizeRef(onResize, 0);

        return (
            <Box
                ref={ref}
                className="calendar-container"
                sx={{
                    height: 'calc(100% - 112px)',
                    position: 'relative',
                    py: 2,
                }}
            >
                <Stack sx={{ height: '28px' }} direction="row" spacing="2px">
                    {getWeekDaysNames(month).map(wDay => (
                        <Box
                            sx={{
                                width: singleItem.width,
                                fontSize: '14px',
                                lineHeight: '24px',
                                textTransform: 'uppercase',
                            }}
                        >
                            {wDay}
                        </Box>
                    ))}
                </Stack>
                <Stack
                    sx={{
                        height: 'calc(100% - 28px)',
                        transition: 'width 2s ease',
                    }}
                    spacing="2px"
                    className="month-container"
                    ref={containerRef}
                >
                    {dates.map(row => (
                        <Stack
                            key={row[0].getTime()}
                            direction="row"
                            spacing="2px"
                            sx={{
                                height: singleItem.height,
                            }}
                        >
                            {row.map(currentDate => (
                                <Box
                                    key={monthString + currentDate.getTime()}
                                    sx={{
                                        width: singleItem.width,
                                        position: 'relative',
                                        minWidth: 0,
                                    }}
                                >
                                    {render({
                                        day: parseInt(
                                            format(currentDate, 'd'),
                                            10
                                        ),
                                        date: currentDate,
                                        isToday: isSameDay(currentDate, today),
                                        active:
                                            currentDate >= start &&
                                            currentDate < end,
                                    })}
                                </Box>
                            ))}
                        </Stack>
                    ))}
                </Stack>
            </Box>
        );
    }
);

export default Calendar;
