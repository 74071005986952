import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { AgeBands, EventItem as IEventItem } from '@travelity/api';
import { Card, Tag, Heading } from '@travelity/ui';

import { useTranslation } from 'react-i18next';
import CircleIcon from '@mui/icons-material/Circle';
import { has } from 'lodash';
import { EventStatus } from '../event-status';
import { ProductTypeIcon } from '../product-type-icon';
import { EventEnd } from '../event-end/event-end';

export interface EventItemMinimalProps {
    event: IEventItem;
    selected: boolean;
    onSelect: () => void;
}

const EventItemMinimal: React.FC<EventItemMinimalProps> = ({
    event,
    selected,
    onSelect,
}) => {
    const { t } = useTranslation('product');

    return (
        <Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                    mt: 0.5,
                    mb: 1,
                    ml: 4,
                    minWidth: 0,
                }}
            >
                <Stack
                    direction="row"
                    gap={1.5}
                    alignItems="center"
                    sx={{
                        minWidth: 0,
                    }}
                >
                    <Heading
                        sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                            pr: 1,
                        }}
                        alpha={0.7}
                        ellipsis
                    >
                        {event.product.name}
                    </Heading>
                    <CircleIcon sx={{ fontSize: '8px', color: '#D9D9D9' }} />
                    <ProductTypeIcon type={event.product.type} />
                    <Typography
                        sx={{
                            ml: '-8px',
                            color: '#6B748C',
                            fontSize: '12px',
                            fontWeight: '500',
                            whiteSpace: 'nowrap',
                            pr: 1.5,
                        }}
                    >
                        {t(event.product.type)}
                    </Typography>
                </Stack>
                <Stack direction="row" gap={1}>
                    {!event.id && (
                        <Tag
                            value="New"
                            valueProps={{ sx: { bgcolor: '#CDEFDB' } }}
                        />
                    )}
                    <EventStatus status={event.status} />
                    <EventEnd date={event.date} />
                </Stack>
            </Stack>

            <Card
                parentProps={{ alignItems: 'center' }}
                leftAdornment={
                    <Stack justifyContent="center">
                        <Box
                            onClick={onSelect}
                            sx={{
                                mr: '14px',
                                cursor: 'pointer',
                                width: '18px',
                                height: '18px',
                                borderStyle: 'solid',
                                borderWidth: selected ? '5px' : '2px',
                                borderColor: selected ? '#6B748C' : '#B5B9C5',
                                borderRadius: '50%',
                            }}
                        />
                    </Stack>
                }
            >
                <Stack
                    direction="row"
                    gap={1}
                    sx={{ p: 2 }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography sx={{ color: '#B7BCC7', fontSize: '12px' }}>
                            Capacity
                        </Typography>
                        {!!event.availableSeats && (
                            <Tag
                                label="Available Seats"
                                value={`${event.availableSeats}`}
                                valueProps={{
                                    sx: { backgroundColor: '#CDEFDB' },
                                }}
                            />
                        )}
                    </Stack>
                    <Stack direction="row" gap={1} alignItems="center">
                        {has(event.capacitySelection.capacity, 'max_count') ? (
                            <>
                                <Tag
                                    label="Min Count"
                                    values={[
                                        // @ts-ignore
                                        `${event.capacitySelection.capacity.min_count}`,
                                    ]}
                                />
                                <Tag
                                    label="Max Count"
                                    values={[
                                        // @ts-ignore
                                        `${event.capacitySelection.capacity.max_count}`,
                                    ]}
                                />
                            </>
                        ) : (
                            Object.values(AgeBands).map(
                                name =>
                                    // @ts-ignore
                                    !!event.capacitySelection.capacity[
                                        name
                                    ] && (
                                        <Tag
                                            label={t(name)}
                                            values={[
                                                // @ts-ignore
                                                event.capacitySelection
                                                    .capacity[name],
                                            ]}
                                        />
                                    )
                            )
                        )}

                        <Typography
                            sx={{
                                borderLeft: '1px solid #C9CEDC',
                                pl: 1.5,
                                color: '#B7BCC7',
                                fontSize: '12px',
                            }}
                        >
                            {event.capacitySelection.capacity.name}
                        </Typography>
                    </Stack>
                </Stack>
            </Card>
        </Box>
    );
};

export default EventItemMinimal;
