/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EventStatus } from './EventStatus';
import type { GetEventsResItem0AccessDto } from './GetEventsResItem0AccessDto';
import type { GetEventsResItem0OperationsDto } from './GetEventsResItem0OperationsDto';
import type { GetEventsResItem0ProductDto } from './GetEventsResItem0ProductDto';
import type { GetTeamsResItemCreatedDto } from './GetTeamsResItemCreatedDto';
import type { PeriodData } from './PeriodData';

export type GetEventsResItem0Dto = {
    /**
     * Type of the resource
     */
    resource_type: GetEventsResItem0Dto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetTeamsResItemCreatedDto;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetTeamsResItemCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle?: Array<GetTeamsResItemCreatedDto>;
    /**
     * Product selected for the event
     */
    product: GetEventsResItem0ProductDto;
    /**
     * Timestamp which represents date and time of the event.
     */
    date: PeriodData;
    /**
     * Event operations
     */
    operations?: GetEventsResItem0OperationsDto;
    /**
     * Automatically computed status of the booking.
     */
    status: EventStatus;
    /**
     * Access to the shared product
     */
    access: GetEventsResItem0AccessDto;
};

export namespace GetEventsResItem0Dto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        EVENT = 'event',
    }


}
