import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Stack } from '@mui/material';
import { AgebandName, BookingProduct } from '@travelity/api';
import { SingleLineKeyValue } from '../booking-participants/components/single-line-key-value';
import { Route } from '../route';

export interface BookingProductFormProps {
    product: Omit<BookingProduct, 'financials'> &
        Partial<Pick<BookingProduct, 'financials'>>;
    preview?: boolean;
}

const BookingProductForm: React.FC<BookingProductFormProps> = ({
    preview,
    product,
}) => {
    const { t } = useTranslation('product');
    const { name, type, financials } = product;
    const { base_amount: base, ...other } = financials?.pricing?.price || {};

    const routes = useMemo(() => {
        const list = [product.route.beginning_of_route.name];

        product.route.stops?.forEach(stop => {
            list.push(stop.location.name);
        });

        list.push(
            product.route.end_of_route.name ||
                product.route.beginning_of_route.name
        );
        return list;
    }, [product]);

    const prices: { price: number; label: string }[] = [];
    Object.values(AgebandName).forEach((k: AgebandName) => {
        // @ts-ignore
        const p = other[k];
        if (p) {
            prices.push({
                // eslint-disable-next-line no-restricted-globals
                price: isNaN(p) ? p.unitPrice : p,
                label: k,
            });
        }
    });

    // @ts-ignore
    return preview ? (
        <Stack spacing={2}>
            <SingleLineKeyValue dark label="Type" value={t(type)} />
            <Divider />
            <SingleLineKeyValue dark label="Name" value={name} />
            <Divider>Route</Divider>
            <Route
                color="grey"
                stops={routes}
                timelineProps={{
                    sx: { pl: '2px' },
                }}
            />
        </Stack>
    ) : (
        <Stack spacing={3} />
    );
};

export default React.memo(BookingProductForm);
