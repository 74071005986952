import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';

export interface SummaryProps {
    currency: string;
    before: number;
    after: number;
    discountAmount: number;
    discountType: 'absolute' | 'relative';
    showDiscountAmount?: boolean;
    isLoading: boolean;
}

function numberWithSpaces(x: number) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

export const Summary: React.FC<SummaryProps> = props => {
    const {
        before,
        after,
        showDiscountAmount,
        discountAmount,
        discountType,
        currency,
        isLoading,
    } = props;

    const discountFormatted =
        discountType === 'absolute'
            ? `${numberWithSpaces(discountAmount)} ${currency}`
            : `${discountAmount}%`;

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ mb: 1 }}
            >
                <Box
                    sx={{
                        color: '#9198AA',
                        fontSize: '14px',
                        fontWeight: '400',
                    }}
                >
                    Total Before Discount
                </Box>
                <Box
                    sx={{
                        color: '#2B395B',
                        textAlign: 'right',
                        fontSize: '14px',
                        fontWeight: '400',
                    }}
                >
                    {isLoading ? (
                        <Skeleton width={60} />
                    ) : (
                        `${numberWithSpaces(before)} ${currency}`
                    )}
                </Box>
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ mb: 1 }}
            >
                <Box
                    sx={{
                        color: '#9198AA',
                        fontSize: '14px',
                        fontWeight: '400',
                    }}
                >
                    Discount
                </Box>
                <Box
                    sx={{
                        color: '#2B395B',
                        textAlign: 'right',
                        fontSize: '14px',
                        fontWeight: '400',
                    }}
                >
                    {isLoading ? <Skeleton width={60} /> : discountFormatted}
                </Box>
            </Stack>
            {showDiscountAmount && (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ mb: 1 }}
                >
                    <Box
                        sx={{
                            color: '#9198AA',
                            fontSize: '14px',
                            fontWeight: '400',
                        }}
                    >
                        Discounted Amount
                    </Box>
                    <Box
                        sx={{
                            color: '#2B395B',
                            textAlign: 'right',
                            fontSize: '14px',
                            fontWeight: '400',
                        }}
                    >
                        {isLoading ? (
                            <Skeleton width={60} />
                        ) : (
                            `${numberWithSpaces(before - after)} ${currency}`
                        )}
                    </Box>
                </Stack>
            )}
        </>
    );
};
