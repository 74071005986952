import React, { useCallback, useMemo } from 'react';
import { useForm } from '@travelity/form';
import { ProductConfigurationForm } from '../../../components/product-configuration';
import StepContent from './step-content';
import { useStepperContext } from '../../../components/stepper';
import { AddProductFormState } from '../add-product.types';

export interface ProductConfigurationStepProps {}

const ProductConfigurationStep: React.FC<
    ProductConfigurationStepProps
> = () => {
    const { state, next } = useStepperContext<AddProductFormState>();
    const onSubmit = useCallback((data: Record<string, any>) => {
        // we can have event as a second parameter if we want to handle back as well
        next({ ...state, configuration: data });
    }, []);
    const { Form, formState } = useForm({
        onSubmit,
        mode: 'onChange',
        validateInitially: true,
        defaultValues: state.configuration,
    });
    const errors = useMemo(() => {
        return Object.values(formState.errors)
            .map(error => error?.message)
            .filter(v => v) as string[];
    }, [formState]);

    return (
        <StepContent title="Product Configuration" errors={errors}>
            <Form id="step">
                <ProductConfigurationForm />
            </Form>
        </StepContent>
    );
};

export default React.memo(ProductConfigurationStep);
