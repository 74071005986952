import { Box, Divider, Stack } from '@mui/material';
import { CreatableTags, DatePicker, Select, TextField } from '@travelity/form';
import { convertEnumToList, emailRefEx } from '@travelity/utils';
import React, { useMemo } from 'react';
import Button from '@mui/material/Button';
import { Country, Nationality } from '@travelity/api';
import { ReactComponent as Attachments } from '../assets/attachments.svg';
import { ReactComponent as ProfilePic } from '../assets/profile-pic.svg';

export interface PersonalInfoProps {
    onSkip: () => void;
}

export const PersonalInfo: React.FC<PersonalInfoProps> = props => {
    const { onSkip } = props;
    const countries = useMemo(() => convertEnumToList<Country>(Country), []);
    const nationalities = useMemo(
        () => convertEnumToList<Nationality>(Nationality),
        []
    );

    return (
        <Stack direction="row" gap={5}>
            <Stack sx={{ width: '244px' }}>
                <ProfilePic />
            </Stack>
            <Stack sx={{ width: '404px', p: 1 }} gap={2}>
                <Box>
                    <Divider>Contact Details</Divider>
                </Box>
                <CreatableTags
                    name="emails"
                    leaveLast
                    regEx={emailRefEx}
                    inputProps={{
                        sx: {
                            '&& .MuiInputBase-root': {
                                paddingRight: '10px',
                            },
                        },
                        label: 'Email',
                        placeholder: 'Type email and press enter',
                    }}
                />
                <CreatableTags
                    name="numbers"
                    regEx={/^\+?\d+ \d+$/}
                    inputProps={{
                        sx: {
                            '&& .MuiInputBase-root': {
                                paddingRight: '10px',
                            },
                        },
                        label: 'Phone Numbers',
                        placeholder: 'Type number and press enter',
                    }}
                />
                <Divider sx={{ mt: 1.5 }}>Personal Information</Divider>
                <DatePicker
                    label="Date of Birth"
                    name="birthDate"
                    TextFieldProps={{
                        helperText: '',
                        placeholder: 'Select date of birth',
                    }}
                />
                <TextField
                    label="Place of Birth"
                    name="birthPlace"
                    placeholder="Type Place of Birth"
                />
                <Select
                    label="Nationality"
                    name="nationality"
                    placeholder="Select Nationality"
                    options={nationalities}
                />
                <Divider sx={{ mt: 1.5 }}>Identification Document</Divider>
                <TextField
                    label="Passport №"
                    name="passportN"
                    placeholder="AA0000000"
                />
                <TextField
                    label="Issued by"
                    name="issuedBy"
                    placeholder="000"
                />
                <Stack direction="row" gap={1}>
                    <DatePicker
                        label="Issued at"
                        name="issuedAt"
                        TextFieldProps={{
                            helperText: '',
                            placeholder: 'Select date',
                        }}
                    />
                    <DatePicker
                        label="Expires at"
                        name="expiresAt"
                        TextFieldProps={{
                            helperText: '',
                            placeholder: 'Select date',
                        }}
                    />
                </Stack>
                <Select
                    label="Citizenship"
                    name="citizenship"
                    placeholder="Select Citizenship"
                    options={countries}
                />
                <Stack direction="row" spacing={1}>
                    <Button
                        color="neutral"
                        variant="contained"
                        onClick={onSkip}
                        sx={{
                            px: 10,
                            py: 1,
                            '&&&': { pointerEvents: 'initial' },
                        }}
                    >
                        Skip
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        sx={{
                            px: 10,
                            py: 1,
                            '&&&': { pointerEvents: 'initial' },
                        }}
                    >
                        Continue
                    </Button>
                </Stack>
            </Stack>
            <Box sx={{ p: 1, width: '260px' }}>
                <Divider>Attachments</Divider>
                <Attachments />
            </Box>
        </Stack>
    );
};
