import { object, string, number } from 'yup';

const schema = object({
    make: string().required(),
    model: string().required(),
    licensePlate: string().required(),
    year: number().required(),
    mileageStart: number().required(),
    maxPassengers: number().required(),
    fuelType: string().required(),
});

export default schema;
