/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AttendanceType {
    PRESENCE = 'presence',
    ABSENCE = 'absence',
}
