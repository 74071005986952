import { useController, useFormContext } from 'react-hook-form';
import {
    StaticDatePicker as MuiStaticDatePicker,
    StaticDatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers/StaticDatePicker';
import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
    PickersDay as MuiPickersDay,
    PickersDayProps,
} from '@mui/x-date-pickers';
import { format, startOfDay } from 'date-fns';
import { IconButton, InputAdornment } from '@mui/material';
import { TextField, TextFieldProps as TextFieldPropsType } from '../text-field';

export type StaticDatePickerProps = MuiDatePickerProps<Date> & {
    showInput?: boolean;
    name: string;
    multiple?: boolean;
    label?: string;
    required?: boolean;
    showErrors?: boolean;
    TextFieldProps?: Partial<TextFieldPropsType>;
};

const findIndexDate = (dates: Date[], date: Date) => {
    const dateTime = date.getTime();
    return dates?.findIndex(item => item.getTime() === dateTime);
};

const PickerDay: React.FC<PickersDayProps<Date>> = props => {
    // @ts-ignore
    const { values, onChange, onDaySelect, ...rest } = props;
    const dateTime = rest.day.getTime();
    const selected = !!values?.find(
        (item: Date | undefined) => item?.getTime() === dateTime
    );
    const onDaySelectCustom = useCallback(
        (v: Date) => {
            // @ts-ignore
            onChange?.(v);
            onDaySelect(v);
        },
        [onChange, onDaySelect]
    );
    return (
        <MuiPickersDay
            {...rest}
            onDaySelect={onDaySelectCustom}
            selected={selected}
        />
    );
};

const StaticDatePicker: React.FC<StaticDatePickerProps> = ({
    name,
    required,
    multiple,
    label,
    showInput,
    TextFieldProps = {},
    showErrors,
    ...rest
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
        defaultValue: multiple ? [] : undefined,
    });

    const [open, setOpen] = useState(false);

    const error = showErrors ? _.get(errors, name)?.message : undefined;

    return (
        <>
            {showInput && (
                <TextField
                    fullWidth
                    value={
                        multiple
                            ? value
                                  .map((d: Date) => format(d, 'd MMM, y'))
                                  .join(', ')
                            : value
                            ? format(value, 'd MMM, y')
                            : ''
                    }
                    label={label}
                    error={!!error}
                    helperText={error as string}
                    {...TextFieldProps}
                    name={name}
                    InputProps={{
                        sx: { '&&&&': { pr: 1 } },
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    size={TextFieldProps.size}
                                    onClick={() => setOpen(!open)}
                                >
                                    <CalendarMonthIcon
                                        sx={{
                                            color: '#B2B9CD',
                                            fontSize:
                                                TextFieldProps.size === 'small'
                                                    ? '20px'
                                                    : '24px',
                                        }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {(!showInput || open) && (
                <MuiStaticDatePicker
                    value={multiple ? undefined : value}
                    {...rest}
                    sx={{
                        '& input': {
                            width: '204px',
                        },
                        '& .MuiPickersToolbar-root': {
                            display: 'none',
                        },
                        '& .MuiDialogActions-root': {
                            display: 'none',
                        },
                        '& .MuiDayCalendar-weekContainer': {
                            justifyContent: 'space-between',
                        },
                        '& .MuiDayCalendar-header': {
                            justifyContent: 'space-between',
                        },
                        ...(rest.sx || {}),
                        // '& .MuiDayCalendar-monthContainer': {
                        //     position: 'initial',
                        // },
                        // '& .MuiPickersSlideTransition-root': {
                        //     minHeight: 'auto',
                        // },
                    }}
                    slots={{
                        day: PickerDay,
                    }}
                    slotProps={_.defaultsDeep(
                        {
                            textField: {
                                InputLabelProps: { required: !!required },
                            },
                            day: {
                                values: multiple ? value : [value],
                                onChange: (newValue?: Date) => {
                                    if (!newValue) return;
                                    if (!multiple) {
                                        onChange(newValue);
                                    } else {
                                        const array = [...value];
                                        const date = startOfDay(newValue);
                                        const index = findIndexDate(
                                            array,
                                            date
                                        );
                                        if (index >= 0) {
                                            array.splice(index, 1);
                                        } else {
                                            array.push(date);
                                        }
                                        onChange(array);
                                    }
                                },
                                sx: {
                                    '&:active': {
                                        backgroundColor: 'primary.main',
                                    },
                                    '&:focus': {
                                        backgroundColor: 'transparent',
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: 'primary.main',

                                        '&:focus': {
                                            backgroundColor: 'primary.main',
                                        },
                                        '&:hover': {
                                            backgroundColor: 'primary.main',
                                        },
                                    },
                                    '&.MuiPickersDay-today': {
                                        border: 0,
                                        backgroundColor: 'transparent',
                                    },
                                },
                            },
                        },
                        rest.slotProps
                    )}
                />
            )}
        </>
    );
};

export default StaticDatePicker;
