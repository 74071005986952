import React, { useEffect } from 'react';
import { xor } from 'lodash';
import { Box, Stack, Typography } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Booking } from '@travelity/api';

import BookingItemMinimal from '../../../components/booking-item-minimal/booking-item-minimal';

export interface SiblingBookingListProps {
    selected: string[];
    setSelected: (v: string[]) => void;
    bookings: Booking[];
}

const SiblingBookingList: React.FC<SiblingBookingListProps> = ({
    selected,
    setSelected,
    bookings,
}) => {
    useEffect(() => {
        setSelected([]);
    }, []);

    return (
        <Box
            sx={{
                p: 2,
                height: 'calc(100% - 76px)',
                '& > div': {
                    maxHeight: 'calc(100vh - 284px)',
                },
            }}
        >
            <Typography sx={{ fontWeight: 600 }}>
                Select bookings which will use this travellers&apos; list
            </Typography>
            <PerfectScrollbar>
                <Stack
                    sx={{
                        pr: 0.5,
                        pb: 1,
                    }}
                    gap={1}
                >
                    {bookings.map(booking => (
                        <BookingItemMinimal
                            key={booking.id}
                            booking={booking}
                            isSelected={selected.includes(booking.id)}
                            onSelect={() =>
                                setSelected(xor(selected, [booking.id]))
                            }
                        />
                    ))}
                </Stack>
            </PerfectScrollbar>
        </Box>
    );
};

export default SiblingBookingList;
