/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserOccupation {
    GUIDE = 'guide',
    DRIVER = 'driver',
    TOUR_AGENT = 'tour_agent',
    ASSOCIATE = 'associate',
}
