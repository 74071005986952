/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OrderStatus } from './OrderStatus';
import type { UpdateBookingReqProductCustomersItemReferenceDto } from './UpdateBookingReqProductCustomersItemReferenceDto';
import type { UpdateOrderReqAccessDto } from './UpdateOrderReqAccessDto';
import type { UpdateOrderReqPriceDto } from './UpdateOrderReqPriceDto';

export type UpdateOrderReqDto = {
    /**
     * Type of the resource
     */
    resource_type?: UpdateOrderReqDto.resource_type;
    /**
     * Information about the customer who booked the event.
     */
    customer?: UpdateBookingReqProductCustomersItemReferenceDto;
    /**
     * Price information.
     */
    price?: UpdateOrderReqPriceDto;
    /**
     * Status of the order
     */
    status?: OrderStatus;
    /**
     * Access to the shared product
     */
    access?: UpdateOrderReqAccessDto;
};

export namespace UpdateOrderReqDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        ORDER = 'order',
    }


}
