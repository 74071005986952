import { useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useGetSchedulesLazy, useUpdateCapacityProducts1 } from '../../queries';

import {
    productScheduleToScheduleDto,
    scheduleDtoToScheduleItem,
} from './schedule.converters';
import { CustomMutationOptions } from '../common.types';
import { DurationValue, ProductScheduleItem } from '../product/product.types';
import {
    compareTimeOffset,
    durationToDurationDto,
} from '../product/product.converters';
import { ScheduleItem } from './schedule.types';
import { convertItemsToActionItemsDto } from '../common.converters';

type UseGetSchedulesLazyParamTypes = Parameters<typeof useGetSchedulesLazy>;
export const useSchedulesLazy = (
    params: UseGetSchedulesLazyParamTypes[0] = {},
    options: UseGetSchedulesLazyParamTypes[2] = {}
) => {
    const queryKey = ['OrdersServiceGetOrdersLazy', params];
    const { data, ...other } = useGetSchedulesLazy(params, queryKey, options);

    const parsedData = useMemo(
        () =>
            data?.pages
                ? data.pages
                      .map(page =>
                          (page.items || []).map(scheduleDtoToScheduleItem)
                      )
                      .reduce((arr, cur) => [...arr, ...cur], [])
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

export const useUpdateProductSchedules = (
    options: CustomMutationOptions<{
        items: (ProductScheduleItem | ScheduleItem)[];
        duration?: DurationValue;
        swing?: string;
    }>,
    productId: string,
    oldItems: ProductScheduleItem[],
    oldDurationValue: DurationValue,
    oldSwing?: string
) => {
    const { mutateAsync: updateSchedules } = useUpdateCapacityProducts1();

    return useMutation(async ({ items, duration, swing }) => {
        const oldDuration = durationToDurationDto(oldDurationValue, oldSwing);
        const newDuration = durationToDurationDto(duration, swing);
        const requestItems = convertItemsToActionItemsDto(
            oldItems.map(s => productScheduleToScheduleDto(s, oldDuration)),
            items.map(s => productScheduleToScheduleDto(s, newDuration)),
            'reference.id',
            (oldItem, newItem) =>
                !compareTimeOffset(
                    oldItem.duration?.estimated,
                    newItem.duration?.estimated
                ) ||
                !compareTimeOffset(
                    oldItem.duration?.deviation,
                    newItem.duration?.deviation
                )
        );

        if (requestItems.length) {
            return updateSchedules({
                productId,
                requestBody: {
                    items: requestItems,
                },
            });
        }
        return {};
    }, options);
};
