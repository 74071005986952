/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PersonNameData } from './PersonNameData';

export type GetTeamsResItemCreatedByDto = {
    /**
     * User's occupation
     */
    type: GetTeamsResItemCreatedByDto.type;
    /**
     * Type of the capacity of the product
     */
    id: string;
    /**
     * name of the referenced user
     */
    name: PersonNameData;
};

export namespace GetTeamsResItemCreatedByDto {

    /**
     * User's occupation
     */
    export enum type {
        HUMAN = 'human',
    }


}
