import React from 'react';
import { ScheduleItem, ScheduleOptionTypes } from '@travelity/api';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Card, Tag } from '@travelity/ui';
import { format } from 'date-fns';
import ScheduleItemMinimalSkeleton from './schedule-item-minimal-skeleton';

export interface TeamItemProps {
    schedule: ScheduleItem;
    isSelected: boolean;
    isDisabled?: boolean;
    onSelect: () => void;
}

const ScheduleItemMinimal: React.FC<TeamItemProps> = ({
    schedule,
    isSelected,
    isDisabled,
    onSelect,
}) => {
    const { t } = useTranslation('product');

    return (
        <Box>
            <Card
                flexGrow={2}
                sx={{
                    px: 2,
                    py: 1,
                }}
                direction="column"
                justifyContent="space-evenly"
                parentProps={{ alignItems: 'center' }}
                leftAdornment={
                    <Stack alignItems="center">
                        <Box
                            onClick={isDisabled ? undefined : onSelect}
                            sx={{
                                mr: '14px',
                                cursor: 'pointer',
                                width: '18px',
                                height: '18px',
                                borderStyle: 'solid',
                                borderWidth: isSelected ? '6px' : '2px',
                                borderColor: isSelected ? '#6B748C' : '#B5B9C5',
                                borderRadius: '4px',
                                ...(isDisabled && { opacity: 0.5 }),
                            }}
                        />
                    </Stack>
                }
            >
                <Stack justifyContent="space-between" direction="row">
                    <Box
                        component="span"
                        sx={{
                            fontSize: 12,
                            color: '#989EAF',
                            pr: 1,
                        }}
                    >
                        {t('Name')}
                    </Box>
                    <Box
                        component="span"
                        sx={{
                            fontSize: 12,
                            color: '#2B395B',
                            pr: 1,
                        }}
                    >
                        {schedule.name}
                    </Box>
                </Stack>
                {schedule.type === ScheduleOptionTypes.CUSTOM ? (
                    <Stack justifyContent="space-between" direction="row">
                        <Box
                            component="span"
                            sx={{
                                fontSize: 12,
                                color: '#989EAF',
                                pr: 1,
                            }}
                        />
                        <Box
                            component="span"
                            sx={{
                                fontSize: 12,
                                color: '#2B395B',
                                pr: 1,
                            }}
                        >
                            {schedule.startDates?.length > 0 && (
                                <Tag
                                    label={t('Custom')}
                                    values={schedule.startDates.map((d: Date) =>
                                        format(d, 'd MMM, yyyy')
                                    )}
                                />
                            )}
                        </Box>
                    </Stack>
                ) : (
                    <Stack justifyContent="space-between" direction="row">
                        <Box
                            component="span"
                            sx={{
                                fontSize: 12,
                                color: '#989EAF',
                                pr: 1,
                            }}
                        />
                        <Stack direction="row" gap="6px">
                            {schedule.startDate && (
                                <Tag
                                    label={t('Start')}
                                    values={[
                                        format(
                                            schedule.startDate,
                                            'dd.MM.yyyy'
                                        ),
                                    ]}
                                />
                            )}
                            {schedule.endDate && (
                                <Tag
                                    label={t('End')}
                                    values={[
                                        format(schedule.endDate, 'dd.MM.yyyy'),
                                    ]}
                                />
                            )}
                        </Stack>
                    </Stack>
                )}
            </Card>
        </Box>
    );
};

export { ScheduleItemMinimalSkeleton };

export default ScheduleItemMinimal;
