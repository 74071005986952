import React, { useEffect, useMemo } from 'react';
import { TransactionMethod } from '@travelity/api';
import { CurrencyField, TagsList, useForm } from '@travelity/form';
import { Box, Paper } from '@mui/material';
import { convertEnumToList } from '@travelity/utils';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialog } from '@travelity/ui';

export interface TransactionModalProps {
    currency: string;
    handleCancel: () => void;
    isLoading?: boolean;
    availableMethods?: TransactionMethod[];
    handleConfirm: (v: { method: TransactionMethod; amount: number }) => void;
    open: boolean;
    title?: string;
    content?: string;
    confirmText?: string;
}

export const TransactionModal: React.FC<TransactionModalProps> = props => {
    const { handleConfirm, currency, availableMethods, ...other } = props;
    const { t } = useTranslation('common');

    const paymentMethodOptions = useMemo(
        () =>
            convertEnumToList<TransactionMethod>(TransactionMethod, t).filter(
                ({ value }) =>
                    !availableMethods || availableMethods.includes(value)
            ),
        [t, availableMethods]
    );

    const { Form, getValues, reset, watch } = useForm({
        isModal: true,
        mode: 'onChange',
        defaultValues: { method: undefined, amount: undefined },
    });

    const method = watch('method');
    const amount = watch('amount');

    useEffect(() => {
        reset({});
    }, [other.open]);

    return (
        <ConfirmationDialog
            {...other}
            disableConfirm={!method || !amount}
            handleConfirm={() => {
                const data = getValues();
                if (data.method && data.amount) {
                    handleConfirm(
                        data as unknown as {
                            method: TransactionMethod;
                            amount: number;
                        }
                    );
                }
            }}
        >
            <Form>
                <Paper
                    sx={{
                        mb: 2,
                        py: 1,
                        px: 2,
                        bgColor: '#F4F6FA',
                        maxWidth: '388px',
                    }}
                >
                    <Box
                        sx={{
                            color: '#2B395B',
                            fontSize: '14px',
                            width: 1,
                            justifyContent: 'space-between',
                            margin: 0,
                        }}
                    >
                        Select Payment Method
                    </Box>

                    <TagsList
                        name="method"
                        selectAll={false}
                        options={paymentMethodOptions}
                    />
                </Paper>

                <CurrencyField
                    label="Amount"
                    placeholder="Type amount"
                    name="amount"
                    currency={currency}
                />
            </Form>
        </ConfirmationDialog>
    );
};
