/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MonthlyRecurrenceData = {
    /**
     * Type of the recurrence. In case it is enabled
     */
    type: MonthlyRecurrenceData.type;
    /**
     * A list of dates when the event happens every month
     */
    days_of_month: Array<number>;
};

export namespace MonthlyRecurrenceData {

    /**
     * Type of the recurrence. In case it is enabled
     */
    export enum type {
        MONTHLY = 'monthly',
    }


}
