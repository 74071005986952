import React, { useState } from 'react';
import Timeline, { TimelineProps } from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined';
import { Box, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { RouteStop, TravelerPickUp } from '@travelity/api';
import { format, subSeconds } from 'date-fns';
import { TimelineOppositeContent } from '@mui/lab';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Dashes from './assets/dashes.svg';
import DashesGrey from './assets/dashes-grey.svg';
import { CreateRouteInput } from './components/create-route-input';
import { RoutePickUpIcon } from './components/route-pick-up-icon';
import { RouteStopIcon } from './components/route-stop-icon';
import { RouteLabel } from './components/route-label';

export interface RouteEditableProps {
    stops: RouteStop[];
    timelineProps?: Omit<Partial<TimelineProps>, 'ref' | 'sx'> & {
        sx?: SystemStyleObject<Theme>;
    };
    selected?: TravelerPickUp;
    setSelected: (v?: TravelerPickUp) => void;
}

const RouteEditable: React.FC<RouteEditableProps> = ({
    stops,
    timelineProps = {},
    selected: selectedStop,
    setSelected,
}) => {
    const [createBefore, setCreateBefore] = useState<number | null>(null);
    const [editStop, setEditStop] = useState<boolean | null>(null);
    const selected = selectedStop || createBefore !== null;
    const color = selected ? 'grey' : 'primary';
    const selectedIndex = stops.findIndex(
        ({ name }) => name === selectedStop?.name
    );
    const beginningIndex = stops.findIndex(({ pinned }) => pinned);

    return (
        <Timeline
            {...timelineProps}
            sx={{
                '&&': timelineProps.sx || {},
                [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                },
            }}
        >
            {stops.map(({ pinned, name: stop, id, time }, i) => (
                <>
                    <TimelineItem
                        key={`plus-${id || stop}${
                            i === beginningIndex ? 'start' : ''
                        }`}
                        sx={{
                            minHeight: '26px',
                        }}
                    >
                        <TimelineOppositeContent
                            sx={{
                                color: '#6B758D',
                                pt: '3px',
                                lineHeight: 1,
                                pl: 1,
                                pb: i === stops.length - 2 ? 0 : 1,
                                maxWidth: '24px',
                            }}
                        />
                        <TimelineSeparator>
                            <TimelineDot
                                color={color}
                                onClick={
                                    !selected
                                        ? () => setCreateBefore(i)
                                        : undefined
                                }
                                sx={{
                                    p: '2px',
                                    mt:
                                        i === createBefore && !selectedStop
                                            ? 1
                                            : 0.5,
                                    mb: '1.5px',
                                    boxShadow: 0,
                                    border: 0,
                                    cursor: !selected ? 'pointer' : undefined,
                                    color:
                                        createBefore !== i && selected
                                            ? 'gray'
                                            : 'primary.main',
                                    backgroundColor: 'transparent',
                                    padding: 0,
                                }}
                            >
                                <AddLocationOutlinedIcon
                                    sx={{ fontSize: '15px' }}
                                />
                            </TimelineDot>
                            <TimelineConnector
                                sx={{
                                    // borderLeft: '2px dashed #55B5CF',
                                    backgroundColor: 'transparent',
                                    backgroundSize: 'contain',
                                    backgroundImage: `url(${
                                        color === 'grey' ? DashesGrey : Dashes
                                    })`,
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent
                            sx={{
                                color: '#6B758D',
                                pt: '3px',
                                pl: 1,
                                lineHeight: 1,
                                pb: i === stops.length - 2 ? 0 : 1,
                                py:
                                    i === createBefore && !selectedStop
                                        ? 0
                                        : undefined,
                            }}
                        >
                            {i === createBefore && (
                                <CreateRouteInput
                                    start={
                                        i === 0
                                            ? subSeconds(time, 60 * 60 * 24)
                                            : stops[i - 1].time
                                    }
                                    end={time}
                                    setValue={v => {
                                        setSelected(v);
                                        setCreateBefore(null);
                                    }}
                                />
                            )}
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem
                        key={`${id || stop}${
                            i === beginningIndex ? 'start' : ''
                        }`}
                        onClick={
                            !selected && id
                                ? () => setSelected({ id, name: stop, time })
                                : undefined
                        }
                        sx={{
                            minHeight: '26px',
                            cursor: !selected && id ? 'pointer' : undefined,
                        }}
                    >
                        <TimelineOppositeContent
                            sx={{
                                color: '#6B758D',
                                pt: '4px',
                                lineHeight: 1,
                                pl: 0,
                                pb: i === stops.length - 2 ? 0 : 1,
                                maxWidth: '24px',
                            }}
                        >
                            <Tooltip
                                title={format(time, 'HH:mm')}
                                placement="left"
                                enterDelay={100}
                                enterNextDelay={100}
                                slotProps={{
                                    popper: {
                                        sx: { zIndex: 10000 },
                                    },
                                }}
                            >
                                <AccessTimeIcon sx={{ fontSize: '14px' }} />
                            </Tooltip>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            {pinned ? (
                                <RouteStopIcon
                                    selected={selectedIndex !== i && !!selected}
                                />
                            ) : (
                                <RoutePickUpIcon selected={!!selected} />
                            )}
                            {i !== stops.length - 1 && (
                                <TimelineConnector
                                    sx={{
                                        // borderLeft: '2px dashed #55B5CF',
                                        backgroundColor: 'transparent',
                                        backgroundSize: 'contain',
                                        backgroundImage: `url(${
                                            color === 'grey'
                                                ? DashesGrey
                                                : Dashes
                                        })`,
                                    }}
                                />
                            )}
                        </TimelineSeparator>
                        <TimelineContent
                            sx={{
                                color: '#6B758D',
                                pt: '3px',
                                lineHeight: 1,
                                pl: 1,
                                pb: i === stops.length - 2 ? 0 : 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '14px',
                                    p: 0,
                                }}
                            >
                                {i === selectedIndex && editStop ? (
                                    <CreateRouteInput
                                        initialValue={{ name: stop, time }}
                                        start={
                                            i === 0
                                                ? subSeconds(time, 60 * 60 * 24)
                                                : stops[i - 1].time
                                        }
                                        end={stops[i + 1].time}
                                        setValue={v => {
                                            if (v) setSelected(v);
                                            setEditStop(false);
                                        }}
                                    />
                                ) : (
                                    <>
                                        <RouteLabel
                                            label={stop}
                                            beginning={i === beginningIndex}
                                            end={i === stops.length - 1}
                                        />
                                        {i === selectedIndex && (
                                            <>
                                                {!pinned && (
                                                    <Box
                                                        sx={{
                                                            cursor: 'pointer',
                                                            verticalAlign:
                                                                'middle',
                                                        }}
                                                        onClick={() => {
                                                            setEditStop(true);
                                                        }}
                                                        component="span"
                                                    >
                                                        <EditIcon
                                                            sx={{
                                                                fontSize:
                                                                    '14px',
                                                                ml: 1.5,
                                                                color: '#BFC4CE',
                                                            }}
                                                        />
                                                    </Box>
                                                )}

                                                <Box
                                                    sx={{
                                                        cursor: 'pointer',
                                                        verticalAlign: 'middle',
                                                    }}
                                                    onClick={() =>
                                                        setSelected(undefined)
                                                    }
                                                    component="span"
                                                >
                                                    <CloseIcon
                                                        sx={{
                                                            fontSize: '14px',
                                                            ml: 1.5,
                                                            color: '#BFC4CE',
                                                        }}
                                                    />
                                                </Box>
                                            </>
                                        )}
                                    </>
                                )}
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                </>
            ))}
        </Timeline>
    );
};

export default RouteEditable;
