import { EventItem, GetEventsResItemDto } from './event.types';
import { GetEventsResItem0Dto } from '../../requests';
import { convertDurationDtoToDuration } from '../product/product.converters';
import { getAssetDtoToAsset } from '../asset/asset.converters';
import { convertNameDtoToFullName } from '../user/user.converters';

// export const eventToGetEventItemDto = (
//     event: EventItem
// ): Omit<GetEventsResItemDto, 'created'> => ({
//     id: event.id,
//     product: event.product,
//     operations: {
//         capacity_selection: event.capacitySelection,
//     },
//     date: {
//         tz_name: Intl.DateTimeFormat().resolvedOptions()
//             .timeZone as TimezoneName,
//         start: Math.round(event.date.start.getTime() / 1000),
//         end: event.date.end
//             ? Math.round(event.date.end.getTime() / 1000)
//             : undefined,
//     },
//     bookings_summary: event.bookingsSummary,
//     notes: event.notes,
//     status: event.status,
//     // available_seats: event.availableSeats,
//     // ephemeral: event.ephemeral,
// });

export const getEventItemDtoToEvent = (
    event: GetEventsResItemDto
): EventItem => ({
    id: event.id,
    product: event.product,
    capacitySelection: event.operations.capacity_selection,
    assets: event.operations.assets?.map(getAssetDtoToAsset) || [],
    // assets: [],
    staff:
        event.operations.staff?.map(({ position, reference }) => ({
            position,
            staff: {
                id: reference.id,
                fullName: reference.name
                    ? convertNameDtoToFullName(reference.name)
                    : undefined,
                email: reference.email,
            },
        })) || [],
    date: {
        duration: convertDurationDtoToDuration(event.date.duration || {}),
        start: new Date(event.date.start * 1000),
        end: event.date.end ? new Date(event.date.end * 1000) : undefined,
    },
    sharedTeams:
        event.rbac?.acl.map(({ group }) => ({
            id: group.id,
            type: group.type,
        })) || [],
    bookingsSummary: event.bookings_summary,
    notes: event.notes,
    status: event.status,
    availableSeats: (event as GetEventsResItem0Dto).available_seats,
    ephemeral: false,
    reason: event.cancelled?.message,
    lifecycle: event.lifecycle || [],
    rawDto: event,
});

export const eventsFilterToRequestBodyConverter = (
    filters: Record<string, any>
) => ({
    startTs: filters?.dates?.startDate
        ? Math.round(filters.dates.startDate.getTime() / 1000)
        : undefined,
    endTs: filters?.dates?.endDate
        ? Math.round(filters.dates.endDate.getTime() / 1000)
        : undefined,
    productId: filters.products,
});
