import React, { useEffect, useState } from 'react';
import { Alert, Box, IconButton, Stack, Typography } from '@mui/material';
import { Select, useForm } from '@travelity/form';
import { useNavigate } from 'react-router-dom';
import { string, object } from 'yup';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@travelity/ui';

export const productTypeOptions = [
    {
        label: 'Transfer',
        value: 'transfer',
    },
    {
        label: 'Tour',
        value: 'tour',
    },
];

const schema = object({
    type: string().required(),
});

const enabled = false;

function AddProduct() {
    const navigate = useNavigate();
    const {
        Form,
        watch,
        formState: { errors },
    } = useForm({
        onSubmit: ({ type }) => {
            navigate(`/products/add/${type}`, { replace: true });
        },
        mode: 'onChange',
        schema,
        validateInitially: true,
    });

    const type = watch('type');

    const [infoClosed, setInfoClosed] = useState(false);
    const [info, setInfo] = useState<React.ReactElement>();
    useEffect(() => {
        if (type === 'transfer') {
            setInfo(
                <>
                    <Box sx={{ fontWeight: 'bold' }}>Transfer</Box>
                    Transfer is a private transportation service.
                </>
            );
        } else if (type === 'tour') {
            setInfo(
                <>
                    <Box sx={{ fontWeight: 'bold' }}>Tour</Box>
                    Tour is a ready-to-be-booked product that has a pre-defined
                    schedule and an itinerary. Tour can be booked by
                    unrelated/random travelers
                </>
            );
        } else {
            setInfo(
                <>
                    <Box sx={{ fontWeight: 'bold' }}>Product Type</Box>
                    To know what features to enable for the product you are
                    creating, Travelity needs to know the product’s type.
                    <br />
                    Please select from the list in the dropdown.
                </>
            );
        }
    }, [type]);

    return (
        <Box
            sx={{
                bgcolor: '#fff',
                mt: 2,
                ml: 2,
                height: 'calc(100% - 16px)',
                '> form': { height: 1 },
            }}
        >
            <Form>
                <Stack
                    sx={{ height: 1 }}
                    gap={2}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography sx={{ color: '#C0C4CE', fontSize: '24px' }}>
                        What type of product are you creating?
                    </Typography>
                    <Select
                        options={productTypeOptions}
                        name="type"
                        label=""
                        width="464px"
                        placeholder="Select Product Type"
                    />
                    <Stack direction="row" spacing={1}>
                        <Button
                            color="neutral"
                            variant="contained"
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </Button>

                        <Button
                            color="secondary"
                            variant="contained"
                            type="submit"
                            requiredFields={
                                errors.type ? ['product type'] : undefined
                            }
                        >
                            Next
                        </Button>
                    </Stack>
                </Stack>
            </Form>

            {!infoClosed && info && enabled && (
                <Alert
                    variant="outlined"
                    severity="info"
                    icon={<TipsAndUpdatesIcon sx={{ color: '#EECC52' }} />}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => setInfoClosed(true)}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{
                        mt: 2,
                        width: '416px',
                        position: 'absolute',
                        right: '30px',
                        top: '74px',
                        bgcolor: '#FFF',
                    }}
                >
                    {info}
                </Alert>
            )}
        </Box>
    );
}

export default AddProduct;
