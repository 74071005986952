/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ActivityType {
    CREATION = 'creation',
    UPDATE = 'update',
    DELETION = 'deletion',
    ARCHIVAL = 'archival',
    CANCELLATION = 'cancellation',
}
