import { array, mixed, object, string } from 'yup';
import { Country, Nationality } from '@travelity/api';

export const schema = object().shape({
    fullName: string().when(['$customer'], ([customer], sch) => {
        return customer ? sch.required() : sch;
    }),
    emails: array().when(['$customer'], ([customer], sch) => {
        if (customer)
            return sch.min(1, 'At least 1 email is required').required();
        return sch;
    }),
    numbers: array(),
    nationality: mixed()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr?.toLowerCase()))
        .oneOf(Object.values(Nationality)),
    birthPlace: string(),
    birthDate: string()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .matches(
            /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d+$/,
            'Is not in correct format'
        )
        .when(
            ['nationality', 'birthPlace'],
            ([nationality, birthPlace], sch) => {
                return nationality || birthPlace
                    ? sch.required('Birth date is required')
                    : sch;
            }
        ),
    passportN: string().when(
        ['citizenship', 'issuedBy', 'issuedAt', 'expiresAt'],
        ([citizenship, issuedBy, issuedAt, expiresAt], sch) => {
            return citizenship || issuedBy || issuedAt || expiresAt
                ? sch.required('Passport number is required')
                : sch;
        }
    ),
    issuedBy: string(),
    issuedAt: string()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .matches(
            /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d+$/,
            'Is not in correct format'
        ),
    expiresAt: string()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .matches(
            /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d+$/,
            'Is not in correct format'
        ),
    citizenship: mixed()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr?.toLowerCase()))
        .oneOf(Object.values(Country)),
});
