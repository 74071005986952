/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PricingType {
    PER_PERSON = 'per_person',
    PER_PRODUCT = 'per_product',
    PER_AGEBAND = 'per_ageband',
}
