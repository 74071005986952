import React from 'react';
import { Paper, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { EventTraveler } from '@travelity/api';
import BookingTravelerCard from './components/booking-traveler-card';

export interface BookingParticipantsFormProps {
    eventId: string;
}

const BookingParticipantsForm: React.FC<BookingParticipantsFormProps> = ({
    eventId,
}) => {
    const { watch } = useFormContext();
    const travelers = watch('travelers') as EventTraveler[];

    return (
        <Stack spacing={2}>
            {!!travelers?.length && (
                <Paper
                    sx={{
                        mt: 2,
                        p: 2,
                        height: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {travelers?.map(traveler => (
                        <BookingTravelerCard
                            key={traveler.id}
                            traveler={traveler}
                            eventId={eventId}
                        />
                    ))}
                </Paper>
            )}
        </Stack>
    );
};

export default React.memo(BookingParticipantsForm);
