/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ScheduleType {
    RECURRING = 'recurring',
    NON_RECURRING = 'non_recurring',
}
