import React, { useCallback, useState } from 'react';
import {
    Alert,
    Box,
    Divider,
    IconButton,
    Skeleton,
    Stack,
    Tooltip,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PriceSummary as IPriceSummary } from '@travelity/api';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { capitalizeFirstLetter } from '@travelity/api/generator/common';
import WarningIcon from '@mui/icons-material/Warning';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { PriceItem } from './components/price-item';
import { Summary } from './components/summary';

export interface PriceSummaryProps {
    price?: Partial<IPriceSummary>;
    isLoading: boolean;
    priceChanged?: boolean;
    updatePrice?: () => void;
}

function numberWithSpaces(x: number) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

const Title = styled('div')(() => ({
    color: '#2B395B',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '11px',
    paddingTop: 12,
    paddingBottom: 12,
}));

const SubTitle = styled('div')(() => ({
    color: '#2B395B',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '11px',
    paddingTop: 12,
    paddingBottom: 12,
}));

export const PriceSummary: React.FC<PriceSummaryProps> = props => {
    const { price, isLoading, priceChanged, updatePrice } = props;
    const [open, setOpen] = useState(false);

    const toggleOpen = useCallback(() => {
        setOpen(prevState => !prevState);
    }, []);

    const finalPrice =
        price?.final || price?.final === 0
            ? `${numberWithSpaces(price.final)} ${price.currency}`
            : '';

    const theme = useTheme();

    return (
        <>
            {priceChanged && (
                <Alert
                    severity="warning"
                    icon={
                        <WarningIcon
                            sx={{
                                color: '#EC8031',
                                fontSize: '24px',
                            }}
                        />
                    }
                    action={
                        <IconButton
                            onClick={updatePrice}
                            sx={{ color: '#3B4D7D' }}
                            size="small"
                        >
                            <RestartAltIcon />
                        </IconButton>
                    }
                    sx={{
                        alignItems: 'center',
                        color: '#2B395B',
                        my: 1,
                        '.MuiAlert-action': { pt: 0 },
                    }}
                >
                    Price has changed. Please{' '}
                    <Box
                        component="span"
                        onClick={updatePrice}
                        sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: '#EC8031',
                        }}
                    >
                        refresh
                    </Box>
                </Alert>
            )}
            <Box
                sx={{
                    overflow: 'hidden',
                    position: 'relative',
                    bgcolor: '#F4F6FA',
                    borderRadius: '8px',
                    minHeight: '60px',
                    pl: 2,
                    pr: 0.5,
                    pt: 3,
                }}
            >
                <Tooltip
                    title={open ? 'Collapse' : 'Expand'}
                    placement="top"
                    slotProps={{
                        tooltip: {
                            sx: {
                                '&&&': { mb: '-14px' },
                            },
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '-24px',
                            left: 'calc(50% - 24px)',
                            border: '4px solid #FFF',
                            width: '48px',
                            height: '48px',
                            borderRadius: '50%',
                            textAlign: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={toggleOpen}
                    >
                        {open ? (
                            <KeyboardArrowDownIcon
                                sx={{
                                    mt: '17px',
                                    color: '#959CAD',
                                    fontSize: '24px',
                                }}
                            />
                        ) : (
                            <KeyboardArrowUpIcon
                                sx={{
                                    mt: '17px',
                                    color: '#959CAD',
                                    fontSize: '24px',
                                }}
                            />
                        )}
                    </Box>
                </Tooltip>
                <Box
                    sx={{
                        transition: theme.transitions.create('height', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                        maxHeight: 1,
                        pr: 1.5,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    component={PerfectScrollbar}
                >
                    {open && (
                        <>
                            {!!price?.main && (
                                <>
                                    <Title>Main Product Price</Title>
                                    <PriceItem
                                        currency={price.currency || ''}
                                        isLoading={isLoading}
                                        price={price.main}
                                    />
                                </>
                            )}
                            {!!price?.options?.length && (
                                <Box sx={{ mb: 1 }}>
                                    <Divider sx={{ my: 1 }} />
                                    <Title sx={{ pb: 0.5 }}>
                                        Product Options’ Price
                                    </Title>
                                    {price.options.map(option =>
                                        option.price ? (
                                            <>
                                                <SubTitle>
                                                    {option.name} (
                                                    {capitalizeFirstLetter(
                                                        option.type
                                                    )}
                                                    )
                                                </SubTitle>
                                                <PriceItem
                                                    currency={
                                                        price.currency || ''
                                                    }
                                                    isLoading={isLoading}
                                                    price={option.price}
                                                />
                                            </>
                                        ) : undefined
                                    )}
                                </Box>
                            )}
                            {!!price?.discount && !!price.beforeDiscount && (
                                <Box>
                                    <Divider sx={{ my: 1 }} />
                                    <SubTitle>Summary</SubTitle>
                                    <Summary
                                        currency={price?.currency || ''}
                                        before={price?.beforeDiscount}
                                        after={price.final as number}
                                        discountAmount={price?.discount?.amount}
                                        discountType={price?.discount?.type}
                                        isLoading={isLoading}
                                    />
                                </Box>
                            )}
                            <Divider sx={{ my: 1 }} />
                        </>
                    )}

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ mt: 1, mb: 1.5 }}
                    >
                        <Box
                            sx={{
                                color: '#6B748C',
                                fontSize: '15px',
                                fontWeight: '500',
                            }}
                        >
                            {price?.beforeDiscount !== price?.final
                                ? 'Total after discount'
                                : 'Total'}
                        </Box>
                        <Box
                            sx={{
                                color: '#2B395B',
                                textAlign: 'right',
                                fontSize: '15px',
                                fontWeight: '500',
                            }}
                        >
                            {isLoading ? <Skeleton width={60} /> : finalPrice}
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </>
    );
};
