/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Language } from './Language';
import type { ProductTag } from './ProductTag';
import type { UpdateProductResAccessDto } from './UpdateProductResAccessDto';
import type { UpdateProductResCapacityDto } from './UpdateProductResCapacityDto';
import type { UpdateProductResConfigurationDto } from './UpdateProductResConfigurationDto';
import type { UpdateProductResOptionsDto } from './UpdateProductResOptionsDto';
import type { UpdateProductResPricingDto } from './UpdateProductResPricingDto';
import type { UpdateProductResRouteDto } from './UpdateProductResRouteDto';
import type { UpdateProductResScheduleDto } from './UpdateProductResScheduleDto';
import type { UpdateUserResLastUpdatedDto } from './UpdateUserResLastUpdatedDto';

export type UpdateProductResDto = {
    /**
     * Type of the resource
     */
    resource_type?: UpdateProductResDto.resource_type;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: UpdateUserResLastUpdatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: UpdateUserResLastUpdatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<UpdateUserResLastUpdatedDto>;
    /**
     * Name of the product.
     */
    name?: string;
    /**
     * Detailed textual description of the product.
     */
    description?: string;
    /**
     * Tags that characterize the product
     */
    tags?: Array<ProductTag>;
    /**
     * Type of the product
     */
    type?: UpdateProductResDto.type;
    /**
     * Indicates whether the product is active or not
     */
    active?: boolean;
    /**
     * Defines the number and groups of people that can be served by a unit of product
     */
    configuration?: UpdateProductResConfigurationDto;
    /**
     * Defines the number and groups of people that can be served by a unit of product
     */
    capacity?: UpdateProductResCapacityDto;
    /**
     * Defines the recurrent and non-recurrent items of the schedule
     */
    schedule?: UpdateProductResScheduleDto;
    /**
     * Financial information of the product.
     */
    pricing?: UpdateProductResPricingDto;
    /**
     * Optional items and services for this product
     */
    options?: UpdateProductResOptionsDto;
    /**
     * Access to the shared product
     */
    access?: UpdateProductResAccessDto;
    /**
     * Languages in which the product/service is provided
     */
    languages: Array<Language>;
    /**
     * Route of the tour/transfer
     */
    route?: UpdateProductResRouteDto;
};

export namespace UpdateProductResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        PRODUCT = 'product',
    }

    /**
     * Type of the product
     */
    export enum type {
        TOUR = 'tour',
    }


}
