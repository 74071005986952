import { useAuth0 } from '@auth0/auth0-react';
import {
    useQuery,
    useInfiniteQuery,
    useMutation,
    UseQueryOptions,
    UseInfiniteQueryOptions,
    UseMutationOptions,
} from '@tanstack/react-query';
import {
    UploadAvatarUsersReqDto,
    UploadAvatarUsersReq1Dto,
    UpdateUserReqDto,
    UpdateTravelersBookingsReqDto,
    UpdateProductReqDto,
    UpdateProductReq1Dto,
    UpdateProduct1ReqDto,
    UpdateProduct1Req1Dto,
    UpdateProduct11ReqDto,
    UpdateProduct111ReqDto,
    UpdateProduct1111ReqDto,
    UpdateOrderReqDto,
    UpdateMemberTeamsReqDto,
    UpdateCustomerReqDto,
    UpdateCapacityProductsReqDto,
    UpdateCapacityProducts1ReqDto,
    UpdateBookingReqDto,
    UpdateBookingReq1Dto,
    UpdateBooking111ReqDto,
    SetTravelersParticipationBookingsReqDto,
    SetTravelerPickupInfoBookingsReqDto,
    RemoveTravelersBookingsReqDto,
    RegisterUsersReqDto,
    ProductType,
    PaxData,
    InviteMemberTeamsReqDto,
    DiscardTransactionBookingsReqDto,
    CurrencySetting,
    CreateTeamReqDto,
    CreateProductReqDto,
    CreateProductReq1Dto,
    CreateOrderReqDto,
    CreateManySchedulesReqDto,
    CreateManyCapacitiesReqDto,
    CreateEventReqDto,
    CreateEventReq1Dto,
    CreateCustomerReqDto,
    CreateBookingReqDto,
    CreateBookingReq1Dto,
    CreateAssetReqDto,
    ComputePriceBookingsReqDto,
    ComputePriceBookingsReq1Dto,
    ComputeAvailabilityReqDto,
    CancelOrdersReqDto,
    CancelEventsReqDto,
    CancelBookingsReqDto,
    AddTravelersBookingsReqDto,
    AddTransactionBookingsReqDto,
    AddAssetEventsReqDto,
    UsersService,
    TeamsService,
    SchedulesService,
    ProjectService,
    ProductsService,
    OrdersService,
    EventsService,
    CustomersService,
    CapacitiesService,
    BookingsService,
    AvailabilityService,
    AssetsService,
} from '../requests';

export const useRegisterUsers = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.registerUsers>>,
            unknown,
            {
                requestBody: RegisterUsersReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return UsersService.registerUsers(authorization, requestBody);
    }, options);
};
export const useGetUserKey = 'UsersServiceGetUser';
export const useGetUser = <TQueryKey extends Array<unknown> = unknown[]>(
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof UsersService.getUser>>,
            unknown,
            Awaited<ReturnType<typeof UsersService.getUser>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetUserKey, ...(queryKey ?? [])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return UsersService.getUser(authorization);
        },
        options
    );
};
export const useUpdateUser = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.updateUser>>,
            unknown,
            {
                requestBody: UpdateUserReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return UsersService.updateUser(authorization, requestBody);
    }, options);
};
export const useUploadAvatarUsers = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.uploadAvatarUsers>>,
            unknown,
            {
                requestBody: UploadAvatarUsersReqDto | UploadAvatarUsersReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return UsersService.uploadAvatarUsers(authorization, requestBody);
    }, options);
};
export const useUpdateCurrencyUsers = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.updateCurrencyUsers>>,
            unknown,
            {
                requestBody: CurrencySetting;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return UsersService.updateCurrencyUsers(authorization, requestBody);
    }, options);
};
export const useGetTeamsKey = 'TeamsServiceGetTeams';
export const useGetTeams = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        teamId,
        memberEmail,
        memberName,
        company,
    }: {
        teamId?: Array<string>;
        memberEmail?: string;
        memberName?: string;
        company?: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof TeamsService.getTeams>>,
            unknown,
            Awaited<ReturnType<typeof TeamsService.getTeams>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [
            useGetTeamsKey,
            ...(queryKey ?? [{ teamId, memberEmail, memberName, company }]),
        ],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return TeamsService.getTeams(
                authorization,
                teamId,
                memberEmail,
                memberName,
                company
            );
        },
        options
    );
};
export const useCreateTeam = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof TeamsService.createTeam>>,
            unknown,
            {
                requestBody: CreateTeamReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return TeamsService.createTeam(authorization, requestBody);
    }, options);
};
export const useInviteMemberTeams = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof TeamsService.inviteMemberTeams>>,
            unknown,
            {
                teamId: string;
                requestBody: InviteMemberTeamsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ teamId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return TeamsService.inviteMemberTeams(
            teamId,
            authorization,
            requestBody
        );
    }, options);
};
export const useAcceptInvitationTeams = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof TeamsService.acceptInvitationTeams>>,
            unknown,
            {
                teamId: string;
                key: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ teamId, key }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return TeamsService.acceptInvitationTeams(teamId, key, authorization);
    }, options);
};
export const useRemoveMemberTeams = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof TeamsService.removeMemberTeams>>,
            unknown,
            {
                teamId: string;
                email: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ teamId, email }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return TeamsService.removeMemberTeams(teamId, email, authorization);
    }, options);
};
export const useUpdateMemberTeams = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof TeamsService.updateMemberTeams>>,
            unknown,
            {
                teamId: string;
                email: string;
                requestBody: UpdateMemberTeamsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ teamId, email, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return TeamsService.updateMemberTeams(
            teamId,
            email,
            authorization,
            requestBody
        );
    }, options);
};
export const useGetSchedulesKey = 'SchedulesServiceGetSchedules';
export const useGetSchedules = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        ids,
        pageNumber,
        pageSize,
    }: {
        ids?: Array<string>;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof SchedulesService.getSchedules>>,
            unknown,
            Awaited<ReturnType<typeof SchedulesService.getSchedules>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetSchedulesKey, ...(queryKey ?? [{ ids, pageNumber, pageSize }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return SchedulesService.getSchedules(
                authorization,
                ids,
                pageNumber,
                pageSize
            );
        },
        options
    );
};
export const useGetSchedulesLazyLazyKey = 'SchedulesServiceGetSchedules';
export const useGetSchedulesLazy = <TQueryKey extends Array<any> = any[]>(
    {
        ids,
        pageNumber,
        pageSize = 20,
    }: {
        ids?: Array<string>;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseInfiniteQueryOptions<
            Awaited<ReturnType<typeof SchedulesService.getSchedules>>,
            unknown,
            Awaited<ReturnType<typeof SchedulesService.getSchedules>>,
            Awaited<ReturnType<typeof SchedulesService.getSchedules>>,
            any[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useInfiniteQuery(
        [
            useGetSchedulesLazyLazyKey,
            ...(queryKey ?? [{ ids, pageNumber, pageSize }]),
        ],
        {
            queryFn: async ({ pageParam = 0 }) => {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return SchedulesService.getSchedules(
                    authorization,
                    ids,
                    pageParam,
                    pageSize
                );
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.items || lastPage.items.length < pageSize)
                    return undefined;
                return allPages.length;
            },
            ...options,
        }
    );
};
export const useCreateManySchedules = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof SchedulesService.createManySchedules>>,
            unknown,
            {
                requestBody: CreateManySchedulesReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return SchedulesService.createManySchedules(authorization, requestBody);
    }, options);
};
export const useGetProjectKey = 'ProjectServiceGetProject';
export const useGetProject = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        subscriptionKey,
    }: {
        subscriptionKey: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof ProjectService.getProject>>,
            unknown,
            Awaited<ReturnType<typeof ProjectService.getProject>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetProjectKey, ...(queryKey ?? [{ subscriptionKey }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return ProjectService.getProject(subscriptionKey);
        },
        options
    );
};
export const useGetProductsKey = 'ProductsServiceGetProducts';
export const useGetProducts = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        type,
        name,
        startTs,
        endTs,
        currency,
        minPrice,
        maxPrice,
        location,
        groupId,
        pageNumber,
        pageSize,
    }: {
        type?: ProductType;
        name?: string;
        startTs?: number;
        endTs?: number;
        currency?: string;
        minPrice?: number;
        maxPrice?: number;
        location?: string;
        groupId?: Array<string>;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof ProductsService.getProducts>>,
            unknown,
            Awaited<ReturnType<typeof ProductsService.getProducts>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [
            useGetProductsKey,
            ...(queryKey ?? [
                {
                    type,
                    name,
                    startTs,
                    endTs,
                    currency,
                    minPrice,
                    maxPrice,
                    location,
                    groupId,
                    pageNumber,
                    pageSize,
                },
            ]),
        ],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return ProductsService.getProducts(
                authorization,
                type,
                name,
                startTs,
                endTs,
                currency,
                minPrice,
                maxPrice,
                location,
                groupId,
                pageNumber,
                pageSize
            );
        },
        options
    );
};
export const useGetProductsLazyLazyKey = 'ProductsServiceGetProducts';
export const useGetProductsLazy = <TQueryKey extends Array<any> = any[]>(
    {
        type,
        name,
        startTs,
        endTs,
        currency,
        minPrice,
        maxPrice,
        location,
        groupId,
        pageNumber,
        pageSize = 20,
    }: {
        type?: ProductType;
        name?: string;
        startTs?: number;
        endTs?: number;
        currency?: string;
        minPrice?: number;
        maxPrice?: number;
        location?: string;
        groupId?: Array<string>;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseInfiniteQueryOptions<
            Awaited<ReturnType<typeof ProductsService.getProducts>>,
            unknown,
            Awaited<ReturnType<typeof ProductsService.getProducts>>,
            Awaited<ReturnType<typeof ProductsService.getProducts>>,
            any[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useInfiniteQuery(
        [
            useGetProductsLazyLazyKey,
            ...(queryKey ?? [
                {
                    type,
                    name,
                    startTs,
                    endTs,
                    currency,
                    minPrice,
                    maxPrice,
                    location,
                    groupId,
                    pageNumber,
                    pageSize,
                },
            ]),
        ],
        {
            queryFn: async ({ pageParam = 0 }) => {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return ProductsService.getProducts(
                    authorization,
                    type,
                    name,
                    startTs,
                    endTs,
                    currency,
                    minPrice,
                    maxPrice,
                    location,
                    groupId,
                    pageParam,
                    pageSize
                );
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.items || lastPage.items.length < pageSize)
                    return undefined;
                return allPages.length;
            },
            ...options,
        }
    );
};
export const useCreateProduct = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.createProduct>>,
            unknown,
            {
                requestBody: CreateProductReqDto | CreateProductReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.createProduct(authorization, requestBody);
    }, options);
};
export const useGetProducts1Key = 'ProductsServiceGetProducts1';
export const useGetProducts1 = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        type,
        name,
        startTs,
        endTs,
        currency,
        minPrice,
        maxPrice,
        location,
        groupId,
        pageNumber,
        pageSize,
    }: {
        type?: ProductType;
        name?: string;
        startTs?: number;
        endTs?: number;
        currency?: string;
        minPrice?: number;
        maxPrice?: number;
        location?: string;
        groupId?: Array<string>;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof ProductsService.getProducts1>>,
            unknown,
            Awaited<ReturnType<typeof ProductsService.getProducts1>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [
            useGetProducts1Key,
            ...(queryKey ?? [
                {
                    type,
                    name,
                    startTs,
                    endTs,
                    currency,
                    minPrice,
                    maxPrice,
                    location,
                    groupId,
                    pageNumber,
                    pageSize,
                },
            ]),
        ],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return ProductsService.getProducts1(
                authorization,
                type,
                name,
                startTs,
                endTs,
                currency,
                minPrice,
                maxPrice,
                location,
                groupId,
                pageNumber,
                pageSize
            );
        },
        options
    );
};
export const useGetProducts1LazyLazyKey = 'ProductsServiceGetProducts1';
export const useGetProducts1Lazy = <TQueryKey extends Array<any> = any[]>(
    {
        type,
        name,
        startTs,
        endTs,
        currency,
        minPrice,
        maxPrice,
        location,
        groupId,
        pageNumber,
        pageSize = 20,
    }: {
        type?: ProductType;
        name?: string;
        startTs?: number;
        endTs?: number;
        currency?: string;
        minPrice?: number;
        maxPrice?: number;
        location?: string;
        groupId?: Array<string>;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseInfiniteQueryOptions<
            Awaited<ReturnType<typeof ProductsService.getProducts1>>,
            unknown,
            Awaited<ReturnType<typeof ProductsService.getProducts1>>,
            Awaited<ReturnType<typeof ProductsService.getProducts1>>,
            any[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useInfiniteQuery(
        [
            useGetProducts1LazyLazyKey,
            ...(queryKey ?? [
                {
                    type,
                    name,
                    startTs,
                    endTs,
                    currency,
                    minPrice,
                    maxPrice,
                    location,
                    groupId,
                    pageNumber,
                    pageSize,
                },
            ]),
        ],
        {
            queryFn: async ({ pageParam = 0 }) => {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return ProductsService.getProducts1(
                    authorization,
                    type,
                    name,
                    startTs,
                    endTs,
                    currency,
                    minPrice,
                    maxPrice,
                    location,
                    groupId,
                    pageParam,
                    pageSize
                );
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.items || lastPage.items.length < pageSize)
                    return undefined;
                return allPages.length;
            },
            ...options,
        }
    );
};
export const useUpdateProduct = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateProduct>>,
            unknown,
            {
                productId: string;
                requestBody: UpdateProductReqDto | UpdateProductReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateProduct(
            productId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateProduct1 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateProduct1>>,
            unknown,
            {
                productId: string;
                requestBody: UpdateProduct1ReqDto | UpdateProduct1Req1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateProduct1(
            productId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateCapacityProducts = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateCapacityProducts>>,
            unknown,
            {
                productId: string;
                requestBody: UpdateCapacityProductsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateCapacityProducts(
            productId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateCapacityProducts1 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateCapacityProducts1>>,
            unknown,
            {
                productId: string;
                requestBody: UpdateCapacityProducts1ReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateCapacityProducts1(
            productId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateProduct11 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateProduct11>>,
            unknown,
            {
                productId: string;
                requestBody: UpdateProduct11ReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateProduct11(
            productId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateProduct111 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateProduct111>>,
            unknown,
            {
                productId: string;
                requestBody: UpdateProduct111ReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateProduct111(
            productId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateProduct1111 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateProduct1111>>,
            unknown,
            {
                productId: string;
                requestBody: UpdateProduct1111ReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateProduct1111(
            productId,
            authorization,
            requestBody
        );
    }, options);
};
export const useActivateProducts = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.activateProducts>>,
            unknown,
            {
                productId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.activateProducts(productId, authorization);
    }, options);
};
export const useDeactivateProducts = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.deactivateProducts>>,
            unknown,
            {
                productId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.deactivateProducts(productId, authorization);
    }, options);
};
export const useGetProductKey = 'ProductsServiceGetProduct';
export const useGetProduct = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        productId,
    }: {
        productId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof ProductsService.getProduct>>,
            unknown,
            Awaited<ReturnType<typeof ProductsService.getProduct>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetProductKey, ...(queryKey ?? [{ productId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return ProductsService.getProduct(productId, authorization);
        },
        options
    );
};
export const useDeleteProduct = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.deleteProduct>>,
            unknown,
            {
                productId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.deleteProduct(productId, authorization);
    }, options);
};
export const useGenerateNameProductsKey = 'ProductsServiceGenerateNameProducts';
export const useGenerateNameProducts = <
    TQueryKey extends Array<unknown> = unknown[]
>(
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof ProductsService.generateNameProducts>>,
            unknown,
            Awaited<ReturnType<typeof ProductsService.generateNameProducts>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGenerateNameProductsKey, ...(queryKey ?? [])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return ProductsService.generateNameProducts(authorization);
        },
        options
    );
};
export const useUpdateProduct11111 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateProduct11111>>,
            unknown,
            {
                productId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateProduct11111(productId, authorization);
    }, options);
};
export const useGetOrdersKey = 'OrdersServiceGetOrders';
export const useGetOrders = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        customerId,
        customerName,
        orderStatus,
        pageNumber,
        pageSize,
    }: {
        customerId?: string;
        customerName?: string;
        orderStatus?: string;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof OrdersService.getOrders>>,
            unknown,
            Awaited<ReturnType<typeof OrdersService.getOrders>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [
            useGetOrdersKey,
            ...(queryKey ?? [
                { customerId, customerName, orderStatus, pageNumber, pageSize },
            ]),
        ],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.getOrders(
                authorization,
                customerId,
                customerName,
                orderStatus,
                pageNumber,
                pageSize
            );
        },
        options
    );
};
export const useGetOrdersLazyLazyKey = 'OrdersServiceGetOrders';
export const useGetOrdersLazy = <TQueryKey extends Array<any> = any[]>(
    {
        customerId,
        customerName,
        orderStatus,
        pageNumber,
        pageSize = 20,
    }: {
        customerId?: string;
        customerName?: string;
        orderStatus?: string;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseInfiniteQueryOptions<
            Awaited<ReturnType<typeof OrdersService.getOrders>>,
            unknown,
            Awaited<ReturnType<typeof OrdersService.getOrders>>,
            Awaited<ReturnType<typeof OrdersService.getOrders>>,
            any[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useInfiniteQuery(
        [
            useGetOrdersLazyLazyKey,
            ...(queryKey ?? [
                { customerId, customerName, orderStatus, pageNumber, pageSize },
            ]),
        ],
        {
            queryFn: async ({ pageParam = 0 }) => {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return OrdersService.getOrders(
                    authorization,
                    customerId,
                    customerName,
                    orderStatus,
                    pageParam,
                    pageSize
                );
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.items || lastPage.items.length < pageSize)
                    return undefined;
                return allPages.length;
            },
            ...options,
        }
    );
};
export const useCreateOrder = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof OrdersService.createOrder>>,
            unknown,
            {
                requestBody: CreateOrderReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return OrdersService.createOrder(authorization, requestBody);
    }, options);
};
export const useGetOrderKey = 'OrdersServiceGetOrder';
export const useGetOrder = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        orderId,
    }: {
        orderId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof OrdersService.getOrder>>,
            unknown,
            Awaited<ReturnType<typeof OrdersService.getOrder>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetOrderKey, ...(queryKey ?? [{ orderId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.getOrder(orderId, authorization);
        },
        options
    );
};
export const useUpdateOrder = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof OrdersService.updateOrder>>,
            unknown,
            {
                orderId: string;
                requestBody: UpdateOrderReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ orderId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return OrdersService.updateOrder(orderId, authorization, requestBody);
    }, options);
};
export const useCancelOrders = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof OrdersService.cancelOrders>>,
            unknown,
            {
                orderId: string;
                requestBody: CancelOrdersReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ orderId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return OrdersService.cancelOrders(orderId, authorization, requestBody);
    }, options);
};
export const useExportDetailsOrdersKey = 'OrdersServiceExportDetailsOrders';
export const useExportDetailsOrders = <
    TQueryKey extends Array<unknown> = unknown[]
>(
    {
        orderId,
    }: {
        orderId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof OrdersService.exportDetailsOrders>>,
            unknown,
            Awaited<ReturnType<typeof OrdersService.exportDetailsOrders>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useExportDetailsOrdersKey, ...(queryKey ?? [{ orderId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.exportDetailsOrders(orderId, authorization);
        },
        options
    );
};
export const useGetEventsKey = 'EventsServiceGetEvents';
export const useGetEvents = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        startTs,
        endTs,
        productId,
        pageNumber,
        pageSize,
    }: {
        startTs?: number;
        endTs?: number;
        productId?: Array<string>;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof EventsService.getEvents>>,
            unknown,
            Awaited<ReturnType<typeof EventsService.getEvents>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [
            useGetEventsKey,
            ...(queryKey ?? [
                { startTs, endTs, productId, pageNumber, pageSize },
            ]),
        ],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return EventsService.getEvents(
                authorization,
                startTs,
                endTs,
                productId,
                pageNumber,
                pageSize
            );
        },
        options
    );
};
export const useGetEventsLazyLazyKey = 'EventsServiceGetEvents';
export const useGetEventsLazy = <TQueryKey extends Array<any> = any[]>(
    {
        startTs,
        endTs,
        productId,
        pageNumber,
        pageSize = 20,
    }: {
        startTs?: number;
        endTs?: number;
        productId?: Array<string>;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseInfiniteQueryOptions<
            Awaited<ReturnType<typeof EventsService.getEvents>>,
            unknown,
            Awaited<ReturnType<typeof EventsService.getEvents>>,
            Awaited<ReturnType<typeof EventsService.getEvents>>,
            any[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useInfiniteQuery(
        [
            useGetEventsLazyLazyKey,
            ...(queryKey ?? [
                { startTs, endTs, productId, pageNumber, pageSize },
            ]),
        ],
        {
            queryFn: async ({ pageParam = 0 }) => {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return EventsService.getEvents(
                    authorization,
                    startTs,
                    endTs,
                    productId,
                    pageParam,
                    pageSize
                );
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.items || lastPage.items.length < pageSize)
                    return undefined;
                return allPages.length;
            },
            ...options,
        }
    );
};
export const useCreateEvent = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof EventsService.createEvent>>,
            unknown,
            {
                requestBody: CreateEventReqDto | CreateEventReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return EventsService.createEvent(authorization, requestBody);
    }, options);
};
export const useCancelEvents = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof EventsService.cancelEvents>>,
            unknown,
            {
                eventId: string;
                requestBody: CancelEventsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ eventId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return EventsService.cancelEvents(eventId, authorization, requestBody);
    }, options);
};
export const useRemoveStaffMemberEvents = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof EventsService.removeStaffMemberEvents>>,
            unknown,
            {
                eventId: string;
                email: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ eventId, email }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return EventsService.removeStaffMemberEvents(
            eventId,
            email,
            authorization
        );
    }, options);
};
export const useAddAssetEvents = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof EventsService.addAssetEvents>>,
            unknown,
            {
                eventId: string;
                requestBody: AddAssetEventsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ eventId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return EventsService.addAssetEvents(
            eventId,
            authorization,
            requestBody
        );
    }, options);
};
export const useRemoveAssetEvents = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof EventsService.removeAssetEvents>>,
            unknown,
            {
                eventId: string;
                assetId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ eventId, assetId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return EventsService.removeAssetEvents(eventId, assetId, authorization);
    }, options);
};
export const useGetCustomerKey = 'CustomersServiceGetCustomer';
export const useGetCustomer = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        name,
        email,
        phoneNumber,
        pageNumber,
        pageSize,
    }: {
        name?: string;
        email?: string;
        phoneNumber?: string;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof CustomersService.getCustomer>>,
            unknown,
            Awaited<ReturnType<typeof CustomersService.getCustomer>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [
            useGetCustomerKey,
            ...(queryKey ?? [
                { name, email, phoneNumber, pageNumber, pageSize },
            ]),
        ],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CustomersService.getCustomer(
                authorization,
                name,
                email,
                phoneNumber,
                pageNumber,
                pageSize
            );
        },
        options
    );
};
export const useGetCustomerLazyLazyKey = 'CustomersServiceGetCustomer';
export const useGetCustomerLazy = <TQueryKey extends Array<any> = any[]>(
    {
        name,
        email,
        phoneNumber,
        pageNumber,
        pageSize = 20,
    }: {
        name?: string;
        email?: string;
        phoneNumber?: string;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseInfiniteQueryOptions<
            Awaited<ReturnType<typeof CustomersService.getCustomer>>,
            unknown,
            Awaited<ReturnType<typeof CustomersService.getCustomer>>,
            Awaited<ReturnType<typeof CustomersService.getCustomer>>,
            any[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useInfiniteQuery(
        [
            useGetCustomerLazyLazyKey,
            ...(queryKey ?? [
                { name, email, phoneNumber, pageNumber, pageSize },
            ]),
        ],
        {
            queryFn: async ({ pageParam = 0 }) => {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return CustomersService.getCustomer(
                    authorization,
                    name,
                    email,
                    phoneNumber,
                    pageParam,
                    pageSize
                );
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.items || lastPage.items.length < pageSize)
                    return undefined;
                return allPages.length;
            },
            ...options,
        }
    );
};
export const useCreateCustomer = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof CustomersService.createCustomer>>,
            unknown,
            {
                requestBody: CreateCustomerReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return CustomersService.createCustomer(authorization, requestBody);
    }, options);
};
export const useGetCustomer1Key = 'CustomersServiceGetCustomer1';
export const useGetCustomer1 = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        customerId,
    }: {
        customerId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof CustomersService.getCustomer1>>,
            unknown,
            Awaited<ReturnType<typeof CustomersService.getCustomer1>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetCustomer1Key, ...(queryKey ?? [{ customerId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CustomersService.getCustomer1(customerId, authorization);
        },
        options
    );
};
export const useUpdateCustomer = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof CustomersService.updateCustomer>>,
            unknown,
            {
                customerId: string;
                requestBody: UpdateCustomerReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ customerId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return CustomersService.updateCustomer(
            customerId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUploadAttachmentCustomers = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof CustomersService.uploadAttachmentCustomers>
            >,
            unknown,
            {
                customerId: string;
                requestBody: UploadAvatarUsersReqDto | UploadAvatarUsersReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ customerId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return CustomersService.uploadAttachmentCustomers(
            customerId,
            authorization,
            requestBody
        );
    }, options);
};
export const useRemoveAttachmentCustomers = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof CustomersService.removeAttachmentCustomers>
            >,
            unknown,
            {
                customerId: string;
                attachmentId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ customerId, attachmentId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return CustomersService.removeAttachmentCustomers(
            customerId,
            attachmentId,
            authorization
        );
    }, options);
};
export const useGetCapacitiesKey = 'CapacitiesServiceGetCapacities';
export const useGetCapacities = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        ids,
        pageNumber,
        pageSize,
    }: {
        ids?: Array<string>;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof CapacitiesService.getCapacities>>,
            unknown,
            Awaited<ReturnType<typeof CapacitiesService.getCapacities>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetCapacitiesKey, ...(queryKey ?? [{ ids, pageNumber, pageSize }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CapacitiesService.getCapacities(
                authorization,
                ids,
                pageNumber,
                pageSize
            );
        },
        options
    );
};
export const useGetCapacitiesLazyLazyKey = 'CapacitiesServiceGetCapacities';
export const useGetCapacitiesLazy = <TQueryKey extends Array<any> = any[]>(
    {
        ids,
        pageNumber,
        pageSize = 20,
    }: {
        ids?: Array<string>;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseInfiniteQueryOptions<
            Awaited<ReturnType<typeof CapacitiesService.getCapacities>>,
            unknown,
            Awaited<ReturnType<typeof CapacitiesService.getCapacities>>,
            Awaited<ReturnType<typeof CapacitiesService.getCapacities>>,
            any[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useInfiniteQuery(
        [
            useGetCapacitiesLazyLazyKey,
            ...(queryKey ?? [{ ids, pageNumber, pageSize }]),
        ],
        {
            queryFn: async ({ pageParam = 0 }) => {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return CapacitiesService.getCapacities(
                    authorization,
                    ids,
                    pageParam,
                    pageSize
                );
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.items || lastPage.items.length < pageSize)
                    return undefined;
                return allPages.length;
            },
            ...options,
        }
    );
};
export const useCreateManyCapacities = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof CapacitiesService.createManyCapacities>>,
            unknown,
            {
                requestBody: CreateManyCapacitiesReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return CapacitiesService.createManyCapacities(
            authorization,
            requestBody
        );
    }, options);
};
export const useGetBookingsKey = 'BookingsServiceGetBookings';
export const useGetBookings = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        orderNumber,
        eventId,
        customerName,
        customerEmail,
        customerPhoneNumber,
        productType,
        productName,
        startTs,
        endTs,
        location,
        pageNumber,
        pageSize,
    }: {
        orderNumber?: string;
        eventId?: string;
        customerName?: string;
        customerEmail?: string;
        customerPhoneNumber?: string;
        productType?: ProductType;
        productName?: string;
        startTs?: number;
        endTs?: number;
        location?: string;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof BookingsService.getBookings>>,
            unknown,
            Awaited<ReturnType<typeof BookingsService.getBookings>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [
            useGetBookingsKey,
            ...(queryKey ?? [
                {
                    orderNumber,
                    eventId,
                    customerName,
                    customerEmail,
                    customerPhoneNumber,
                    productType,
                    productName,
                    startTs,
                    endTs,
                    location,
                    pageNumber,
                    pageSize,
                },
            ]),
        ],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.getBookings(
                authorization,
                orderNumber,
                eventId,
                customerName,
                customerEmail,
                customerPhoneNumber,
                productType,
                productName,
                startTs,
                endTs,
                location,
                pageNumber,
                pageSize
            );
        },
        options
    );
};
export const useGetBookingsLazyLazyKey = 'BookingsServiceGetBookings';
export const useGetBookingsLazy = <TQueryKey extends Array<any> = any[]>(
    {
        orderNumber,
        eventId,
        customerName,
        customerEmail,
        customerPhoneNumber,
        productType,
        productName,
        startTs,
        endTs,
        location,
        pageNumber,
        pageSize = 20,
    }: {
        orderNumber?: string;
        eventId?: string;
        customerName?: string;
        customerEmail?: string;
        customerPhoneNumber?: string;
        productType?: ProductType;
        productName?: string;
        startTs?: number;
        endTs?: number;
        location?: string;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseInfiniteQueryOptions<
            Awaited<ReturnType<typeof BookingsService.getBookings>>,
            unknown,
            Awaited<ReturnType<typeof BookingsService.getBookings>>,
            Awaited<ReturnType<typeof BookingsService.getBookings>>,
            any[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useInfiniteQuery(
        [
            useGetBookingsLazyLazyKey,
            ...(queryKey ?? [
                {
                    orderNumber,
                    eventId,
                    customerName,
                    customerEmail,
                    customerPhoneNumber,
                    productType,
                    productName,
                    startTs,
                    endTs,
                    location,
                    pageNumber,
                    pageSize,
                },
            ]),
        ],
        {
            queryFn: async ({ pageParam = 0 }) => {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return BookingsService.getBookings(
                    authorization,
                    orderNumber,
                    eventId,
                    customerName,
                    customerEmail,
                    customerPhoneNumber,
                    productType,
                    productName,
                    startTs,
                    endTs,
                    location,
                    pageParam,
                    pageSize
                );
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.items || lastPage.items.length < pageSize)
                    return undefined;
                return allPages.length;
            },
            ...options,
        }
    );
};
export const useCreateBooking = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.createBooking>>,
            unknown,
            {
                requestBody: CreateBookingReqDto | CreateBookingReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.createBooking(authorization, requestBody);
    }, options);
};
export const useGetBookingKey = 'BookingsServiceGetBooking';
export const useGetBooking = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        bookingId,
    }: {
        bookingId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof BookingsService.getBooking>>,
            unknown,
            Awaited<ReturnType<typeof BookingsService.getBooking>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetBookingKey, ...(queryKey ?? [{ bookingId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.getBooking(bookingId, authorization);
        },
        options
    );
};
export const useUpdateBooking = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.updateBooking>>,
            unknown,
            {
                bookingId: string;
                requestBody: UpdateBookingReqDto | UpdateBookingReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.updateBooking(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useComputePriceBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.computePriceBookings>>,
            unknown,
            {
                requestBody:
                    | ComputePriceBookingsReqDto
                    | ComputePriceBookingsReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.computePriceBookings(authorization, requestBody);
    }, options);
};
export const useCancelBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.cancelBookings>>,
            unknown,
            {
                bookingId: string;
                requestBody: CancelBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.cancelBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useAddTransactionBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.addTransactionBookings>>,
            unknown,
            {
                bookingId: string;
                requestBody: AddTransactionBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.addTransactionBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useDiscardTransactionBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof BookingsService.discardTransactionBookings>
            >,
            unknown,
            {
                bookingId: string;
                transactionId: string;
                requestBody: DiscardTransactionBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, transactionId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.discardTransactionBookings(
            bookingId,
            transactionId,
            authorization,
            requestBody
        );
    }, options);
};
export const useHoldAvailabilityBookingsKey =
    'BookingsServiceHoldAvailabilityBookings';
export const useHoldAvailabilityBookings = <
    TQueryKey extends Array<unknown> = unknown[]
>(
    {
        bookingId,
    }: {
        bookingId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<
                ReturnType<typeof BookingsService.holdAvailabilityBookings>
            >,
            unknown,
            Awaited<
                ReturnType<typeof BookingsService.holdAvailabilityBookings>
            >,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useHoldAvailabilityBookingsKey, ...(queryKey ?? [{ bookingId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.holdAvailabilityBookings(
                bookingId,
                authorization
            );
        },
        options
    );
};
export const useReserveBookingsKey = 'BookingsServiceReserveBookings';
export const useReserveBookings = <
    TQueryKey extends Array<unknown> = unknown[]
>(
    {
        bookingId,
    }: {
        bookingId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof BookingsService.reserveBookings>>,
            unknown,
            Awaited<ReturnType<typeof BookingsService.reserveBookings>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useReserveBookingsKey, ...(queryKey ?? [{ bookingId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.reserveBookings(bookingId, authorization);
        },
        options
    );
};
export const useAddTravelersBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.addTravelersBookings>>,
            unknown,
            {
                bookingId: string;
                requestBody: AddTravelersBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.addTravelersBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateTravelersBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.updateTravelersBookings>>,
            unknown,
            {
                bookingId: string;
                requestBody: UpdateTravelersBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.updateTravelersBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useRemoveTravelersBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.removeTravelersBookings>>,
            unknown,
            {
                bookingId: string;
                requestBody: RemoveTravelersBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.removeTravelersBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useSetTravelersParticipationBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof BookingsService.setTravelersParticipationBookings
                >
            >,
            unknown,
            {
                bookingId: string;
                requestBody: SetTravelersParticipationBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.setTravelersParticipationBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateBooking1 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.updateBooking1>>,
            unknown,
            {
                bookingId: string;
                eventId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, eventId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.updateBooking1(
            bookingId,
            eventId,
            authorization
        );
    }, options);
};
export const useUpdateBooking11 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.updateBooking11>>,
            unknown,
            {
                bookingId: string;
                requestBody: PaxData;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.updateBooking11(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateBooking111 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.updateBooking111>>,
            unknown,
            {
                bookingId: string;
                requestBody: UpdateBooking111ReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.updateBooking111(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useSetTravelerPickupInfoBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof BookingsService.setTravelerPickupInfoBookings>
            >,
            unknown,
            {
                bookingId: string;
                travelerId: string;
                requestBody: SetTravelerPickupInfoBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, travelerId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.setTravelerPickupInfoBookings(
            bookingId,
            travelerId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUnsetTravelerPickupInfoBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof BookingsService.unsetTravelerPickupInfoBookings
                >
            >,
            unknown,
            {
                bookingId: string;
                travelerId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, travelerId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.unsetTravelerPickupInfoBookings(
            bookingId,
            travelerId,
            authorization
        );
    }, options);
};
export const useComputeAvailability = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof AvailabilityService.computeAvailability>>,
            unknown,
            {
                requestBody: ComputeAvailabilityReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return AvailabilityService.computeAvailability(
            authorization,
            requestBody
        );
    }, options);
};
export const useGetAssetsKey = 'AssetsServiceGetAssets';
export const useGetAssets = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        type,
        byOwner,
        byAssignee,
        id,
        email,
        name,
        usagePeriodStart,
        usagePeriodEnd,
        vehicleLicensePlate,
        vehicleMake,
        vehicleModel,
        vehicleMinYear,
        vehicleMaxYear,
    }: {
        type?: string;
        byOwner?: boolean;
        byAssignee?: boolean;
        id?: string;
        email?: string;
        name?: string;
        usagePeriodStart?: number;
        usagePeriodEnd?: number;
        vehicleLicensePlate?: string;
        vehicleMake?: string;
        vehicleModel?: string;
        vehicleMinYear?: string;
        vehicleMaxYear?: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof AssetsService.getAssets>>,
            unknown,
            Awaited<ReturnType<typeof AssetsService.getAssets>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [
            useGetAssetsKey,
            ...(queryKey ?? [
                {
                    type,
                    byOwner,
                    byAssignee,
                    id,
                    email,
                    name,
                    usagePeriodStart,
                    usagePeriodEnd,
                    vehicleLicensePlate,
                    vehicleMake,
                    vehicleModel,
                    vehicleMinYear,
                    vehicleMaxYear,
                },
            ]),
        ],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return AssetsService.getAssets(
                authorization,
                type,
                byOwner,
                byAssignee,
                id,
                email,
                name,
                usagePeriodStart,
                usagePeriodEnd,
                vehicleLicensePlate,
                vehicleMake,
                vehicleModel,
                vehicleMinYear,
                vehicleMaxYear
            );
        },
        options
    );
};
export const useCreateAsset = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof AssetsService.createAsset>>,
            unknown,
            {
                requestBody: CreateAssetReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return AssetsService.createAsset(authorization, requestBody);
    }, options);
};
