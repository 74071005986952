import { array, object, string } from 'yup';
import { ProductType } from '@travelity/api';

const schema = object({
    name: string().required('Product Name').max(50, 'Product Name is too long'),
    description: string().required('Description'),
    languages: array().when(['$type'], ([type], sch) => {
        return type === ProductType.TOUR
            ? sch
                  .of(string())
                  .min(1, 'At least one selected language')
                  .required('At least one selected language')
            : sch.notRequired();
    }),
});

export default schema;
