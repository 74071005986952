/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum TransferEventStaffMemberPosition {
    ADMINISTRATOR = 'administrator',
    DRIVER = 'driver',
}
