import React from 'react';
import { Typography } from '@mui/material';

export interface RouteLabelProps {
    label: string;
    beginning?: boolean;
    end?: boolean;
}

export const RouteLabel: React.FC<RouteLabelProps> = ({
    label,
    beginning,
    end,
}) => {
    return (
        <>
            {label}
            {beginning && (
                <Typography
                    sx={{
                        fontSize: '12px',
                        color: '#C0C4CE',
                        p: 0,
                        pl: 1,
                    }}
                    component="span"
                >
                    Beginning of route
                </Typography>
            )}
            {end && (
                <Typography
                    sx={{
                        fontSize: '12px',
                        color: '#C0C4CE',
                        mt: 1,
                        p: 0,
                        pl: 1,
                    }}
                    component="span"
                >
                    End of route
                </Typography>
            )}
        </>
    );
};
