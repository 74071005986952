import {
    useMutation,
    UseMutationOptions,
    useQueryClient,
} from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useGetBookings } from '../../queries';
import { Booking, PaxData } from './booking.types';
import {
    BookingsService,
    CreateEventReqOperationsDto,
    EventsService,
    GetBookingsResDto,
    type UpdateBookingReqProductOptionsItemDto,
} from '../../requests';
import {
    getBookingDtoToBooking,
    bookingToGetBookingDto,
} from './booking.converters';
import { useUpdateOrderBooking } from '../order/order.hooks';
import { useUpdateEventBooking } from '../event/event.hooks';
import { EventItem } from '../event/event.types';

type UseGetBookingsParamTypes = Parameters<typeof useGetBookings>;
export const useBookings = (params: UseGetBookingsParamTypes[0] = {}) => {
    const queryKey = ['BookingsServiceGetBookings', params];
    const { data, ...other } = useGetBookings(params, [params]);

    const queryClient = useQueryClient();
    const update = (bookingId: string, booking: Booking) => {
        queryClient.setQueryData<GetBookingsResDto>(queryKey, bookings => {
            if (!bookings?.items) return undefined;
            const items = bookings.items.map(b =>
                b.id === bookingId
                    ? { ...b, ...bookingToGetBookingDto(booking) }
                    : b
            );
            return { ...bookings, items };
        });
    };

    const parsedData = useMemo(
        () => data?.items?.map(getBookingDtoToBooking),
        [data]
    );

    return {
        update,
        data: parsedData,
        ...other,
    };
};

export const useHoldBookingAvailability = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof BookingsService.holdAvailabilityBookings>
            >,
            unknown,
            { bookingId: string },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.holdAvailabilityBookings(
            bookingId,
            authorization
        );
    }, options);
};

export const useReserveBooking = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.reserveBookings>>,
            unknown,
            { bookingId: string },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.reserveBookings(bookingId, authorization);
    }, options);
};

export const useBooking = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.getBooking>>,
            unknown,
            {
                id: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const updateOrderBooking = useUpdateOrderBooking();
    const updateEventBooking = useUpdateEventBooking();

    return useMutation(
        async ({ id }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.getBooking(id, authorization);
        },
        {
            onSuccess: booking => {
                updateOrderBooking(booking.id, () => booking);
                updateEventBooking(booking.event.id, booking.id, () => booking);
            },
            ...options,
        }
    );
};

export const useUpdatePax = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.updateBooking11>>,
            unknown,
            { bookingId: string; pax: PaxData; eventId: string },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const updateOrderBooking = useUpdateOrderBooking();
    const updateEventBooking = useUpdateEventBooking();

    return useMutation(
        async ({ bookingId, pax }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.updateBooking11(
                bookingId,
                authorization,
                pax
            );
        },
        {
            ...options,
            onSuccess: (...args) => {
                options?.onSuccess?.(...args);
                const { rbac, ...booking } = args[0];
                const params = args[1];
                updateOrderBooking(params.bookingId, b => ({
                    ...b,
                    ...booking,
                    // @ts-ignore
                    product: {
                        ...b.product,
                        ...booking.product,
                    },
                }));
                updateEventBooking(params.eventId, params.bookingId, b => ({
                    ...b,
                    ...booking,
                    // @ts-ignore
                    product: {
                        ...b.product,
                        ...booking.product,
                    },
                }));
            },
        }
    );
};

export const useUpdateOptions = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.updateBooking11>>,
            unknown,
            {
                bookingId: string;
                productOptions: UpdateBookingReqProductOptionsItemDto[];
                eventId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const updateOrderBooking = useUpdateOrderBooking();
    const updateEventBooking = useUpdateEventBooking();

    return useMutation(
        async ({ bookingId, productOptions }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.updateBooking111(bookingId, authorization, {
                options: productOptions,
            });
        },
        {
            ...options,
            onSuccess: (...args) => {
                options?.onSuccess?.(...args);
                const { rbac, ...booking } = args[0];
                const params = args[1];
                updateOrderBooking(params.bookingId, b => ({
                    ...b,
                    ...booking,
                    // @ts-ignore
                    product: {
                        ...b.product,
                        ...booking.product,
                    },
                }));
                updateEventBooking(params.eventId, params.bookingId, b => ({
                    ...b,
                    ...booking,
                    // @ts-ignore
                    product: {
                        ...b.product,
                        ...booking.product,
                    },
                }));
            },
        }
    );
};

export const useMoveBooking = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.updateBooking1>>,
            unknown,
            {
                bookingId: string;
                event: EventItem;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, event }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;

        let eventId = event.id;
        if (!eventId) {
            const newEvent = await EventsService.createEvent(authorization, {
                date: event.rawDto.date,
                operations: event.rawDto
                    .operations as CreateEventReqOperationsDto,
                product: {
                    id: event.product.id,
                    type: event.product.type,
                },
                status: event.rawDto.status,
            });
            eventId = newEvent.id;
        }

        return BookingsService.updateBooking1(
            bookingId,
            eventId,
            authorization
        );
    }, options);
};
